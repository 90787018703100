import React, { useEffect, useState } from 'react';
import { Row, Col, Breadcrumb, Spin } from 'antd';
import { useHistory } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getUserName,
  getUserList,
  getClientid
} from 'redux/selectors';
import { find, get, map } from 'lodash';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import Document from 'molecules/Document';
import { CALL_API } from 'common/API';
import { DEVICE } from 'common/Device';

const StyledDocumentContainer = styled.div`
  padding-top: 15px;
`;

const StyledDocumentPageTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 0px;
`;

const StyledTotalDocument = styled.div`
  color: grey;
`;

const StyledDocumentRow = styled(Row)`
  padding-top: 15px;
  @media ${DEVICE.tablet}  {
    .ant-col-xs-24, .ant-col-sm-24 {
      margin-bottom: 20px;
    }
  }
`;

function Documents({
  userName,
  userList,
  userRoleId,
  departmentUserList,
  selectedDepartmentId,
  ClientId
}) {
  const [ selectedStaffName, setSelectedStaffName ] = useState('');
  const [ documentList, setDocumentList ] = useState([]);
  const [ isLoadingDocuments, toggleLoadingDocuments ] = useState(false);
  const { id: staffId } = useParams();
  let history = useHistory();
  
  useEffect(() => {
    setSelectedStaffName(get(find(userList, { user_id: parseInt(staffId) }), 'name'));
    getDocumentList();
  }, []);

  const getDocumentList = async () => {
    toggleLoadingDocuments(true);
    const { list } = await CALL_API(`upload-document/${ClientId}`, 'get', {
      method: 'document-list',
      staffId
    });
    setDocumentList(list);
    toggleLoadingDocuments(false);
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a href="" onClick={() => history.push(`/team`)}>Team</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Documents</Breadcrumb.Item>
      </Breadcrumb>
      <StyledDocumentContainer>
        <StyledDocumentPageTitle>
          {selectedStaffName}'s documents
        </StyledDocumentPageTitle>
        <StyledTotalDocument>
         {documentList.length} documents
        </StyledTotalDocument>
        <Spin spinning={isLoadingDocuments}>
          <StyledDocumentRow gutter={24}>
            {
              map(documentList, ({document_id, document_name, created_at}, index) => (
                <Col md={8} sm={12} xs={24} lg={6} xl={4} key={index}>
                  <Document
                    id={document_id}
                    name={document_name}
                    staffName={selectedStaffName} 
                    uploadTime={created_at}
                  />
                </Col>
              ))
            }
          </StyledDocumentRow>
        </Spin>
      </StyledDocumentContainer>
    </>
  );
}

const mapStateToProps = state => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(Documents);
