import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Form, Input, Select, message } from "antd";
import styled from "styled-components";
import { CALL_API } from "common/API";
import {
  getClientid,
  getUserName,
  getUserInfo,
  getTrailPeriod,
  getCurrentPlan,
  getSubscriptionId,
  getSubscriptionStatus,
} from "redux/selectors";
import {
  setCurrentPlan,
  setSubscriptionId,
  setSubscriptionStatus,
} from "redux/actions";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { STATUS_CODE } from "common/Constants";
import { bindActionCreators } from "redux";

const StyledContainer = styled.div`
  width: 100%;
  background: red;
  color: #fff;
  .trialperiod {
    color: yellow;
  }
  .upgrade {
    color: #000;
  }
`;
const PlanWrap = styled(Row)`
  .title {
    font-size: 26px;
    font-weight: bold;
    color: #3759a7;
    /* margin-bottom: 5%; */
  }
  .subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #3759a7;
    margin-bottom: 5%;
  }
  .styled-box {
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    /* margin: 5px; */
  }
  .black-title {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .styledbtn {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #ee2738;
    font-weight: bold;
    background: #f8f9fa;
    border: 2px solid #ee2738;
    border-radius: 30px;
    opacity: 1;
    padding-bottom: 5px;
  }
  .styledbtn-active {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #00aeff;
    font-weight: bold;
    background: #f8f9fa;
    border: 2px solid #00aeff;
    border-radius: 30px;
    opacity: 1;
    padding-bottom: 5px;
  }
  .proceed-btn {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: bold;
    background: #13337e 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    text-align: center;
    vertical-align: center;
  }
  .styled-div {
    width: 100%;
    text-align: center !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const StyledButton = styled(Button)`
background-color: #26A4FF;
border-radius: 5px;
color: white;
:focus{
  border:none;
  box-shadow: none;
  background-color: #26A4FF;
  color:white;
}
:hover{
  border:none;
  box-shadow: none;
  background-color: #26A4FF;
  color:white;
}
`;
const StyledplanModal = styled(Modal)`
  .title {
    font-size: 26px;
    font-weight: bold;
    color: #3759a7;
    /* margin-bottom: 5%; */
  }
  .subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #3759a7;
    margin-bottom: 5%;
  }
  .styled-box {
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    margin: 5px;
  }
  .black-title {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
  }
  .styledbtn {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #ee2738;
    font-weight: bold;
    background: #f8f9fa;
    border: 2px solid #ee2738;
    border-radius: 30px;
    opacity: 1;
    padding-bottom: 5px;
  }
  .styledbtn-active {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #00aeff;
    font-weight: bold;
    background: #f8f9fa;
    border: 2px solid #00aeff;
    border-radius: 30px;
    opacity: 1;
    padding-bottom: 5px;
  }
  .proceed-btn {
    /* font-size: 16px; */
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: bold;
    background: #13337e 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    text-align: center;
    vertical-align: center;
  }
  .styled-div {
    width: 100%;
    text-align: center !important;
    margin-top: 10px;
    /* margin-bottom: 10px; */
  }
`;

const Subscription = ({
  showplan,
  setshowplan,
  saveSubscriptionId,
  saveSubscriptionStatus,
  ClientId,
  userName,
  userInfo,
  TrailPeriod,
  CurrentPlan,
  subscriptionId,
  subscriptionStatus,
  saveCurrentPlan,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const [plan, setPlan] = useState(0);
  const [loading,setloading] = useState(false);
  const [planName, setPlanName] = useState("Start Up");
  const [stripeModalVisible, setStripeModalVisible] = useState(false);
  const stripePromise = stripeModalVisible
    ? loadStripe(
        "pk_test_51OFBIjF2reQ5Y8ZnwHYNbjLrmvPRdeaBO07aAno1YRwjI8npiqboLStodFFCMB0BP6Ain5kfsmHwoAG8yoUtQiDl00l99byquD"
      )
    : "";
  const subscriptionCurrentStatus = subscriptionStatus !== "";
  const isFreePlan = CurrentPlan === "Free";
  const isTrailPeriod = TrailPeriod !== "";
  const showUpgradeButton = isFreePlan || (isTrailPeriod && !subscriptionCurrentStatus);

  const paymentdata = [
    {
      id: 1,
      amount: "$9",
      plan: "Start Up",
      users: 20,
    },
    {
      id: 2,
      amount: "$15",
      plan: "Business",
      users: 50,
    },
    {
      id: 3,
      amount: "$35",
      plan: "Enterprise",
      users: 100,
    },
  ];

  let getCurrentPlanId;
  for (let i = 0; i < paymentdata.length; i++) {
    if (paymentdata[i].plan === CurrentPlan) {
      getCurrentPlanId = paymentdata[i].id;
      break;
    }
  }

  const fetchMasterData = async () => {
    let fetch_master_data = true;
    const { code, currentPlan } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveCurrentPlan(currentPlan);
      }
    }
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    const { subscriptionId, subscriptionStatus } = await CALL_API(
      `payment/subscriptiondetails/${ClientId}`,
      "get"
    );
    saveSubscriptionId(subscriptionId);
    saveSubscriptionStatus(subscriptionStatus);
  };

  const handleregister = async () => {
    setloading(true);
    if (showplan) {
      if (subscriptionId === "" || subscriptionStatus !== "active") {
        const { clientSecret } = await CALL_API(
          `payment/subscriptioncreate/${ClientId}`,
          "post",
          {
            userId: userInfo.userid,
            name: userName,
            subscriptionPlan: planName,
          }
        );
        setClientSecret(clientSecret);
        setStripeModalVisible(true);
        setloading(false);
      } else {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        const { clientSecret } = await CALL_API(
          `payment/changeplan/${ClientId}`,
          "post",
          {
            userId: userInfo.userid,
            subscriptionPlan: planName,
          }
        );
        setClientSecret(clientSecret);
        setshowplan(false);
        message.success("Plan has been Changed");
        await delay(100);
        fetchMasterData();
        getSubscriptionDetails();
      }
    }
  };

  const appearance = {
    theme: "stripe",
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#aab7c4",
      },
    },
  };
  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <>
      {/* {CurrentPlan === "Free" || (TrailPeriod != "" && subscriptionCurrentStatus === false) ?( <Button className="btn btn-primary me-2" onClick={() => setshowplan(true)}>Upgrade Plan</Button>):
    (<Button className="btn btn-primary me-2" onClick={() => setshowplan(true)}>Change Plan</Button>)} */}

      <StyledButton
        className="btn btn-primary me-2"
        onClick={() => setshowplan(true)}
      >
        {subscriptionStatus === "active" ? "Change Plan" : "Upgrade Plan"}
      </StyledButton>
      <StyledplanModal
        open={showplan}
        width={700}
        footer={null}
        onCancel={() => {
          setshowplan(false);
        }}
      >
        <Row>
          <Col xs={24} className="title">
            Select a plan
          </Col>
          <Col xs={24} className="subtitle mb-1 pb-0">
            Pricing plans
          </Col>
        </Row>

        <Row>
          {paymentdata.map((index) => (
            <Col
              className=" mt-0"
              xs={24}
              md={CurrentPlan === "Free" || TrailPeriod != "" ? 8 : 12}
              key={index.id}
            >
              <div className="styled-box">
                <p className="title mb-0 pb-0">
                  {index.amount}
                  <span className="subtitle mb-0 pb-0">{index.plan}</span>
                </p>
                <p className="black-title mt-0">Per month</p>
                <h5 className="subtitle">{index.users} Users</h5>
                <div className="styled-div">
                  {subscriptionId === "" ? (
                    <Button
                      className={
                        (plan === index.id
                          ? "styledbtn-active mt-2"
                          : "styledbtn mt-2") +
                        (index.id < getCurrentPlanId ? "disabled" : "")
                      }
                      onClick={() => {
                        if (!(index.id < getCurrentPlanId)) {
                          setPlan(index.id);
                          setPlanName(index.plan);
                        }
                      }}
                      disabled={index.id < getCurrentPlanId}
                    >
                      Select
                    </Button>
                  ) : (
                    <Button
                      className={
                        (plan === index.id
                          ? "styledbtn-active mt-2"
                          : "styledbtn mt-2") +
                        (index.id <= getCurrentPlanId ? "disabled" : "")
                      }
                      onClick={() => {
                        if (!(index.id <= getCurrentPlanId)) {
                          setPlan(index.id);
                          setPlanName(index.plan);
                        }
                      }}
                      disabled={index.id <= getCurrentPlanId}
                    >
                      Select
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <div className="styled-div">
          <Button className="proceed-btn" onClick={handleregister} loading={loading}>
            Proceed
          </Button>
        </div>

        <StyledplanModal
          open={stripeModalVisible}
          footer={null}
          onCancel={() => {
            setStripeModalVisible(false);
          }}
        >
          {" "}
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                planName={planName}
                setshowplan={setshowplan}
                getSubscriptionDetails={getSubscriptionDetails}
                setStripeModalVisible={setStripeModalVisible}
              />
            </Elements>
          )}
        </StyledplanModal>
      </StyledplanModal>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCurrentPlan: setCurrentPlan,
      saveSubscriptionId: setSubscriptionId,
      saveSubscriptionStatus: setSubscriptionStatus,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userName: getUserName(state),
  userInfo: getUserInfo(state),
  TrailPeriod: getTrailPeriod(state),
  CurrentPlan: getCurrentPlan(state),
  subscriptionId: getSubscriptionId(state),
  subscriptionStatus: getSubscriptionStatus(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
