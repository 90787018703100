import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Modal,
  message,
  Button,
  DatePicker,
  Switch,
  Checkbox,
} from "antd";
import Table from "atoms/Table";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import {
  getUserRoleId,
  getClientid,
  getLocations,
  getMenuList,
  getallHolidayList,
} from "redux/selectors";
import { connect } from "react-redux";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import EditHoliday from "organisms/EditHoliday";
import moment from "moment";
import { getMySqlDate, momentDate, numberOfDaysBetweenDates } from "utils/Date";
import BasicInformation from "molecules/BasicInformation";
import StepsProgress from "organisms/StepsProgress";
import SelectTable from "molecules/SelectTable";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "organisms/FormModal";
import InReview from "molecules/InReview";
import { isEmpty, orderBy } from "lodash";
import { bindActionCreators } from "redux";
import { setAllHolidayList } from "redux/actions";

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const Holiday = ({ ClientId, locationList, menuList, userRoleId, allHolidayList, saveHolidayList }) => {
  const [displayholidayLoader, setDisplayHolidayLoader] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [displayAddHolidayModal, toggleAddHolidayModal] = useState(false);
  const [displayEditHolidayModal, toggleEditHolidayModal] = useState(false);
  const [HolidayDetails, setHolidayDetails] = useState({});
  const [deactivateHoliday, toggledeactivateHoliday] = useState(false);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [confirmdeactivateHoliday, toggleconfirmdeactivateHoliday] =
    useState(false);
  const [Holidayid, setHolidayid] = useState();
  const selectref = useRef(null);
  const currentdate = momentDate(new Date());
  const currentYear = moment(currentdate.selectedYear).year();
  const [startdate, setStartDate] = useState(
    moment({ year: currentYear, month: 0, day: 1 }).format("YYYY-MM-DD")
  );
  const [enddate, setEndDate] = useState(
    moment({ year: currentYear, month: 11, day: 31 }).format("YYYY-MM-DD")
  );


  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const onChange = (date) => {
    if (date) {
      const selectedYear = moment(date).year(); 
      const startDate = moment({ year: selectedYear, month: 0, day: 1 }).format("YYYY-MM-DD");
      const endDate = moment({ year: selectedYear, month: 11, day: 31 }).format("YYYY-MM-DD"); 
      setStartDate(startDate); 
      setEndDate(endDate); 
    }
  };

  useEffect(() => {
    getHolidayList();
  }, [setHolidayList, startdate, enddate]);

  useEffect(() => {
    setHolidayList(allHolidayList);
  }, [allHolidayList]);

  const getHolidayList = async () => {
    try {
      setDisplayHolidayLoader(true);
      const response = await CALL_API(`holiday-list/${ClientId}`, "post", {
        start_date: moment(startdate).format("yyyy-MM-DD"),
        end_date: moment(enddate).format("yyyy-MM-DD"),
      });
      if(!isEmpty(response)){
        saveHolidayList(orderBy(response, ["start_date"], ["asc"]))
      }
      setDisplayHolidayLoader(false);
    } catch (error) {
      console.log("[getHolidayList], Error-->", error);
    }
  };

  const ViewHoliday = (HolidayObject, isViewOnly = false) => {
    setHolidayid(HolidayObject.holiday_id);
    toggleEditHolidayModal(true);
    setHolidayDetails({
      ...HolidayObject,
      isViewOnly,
    });
  };
  const deletedHoliday = () => {
    toggledeactivateHoliday(true);
  };
  const deleteHoliday = async (Holiday_id) => {
    togglebtnLoader(true);
    const { code } = await CALL_API(
      `holiday/${ClientId}/${Holiday_id}`,
      "delete",
      {
        statusid: "0",
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      togglebtnLoader(false);
      toggledeactivateHoliday(false);
      toggleconfirmdeactivateHoliday(true);
      toggleEditHolidayModal(false);
      getHolidayList();
    }
  };

  const columns = [
    {
      title: "Holiday Name",
      dataIndex: "holiday_name",
      key: "HolidayName",
      width: "15%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "Start Date",
      width: "15%",
      render: (_, record) => {
        return moment(record?.start_date).format("DD-MM-yyyy");
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "15%",
      render: (_, record) => {
        return moment(record?.end_date).format("DD-MM-yyyy");
      },
    },
    {
      title: "No of Days",
      dataIndex: "end_date",
      key: "end_date",
      width: "15%",
      render: (_, record) => {
        return numberOfDaysBetweenDates(record?.start_date, record?.end_date) + 1;
      },
    },
    {
      title: "No of Location",
      dataIndex: "no_of_location",
      key: "no_of_location",
      width: "15%",
    },
  ];

  // ----add holiday----

  const [step, setStep] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [create, setCreate] = useState(false);
  const [values, setValues] = useState("");
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectAllChecked(selectedLocationId.length === locationList.length);
  }, [selectedLocationId, locationList]);

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Location" },
    { id: 3, title: "Review" },
  ];

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllChecked || selectedLocationId.includes(location_id)
            }
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
  };

  const handleSelectAllLocation = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const allLocationIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allLocationIds : []);
  };

  const onFinish = async () => {
    const { name, start_date, end_date } = values;
    setLoading(true);
    const { code } = await CALL_API(`holiday/${ClientId}`, "post", {
      locationid: selectedLocationId,
      holidayname: name,
      startdate: getMySqlDate(start_date),
      enddate: getMySqlDate(end_date),
      color: "#EB4747",
    });
    if (code === STATUS_CODE.SUCCESS) {
      message.success("Successfully Added");
      getHolidayList();
      setValues({});
      setSelectedLocationId([]);
      setLoading(false);
      setCreate(false);
      setStep(0);
      toggleAddHolidayModal(false);
    }
  };

  useEffect(() => {
    if (create) {
      onFinish();
    }
  }, [create]);

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };
  return (
    <>
      {!displayAddHolidayModal && !displayEditHolidayModal && (
        <>
        <Row className="page-title">
            <Col sm={24} md={12} style={headerstyle}>
              List of Holiday
            </Col>
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "addbreak" && subItem?.permission.create
                )
            ) && (
              <Col sm={24} md={12} className="align-right">
                <StyledButton
                  className="mx-0"
                  type="primary" 
                  htmlType="submit"
                  onClick={() => toggleAddHolidayModal(true)}
                >
                  + Add Holidays
                </StyledButton>
              </Col>
            )}
          </Row>

          <Form name="yearDatePickerForm" className="mt-2 mb-0 pb-0">
            <Row>
              <Col xs={24} ref={selectref}>
              <Form.Item name="selectedYear">
      <DatePicker
        onChange={onChange}
        picker="year"
        getPopupContainer={() => selectref.current}
      />
    </Form.Item>
              </Col>
            </Row>
          </Form>

          <Spin spinning={displayholidayLoader && holidayList?.length === 0}>
            <Table
              className="mb-0"
              dataSource={holidayList}
              columns={columns}
              rowKey="holiday_id"
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    toggleEditHolidayModal(true);
                    ViewHoliday(record);
                  },
                };
              }}
            />
          </Spin>
        </>
      )}

      {displayAddHolidayModal && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => toggleAddHolidayModal(false)}
          >
            Holiday <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className="pb-0 pt-1">Add Holiday</b>
          <StepsProgress stepdata={stepdata} title="Add Holiday:" step={step} />
        </>
      )}
      {displayAddHolidayModal && (
        <div className="styled-card">
          {displayAddHolidayModal && step === 0 && (
            <BasicInformation
              step={step}
              setStep={setStep}
              setValues={setValues}
              title={"holiday"}
              values={values}
            />
          )}

          {displayAddHolidayModal && step === 1 && (
            <>
              {locationList?.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllChecked}
                  onChange={handleSelectAllLocation}
                >
                  Select All
                </Checkbox>
              )}

              <SelectTable
                step={step}
                setStep={setStep}
                columns={locatincolumns}
                tabledata={locationList}
                setOpenModal={() => setOpenLocationModal(true)}
                Name="Add Location"
              />
              <FormModal
                isModalVisible={openLocationModal}
                closeModal={() => setOpenLocationModal(false)}
                Name="Location"
              />
            </>
          )}
          {step === 2 && (
            <InReview
              step={step}
              setStep={setStep}
              BasicInformation={values}
              location={selectedLocationId}
              create={onFinish}
              loading={loading}
            />
          )}
        </div>
      )}

      {displayEditHolidayModal && (
        <EditHoliday
          isModalVisible={displayEditHolidayModal}
          closeModal={() => toggleEditHolidayModal(false)}
          getHolidayList={getHolidayList}
          HolidayDetails={HolidayDetails}
          deleteHoliday={deletedHoliday}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "holiday" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this Holiday?"
        visible={deactivateHoliday}
        width={500}
        onCancel={() => toggledeactivateHoliday(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateHoliday(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteHoliday(Holidayid)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateHoliday}
        width={500}
        onCancel={() => toggleconfirmdeactivateHoliday(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Holiday has been deactivated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
  allHolidayList: getallHolidayList(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveHolidayList: setAllHolidayList
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(Holiday);
