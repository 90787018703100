import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import RoundIcon from "atoms/RoundIcon";
import Icon from "atoms/Icon";

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  .styled-circle {
    svg {
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
  }
  .legends {
    padding-top: 2rem;
    padding-left: 5px;
    div {
      padding-bottom: 5px;
    }
  }
`;

const chatStyle = {
  fontSize: 13,
}

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 8px;
  margin-right: 10px;
`;

function LeaveDonutChart({ iconName, title, data }) {

  let numbers = [7, 1, 5, 6];

let randomIndex = Math.floor(Math.random() * numbers.length);

let randomNumber = numbers[randomIndex];

  const config = {
    width: 120,
    height: 120,
    appendPadding: 6,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.8,
    tooltip: false,
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: chatStyle,
        content: `${data[1]?.value}/${(data[0]?.value) - (data[1]?.value)}`,
      },
    },
  };

  return (
    <StyledChartContainer>
      <Row>
        <Col span={24}>
          <RoundIcon>{iconName && <Icon name={iconName} style={{color: "#000"}}/>}</RoundIcon> <b>{title}</b>
        </Col>
        <Col xs={24} sm={12} className="legends">
          <div>
            <StyledColorBox color={'#6395FA'}></StyledColorBox>
            <span>Available : {(data[0]?.value) - (data[1]?.value)}</span>
          </div>
          <div>
            <StyledColorBox color={'#62DAAB'}></StyledColorBox>
            <span>Consumed : {data[1]?.value}</span>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <Pie {...config} />
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default LeaveDonutChart;
