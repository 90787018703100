import React, { useMemo, useState } from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Modal, Card } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';
import UITabs from 'atoms/UITabs';
import moment from 'moment';
import Wrapper, { CardImage } from './style';


const { Meta } = Card;

const StyledModal = styled(Modal)`
  border-radius: 10px;
  p{
    font-size: 16px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
`;



const img = 'https://tymeplusformbuildtemplate.s3.ap-south-1.amazonaws.com/image+(19).png'

function FormListModal({ handleChoose, templateForm, createdForm, onCancel, open }) {

    const CardContainer = ({ data }) => {
        return (
            <Wrapper>
                <div className='card-container'>
                    {data?.map((item, index) => (
                        <MemoizedCard item={item} />
                    ))}

                </div>
            </Wrapper>
        )
    }

    const items = [
        {
            id: 1,
            label: "Created",
            component: <CardContainer data={createdForm} />
        },
        {
            id: 2,
            label: "Templates",
            component: <CardContainer data={templateForm} />
        },

    ]



    const MemoizedCard = useMemo(() => {

        return React.memo(({ item, description = false }) => {



            return (
                <Wrapper>
                    <Card
                        key={item.id}
                        hoverable
                        cover={
                            <CardImage
                                // backgroundColor={generateRandomLightColors()}
                                className={`px-4`}
                                onClick={() => {
                                    handleChoose(item)
                                    console.log("clicked")
                                }}
                            >
                                <img alt="example" src={item?.img ? item?.img : img} />
                            </CardImage>
                        }
                      
                        bordered={true}
                        className={`cards`}
                    // style={isEqual(isSelected, item) ? selectedStyle : {}}
                    >
                        {description ? (
                            <Meta
                                title={item?.title}
                                description={`Created on ${moment(item?.insertedAt).format("DD MMM YYYY")}`}
                            />
                        ) : (
                            <Meta
                                title={item?.title ? item?.title : 'Untitled form'}
                                description={`Created on ${moment(item?.insertedAt).format("DD MMM YYYY")}`}
                            />
                        )}
                    </Card>
                </Wrapper>
            );
        });
    }, []);




    return (
        <div>
            <StyledModal
                title=""
                open={open}
                // onOk={handleOk}
                onCancel={onCancel}
                closeIcon={null}
                width={1000}
            >
                <div className='mt-3 text-center'>
                    <p ></p>
                    <div className=''>
                        <UITabs items={items} />
                        {/* <ButtonOutlined key="back" onClick={handleCancel} className='stylebtn'>
                            No
                        </ButtonOutlined>,
                        <ButtonFilled
                            key="submit"
                            loading={loading}
                            onClick={handleOk}
                            className='stylebtn'
                        >
                            Yes
                        </ButtonFilled> */}
                    </div>

                </div>

            </StyledModal>
        </div>
    )
}

export default FormListModal