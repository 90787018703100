import COLORS from "common/Colors";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import React, { useContext } from "react";
import { ContainerContext } from "pages/Container";
import { OrganizationContext, calculateNumberOfReportees } from ".";
import { ReactComponent as ChatIcon } from "assets/chat.svg";
import { ReactComponent as EmailIcon } from "assets/email.svg";
import { Popover } from "antd";

export const Card = ({ person, linked }) => {
  const {
    setActivePerson,
    organizationLevels,
    findEmployeeById,
    setOrganization,
  } = React.useContext(OrganizationContext);

  const { layoutContentRef } = useContext(ContainerContext);

  const history = useHistory();

  const handleReporteesClick = (personId) => {
    // Find the level of the person

    if (person.numberOfReportees === 0) return;
    const personLevelId = organizationLevels.find((level) =>
      level.people.includes(personId)
    ).levelid;

    setActivePerson((prev) => {
      return prev.map((level) => {
        // the clicked person should come to the first index

        if (level.levelid === personLevelId) {
          return {
            ...level,
            activePerson: findEmployeeById(personId),
          };
        } else if (level.levelid > personLevelId) {
          const reportees = findEmployeeById(personId).reportees;

          return {
            ...level,
            activePerson:
              reportees.length > 0 ? findEmployeeById(reportees[0]) : null,
          };
        }
        return level;
      });
    });

    setOrganization((prev) => {
      return prev.map((level) => {
        if (level.levelid === personLevelId) {
          // the clicked person should come to the first index
          let newPeople = level.people.filter(
            (person) => person.id !== personId
          );

          let employee = findEmployeeById(personId);
          employee.numberOfReportees = calculateNumberOfReportees(employee);

          newPeople.unshift(employee);

          level.people = newPeople;

          return {
            ...level,
            show: true,
          };
        }
        return level;
      });
    });

    // Scoll Horizontally to the Left fully
    layoutContentRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <CardWrap>
      <div className="card">
        <div className="card-layout">
          <div className="card-main">
            {person.profileurl ? (
              <img className="card-img" src={person.profileurl} alt="" />
            ) : (
              <>
                <div className="card-img person-name">{person.name?.[0]}</div>
              </>
            )}
            <div className="card-text">
              <Popover title={person.name} content={false}>
                <div className="name">{person.name}</div>
              </Popover>
              <div className="role">{person.grade}</div>
            </div>
          </div>
          <div className="card-footer">
            <div className="buttons">
              <div className="chat">
                <ChatIcon />
              </div>
              <div className="email">
                <EmailIcon />
              </div>
            </div>

            <div
              className="view"
              onClick={() => {
                history.push(`/profile-details/${person.userid}/?type=manage`);
              }}
            >
              View{" "}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => handleReporteesClick(person.id)}
        className={`box ${linked ? "linked" : ""}`}
      >
        {person.numberOfReportees}
      </div>
    </CardWrap>
  );
};

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  .card {
    background-color: ${COLORS.white};
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    width: 210px;
    height: 110px;

    .card-layout {
      display: flex;
      flex-direction: column;
      height: 100%;
      .card-main {
        display: flex;
        align-items: end;
        gap: 0.5rem;
        padding: 0.5rem;
        .card-img {
          width: 55px;
          height: 55px;

          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          background-color: #b5b5b5;
          border-radius: 5px;
          color: white;
        }
        .card-text {
          line-height: 1.4;
          .name {
            font-size: 14px;
            font-weight: 650;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 130px;
          }
          .role {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .card-footer {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0.4rem;
        background-color: ${COLORS.PRIMARY};
        border-radius: 0 0 5px 5px;
        .buttons {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .chat,
          .email {
            padding-right: 10px;
            border-right: 0.5px solid #575757;
            cursor: pointer;
          }
        }
        .view {
          font-size: 12px;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    color: white;
    background-color: #b5b5b5;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      bottom: 100%;
      height: 1rem;
      width: 1.5px;
      background-color: #b5b5b5;
    }

    &.linked {
      background-color: ${COLORS.BLUE};

      &::before {
        background-color: ${COLORS.BLUE};
      }
      &::after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 100%;
        height: 1rem;
        width: 1.5px;
        background-color: ${COLORS.BLUE};
      }
    }
  }
`;
