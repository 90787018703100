import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table, Spin } from "antd";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  margin-bottom: 25px;
  height: 320px;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 320px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function RecentEmployeeList({ ClientId }) {
  const [employeeList, setEmployeeList] = useState([]);
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const columns = [
    {
      title: "User ID",
      dataIndex: "userid",
      key: "userid",
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Client ID",
      dataIndex: "clientid",
      key: "clientid",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
  ];

  const getData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const { recentlyAddUsersData } = await CALL_API(
        `owner-dashboard/${ClientId}`,
        "post",
        {
          method: "recentlyAddUsers",
        }
      );
      setEmployeeList(recentlyAddUsersData);
      toggleReportLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [ClientId]);

  return (
    <>
      <StyledTitle>Recent Users</StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <StyledTable
            dataSource={employeeList}
            columns={columns}
            // Note : First parameter is Needed
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="userid"
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(RecentEmployeeList);
