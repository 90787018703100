import { Box, Checkbox, FormControlLabel, FormGroup, TextField, FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { CustomTextField } from '../../common/common';

const InputAndOptions = ({ form, onChange, idx, requiredValidation }) => {

    const radioBtnStyle = { width: "20px", height: "20px", marginRight: "10px" };

    //added state for dropdown type question alone.So that default value will be shown as Choose
    const [dropdownType, setDropDownType] = useState("");

    const dropdownOnChange = (e) => {
        setDropDownType(e.target.value);
        onChange(e, form)
    }

    return (
        <Box key={form.id} sx={{ mt: 2 }}>
            {form?.formType === "PARAGRAPH" && (
                <>
                    <CustomTextField
                        variant="standard"
                        onChange={(e) => {
                            onChange(e, form, idx)
                        }}
                        fullWidth
                        placeholder="Long answer"
                    />
                </>
            )}

            {form?.formType === "SHORT_ANS" && (
                <Box>
                    <CustomTextField
                        variant="standard"
                        onChange={(e) => onChange(e, form, idx)}
                        fullWidth
                        placeholder="Short answer"
                    />
                </Box>
            )}

            {form?.formType === "DATE" && (
                <>
                    <CustomTextField
                        variant="standard"
                        onChange={(e) => onChange(e, form, idx)}
                        type="date"
                    />
                </>
            )}

            {form?.formType === "TIME" && (
                <CustomTextField
                    variant="standard"
                    onChange={(e) => onChange(e, form, idx)}
                    type="time"
                />
            )}

            {form?.formType === "CHECKBOX" && (
                <div key={form.id}>
                    {form?.options.map((opn, checkBoxindex) => {
                        return (
                            <div key={`${checkBoxindex}-${form.id}`}
                                className='d-flex'
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        className='d-flex'
                                        control={
                                            <Checkbox

                                                value={opn?.optionText}
                                                onChange={(e) => onChange(e, form, checkBoxindex)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label={opn?.optionText} />
                                </FormGroup>
                            </div>
                        );
                    })}
                </div>
            )}

            {form?.formType === "MULIPLE_CHOICE" && (
                <div key={form.id}>
                    {form?.options.map((opn, index) => {
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "5px" }}>
                                <input
                                    type="radio"
                                    value={opn?.optionText}
                                    id={`answer-radio-${form.id}`}
                                    onChange={(e) => onChange(e, form, index)}
                                    name={`answer-radio-${form.id} `}
                                    style={radioBtnStyle}
                                />
                                <label className='mb-1' htmlFor={`answer-radio-${form.id} `}>
                                    {opn?.optionText}
                                </label>
                            </Box>
                        )
                    }
                    )}
                </div>
            )
            }


            {form.formType === "DROPDOWN" && (
                <Box sx={{ maxWidth: 280, minWidth: 250, mt: 2 }}>
                    <FormControl fullWidth>
                        <Select
                            labelId="select-filled-label"
                            id="select-filled"
                            displayEmpty
                            value={dropdownType}
                            onChange={(e) => dropdownOnChange(e)}
                        >
                            <MenuItem disabled value="">
                                <em>Choose</em>
                            </MenuItem>
                            {form?.options?.map((option, optionindex) => {
                                return (
                                    <MenuItem value={option?.optionText} key={`${optionindex} `}>{option?.optionText}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            )}

            {/* Show error messsage only when the question has requried true */}
            {
                (form.requiredSwitch && requiredValidation[form.id]) && (
                    <Box sx={{ color: "#e53935", mt: "5px" }}>
                        This field is required
                    </Box>
                )
            }

        </Box>
    );
};

export default InputAndOptions;

