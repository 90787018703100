import React from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import { isNaN, map, max } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeesPerRole({ report }) {
 
  const config = {
    padding: 'auto',
    data: report,
    height: 300,
    xField: 'userrole',
    yField: 'users',
    seriesField: 'userrole',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      // max: isNaN(max(map(report, 'users'))) ? 100 : max(map(report, 'users')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    theme: {
      colors10: [
        '#3C88C0',
        '#4BA7B3',
        '#5AC39B',
        '#6ADD7F',
        '#A8DD6A',
        '#DDDD6A',
      ],
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
  };
  return (
    <>      
      <StyledTitle>
          Number Of Users Per Role
      </StyledTitle>
      <StyledChartContainer>
          <Column
            {...config}
             />
      </StyledChartContainer>
    </>
  );
}


export default EmployeesPerRole