import React, { useState, useEffect } from "react";
import { Form, Switch, Row, Col, Checkbox } from "antd";
import { connect } from "react-redux";
import {
  toggleProcessingModal,
  toggleSuccessModal,
  setAllDepartmentList,
  setRoleList,
} from "redux/actions";
import { getLocations, getClientid, getRoleList } from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE, VALIDATE_CHARACTER, VALIDATE_SYMBOL_CHARACTER } from "common/Constants";
import TabsList from "molecules/TabsList";
import EditInformation from "molecules/EditInformation";
import EditSelectTable from "molecules/EditSelectTable";
import FormModal from "./FormModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function EditDepartment({
  isModalVisible,
  closeModal,
  getDepartmentList,
  DepartmentDetails,
  locationList,
  saveAllDepartmentList,
  ClientId,
  roleList,
  //need for delete
  deleteDepartment,
  viewOnly,
  saveRoleList
}) {
  const [form] = Form.useForm();

  const fetchMasterData = async (fetch_master_data) => {
    const { code, departmentList, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveAllDepartmentList(departmentList);
        saveRoleList(roleList);
      }
    }
  };

  // -----edit department----
  const [values, setValues] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [create, setCreate] = useState(false);
  const [submit,setsubmit] =useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllLocationChecked, setSelectAllLocationChecked] =
    useState(false);
  const [selectAllRolesChecked, setSelectAllRolesChecked] = useState(false);
  

  useEffect(() => {
    const {
      department_name = "",
    } = DepartmentDetails;
    form.setFieldsValue({
      name: department_name,
    });
    setSelectAllRolesChecked(selectedRoleId?.length === roleList?.length);
      setSelectAllLocationChecked(selectedLocationId?.length === locationList?.length);
  }, [DepartmentDetails,selectedRoleId,selectedLocationId,roleList,locationList]);

  useEffect(() => {
    setValues({ name: DepartmentDetails.department_name });
    setSelectedRoleId(DepartmentDetails.role_id);
    setSelectedLocationId(DepartmentDetails.location_id);
  }, [DepartmentDetails]);

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    setsubmit(true);
  };

  const handleSelectAllRoleChange = (e) => {
    const checked = e.target.checked;
    setSelectAllRolesChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setsubmit(true);
  };

  const locatincolumns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
    },
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        const check = selectedLocationId?.includes(location_id) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            checked={check}
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const handleSubmit = async () => {
    setCreate(true);
    const { name } = values;
    const containsSpecialCharacters = !VALIDATE_SYMBOL_CHARACTER.test(name);
    if (containsSpecialCharacters) { // Handle the case where DepartmentName contains special characters
      return; // Prevent further execution
    }
    const { code } = await CALL_API(
      `owner-department/${ClientId}/${DepartmentDetails.department_id}`,
      "patch",
      {
        department_id: DepartmentDetails.department_id,
        department_name: name,
        location_id: selectedLocationId,
        role_id: selectedRoleId,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData(true);
      getDepartmentList();
    }
  };

  
  useEffect(()=>{
    if(submit){
      handleSubmit();
    }
  },[DepartmentDetails,selectedLocationId,selectedRoleId, values])

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
    setsubmit(true);
  };

  const handleSelectAllLocationChange = (e) => {
    const checked = e.target.checked;
    setSelectAllLocationChecked(checked);
    const allDepaetmentIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allDepaetmentIds : []);
    setsubmit(true);
  };

  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {viewOnly ? (
            <Row className="styled-card">
              <Col>
                <b>Location Name</b> : {values.name}
              </Col>
            </Row>
          ) : (
            <EditInformation
              details={DepartmentDetails}
              setValues={setValues}
              create={create}
              setsubmit={setsubmit}
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Role",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {roleList?.length > 0 &&(
                <Checkbox
                className=""
                checked={selectAllRolesChecked}
                onChange={handleSelectAllRoleChange}
              >
                Select All
              </Checkbox>
              )}
            </div>
          )}
          <EditSelectTable columns={rolecolumns} tabledata={roleList} setOpenModal={() => setOpenRoleModal(true)} Name="Add Role"/>
          <FormModal
            isModalVisible={openRoleModal}
            closeModal={() => setOpenRoleModal(false)}
            Name="Role"
          />
        </>
      ),
    },
    {
      id: 3,
      label: "Country/Location",
      component: (
        <>
          {!viewOnly && (
            <div className=" mb-3 d-flex justify-content-between">
              {locationList?.length > 0 &&(
                <Checkbox
                className=""
                checked={selectAllLocationChecked}
                onChange={handleSelectAllLocationChange}
              >
                Select All
              </Checkbox>
              )}
            </div>
          )}

          <EditSelectTable columns={locatincolumns} tabledata={locationList} setOpenModal={() => setOpenLocationModal(true)} Name="Add Location"/>
          <FormModal
            isModalVisible={openLocationModal}
            closeModal={() => setOpenLocationModal(false)}
            Name="Location"
          />
        </>
      ),
    },
  ];
  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {closeModal(false)}}
          >
            Competency/SL{" "}
            <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">
            {values.name}
          </b>
          <TabsList items={items} form={form} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveAllDepartmentList: setAllDepartmentList,
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(EditDepartment);
