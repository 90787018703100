import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { connect } from "react-redux";
import { getUserName, getUserRoleId, getClientid } from "redux/selectors";
import styled from "@emotion/styled";
import AllEmployees from "./AllEmployees";
import AttendanceBarChart from "./AttendanceBarChart";
import LocationBasedPresentStaff from "./LocationBasedPresentStaff";
import LeaveBifurcationReport from "./LeaveBifurcationReport";
import DashboardPendingLeaves from "./DashboardPendingLeaves";
import EmployeesPerDepartment from "./EmployeesPerDepartment";
import EmployeesPerRole from "./EmployeesPerRole";
import EmployeesPerLocation from "./EmployeesPerLocation";
import RecentEmployeeList from "./RecentEmployeeList";
import OverTimeAnalysis from "./OverTimeAnalysis";
import EmployeeAttendanceAnalysis from "./EmployeeAttendanceAnalysis";
import { CALL_API } from "common/API";

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function NonStaffDashboard({ userRoleId, ClientId }) {
  const [employeeDept, setEmployeeDept] = useState([]);
  const [employeeRole, setEmployeeRole] = useState([]);
  const [employeeLoc, setEmployeeLoc] = useState([]);
  const [displayReportLoader, toggleReportLoader] = useState(false);

  const getData = async () => {
    if (userRoleId == 1  && ClientId) {
      toggleReportLoader(true);
      const {
        numberOfUsersPerDepartmentData,
        numberOfUsersPerLocationData,
        numberOfUsersPerRoleData,
      } = await CALL_API(`owner-dashboard/${ClientId}`, "post", {
        method: "numberOfUsersPerCategory",
      });
      setEmployeeDept(numberOfUsersPerDepartmentData);
      setEmployeeLoc(numberOfUsersPerLocationData);
      setEmployeeRole(numberOfUsersPerRoleData);
      toggleReportLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [userRoleId, ClientId]);
  return (
    <StyledRow gutter={16}>
      {userRoleId != 1 &&(
        <>
          <Col md={24} sm={24} xs={24} xl={11}>
            <LeaveBifurcationReport />
          </Col>

          <Col md={24} sm={24} xs={24} xl={8}>
            <AttendanceBarChart />
          </Col>

          <Col md={24} sm={24} xs={24} xl={5}>
            <AllEmployees />
          </Col>

          <Col md={24} sm={24} xs={24} xl={13}>
            <DashboardPendingLeaves />
          </Col>
          <Col md={24} sm={24} xs={24} xl={11}>
            <LocationBasedPresentStaff />
          </Col>
        </>
      )}
      {userRoleId == 1 && (
        <>
          {/* <Col md={24} sm={24} xs={24} xl={12}>
            <OverTimeAnalysis/>
          </Col>
          
          <Col md={24} sm={24} xs={24} xl={12}>
           <EmployeeAttendanceAnalysis/>
          </Col> */}
          <Col md={24} sm={24} xs={24} xl={14}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerDepartment report={employeeDept} />
            </Spin>
          </Col>
          <Col md={24} sm={24} xs={24} xl={10}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerLocation report={employeeLoc} />
            </Spin>
          </Col>
          {/* <Col md={24} sm={24} xs={24} xl={10}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerRole report={employeeRole} />
            </Spin>
          </Col> */}
          <Col md={24} sm={24} xs={24} xl={14}>
            <RecentEmployeeList />
          </Col>
        </>
      )}
    </StyledRow>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(NonStaffDashboard);
