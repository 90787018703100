export function generateOrganization(inputData, type) {
  const employees = [];
  const supervisorMap = new Map(); // Map to store supervisor relationships

  // Step 1: Populate employees and supervisorMap
  inputData.forEach((item) => {
    const id = item.id; // Keep id as a string

    let supervisorId = null;

    if (type === "office") {
      supervisorId = item.OfficeSupervisorId || null;
    } else if (type === "regional") {
      supervisorId = item.RegionalSupervisorId || null;
    }

    if (supervisorId !== null) {
      employees.push({
        id,
        name: item.fullname,
        grade: item.grade,
        userid: item.userid,
        profileurl: item.profileurl,
        reportsTo: supervisorId && supervisorId !== id ? supervisorId : null,
        reportees: [], // Initialize reportees array
      });

      // Update supervisorMap
      if (supervisorId && supervisorId !== id) {
        if (!supervisorMap.has(supervisorId)) {
          supervisorMap.set(supervisorId, []);
        }
        supervisorMap.get(supervisorId).push(id);
      }
    }
  });

  // Step 2: Populate reportees in employees
  employees.forEach((employee) => {
    const id = employee.id;
    if (supervisorMap.has(id)) {
      employee.reportees = supervisorMap.get(id);
    }
  });

  return employees;
}

export const getOrganizationLevels = (employees) => {
  const levels = {};
  const visited = new Set(); // To keep track of visited nodes and avoid cycles

  const addToLevel = (employee, level) => {
    if (visited.has(employee.id)) {
      console.warn(
        `Circular reference detected for employee ID: ${employee.id}`
      );
      return;
    }
    visited.add(employee.id);

    if (!levels[level]) {
      levels[level] = [];
    }
    levels[level].push(employee.id);

    employee.reportees.forEach((reporteeId) => {
      const reportee = employees.find((e) => e.id === reporteeId);
      if (reportee) {
        addToLevel(reportee, level + 1);
      }
    });
  };

  employees
    .filter((e) => e.reportsTo === null || e.reportsTo === "none")
    .forEach((root) => {
      addToLevel(root, 1);
    });

  return Object.entries(levels).map(([level, people]) => {
    // except first index, sort people by the number of reportees

    let firstPerson = people.shift();
    people.sort((a, b) => {
      const aReportees = employees.find((e) => e.id === a).reportees.length;
      const bReportees = employees.find((e) => e.id === b).reportees.length;
      return bReportees - aReportees;
    });
    people.unshift(firstPerson);

    return {
      levelid: parseInt(level),
      name: `L${level}`,
      people,
    };
  });
};
