import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import LeaveDonutChart from "./LeaveDonutChart";
import styled from "@emotion/styled";
import { LEAVE_ICON,  } from "common/Constants";
import { CALL_API } from "common/API";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import {
  getUserRoleId,
  getClientid,
  getLeaveCategoryList,
  getUserList,
  getUserInfo,
  getRoleList
} from "redux/selectors";
import { connect } from "react-redux";
import { intersection } from "lodash";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function UserLeaveReport({ ClientId, leaveCategoryList, userRoleId, userList, userInfo, roleList }) {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);

  const getCheckinData = async () => {
    setLoading(true);
    const response = await CALL_API(
      `employee-leave-details/${ClientId}`,
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
      }
    );
    console.log('response--->',response);
    setLeaveData(response);
    setLoading(false);
  };

  useEffect(() => {
    if(ClientId){
      getCheckinData();
    }
  }, [ClientId]);

  const filterrolelist = roleList?.filter((val)=>val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterleaves = [];
  leaveCategoryList?.forEach((item) => {
    if (item?.departmentId?.length > 0 && item?.locationId?.length > 0 ){
          if(intersection(item?.departmentId, filterdepartmentid).length > 0 &&
          intersection(item?.locationId, filterlocationid).length > 0){
            filterleaves.push(item);
          }
        }else {
          if(intersection(item?.locationId, filterlocationid).length > 0) {
          filterleaves.push(item);
        }
      }
        
});


  const filteruser = userList?.filter((item)=>item.userid === userInfo?.userid);
  const filtergender = filteruser[0]?.gender;
  const filteredCategoriesbygender = filtergender?.toLowerCase() === "male" ? filterleaves?.filter((item)=>(item.eligible)?.toLowerCase() !== "female") : filterleaves?.filter((item)=>(item.eligible)?.toLowerCase() !== "male")


  return (
    <>{
      filteredCategoriesbygender.length > 0 &&(
          <StyledTitle>LEAVE REPORT</StyledTitle>
      )
    }
      
      <Row gutter={16}>
        {filteredCategoriesbygender.map((data) => (
          <Col
            md={24}
            sm={24}
            xs={24} 
            xl={12}
            key={data.leave_category_short_name}
          >
            <Spin spinning={loading}>
              <LeaveDonutChart
                iconName={LEAVE_ICON[7]}
                title={data.leave_category_name.toUpperCase()}
                data={
                  leaveData?.find(
                    (item) => item[data?.leave_category_short_name]
                  )?.[data?.leave_category_short_name] ?? []
                }
              />
            </Spin>
          </Col>
        ))}
      </Row>
    </>
  );
}
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(UserLeaveReport);
