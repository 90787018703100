import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import COLORS from "common/Colors";

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    padding-right: 20px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

const UITabs = ({ items, activeTab, onTabChange }) => {
  return (
    <StyledTabs
      activeKey={String(activeTab)}  // Ensure activeKey is a string
      onChange={onTabChange} // Add onChange callback
    >
      {items.map((val) => (
        <Tabs.TabPane
          key={String(val?.id)}  // Ensure the key is a string
          tab={val?.label}
        >
          {val?.component}
        </Tabs.TabPane>
      ))}
    </StyledTabs>
  );
};

export default UITabs;
