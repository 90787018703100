import React, { useRef, useEffect } from "react";
import { Form, Input, Row, Col, DatePicker, Select } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { momentDate } from "utils/Date";
import { NON_EMPTY_REGEX, NON_CHARACTER, VALIDATE_CHARACTER, CountryCompany, VALIDATE_SYMBOL_CHARACTER } from "common/Constants";

const Styleddiv = styled.div`
  .ant-form-item-label > label {
    font-weight: bold;
  }
`;

const EditInformation = ({ title, details, setValues, viewOnly, setsubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const {
      name = title === "Add Location"? details.location_name
        : title === "holiday" ? details.holiday_name
        : title === "break" ? details.breakName : details.department_name,
      location_latitude = details.latitude,
      location_longitude = details.longitude,
      start_date = details.start_date,
      end_date = details.end_date,
      country = details.country
    } = details;

    form.setFieldsValue({
      name: name,
      country: country,
      location_latitude: location_latitude,
      location_longitude: location_longitude,
      start_date: moment(start_date),
      end_date: moment(end_date),
    });
    setValues(form.getFieldValue());
  }, [details]);

 

  const containerRef = useRef(null);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    setsubmit(true);
    setValues(allValues);
  };

  const filterlocation = CountryCompany?.filter((item)=>item.country === form.getFieldValue('country'));

  useEffect(() => {
    if(title === "Add Location"){
      form.setFieldsValue({
        name: filterlocation[0]?.location,
      });
      setValues(form.getFieldValue());
    }
  }, [form.getFieldValue('country'), details]);

  return (
    <Styleddiv>
      <Form
        form={form}
        name="AddDepartment"
        layout="vertical"
        disabled={viewOnly}
        initialValues={{
          name: "",
          latitude: "",
          longtitude: "",
          start_date: momentDate(new Date()),
          end_date: "",
          country: ""
        }}
        onValuesChange={handleFormValuesChange}
      >
        <div className="styled-card">
        {title !== "Add Location" && (
          <Form.Item
            label={
              title === "holiday"
                ? "Holiday Name"
                : title === "break"
                ? "Break Name"
                : "Team Name"
            }
            name="name"
            rules={[
              {
                required: true,
                message:
                  title === "holiday"
                    ? "Holiday Name is required"
                    : title === "break"
                    ? "Break Name is required"
                    : "Team Name is required",
              },
              {
                validator: (rule, value) => {
                  if (value && !VALIDATE_SYMBOL_CHARACTER.test(value)) {
                    return Promise.reject(
                      title === "Add Location"
                        ? "Location Name should not contain special characters"
                        : title === "holiday"
                          ? "Holiday Name should not contain special characters"
                          : title === "break"
                            ? "Break Name should not contain Numbers"
                            : "Team Name should not contain Numbers"
                    );
                  }
                  return Promise.resolve();
                },              
              },
            ]}
          >
            <Input
              placeholder={
                title === "holiday"
                  ? "Holiday name"
                  : title === "break"
                  ? "Break Name"
                  : "Team Name"
              }
              maxLength={75}
            />
          </Form.Item>
        )}
          {title === "Add Location" && (
            <>
            <Col xs={24} sm={24} md={24} ref={containerRef}>
                <Form.Item
                    name="country"
                    label="Country/ Company"
                    rules={[
                      { required: true, message: "Please select Country/ Company" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      disabled={details?.login_from_anywhere == 1}
                    >
                      {CountryCompany?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.country}
                          label={value.country}
                        >
                          {value.country}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                

                <Col xs={24} sm={24} md={24} ref={containerRef}>
                <Form.Item
                    name="name"
                    label="Location"
                    rules={[
                      { required: true, message: "Please select Location" },
                    ]}
                    
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      disabled={!form.getFieldValue('country') || details?.login_from_anywhere == 1}
                    >
                      {filterlocation?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.location}
                          label={value.location}
                        >
                          {value.location}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {details?.login_from_anywhere != 1 &&(
                  <>
                  <Form.Item
                label="Latitude"
                name="location_latitude"
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: "Latitude is required",
                  },
                ]}
              >
                <Input placeholder="Enter latitude" maxLength={20} />
              </Form.Item>
              <Form.Item
                label="Longitude"
                name="location_longitude"
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: "Longitude is required",
                  },
                ]}
              >
                <Input placeholder="Enter longitude" maxLength={20} />
              </Form.Item>
                  </>
                )}
              
            </>
          )}

          {title === "holiday" && (
            <>
              <Row>
                <Col xs={24} xl={24} ref={containerRef}>
                  <Form.Item
                    label="Start date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Start Date is required",
                      },
                      () => ({
                        validator(rule, value) {
                          const date = moment(value);
                          return date.isValid()
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD-MM-YYYY"
                      onChange={validateDateRange}
                      placeholder="Select start date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} ref={containerRef}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.start_date !== currentValues.start_date
                    }
                  >
                    {() => (
                      <Form.Item
                        label="End date"
                        name="end_date"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "End Date is required",
                          },
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select end date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </Styleddiv>
  );
};

export default EditInformation;
