import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CustomTextField } from '../../common/common';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectFormDetail, formtitle } from './formSlice';


export default function FormBasicDetail({
    formDetailshow,
    setFormBasicDetail,
    formbasicDetail,
}) {
    const dispatch = useAppDispatch();
    const formSectionDetail = useAppSelector(selectFormDetail);

    const { title, description } = formbasicDetail;

    const onChange = (e) => {
        setFormBasicDetail({ ...formbasicDetail, [e.target.name]: e.target.value });
        dispatch(
            formtitle({ ...formbasicDetail, [e.target.name]: e.target.value })
        );
    };

    useEffect(() => {
        if (formSectionDetail !== null || undefined) {
            setFormBasicDetail({
                title: '',
                description: ''
            });
        }
    }, []);



    return (
        <React.Fragment>
            <div className='pt-3 pb-4'>
                <Grid>
                    <Paper elevation={0}>
                        <Grid container className='mb-3'>
                            <Grid item md={12} xs={12}>
                                <CustomTextField
                                    id="standard-basic1"
                                    variant="standard"
                                    name="title"
                                    value={title}
                                    onChange={(e) =>
                                        onChange(e)
                                    }
                                    placeholder='Form Title'
                                    InputProps={{
                                        style: {
                                            fontSize: 20,
                                            fontWeight: 600,
                                        }
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>

                        <div>
                            {formDetailshow && (
                                <CustomTextField
                                    id="standard-basic2"
                                    variant="standard"
                                    name="description"
                                    value={description}
                                    onChange={(e) =>
                                        onChange(e)
                                    }
                                    placeholder='Form Description'
                                    fullWidth
                                    required
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>

            </div>
        </React.Fragment>
    );
}
