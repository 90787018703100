import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Spin } from "antd";
import Icon from "atoms/Icon";
import UICard from "reusableComponent/UICard/UICard";
import { CALL_API } from "common/API";
import { getClientid, getUserList, getRoleList } from "redux/selectors";
import { connect } from "react-redux";
import moment from "moment";
import { STATUS_CODE } from "common/Constants";
import { Link } from "react-router-dom";
import { flatMap, isEmpty, uniq } from "lodash";

const DashboardWrap = styled.div`
  /* margin-left: 10px; */
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
  }
  .styled-col {
    height: 250px;
    background-color: #ffffff;
    /* background-color: yellow; */
    border-radius: 15px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .styled-add-button {
    background: transparent;
    border: 1px solid #000000;
    color: #000000;
    font-size: 14px;
    border-radius: 5px;
  }
  .styled-icon {
    width: 40px !important;
    height: 40px !important;
  }
`;

const Ownerdashboard = ({ ClientId, userList }) => {
  const [displayBreakloader, setDisplayBreakLoader] = useState(false);
  const [displayShiftloader, setDisplayShiftLoader] = useState(false);
  const [displayHolidayloader, setDisplayHolidayLoader] = useState(false);
  const [displayRoleloader, setDisplayRoleLoader] = useState(false);
  const [displayLeaveloader, setDisplayLeaveLoader] = useState(false);
  const [displayDepartmentloader, setDisplayDepartmentLoader] = useState(false);
  const [displayLocationloader, setDisplayLocationLoader] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [BreakData, setBreakData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const currentYear = moment().year();

  const getfilterMaleList = userList?.filter(
    (item) => item?.gender.toLowerCase() === "male" && item?.statusid === "1"
  );
  const getfilterFemaleList = userList?.filter(
    (item) => item?.gender.toLowerCase() === "female" && item?.statusid === "1"
  );

  const getShiftList = async () => {
    try {
      setDisplayShiftLoader(true);
      setDisplayBreakLoader(true);
      setDisplayHolidayLoader(true);
      setDisplayRoleLoader(true);
      setDisplayLeaveLoader(true);
      setDisplayDepartmentLoader(true);
      setDisplayLocationLoader(true);
      const response = await CALL_API(`user-shift/${ClientId}`, "get", {});
      if (!isEmpty(response)) {
        setShiftData(response.reverse());
      }
      setDisplayShiftLoader(false);
    } catch (error) {
      setDisplayShiftLoader(false);
      console.log("[getShiftList] Error-->", error);
    }
  };

  const getLeaveList = async () => {
    try {
      setDisplayLeaveLoader(true);
      const { response, code } = await CALL_API(
        `owner-leave/${ClientId}`,
        "get",
        {}
      );
      if (code === STATUS_CODE.SUCCESS) {
        setLeaveData(response.reverse());
      }
      setDisplayLeaveLoader(false);
    } catch (error) {
      setDisplayLeaveLoader(false);
      console.log("[getLeaveList], Error-->", error);
    }
  };

  const getBreakList = async () => {
    try {
      setDisplayBreakLoader(true);
      const response = await CALL_API(`owner-break/${ClientId}`, "get", {});
      if (!isEmpty(response)) {
        setBreakData(response.reverse());
      }
      setDisplayBreakLoader(false);
    } catch (error) {
      setDisplayBreakLoader(false);
      console.log("[getBreakList], Error-->", error);
    }
  };

  const getHolidayList = async () => {
    try {
      setDisplayHolidayLoader(true);
      const response = await CALL_API(`holiday-list/${ClientId}`, "post", {
        start_date: moment()
          .year(currentYear)
          .startOf("year")
          .format("yyyy-MM-DD"),
        end_date: moment().year(currentYear).endOf("year").format("yyyy-MM-DD"),
      });
      if (!isEmpty(response)) {
        setHolidayData(response.reverse());
      }
      setDisplayHolidayLoader(false);
    } catch (error) {
      setDisplayHolidayLoader(false);
      console.log("[getHolidayList], Error-->", error);
    }
  };

  const getDepartmentList = async () => {
    try {
      setDisplayDepartmentLoader(true);
      const { response, code } = await CALL_API(
        `owner-department/${ClientId}`,
        "get",
        {}
      );
      if (code === STATUS_CODE.SUCCESS) {
        const mergedData = response?.map((item) => {
          return {
            department_id: item?.department_id,
            clientid: item?.clientid,
            department_name: item?.department_name,
            location_id: item?.location_id,
            role_id: item?.role_id || [],
            no_of_employee: item?.user_id?.length || 0,
            no_of_location: item?.location_id.length || 0,
            no_of_roles: item?.role_id?.length || 0,
            status_id: item?.status_id || 0,
            inserted_at: item?.inserted_at || "",
          };
        });
        setDepartmentData(mergedData);
      }
      setDisplayDepartmentLoader(false);
    } catch (error) {
      setDisplayDepartmentLoader(false);
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const getRoleLists = async () => {
    try {
      setDisplayRoleLoader(true);
      const { code, response } = await CALL_API(
        `owner-roles/${ClientId}`,
        "get",
        {}
      );
      if (code === STATUS_CODE.SUCCESS) {
        setRoleData(response);
      }
      setDisplayRoleLoader(false);
    } catch (error) {
      setDisplayRoleLoader(false);
      console.log("[getRoleLists], Error-->", error);
    }
  };

  const getLocationList = async () => {
    try {
      setDisplayLocationLoader(true);
      const { response, code } = await CALL_API(
        `owner-location/${ClientId}`,
        "get",
        {}
      );
      if (code === STATUS_CODE.SUCCESS) {
        setLocationData(response);
      }
      setDisplayLocationLoader(false);
    } catch (error) {
      console.log("[getLocationList], Error-->", error);
    }
  };

  const Rolecolumns = [
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "M/F",
      dataIndex: "roleId",
      key: "roleId",
      render: (_, record) => {
        const filteredMaleUsers = userList.filter(
          (item) =>
            item?.gender.toLowerCase() === "male" &&
            item?.statusid === "1" &&
            item?.roleid.includes(record?.roleId)
        );
        const filteredFemaleUsers = userList.filter(
          (item) =>
            item?.gender.toLowerCase() === "female" &&
            item?.statusid === "1" &&
            item?.roleid.includes(record?.roleId)
        );
        if (filteredMaleUsers || filteredFemaleUsers) {
          return `${filteredMaleUsers?.length} / ${filteredFemaleUsers?.length}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "roleId",
      key: "roleId",
      render: (_, record) => {
        const filteredMaleUsers = userList?.filter(
          (item) =>
            item?.gender.toLowerCase() === "male" &&
            item?.statusid === "1" &&
            item?.roleid.includes(record?.roleId)
        );
        const filteredFemaleUsers = userList?.filter(
          (item) =>
            item?.gender.toLowerCase() === "female" &&
            item?.statusid === "1" &&
            item?.roleid.includes(record?.roleId)
        );
        if (filteredMaleUsers || filteredFemaleUsers) {
          return filteredMaleUsers?.length + filteredFemaleUsers?.length;
        } else {
          return "-";
        }
      },
      align: "center",
    },
  ];

  const Departmentcolumns = [
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "M/F",
      dataIndex: "department_id",
      key: "department_id",
      render: (_, record) => {
        const filteredMaleUsers = userList?.filter((item) => {
          let result = [];
          const concatenatedArray = flatMap(item?.userdepartmentid);
          const uniqueArray = uniq(concatenatedArray);
          result = result?.concat(uniqueArray);
          if (
            item?.gender.toLowerCase() === "male" &&
            item?.statusid === "1" &&
            result.includes(record?.department_id)
          ) {
            return item;
          }
        });
        const filteredFemaleUsers = userList?.filter((item) => {
          let result = [];
          const concatenatedArray = flatMap(item?.userdepartmentid);
          const uniqueArray = uniq(concatenatedArray);
          result = result?.concat(uniqueArray);
          if (
            item?.gender.toLowerCase() === "female" &&
            item?.statusid === "1" &&
            result.includes(record?.department_id)
          ) {
            return item;
          }
        });
        if (filteredMaleUsers || filteredFemaleUsers) {
          return `${filteredMaleUsers?.length} / ${filteredFemaleUsers?.length}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "department_id",
      key: "department_id",
      render: (_, record) => {
        const filteredMaleUsers = userList?.filter((item) => {
          let result = [];
          const concatenatedArray = flatMap(item?.userdepartmentid);
          const uniqueArray = uniq(concatenatedArray);
          result = result?.concat(uniqueArray);
          if (
            item?.gender.toLowerCase() === "male" &&
            item?.statusid === "1" &&
            result.includes(record?.department_id)
          ) {
            return item;
          }
        });
        const filteredFemaleUsers = userList?.filter((item) => {
          let result = [];
          const concatenatedArray = flatMap(item?.userdepartmentid);
          const uniqueArray = uniq(concatenatedArray);
          result = result?.concat(uniqueArray);
          if (
            item?.gender.toLowerCase() === "female" &&
            item?.statusid === "1" &&
            result.includes(record?.department_id)
          ) {
            return item;
          }
        });
        if (filteredMaleUsers || filteredFemaleUsers) {
          return filteredMaleUsers?.length + filteredFemaleUsers?.length;
        } else {
          return "-";
        }
      },
      align: "center",
    },
  ];

  const locationcolumns = [
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "M/F",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id, record) => {
        const filteredMaleUsers = userList.filter(
          (user) =>
            user_id.includes(user?.userid) &&
            user?.statusid === "1" &&
            user?.gender.toLowerCase() === "male"
        );

        const filteredFemaleUsers = userList.filter(
          (user) =>
            user_id.includes(user?.userid) &&
            user?.statusid === "1" &&
            user?.gender.toLowerCase() === "female"
        );

        if (filteredMaleUsers || filteredFemaleUsers) {
          return `${filteredMaleUsers?.length} / ${filteredFemaleUsers?.length}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id) => {
        if (user_id) {
          const filteredUsers = userList.filter(
            (user) => user_id.includes(user?.userid) && user?.statusid === "1"
          );
          return filteredUsers.length;
        } else {
          return 0;
        }
      },
      align: "center",
    },
  ];

  const shiftcolumns = [
    {
      title: "People",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Starts",
      dataIndex: "shiftstarttime",
      key: "shiftstarttime",
      align: "center",
      render: (shiftstarttime) => {
        return moment(shiftstarttime, "HH:mm:ss").format("HH:mm");
      },
    },
    {
      title: "Ends",
      dataIndex: "shiftendtime",
      key: "shiftendtime",
      align: "center",
      render: (shiftendtime) => {
        return moment(shiftendtime, "HH:mm:ss").format("HH:mm");
      },
    },
  ];

  const holidaycolumns = [
    {
      title: "Name",
      dataIndex: "holiday_name",
      key: "holiday_name",
    },
    {
      title: "Starts",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
    },
    {
      title: "Ends",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
    },
  ];

  const leavecolumns = [
    {
      title: "Category",
      dataIndex: "leaveCategoryName",
      key: "leaveCategoryName",
    },
    {
      title: "Days",
      dataIndex: "numberOfLeaves",
      key: "numberOfLeaves",
      align: "center",
    },
    {
      title: "Allocation",
      dataIndex: "allocation",
      key: "allocation",
      align: "center",
    },
  ];

  const breakcolumns = [
    {
      title: "Break",
      dataIndex: "breakName",
      key: "breakName",
    },
    {
      title: "Department",
      dataIndex: "no_of_department",
      key: "no_of_department",
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "no_of_location",
      key: "no_of_location",
      align: "center",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      getShiftList();
    }, 500);
    setTimeout(() => {
      getBreakList();
    }, 1000); // Increase timeout for the second function call
    setTimeout(() => {
      getHolidayList();
    }, 1500); // Increase timeout for the third function call
    setTimeout(() => {
      getDepartmentList();
    }, 2000); // Increase timeout for the fourth function call
    setTimeout(() => {
      getRoleLists();
    }, 2500); // Increase timeout for the fifth function call
    setTimeout(() => {
      getLocationList();
    }, 3000); // Increase timeout for the sixth function call
    setTimeout(() => {
      getLeaveList();
    }, 3500);
  }, []);

  return (
    <DashboardWrap>
      <div className="container">
        <Row>
          <Col xs={24} md={24} lg={6} xl={4} className="p-2">
            <Spin spinning={displayShiftloader}>
              <div className="styled-col p-3">
                <b>People</b>
                <div className="text-center">
                  <Icon className="m-1 styled-icon" name="dashboardpeople" />
                  <h4 className="text-center">
                    {getfilterMaleList?.length + getfilterFemaleList?.length}
                  </h4>
                  <Row className="pt-1">
                    <Col sm={12} xl={14} className="text-end px-1">
                      Male
                    </Col>
                    <Col sm={12} xl={10} className="text-start px-1">
                      {getfilterMaleList?.length}
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col sm={12} xl={14} className="text-end px-1">
                      Female
                    </Col>
                    <Col sm={12} xl={10} className="text-start px-1">
                      {getfilterFemaleList?.length}
                    </Col>
                  </Row>
                  <Link to="/employee" className="mt-1 p-1 styled-add-button">
                    <Icon name="peoplebtn" /> Add
                  </Link>
                </div>
              </div>
            </Spin>
          </Col>

          <Col xs={24} md={24} lg={9} xl={10} className="p-2">
            <Spin spinning={displayRoleloader}>
              <UICard
                tabledata={roleData}
                tablecolumns={Rolecolumns}
                name="Role"
                iconsname="dashboardrole"
                btnicon="rolebtn"
                total={roleData?.length}
                path={"roles"}
              />
            </Spin>
          </Col>

          <Col xs={24} md={24} lg={9} xl={10} className="p-2">
            <Spin spinning={displayDepartmentloader}>
              <UICard
                tabledata={departmentData}
                tablecolumns={Departmentcolumns}
                name="Departments"
                iconsname="dashboarddepartment"
                btnicon="departmentbtn"
                total={departmentData?.length}
                path={"department"}
              />
            </Spin>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} lg={12} xl={12} className="p-2">
            <Spin spinning={displayLocationloader}>
              <UICard
                tabledata={locationData}
                tablecolumns={locationcolumns}
                name="Locations"
                iconsname="dashboardlocation"
                btnicon="locationbtn"
                total={locationData?.length}
                path={"location"}
              />
            </Spin>
          </Col>

          <Col xs={24} md={24} lg={12} xl={12} className="p-2">
            <Spin spinning={displayShiftloader}>
              <UICard
                tabledata={shiftData}
                tablecolumns={shiftcolumns}
                name="Shift"
              />
            </Spin>
          </Col>
        </Row>

        <Row className="pb-3">
          <Col xs={24} md={24} lg={8} xl={8} className="p-2">
            <Spin spinning={displayLeaveloader}>
              <UICard
                tabledata={leaveData}
                tablecolumns={leavecolumns}
                name="Leave"
                iconsname="dashboardleave"
                btnicon="leavebtn"
                total={leaveData?.length}
                path={"leave-categories"}
              />
            </Spin>
          </Col>

          <Col xs={24} md={24} lg={8} xl={8} className="p-2">
            <Spin spinning={displayBreakloader}>
              <UICard
                tabledata={BreakData}
                tablecolumns={breakcolumns}
                name="Break"
                iconsname="dashboardbreak"
                btnicon="breakbtn"
                total={BreakData?.length}
                path={"break"}
              />
            </Spin>
          </Col>

          <Col xs={24} md={24} lg={8} xl={8} className="p-2">
            <Spin spinning={displayHolidayloader}>
              <UICard
                tabledata={holidayData}
                tablecolumns={holidaycolumns}
                name="Holiday"
                iconsname="dashboardholiday"
                btnicon="holidaybtn"
                total={holidayData?.length}
                path={"holiday"}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    </DashboardWrap>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userList: getUserList(state),
  roleList: getRoleList(state),
});
export default connect(mapStateToProps)(Ownerdashboard);
