import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
  message,
  Switch,
} from "antd";
import {
  CompetencyQC,
  CompetencyRole,
  Grade,
  Industry,
  IndustryRole,
  SUPERVISOR,
  NON_EMPTY_REGEX,
  STATUS_CODE,
  Sector,
  ServiceLine,
  SubServiceLine,
  countryList,
  AwardList,
  MANAGER,
  secondaryIndustry,
  ContractingCompany,
  Greetings,
  RegionalCometencyRole,
} from "common/Constants";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "@emotion/styled";
import {
  getBandList,
  getClientid,
  getLocations,
  getRoleList,
  getSupervisorList,
  getUserList,
} from "redux/selectors";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { CALL_API, CALL_FEATCH_API } from "common/API";
import { setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import { chain, flatMap, isEmpty, filter, find, concat, get } from "lodash";
import TabPane from "antd/lib/tabs/TabPane";
import FormModal from "./FormModal";
import SelectTable from "../molecules/EditEmployeeRole";
import COLORS from "common/Colors";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    /* .anticon svg {
    color: #397FF4;
} */
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;
const EditProfileBox = styled.div`
  .ant-tabs-nav {
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

function generateUniqueId() {
  // Generate a random number and convert it to base 36 (numbers + letters)
  const randomNumber = Math.random().toString(36).substr(2, 9);

  // Get the current timestamp
  const timestamp = Date.now();

  // Combine the random number with the timestamp
  const uniqueId = randomNumber + timestamp;

  return uniqueId;
}

function EditProfile({
  updateState,
  setUpdateState,
  EmployeeDetails,
  userList,
  roleList,
  ClientId,
  bandList,
  updateUserList,
  locationList,
  pageName,
  supervisorList,
}) {
  const containerRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  const [showLoader, toggleLoader] = useState(false);
  const [employeeStatusId, setEmployeeStatusId] = useState("1");

  const employeesList = userList?.map((user) => user.userid);
  const [activeTab, setActiveTab] = useState("0");
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const filteredUserName = userList
    ?.filter((item) => item.roleid.includes("4"))
    ?.map((item) => item.fullname);

  const filteredLocation = locationList?.filter(
    (item) => item.login_from_anywhere !== 1
  );

  useEffect(() => {
    const {
      userid = "",
      startdate = "",
      dateofbirth = "",
      enddate = "",
      bandname = "",
      bandid = "",
      overtimestatus = "",
      salary = "",
      password = "",
      profileurl = "",
      gender = "",
      maritalstatus = "",
      nationality = "",
      facerecognition,
      roleid = "",
      nssf = "",
      national_id = "",
      address = "",
      phoneno = "",
      institutename = "",
      degree = "",
      specialization = "",
      yearofcompletion = "",
      companyname = "",
      jobtitle = "",
      professionalsince = "",
      hireddate = "",
      relevant = "",
      employeesponser = "",
      statusname = "",
      jobdescription = "",
      emergencycontactname = "",
      emergencycontactnumber = "",
      employementtype = "",
      employeeid = "",
      initial,
      firstname,
      lastname,
      salutation,
      competencyQC,
      serviceline,
      subserviceline,
      competencyrole,
      industry,
      sector,
      secondaryIndustry,
      industryrole,
      expertise,
      designation,
      immediatesupervisor,
      grade,
      targetchargeability,
      chargeoutrate,
      greetings,
      professionalcertification,
      lastpromotionlevel,
      company_Name,
      dependent,
      educationdetails,
      workexperience,
      professionalcertifications,
      practicaltrainings,
      organisationlevel,
      managingoffice,
      manager,
      supervisor,
      lastpromotion,
      levellocationid,
      leveldepartmentname,
      regionalsupervisor,
      regionalcompetencyrole,
      officesupervisor,
      engagementsupervisor,
    } = EmployeeDetails;

    setFormValues({
      user_id: userid,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      password: password,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      employementtype: employementtype,
      facerecognition: facerecognition,
      profile_url: profileurl !== ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      phoneno: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion
        ? moment(yearofcompletion, "YYYY-MM-DD")
        : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      company_Name: company_Name,
      status_id: employeeStatusId,
      description: jobdescription,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      industry: industry,
      sector: sector,
      secondaryIndustry: secondaryIndustry,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      immediatesupervisor: immediatesupervisor,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      professionalcertification: professionalcertification,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      organisationlevel: organisationlevel,
      managingoffice: managingoffice,
      manager: manager,
      supervisor: supervisor,
      lastpromotion: lastpromotion,
      location: levellocationid,
      leveldepartmentname: leveldepartmentname,
      regionalsupervisor: regionalsupervisor,
      regionalcompetencyrole: regionalcompetencyrole,
      officesupervisor: officesupervisor,
      engagementsupervisor: engagementsupervisor,
      educationdetails:
        educationdetails?.length !== 0
          ? educationdetails
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? workexperience
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? professionalcertifications
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? practicaltrainings
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
    });
    const transformedDetails = (data) =>
      data?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate);
        const formattedToDate = moment(todate);
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
    form.setFieldsValue({
      user_id: userid,
      password: password,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      facerecognition: facerecognition,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      employementtype: employementtype,
      phoneno: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion
        ? moment(yearofcompletion, "YYYY-MM-DD")
        : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      description: jobdescription,
      emergencycontactnumber: emergencycontactnumber,
      emergencycontactname: emergencycontactname,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      company_Name: company_Name,
      industry: industry,
      sector: sector,
      secondaryIndustry: secondaryIndustry,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      immediatesupervisor: immediatesupervisor,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      professionalcertification: professionalcertification,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      organisationlevel: organisationlevel,
      managingoffice: managingoffice,
      manager: manager,
      supervisor: supervisor,
      lastpromotion: lastpromotion,
      location: levellocationid,
      leveldepartmentname: leveldepartmentname,
      regionalsupervisor: regionalsupervisor,
      officesupervisor: officesupervisor,
      engagementsupervisor: engagementsupervisor,
      educationdetails:
        educationdetails?.length !== 0
          ? transformedDetails(educationdetails)
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? transformedDetails(workexperience)
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? transformedDetails(professionalcertifications)
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? transformedDetails(practicaltrainings)
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
    });
  }, [EmployeeDetails]);

  const validateEmployeeId = (rule, value) => {
    if (EmployeeDetails?.userid === value) {
      return Promise.resolve();
    } else if (value && employeesList.includes(value)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  const onFinish = () => {
    const currentFormValues = form.getFieldValue();
    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
  };

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        await CALL_API(`upload-userdocuments/${ClientId}`, "post", {
          userId: EmployeeDetails?.userid,
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId,
          content: file.response.base64Data,
        });
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };

  const handlesubmit = async () => {
    try {
      toggleLoader(true);
      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      const {
        user_id,
        start_date,
        password,
        gender,
        dateof_birth,
        nationality,
        maritalstatus,
        overtime,
        salary,
        facerecognition,
        nssf,
        national_id,
        address,
        phoneno,
        institutename,
        degree,
        specialization,
        company_Name,
        yearofcompletion,
        companyname,
        jobtitle,
        professionalsince,
        hireddate,
        relevent,
        employeesponser,
        employeestatus,
        description,
        emergencycontactname,
        emergencycontactnumber,
        employementtype,
        employeeid,
        initial,
        firstname,
        lastname,
        salutation,
        competencyQC,
        serviceline,
        subserviceline,
        competencyrole,
        industry,
        sector,
        industryrole,
        expertise,
        designation,
        immediatesupervisor,
        grade,
        targetchargeability,
        chargeoutrate,
        greetings,
        professionalcertification,
        lastpromotionlevel,
        dependent,
        educationdetails,
        workexperience,
        professionalcertifications,
        practicaltrainings,
        manager,
        supervisor,
        managingoffice,
        organisationlevel,
        lastpromotion,
        location,
        secondaryIndustry,
        leveldepartmentname,
        regionalsupervisor,
        regionalcompetencyrole,
        officesupervisor,
        engagementsupervisor,
      } = formValues;

      const workexperienceDetails = workexperience
      ?.filter((item) => {
        const {
          companyname,
          fromdate,
          todate,
          jobtitle,
          relevnt,
          jobdescription,
        } = item;
        return (
          (companyname?.trim() !== "" || companyname === null || companyname === undefined) ||
          (fromdate?.trim() !== "" || fromdate === null || fromdate === undefined) ||
          (todate?.trim() !== "" || todate === null || todate === undefined) ||
          (jobtitle?.trim() !== "" || jobtitle === null || jobtitle === undefined) ||
          (relevnt?.trim() !== "" || relevnt === null || relevnt === undefined) ||
          (jobdescription?.trim() !== "" || jobdescription === null || jobdescription === undefined)
        );
      })
      .map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
        const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null;
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
    

        const transformedEducationDetails = educationdetails
        ?.filter((item) => {
          const { institutename, fromdate, todate, exambody, awarded } = item;
          return (
            (institutename?.trim() !== "" || institutename === null || institutename === undefined) ||
            (fromdate?.trim() !== "" || fromdate === null || fromdate === undefined) ||
            (todate?.trim() !== "" || todate === null || todate === undefined) ||
            (exambody?.trim() !== "" || exambody === null || exambody === undefined) ||
            (awarded?.trim() !== "" || awarded === null || awarded === undefined)
          );
        })
        .map((item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
          const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null;
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId ? att?.response?.uniqueId : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        });
      

        const practicaltrainingsDetails = practicaltrainings
        ?.filter((item) => {
          const { organisationname, fromdate, todate, positionheld, tasksperformed } = item;
          return (
            (organisationname?.trim() !== "" || organisationname === null || organisationname === undefined) ||
            (fromdate?.trim() !== "" || fromdate === null || fromdate === undefined) ||
            (todate?.trim() !== "" || todate === null || todate === undefined) ||
            (positionheld?.trim() !== "" || positionheld === null || positionheld === undefined) ||
            (tasksperformed?.trim() !== "" || tasksperformed === null || tasksperformed === undefined)
          );
        })
        .map((item) => {
          const { fromdate, todate, ...rest } = item;
          const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
          const formattedToDate = moment(todate).format("YYYY-MM-DD");
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
          };
        });
      

        const professionalcertificationsDetails = professionalcertifications
        ?.filter((item) => {
          const { certificationtype, fromdate, todate, remarks } = item;
          // Check if all fields are empty
          return (
            (certificationtype?.trim() !== "" || certificationtype === null || certificationtype === undefined) ||
            (fromdate?.trim() !== "" || fromdate === null || fromdate === undefined) ||
            (todate?.trim() !== "" || todate === null || todate === undefined) ||
            (remarks?.trim() !== "" || remarks === null || remarks === undefined)
          );
        })
        .map((item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
          const formattedToDate = moment(todate).format("YYYY-MM-DD");
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId ? att?.response?.uniqueId : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        });
      
      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      const dobDate = new Date(dateof_birth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const response = await CALL_API(`add-users/${ClientId}`, "patch", {
        user_id: user_id?.toLowerCase(),
        full_name: `${firstname} ${lastname}`,
        end_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MM-DD")
          : "",
        start_date: start_date
          ? moment(start_date?._d).format("YYYY-MM-DD")
          : "",
        overtime_status: overtime,
        bandname: EmployeeDetails?.bandname,
        bandid: EmployeeDetails?.bandid,
        gender: gender,
        dateofbirth: dateof_birth
          ? moment(dateof_birth?._d).format("YYYY-MM-DD")
          : "",
        age: agecalculte,
        password: password,
        maritalstatus: maritalstatus,
        nationality: nationality,
        salary: salary,
        facerecognition: facerecognition,
        role_id: selectedRoleId,
        role_name: roleNames ? roleNames : "",
        nssf: nssf,
        national_id: national_id,
        phoneno: phoneno,
        address: address,
        permanentaddress: address,
        presentaddress: address,
        employementtype: employementtype,
        profile_url: EmployeeDetails?.profileurl,
        institutename: institutename,
        degree: degree,
        specialization: specialization,
        company_Name: company_Name,
        yearofcompletion: yearofcompletion
          ? moment(yearofcompletion?._d).format("YYYY-MM-DD")
          : "",
        companyname: companyname,
        jobtitle: jobtitle,
        professionalsince: professionalsince
          ? moment(professionalsince?._d).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? moment(hireddate?._d).format("YYYY-MM-DD") : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        status_id: employeeStatusId,
        jobdescription: description,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baseLocation,
        employeeid: employeeid,
        initial: initial,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        competencyQC: competencyQC,
        serviceline: serviceline,
        subserviceline: subserviceline,
        competencyrole: competencyrole,
        industry: industry,
        sector: sector,
        industryrole: industryrole,
        expertise: expertise,
        designation: designation,
        immediatesupervisor: immediatesupervisor,
        grade: grade,
        targetchargeability: targetchargeability,
        chargeoutrate: chargeoutrate,
        greetings: greetings,
        professionalcertification: professionalcertification,
        lastpromotionlevel: lastpromotionlevel,
        dependent: dependent,
        educationdetails: transformedEducationDetails,
        workexperience: workexperienceDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        managingoffice: managingoffice,
        organisationlevel: organisationlevel,
        manager: get(
          find(supervisorList, { employeeId: manager }),
          "name",
          null
        ),
        supervisor: get(
          find(supervisorList, { employeeId: supervisor }),
          "name",
          null
        ),
        lastpromotion: lastpromotion,
        levellocationid: location,
        secondaryindustry: secondaryIndustry,
        leveldepartmentname: leveldepartmentname,
        regionalsupervisor:
          regionalsupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: regionalsupervisor }),
                "name",
                null
              )
            : "none",
        RegionalSupervisorId:
          regionalsupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: regionalsupervisor }),
                "employeeId",
                null
              )
            : "none",
        regionalcompetencyrole: regionalcompetencyrole,
        officesupervisor:
          officesupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: officesupervisor }),
                "name",
                null
              )
            : "none",
        OfficeSupervisorId:
          officesupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: officesupervisor }),
                "employeeId",
                null
              )
            : "none",
        engagementsupervisor: get(
          find(supervisorList, { employeeId: engagementsupervisor }),
          "name",
          null
        ),
        engagementsupervisorid: get(
          find(supervisorList, { employeeId: engagementsupervisor }),
          "employeeId",
          null
        ),
      });

      toggleLoader(false);
      //   setFileName("");
      form.resetFields();
      //   closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          setUpdateState("");
        }
      }
      setUpdateState("");
      //   await getEmployeeList();
      await fetchMasterData(true);
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [organisationLevelData, setOrganisationLevelData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [competencyroleData, setcompetencyroleData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const fetchData = async () => {
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level1`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setManagingOfficeData(result);
    }
  };

  // const organisationLevelfetchData1= async () => {
  //   const filtervalue = managingOfficeData.filter((item)=>form.getFieldValue('managingoffice').includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setOrganisationLevelData(result);
  //   }
  // };

  const competencyQCfetchData1 = async () => {
    const filtervalue = managingOfficeData?.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setCompetencyQCfetchData(result);
    }
  };

  const servicelinefetchData1 = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("competencyQC")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setServicelinefetchData(result);
    }
  };

  const subservicelinefetchData1 = async () => {
    const filtervalue = servicelineData?.filter((item) =>
      form.getFieldValue("serviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const serviceLines = form.getFieldValue("serviceline"); // e.g., ['Deal Advisory', 'Risk Advisory']
      // Map each service line to a cleaned-up string (remove spaces)
      const formValues = serviceLines?.map((line) => line.replace(/\s+/g, ""));
      // Filter the response data to include only items that match any cleaned-up service line
      const filterResponse = response?.data?.filter((item) =>
        formValues.some((formValue) => item?.name?.includes(formValue))
      );

      const result = flatMap(response?.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setsubservicelineData(result);
    }
  };

  const departmentfetchData1 = async () => {
    const filtervalue = subservicelineData?.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setDepartmentLevelData(result);
    }
  };

  const competencyrolefetchData1 = async () => {
    const filtervalue = subservicelineData.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setcompetencyroleData(result);
    }
  };

  // const organisationLevelfetchData = async () => {
  //   const filtervalue = managingOfficeData.filter((item) =>
  //     form.getFieldValue("managingoffice").includes(item.value)
  //   );
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(
  //     `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
  //     "get"
  //   );
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, (item) =>
  //       item.value.map((value) => ({
  //         name: item.name,
  //         value,
  //         parentLevel: item.parentLevel,
  //       }))
  //     );
  //     setOrganisationLevelData(result);
  //     if (isEmpty(result)) {
  //       form.setFieldValue("organisationlevel", []);
  //       form.setFieldValue("competencyQC", []);
  //       form.setFieldValue("serviceline", []);
  //       form.setFieldValue("subserviceline", []);
  //       form.setFieldValue("competencyrole", []);
  //     } else {
  //       // organisationlevel
  //       const organisationlevelList = filter(result, (item) =>
  //         form.getFieldValue("managingoffice").includes(item.parentLevel)
  //       );
  //       const organisationlevel = filter(
  //         form.getFieldValue("organisationlevel"),
  //         (value) => find(organisationlevelList, { value: value })
  //       );
  //       form.setFieldValue("organisationlevel", organisationlevel);
  //       // competencyQC

  //       const competencyQClevelList = filter(competencyQCData, (item) =>
  //         form.getFieldValue("organisationlevel").includes(item.parentLevel)
  //       );
  //       setCompetencyQCfetchData(competencyQClevelList);
  //       const competencyQClevel = filter(
  //         form.getFieldValue("competencyQC"),
  //         (value) => find(competencyQClevelList, { value: value })
  //       );
  //       form.setFieldValue("competencyQC", competencyQClevel);
  //       // serviceline
  //       const servicelinelevelList = filter(servicelineData, (item) =>
  //         form.getFieldValue("competencyQC").includes(item.parentLevel)
  //       );
  //       setServicelinefetchData(servicelinelevelList);
  //       const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
  //         find(servicelinelevelList, { value: value })
  //       );
  //       form.setFieldValue("serviceline", serviceline);
  //       // subserviceline
  //       const subservicelinelevelList = filter(subservicelineData, (item) =>
  //         form.getFieldValue("serviceline").includes(item.parentLevel)
  //       );
  //       setsubservicelineData(subservicelinelevelList);
  //       const subserviceline = filter(
  //         form.getFieldValue("subserviceline"),
  //         (value) => find(subservicelinelevelList, { value: value })
  //       );
  //       form.setFieldValue("subserviceline", subserviceline);
  //       //  // competencyrole
  //       //  const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //       //  setcompetencyroleData(competencyrolelevelList)
  //       //  const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //       //  form.setFieldValue('competencyrole', competencyrole);
  //     }
  //   }
  // };

  const competencyQCfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setCompetencyQCfetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("competencyQC", []);
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("competencyrole", []);
        form.setFieldValue("leveldepartmentname", []);

        setServicelinefetchData([]);
      } else {
        // competencyQC

        const competencyQClevelList = filter(result, (item) =>
          form.getFieldValue("managingoffice").includes(item.parentLevel)
        );
        const competencyQClevel = filter(
          form.getFieldValue("competencyQC"),
          (value) => find(competencyQClevelList, { value: value })
        );
        form.setFieldValue("competencyQC", competencyQClevel);
        // serviceline
        const servicelinelevelList = filter(servicelineData, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        setServicelinefetchData(servicelinelevelList);
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);

        // const leveldepartmentnameList = filter(subservicelineData, (item) =>
        //   form.getFieldValue("serviceline").includes(item.parentLevel)
        // );
        // setsubservicelineData(leveldepartmentnameList);
        // const leveldepartmentname = filter(
        //   form.getFieldValue("leveldepartmentname"),
        //   (value) => find(leveldepartmentnameList, { value: value })
        // );
        // form.setFieldValue("leveldepartmentname", leveldepartmentname);
        // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const servicelinefetchData = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("competencyQC")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setServicelinefetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("competencyrole", []);
      } else {
        // serviceline
        const servicelinelevelList = filter(result, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);

        const leveldepartmentnameList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(leveldepartmentnameList);
        const leveldepartmentname = filter(
          form.getFieldValue("leveldepartmentname"),
          (value) => find(leveldepartmentnameList, { value: value })
        );
        form.setFieldValue("leveldepartmentname", leveldepartmentname);

        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const subservicelinefetchData = async () => {
    const filtervalue = servicelineData?.filter((item) =>
      form.getFieldValue("serviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`,
      "get"
    );

    if (!isEmpty(response)) {
      const serviceLines = form.getFieldValue("serviceline"); // e.g., ['Deal Advisory', 'Risk Advisory']
      // Map each service line to a cleaned-up string (remove spaces)
      const formValues = serviceLines?.map((line) => line.replace(/\s+/g, ""));

      // Filter the response data to include only items that match any cleaned-up service line
      const filterResponse = response?.data?.filter((item) =>
        formValues.some((formValue) => item?.name?.includes(formValue))
      );

      const result = filterResponse.flatMap((item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setsubservicelineData(result);
      if (isEmpty(result)) {
        form.setFieldValue("subserviceline", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );

        form.setFieldValue("subserviceline", subserviceline);
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };
  const departmentfetchData = async () => {
    const filtervalue = subservicelineData?.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setDepartmentLevelData(result);
      if (isEmpty(result)) {
        // form.setFieldValue("subserviceline", []);
        // form.setFieldValue("competencyrole", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );

        // form.setFieldValue("subserviceline", subserviceline);
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  useEffect(() => {
    if (updateState === "basic") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (competencyQCData?.length == 0 && updateState === "basic") {
      competencyQCfetchData1();
    }
  }, [managingOfficeData]);

  useEffect(() => {
    if (servicelineData?.length == 0 && updateState === "basic") {
      servicelinefetchData1();
    }
  }, [competencyQCData]);

  useEffect(() => {
    if (subservicelineData?.length == 0 && updateState === "basic") {
      subservicelinefetchData1();
    }
  }, [servicelineData]);

  useEffect(() => {
    if (departmentLevelData?.length == 0 && updateState === "basic") {
      departmentfetchData1();
    }
  }, [subservicelineData]);

  // useEffect(() => {
  //   if (competencyroleData?.length == 0 && updateState === "basic") {
  //     competencyrolefetchData1();
  //   }
  // }, [subservicelineData]);

  const tabTitles = ["Basic", "Role", "Location"];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => {
              getRoleId(roleId, checked);
            }}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => {
              setBaseLocation(checked ? location_id : "");
              onFinish();
            }}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    onFinish();
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const handleResetAllRole = (e) => {
    setSelectedRoleId([]);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  useEffect(() => {
    setSelectedRoleId(EmployeeDetails.roleid);
    setBaseLocation(
      EmployeeDetails?.baselocationid ? EmployeeDetails?.baselocationid : ""
    );
  }, [EmployeeDetails]);

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId?.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter((val) =>
    locationId.includes(val?.location_id)
  );

  const iconsize = { fontSize: "14px" };

  const cursorstyle = { cursor: "pointer" };

  const handleDateChange = (date, dateString, key, field) => {
    const updatedEducationDetails = [...formValues.educationdetails];
    updatedEducationDetails[key][field] = date;

    console.log("formValues", updatedEducationDetails[key], key);
    setFormValues({
      ...formValues,
      educationdetails: updatedEducationDetails,
    });

    onFinish();
  };

  return (
    <EditProfileBox>
      {updateState === "personalinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Personal Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              user_id: "",
              department_id: "",
              gender: "",
              dateof_birth: "",
              maritalstatus: "",
              nationality: "",
              nssf: "",
              national_id: "",
              address: "",
              phoneno: "",
              overtime: "",
              start_date: "",
              emergencycontactname: "",
              emergencycontactnumber: "",
              employementtype: "",
              initial: "",
              first_name: "",
              last_name: "",
              salutation: "",
              dependent: "",
            }}
            onFinish={() => {
              handlesubmit();
            }}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="salutation"
                  label="Salutation"
                  placeholder="Select Salutation"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Salutation is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Salutation"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="Mr.">Mr.</Option>
                    <Option value="Dr.">Dr.</Option>
                    <Option value="Prof.">Prof.</Option>
                    <Option value="Ms.">Ms.</Option>
                    <Option value="Mrs.">Mrs.</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="initial"
                  label="Initial"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Initial is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Initial"
                    maxLength={5}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="First name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="employeeid"
                  label="Employee ID"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Employee ID is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Employee ID"
                    maxLength={15}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z0-9\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="user_id"
                  label="User ID"
                  rules={[
                    {
                      required: true,
                      message: "Email ID is required",
                    },
                    { validator: validateEmployeeId },
                  ]}
                >
                  <Input
                    placeholder="Email ID"
                    disabled={true}
                    onChange={onFinish}
                    maxLength={60}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="phoneno" label="Phone No">
                  <PhoneInput
                    type="text"
                    placeholder="Phone No"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="They/ Them">They/ Them</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="dependent" label="No. of Dependants">
                  <Input
                    placeholder="No. of Dependants"
                    maxLength={10}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Date of Birth"
                  name="dateof_birth"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Date of Birth is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={() => {
                      onFinish();
                    }}
                    placeholder="Select Date of Birth"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="maritalstatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="single">Single</Option>
                    <Option value="married">Married</Option>
                    <Option value="widowed">Widowed</Option>
                    <Option value="divorced">Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="greetings"
                  label="Greetings"
                  rules={[
                    { required: false, message: "Greetings is required" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {Greetings?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="nationality"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactname"
                  label="Emergency Contact Name"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Emergency Contact Name"
                    maxLength={15}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactnumber"
                  label="Emergency Contact Number"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Emergency Contact Number"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Address"
                    onChange={onFinish}
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeidentityform"
            layout="vertical"
            initialValues={{
              company_Name: "",
              industry: [],
              sector: [],
              secondaryIndustry: [],
              industryrole: "",
              expertise: "",
              designation: "",
              immediatesupervisor: "",
              grade: "",
              targetchargeability: "",
              chargeoutrate: "",
              jobtitle: "",
              professionalsince: "",
              hireddate: "",
              employeesponser: "",
              relevent: "",
              maritalstatus: "",
              description: "",
              lastpromotionlevel: "",
              overtime: "",
              lastpromotion: "",
              regionalsupervisor: "",
              regionalcompetencyrole: "",
              officesupervisor: "",
              engagementsupervisor: "",
            }}
            onFinish={() => handlesubmit()}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="overtime"
                                label="Overtime Eligible"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="companyname"
                  label="Contracting Company"
                  rules={[
                    {
                      required: false,
                      message: "Contracting Company is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {ContractingCompany?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="industry"
                  label="Primary Industry"
                  rules={[
                    {
                      required: false,
                      message: "Primary Industry is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {Industry?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="secondaryIndustry"
                  label="Secondary Industry"
                  rules={[
                    {
                      required: false,
                      message: "Secondary Industry is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {secondaryIndustry?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="sector"
                  label="Sector"
                  rules={[
                    {
                      required: false,
                      message: "Sector is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {Sector?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="industryrole"
                  label="Industry Role"
                  rules={[
                    {
                      required: false,
                      message: "Industry Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {IndustryRole?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="regionalsupervisor"
                  label="Regional Competency Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Regional Competency Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    // options={managingOfficeData}
                    allowClear
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="regionalcompetencyrole"
                  label="Regional Competency Role"
                  rules={[
                    {
                      required: false,
                      message: "Regional Competency Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {RegionalCometencyRole.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="officesupervisor"
                  label="Office Competency Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Office Competency Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    allowClear
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="engagementsupervisor"
                  label="Engagement Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Engagement Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="expertise"
                  label="Expertise"
                  rules={[
                    {
                      required: false,
                      message: "Expertise is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Expertise"
                    // maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      required: false,
                      message: "Designation is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Designation"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="immediatesupervisor"
                  label="Immediate Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Immediate Supervisor is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Immediate Supervisor"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="grade"
                  label="Grade"
                  rules={[
                    {
                      required: true,
                      message: "Grade is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {Grade?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="targetchargeability"
                  label="Target Chargeability %"
                  rules={[
                    {
                      required: false,
                      message: "Target Chargeability % is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Target Chargeability %"
                    maxLength={50}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="chargeoutrate"
                  label="Charge Out Rate"
                  rules={[
                    {
                      required: false,
                      message: "Charge Out Rate is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Charge Out Rate"
                    maxLength={50}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Last Promotion Date"
                  name="lastpromotionlevel"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last Promotion Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select Last Promotion Date"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastpromotion"
                  label="Last Promotion Level"
                  rules={[
                    {
                      required: false,
                      message: "Last Promotion is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Last Promotion"
                    maxLength={100}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="jobtitle"
                  label="Job Title"
                  rules={[
                    {
                      required: true,
                      message: "Job title is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Job Title"
                    maxLength={50}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Professional Since"
                  name="professionalsince"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Professional Since is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Professional Since"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Hired Date"
                  name="hireddate"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Hired Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Select Hired Date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employeesponser"
                  label="Employee Sponsor"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employeestatus"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={(value) => {
                      setEmployeeStatusId(value === "active" ? "1" : "0");
                      onFinish();
                    }}
                  >
                    <Option value="active">Active</Option>
                    <Option value="in active">In active</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="relevent"
                  label="Relevant"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    onChange={onFinish}
                    placeholder="description"
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "level" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            <ArrowForwardIosIcon style={iconsize} /> Back
          </button>
          <b>Level:</b>
          <Form
            className=""
            form={form}
            name="addemployeelevelform"
            layout="vertical"
            initialValues={{
              competencyQC: [],
              serviceline: [],
              subserviceline: [],
              competencyrole: [],
              managingoffice: [],
              organisationlevel: [],
            }}
            onFinish={() => handlesubmit()}
          >
            <StyleRow gutter={20}>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="managingoffice"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    options={managingOfficeData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    getPopupContainer={() => containerRef.current}
                    onChange={() => {
                      onFinish();
                      competencyQCfetchData();
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="organisationlevel"
                  label="Organisation Level"
                  rules={[
                    {
                      required: true,
                      message: "Organisation Level is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={form.getFieldValue('managingoffice')?.length == 0}
                    options={organisationLevelData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      competencyQCfetchData();
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="competencyQC"
                  label="Competency/ QC"
                  rules={[
                    {
                      required: true,
                      message: "Competency/ QC is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={competencyQCData?.length == 0}
                    options={competencyQCData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      servicelinefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="serviceline"
                  label="Service Line"
                  rules={[
                    {
                      required: true,
                      message: "Service Line is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={servicelineData?.length === 0}
                    options={servicelineData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      subservicelinefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="subserviceline"
                  label="Sub Serviceline"
                  rules={[
                    {
                      required: true,
                      message: "Sub Serviceline is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={subservicelineData?.length == 0}
                    options={subservicelineData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      departmentfetchData();
                      // competencyrolefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="leveldepartmentname"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Department is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={departmentLevelData?.length == 0}
                    options={departmentLevelData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      // competencyrolefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="competencyrole"
                  label="Office Competency Role"
                  rules={[
                    {
                      required: false,
                      message: "Office Competency Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    {CompetencyRole?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="supervisor"
                  label="Supervisor / Manager"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Supervisor/Manager is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                  />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="manager"
                                label="Manager"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Manager is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  {filteredUserName?.map((value, index) => (
                                    <Option key={index} value={value} label={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col> */}
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "basic" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {" "}
            {pageName} /
          </button>{" "}
          <b>Edit Employee</b>
          <Tabs
            defaultActiveKey={activeTab}
            className="mt-3"
            onChange={handleTabChange}
          >
            {tabTitles.map((title, index) => (
              <TabPane tab={title} key={index.toString()}>
                {activeTab === "0" && (
                  <>
                    <Form
                      className=""
                      form={form}
                      name="addemployeeform"
                      layout="vertical"
                      initialValues={{
                        user_id: "",
                        department_id: "",
                        gender: "",
                        dateof_birth: "",
                        maritalstatus: "",
                        nationality: "",
                        nssf: "",
                        national_id: "",
                        address: "",
                        phoneno: "",
                        overtime: "",
                        start_date: "",
                        emergencycontactname: "",
                        emergencycontactnumber: "",
                        employementtype: "",
                        initial: "",
                        first_name: "",
                        last_name: "",
                        salutation: "",
                        dependent: "",
                        company_Name: "",
                        industry: [],
                        sector: [],
                        industryrole: "",
                        expertise: "",
                        designation: "",
                        immediatesupervisor: "",
                        grade: "",
                        targetchargeability: "",
                        chargeoutrate: "",
                        jobtitle: "",
                        professionalsince: "",
                        hireddate: "",
                        employeesponser: "",
                        relevent: "",
                        description: "",
                        lastpromotionlevel: "",
                        competencyQC: [],
                        serviceline: [],
                        subserviceline: [],
                        competencyrole: [],
                        managingoffice: [],
                        organisationlevel: [],
                        lastpromotion: "",
                        location: "",
                        secondaryIndustry: [],
                        regionalsupervisor: "",
                        regionalcompetencyrole: "",
                        officesupervisor: "",
                        engagementsupervisor: "",
                      }}
                      onFinish={() => {
                        handlesubmit();
                      }}
                    >
                      <b className="mt-2">Edit Level Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="managingoffice"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              options={managingOfficeData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              getPopupContainer={() => containerRef.current}
                              onChange={() => {
                                onFinish();
                                // setDepartmentLevelData([]);
                                competencyQCfetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="organisationlevel"
                  label="Organisation Level"
                  rules={[
                    {
                      required: true,
                      message: "Organisation Level is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={form.getFieldValue('managingoffice')?.length == 0}
                    options={organisationLevelData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      competencyQCfetchData();
                    }}
                  />
                </Form.Item>
              </Col> */}
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="competencyQC"
                            label="Competency/ QC"
                            rules={[
                              {
                                required: true,
                                message: "Competency/ QC is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={competencyQCData?.length === 0}
                              options={competencyQCData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                servicelinefetchData();
                                // setDepartmentLevelData([]);
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="serviceline"
                            label="Service Line"
                            rules={[
                              {
                                required: true,
                                message: "Service Line is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={servicelineData?.length === 0}
                              options={servicelineData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                subservicelinefetchData();

                                // form.setFieldValue("subserviceline", []);
                                // form.setFieldValue("competencyrole", []);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="subserviceline"
                            label="Sub Serviceline"
                            rules={[
                              {
                                required:
                                  subservicelineData &&
                                  subservicelineData.length > 0,
                                message: "Sub Serviceline is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={subservicelineData?.length === 0}
                              options={subservicelineData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                departmentfetchData();
                                // competencyrolefetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>

                        {/* {departmentLevelData?.length !== 0 &&( */}
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="leveldepartmentname"
                            label="Department"
                            rules={[
                              {
                                required: true,
                                message: "Department is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Department"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={departmentLevelData?.length === 0}
                              options={departmentLevelData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                // competencyrolefetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* )} */}

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="competencyrole"
                            label="Competency Role"
                            rules={[
                              {
                                required: true,
                                message: "Competency Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              mode="multiple"
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {CompetencyRole?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="location"
                            label="Location"
                            rules={[
                              {
                                required: true,
                                message: "Location is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {filteredLocation?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value?.location_id}
                                  label={value?.location_name}
                                >
                                  {value?.location_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="supervisor"
                            label="Supervisor / Manager"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Supervisor / Manager is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      <b className="my-2">Edit Work Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="overtime"
                                label="Overtime Eligible"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="companyname"
                            label="Contracting Company"
                            rules={[
                              {
                                required: false,
                                message: "Contracting Company is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {ContractingCompany?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="industry"
                            label="Primary Industry"
                            rules={[
                              {
                                required: false,
                                message: "Primary Industry is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {Industry?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="secondaryIndustry"
                            label="Secondary Industry"
                            rules={[
                              {
                                required: false,
                                message: "Secondary Industry is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {secondaryIndustry?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="sector"
                            label="Sector"
                            rules={[
                              {
                                required: false,
                                message: "Sector is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {Sector?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="industryrole"
                            label="Industry Role"
                            rules={[
                              {
                                required: false,
                                message: "Industry Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {IndustryRole?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="regionalsupervisor"
                            label="Regional Competency Supervisor"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Regional Competency Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                              allowClear
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="regionalcompetencyrole"
                            label="Regional Competency Role"
                            rules={[
                              {
                                required: false,
                                message: "Regional Competency Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                            >
                              {RegionalCometencyRole.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="officesupervisor"
                            label="Office Competency Supervisor"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Office Competency Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Engagement Supervisor"
                            rules={[
                              {
                                required: false,
                                message: "Engagement Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="expertise"
                            label="Expertise"
                            rules={[
                              {
                                required: false,
                                message: "Expertise is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Expertise"
                              // maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[
                              {
                                required: false,
                                message: "Designation is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Designation"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        {/* 
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="immediatesupervisor"
                  label="Immediate Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Immediate Supervisor is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Immediate Supervisor"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col> */}

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="grade"
                            label="Grade"
                            rules={[
                              {
                                required: true,
                                message: "Grade is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {Grade?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="targetchargeability"
                            label="Target Chargeability %"
                            rules={[
                              {
                                required: false,
                                message: "Target Chargeability % is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Target Chargeability %"
                              maxLength={50}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="chargeoutrate"
                            label="Charge Out Rate"
                            rules={[
                              {
                                required: false,
                                message: "Charge Out Rate is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Charge Out Rate"
                              maxLength={50}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Last Promotion Date"
                            name="lastpromotionlevel"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last Promotion Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="Select Last Promotion Date"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastpromotion"
                            label="Last Promotional Level"
                            rules={[
                              {
                                required: false,
                                message: "Last Promotion is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Last Promotion"
                              maxLength={100}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="jobtitle"
                            label="Job Title"
                            rules={[
                              {
                                required: false,
                                message: "Job title is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Job Title"
                              maxLength={50}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Professional Since"
                            name="professionalsince"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Professional Since is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Professional Since"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Hired Date"
                            name="hireddate"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Hired Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Select Hired Date"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employeesponser"
                            label="Employee Sponsor"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employeestatus"
                            label="Status"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={(value) => {
                                setEmployeeStatusId(
                                  value === "active" ? "1" : "0"
                                );
                                onFinish();
                              }}
                            >
                              <Option value="active">Active</Option>
                              <Option value="in active">In active</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="relevent"
                            label="Relevant"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item name="description" label="Description">
                            <Input.TextArea
                              onChange={onFinish}
                              placeholder="description"
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      <b className="my-2">Edit Personal Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="salutation"
                            label="Salutation"
                            placeholder="Select Salutation"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Salutation is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Salutation"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="Mr.">Mr.</Option>
                              <Option value="Dr.">Dr.</Option>
                              <Option value="Prof.">Prof.</Option>
                              <Option value="Ms.">Ms.</Option>
                              <Option value="Mrs.">Mrs.</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="initial"
                            label="Initial"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Initial is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Initial"
                              maxLength={5}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="firstname"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "First name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastname"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="employeeid"
                            label="Employee ID"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Employee ID is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Employee ID"
                              maxLength={15}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z0-9\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="user_id"
                            label="User ID"
                            rules={[
                              {
                                required: true,
                                message: "Email ID is required",
                              },
                              { validator: validateEmployeeId },
                            ]}
                          >
                            <Input
                              placeholder="Email ID"
                              disabled={true}
                              onChange={onFinish}
                              maxLength={60}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="phoneno" label="Phone No">
                            <PhoneInput
                              type="text"
                              placeholder="Phone No"
                              maxLength={20}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Gender is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select gender"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="male">Male</Option>
                              <Option value="female">Female</Option>
                              <Option value="They/ Them">They/ Them</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="dependent" label="No. of Dependants">
                            <Input
                              placeholder="No. of Dependants"
                              maxLength={10}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateof_birth"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Date of Birth is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={() => {
                                onFinish();
                              }}
                              placeholder="Select Date of Birth"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="maritalstatus"
                            label="Marital Status"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="single">Single</Option>
                              <Option value="married">Married</Option>
                              <Option value="widowed">Widowed</Option>
                              <Option value="divorced">Divorced</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="greetings"
                            label="Greetings"
                            rules={[
                              {
                                required: false,
                                message: "Greetings is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {Greetings?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="nationality"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {countryList?.map((country, index) => (
                                <Select.Option
                                  key={index}
                                  value={country}
                                  label={country}
                                >
                                  {country}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactname"
                            label="Emergency Contact Name"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Emergency Contact Name"
                              maxLength={15}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactnumber"
                            label="Emergency Contact Number"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Name is required",
                              },
                            ]}
                          >
                            <PhoneInput
                              placeholder="Emergency Contact Number"
                              maxLength={20}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                              {
                                required: false,
                                message: "Address is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Address"
                              onChange={onFinish}
                              maxLength={60}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9,/]$/.test(e.key) ||
                                  /^[a-zA-Z\s,/]+$/.test(e.key);
                                if (!value && e.key !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>

                      <div className="text-end mt-3">
                        <StyledButton
                          loading={showLoader}
                          type="primary"
                          htmlType="submit"
                        >
                          {showLoader ? "Update" : "Update"}
                        </StyledButton>
                      </div>
                    </Form>
                  </>
                )}
                {activeTab === "1" && (
                  <>
                    <div className="styled-card mb-5">
                      {roleList.length === 0 && (
                        <StyledButton
                          className="mt-2"
                          // onClick={() => setOpenModal(true)}
                        >
                          Add Role
                        </StyledButton>
                      )}
                      {roleList?.length > 0 && (
                        <Checkbox
                          className="mt-3"
                          checked={selectAllRoleChecked}
                          onChange={handleSelectAllRole}
                        >
                          Select All
                        </Checkbox>
                      )}
                      <SelectTable
                        // step={step}
                        // setStep={setStep}
                        columns={rolecolumns}
                        tabledata={roleList}
                        handleClickRole={handlesubmit}
                        handleReset={handleResetAllRole}
                        selectedRoleId={selectedRoleId}
                        showLoader={showLoader}
                      />
                      {/* <FormModal
                      isModalVisible={openModal}
                      closeModal={() => setOpenModal(false)}
                      Name="Role"
                    /> */}
                    </div>
                  </>
                )}

                {activeTab === "2" && (
                  <>
                    <div className="styled-card mb-5">
                      <SelectTable
                        // step={step}
                        // setStep={setStep}
                        columns={baseLocationColumn}
                        tabledata={locationBasedOnRole}
                        editRole="edit"
                        handleClickRole={handlesubmit}
                        selectedBaseLocation={baseLocation}
                        showLoader={showLoader}
                      />
                    </div>
                  </>
                )}
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
      {updateState === "educationdetails" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Education Details:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              educationdetails: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="educationdetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const fromDate = formValues.educationdetails[key]?.fromdate;
                    const toDate = formValues.educationdetails[key]?.todate;

                    return (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Institute Name"
                            name={[name, "institutename"]}
                            rules={[
                              {
                                required: false,
                                message: "Institute Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Institute Name"
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            // {...restField}
                            label="From:"
                            // name={[name, "fromdate"]}
                            // rules={[
                            //   { required: false, message: "Date required" },
                            // ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  key,
                                  "fromdate"
                                )
                              }
                              value={
                                fromDate
                                  ? moment(fromDate, "YYYY-MM-DD").isValid()
                                    ? moment(fromDate, "YYYY-MM-DD")
                                    : null
                                  : null
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            // {...restField}
                            label="To:"
                            // name={[name, "todate"]}
                            // rules={[
                            //   { required: false, message: "Date required" },
                            // ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  key,
                                  "todate"
                                )
                              }
                              value={
                                toDate
                                  ? moment(toDate, "YYYY-MM-DD").isValid()
                                    ? moment(toDate, "YYYY-MM-DD")
                                    : null
                                  : null
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Exam Body:"
                            name={[name, "exambody"]}
                            rules={[
                              {
                                required: false,
                                message: "Exam Body required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Exam Body"
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Awarded:"
                            name={[name, "awarded"]}
                            rules={[
                              { required: false, message: "Awarded required" },
                            ]}
                          >
                            {/* <Input placeholder="Awarded" onChange={onFinish}/> */}
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="Doctorate">Doctorate</Option>
                              <Option value="Master">Master</Option>
                              <Option value="Bachelor">Bachelor</Option>
                              <Option value="Diploma">Diploma</Option>
                              <Option value="Certificate">Certificate</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: false,
                                message: "file is required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data =
                                    reader.result.split(",")[1];
                                  // Pass both the file and base64Data to onSuccess
                                  const uniqueId = generateUniqueId();
                                  onSuccess({ file, base64Data, uniqueId });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              listType="picture"
                              style={{ width: "100%" }}
                              onChange={(value) => {
                                onFinish();
                                uploadFile(value);
                              }}
                              maxCount={1}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start"
                                  size="large"
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    );
                  })}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "practicaltrainings" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Practical Training:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              practicaltrainings: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="practicaltrainings">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Organisation Name"
                          name={[name, "organisationname"]}
                          rules={[
                            {
                              required: false,
                              message: "Organisation Name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Organisation Name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Position Held"
                          name={[name, "positionheld"]}
                          rules={[
                            {
                              required: false,
                              message: "Position Held required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Position Held"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Nature of work & Tasks performed or completed"
                          name={[name, "tasksperformed"]}
                          rules={[
                            {
                              required: false,
                              message:
                                "Nature of work & Tasks performed or completed required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Nature of work & Tasks performed or completed"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workexperience" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Experience:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              workexperience: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="workexperience">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Company name"
                          name={[name, "companyname"]}
                          rules={[
                            {
                              required: false,
                              message: "Company name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Company name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Job Title"
                          name={[name, "jobtitle"]}
                          rules={[
                            { required: false, message: "Job Title required" },
                          ]}
                        >
                          <Input
                            placeholder="Job Title"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Relevant"
                          name={[name, "relevant"]}
                          rules={[
                            { required: false, message: "Relevnt required" },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            getPopupContainer={() => containerRef.current}
                            onChange={onFinish}
                          >
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                          </Select>
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Job Description"
                          name={[name, "jobdescription"]}
                          rules={[
                            {
                              required: false,
                              message: "Job Description required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Job Description"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "professionalcertifications" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Professional Certification:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              professionalcertifications: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="professionalcertifications">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Certification Type"
                          name={[name, "certificationtype"]}
                          rules={[
                            {
                              required: true,
                              message: "Certification Type required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select"
                            optionFilterProp="children"
                            getPopupContainer={() => containerRef.current}
                            defaultValue={AwardList[0]}
                            // onChange={onFinish}
                            onChange={() => {
                              onFinish();
                            }}
                          >
                            {AwardList?.map((value, index) => (
                              <Select.Option
                                key={index}
                                value={value}
                                label={value}
                              >
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Remarks"
                          name={[name, "remarks"]}
                          rules={[
                            { required: true, message: "Remarks required" },
                          ]}
                        >
                          <Input
                            placeholder="Remarks"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Attachment"
                          name={[name, "attachment"]}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message: "file is required",
                            },
                          ]}
                          getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          }}
                        >
                          <Upload
                            customRequest={({ file, onSuccess }) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                const base64Data = reader.result.split(",")[1];
                                const uniqueId = generateUniqueId();
                                onSuccess({ file, base64Data, uniqueId });
                              };

                              reader.onerror = (error) => {
                                console.error("File read error:", error);
                              };
                            }}
                            name="logo"
                            // action="/upload.do"
                            onChange={(value) => {
                              onFinish();
                              uploadFile(value);
                            }}
                            listType="picture"
                            style={{ width: "100%" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Button className="w-100 text-start" size="large">
                                Upload File
                              </Button>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {/* <button onClick={() => setUpdateState("")}>back</button> */}
    </EditProfileBox>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  roleList: getRoleList(state),
  ClientId: getClientid(state),
  bandList: getBandList(state),
  locationList: getLocations(state),
  supervisorList: getSupervisorList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(EditProfile);
