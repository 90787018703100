import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div`
   padding : 3%;
   min-height: 100%;
   background:#ffffff;
   .version{
     padding: 2%;
     margin-bottom:1%;
   }
   p {
        width: 75%;
        word-spacing:3px;
        letter-spacing:0.5px;
    }
   span{
      font-size:14px;
   }
`;

const StyledNoData = styled.div`
   .nodata {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 20%;
  }
`;

function ReleasedNote() {

  const versions = []

  return (
    <>
      { versions.length > 0 ?
        <StyledContainer>
          {versions.map((item,index) => {
            return (
                <div className='version'>
                    <h3>{item.title} <span>{item.type}</span></h3>
                    <h6>Release Date : {item.Date}</h6>
                    <br/>
                    <h5>Feature</h5>
                    <p>
                    {item.content}
                    </p>
               </div>
            )
          })}
        </StyledContainer>
        : 
        <StyledNoData>
          <div className='nodata'>
            <p>No Data</p>
          </div>
        </StyledNoData>
      }
    </>
  )
}

export default ReleasedNote