import { Card, Col, Input, Row, Spin } from 'antd'
import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux';
import { getMenuList, getUserList, getUserRoleId } from 'redux/selectors';
import Wrapper, { CardImage } from './style';
import folder from 'assets/folder.svg'
import DetailListing from './DetailListing';
import { SearchOutlined } from '@ant-design/icons';


const { Meta } = Card;

function HRDocuments({ userList, menuList, userRoleId }) {
    const [showComponent, setShowComponent] = useState(1)
    const [selectedItem, setSelectedItem] = useState({})
    const [searchTerm, setSearchTerm] = useState('');
    const [dataSource, setDataSource] = useState(userList)

    const Menulist = menuList[userRoleId] || Object.values(menuList)[0];


    const handleClick = (item) => {
        setShowComponent(2)
        setSelectedItem(item)
    }


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredDataSource = dataSource?.filter((item) =>
        item.fullname.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const MemoizedCard = useMemo(() => {
        return React.memo(({ item, description = false }) => (

            <Wrapper>
                <Card
                    key={item.id}
                    hoverable
                    cover={
                        <CardImage className='px-4'>
                            <img
                                alt="example"
                                src={folder}
                            />
                        </CardImage>
                    }
                    bordered={true}
                    className='cards'
                    onClick={() => handleClick(item)}
                >
                    {description ? (
                        <Meta
                            title={<small>{item?.fullname}-{item?.employeeid}</small>}
                        // description={`Created on ${moment(item?.insertedAt).format("DD MMM YYYY")}`}
                        />
                    ) : (
                        <Meta
                            title={<small>{item?.fullname}-{item?.employeeid}</small>}

                        />
                    )}
                </Card>
            </Wrapper>
        ));
    }, []);

    const searchStyle = {margin:'10px',maxWidth: '400px'}
    return (
        <Wrapper>
            {showComponent === 1 && (
                <>
                    <h5>Documents</h5>
                    <Input
                        placeholder="Search"
                        onChange={handleSearchChange}
                        suffix={<SearchOutlined />}
                        style={searchStyle}
                    />
                    <div className='card-container'>
                        {filteredDataSource?.map((item, index) => (
                            <>
                                <MemoizedCard item={item} />
                            </>

                        ))}
                    </div>
                </>
            )}
            {showComponent === 2 && (
                <>
                    <DetailListing item={selectedItem} goback={() => setShowComponent(1)} MenuList={Menulist}/>
                </>
            )}

        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    userList: getUserList(state),
    menuList: getMenuList(state),
    userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(HRDocuments);
