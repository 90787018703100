import React from "react";
import {
  Row,
  Col,
  Card,
  Modal,
  Avatar,
  Spin,
  TimePicker,
  Tabs,
  Table,
  message,
  Button
} from "antd";
import { useState, useEffect } from "react";
import COLORS from "common/Colors";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getUserInfo,
  getNotificationList,
  getUserRoleId,
  getProfilePic,
  getClientid,
  getMenuList,
  getUserList,
} from "redux/selectors";
import { get, startCase } from "lodash";
import { DEVICE } from "common/Device";
import ChangePasswordModal from "organisms/ChangePasswordModal";
import { bindActionCreators } from "redux";
import { clearStore, setIsUserLoggedIn, setProfilePic } from "redux/actions";
import { Switch,Input } from "antd";
import { CALL_API } from "common/API";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TabPane from "antd/lib/tabs/TabPane";
import Icon from "atoms/Icon";
import { ButtonFilled } from "reusableComponent/UIButtons";
import ViewMore from "organisms/ViewMore";
import EditProfile from "organisms/EditProfile";
import download from "downloadjs";
import { STATUS_CODE } from "common/Constants";
import { SearchOutlined } from '@ant-design/icons';
import Details from "./HRPolicy/Details";

const StyledProfileContainer = styled.div`
  .right {
    display: flex;
    justify-content: end;
    gap: 20px;
    width: 100%;
    background: #e6e6e6;
    padding: 15px;
    border-radius: 5px;
    @media (max-width: 376px) {
      flex-direction: column;
      font-size: 12px;
    }
  }
  @media ${DEVICE.tablet} {
    .second_half_info {
      margin-top: 15px;
    }
  }
`;
const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;

const StyledTable = styled(Table)`
  padding: 0;
  margin-bottom: 0;
  table {
    table-layout: auto !important;
  }
  .ant-table-wrapper {
    margin-bottom: 0;
    border: 2px solid red !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #e2e2e8;

    border-radius: 8px;
  }
  .ant-table-cell {
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #1f243b;
  }
  .ant-table-tbody > tr > td {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 100px;
  width: 100px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  color: #000;
  border-radius: 10%;
  border: 3px solid black;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }

  @media screen and (max-width: 430px) {
    padding-left: 8%;
  }
`;

const StyledDocumentModal = styled(Modal)`
  height: 100vh;
  max-width: 80rem;
  padding: 1%;
  width: 100%;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 80rem;
    transform: scale(0.85);
  }

  .iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: scale(0.85);
  }
  .file_fit {
    width: 100%;
    height: 100%;
  }
  .img_fit {
    max-width: 80vw;
    max-height: 100vh;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  .main_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 912px) {
    .second_half_info {
      margin-top: -25px;
    }
  }
`;

const StyledCard1 = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 10px;
  .main_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    .mb-2 {
      font-size: 18px;
      padding-left: 0px !important;
    }
  }
  @media (max-width: 426px) {
    padding: 0px;
    .main_div {
      gap: 20px;
    }
  }
`;
const StyleRow = styled(Row)`
  .sub_title {
    font-weight: bold;
  }
  @media (max-width: 426px) {
    font-size: 10px;
    .row-gap {
      padding-bottom: 10px;
    }
    .ant-picker-input > input {
      font-size: 10px;
    }
  }
`;
const StyledTab = styled.div`
  .ant-tabs-nav {
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: #000;
    background-color: ${COLORS.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
  .personal_details_box {
    padding: 10px;
    box-shadow: 0px 3px 6px #00000015;
  }
  .border-style {
    border-bottom: 1px solid #f2f2f2;
    border-width: 90%;
  }
`;

const ResetPasswordLink = styled.div`
  cursor: pointer;
  color: #000;
  font-weight: bold;
  .ant-btn {
    border: 1px solid rgb(45, 83, 218) !important;
    color: #000 !important;
  }
`;

function MyProfile({
  userInfo,
  userRoleId,
  profilePic,
  ClientId,
  menuList,
  userList,
  setUserLoggedIn,
}) {

  console.log('menulist--->',menuList)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const filteruser = userList?.filter(
    (item) => item?.userid === get(userInfo, "userid", "")
  );

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];
  const filtermenulist = Menulist?.filter((item) => item?.key === "profile");

  let tabMenus = filtermenulist[0]?.subMenu?.filter(
    (item) => item?.key === "mydetails" || item?.key === "reportpreference" || item?.key === "companypolicy"
  );
  let newArray = [];
  if(tabMenus){
    newArray?.push(...tabMenus);
  }
  

  const items =
    newArray &&
    newArray
      ?.filter((data) => data?.access === true)
      .map((data, index) => ({
        id: index,
        label: data?.title,
        component: <></>,
      }));

  const [attendanceReport, setAttendanceReport] = useState(false);
  const [overtimeReport, setOvertimeReport] = useState(false);
  const [earlyCheckoutReport, setEarlyCheckoutReport] = useState(false);
  const [lateCheckinReport, setLateCheckinReport] = useState(false);
  const [absentReport, setAbsentReport] = useState(false);
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [attendanceReportId, setAttendanceReportId] = useState(0);
  const [overtimeReportId, setOvertimeReportId] = useState(0);
  const [earlyCheckoutReportId, setEarlyCheckoutReportId] = useState(0);
  const [lateCheckinReportId, setLateCheckinReportId] = useState(0);
  const [absentReportId, setAbsentReportId] = useState(0);
  const [attendanceReportTime, setAttendanceReportTime] = useState();
  const [overtimeReportTime, setOvertimeReportTime] = useState();
  const [earlyCheckoutReportTime, setEarlyCheckoutReportTime] = useState();
  const [lateCheckinReportTime, setLateCheckinReportTime] = useState();
  const [absentReportTime, setAbsentReportTime] = useState();
  const [activeTab, setActiveTab] = useState(items ? items[0]?.label : "");
  const [updateState, setUpdateState] = useState("");
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [previewType, setPrreviewType] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [showComponent, setShowComponent] = useState(1);
  const [isEdit, setIsEdit] = useState(false);



  const goBack = () => {
    setIsEdit(false);
    setShowComponent(1);
  };



  const logoutbtnstyle = { borderColor: "#000", color: "#000" };

  let history = useHistory();

  const handleTabSelect = (key) => {
    const activeTab = items.find((item) => item.id === key);
    if (activeTab) {
      setActiveTab(activeTab.label);
    }
  };

  const logoutUser = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to logout?",
      okText: "Logout",
      cancelText: "Cancel",
      cancelButtonProps: {
        style: logoutbtnstyle,
      },
      onOk: () => {
        setTimeout(() => {
          history.push("/");
          setUserLoggedIn(false);
        }, 500);
      },
    });
  };

  const getPreferenceData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const response = await CALL_API(`user-preference/${ClientId}`, "get");
      response.map((val) => {
        if (val?.reportId === 1) {
          setAttendanceReport(val?.statusName === "true" ? true : false);
          setAttendanceReportId(val?.userPreferenceId);
          setAttendanceReportTime(moment(val?.userPreferenceTime, "HH:mm"));
        } else if (val?.reportId === 2) {
          setOvertimeReport(val?.statusName === "true" ? true : false);
          setOvertimeReportId(val?.userPreferenceId);
          setOvertimeReportTime(moment(val?.userPreferenceTime, "HH:mm"));
        } else if (val?.reportId === 3) {
          setLateCheckinReport(val?.statusName === "true" ? true : false);
          setLateCheckinReportId(val?.userPreferenceId);
          setLateCheckinReportTime(moment(val?.userPreferenceTime, "HH:mm"));
        } else if (val?.reportId === 4) {
          setEarlyCheckoutReport(val?.statusName === "true" ? true : false);
          setEarlyCheckoutReportId(val?.userPreferenceId);
          setEarlyCheckoutReportTime(moment(val?.userPreferenceTime, "HH:mm"));
        } else if (val?.reportId === 5) {
          setAbsentReport(val?.statusName === "true" ? true : false);
          setAbsentReportId(val?.userPreferenceId);
          setAbsentReportTime(moment(val?.userPreferenceTime, "HH:mm"));
        }
      });

      toggleReportLoader(false);
    }
  };

  const handleChange = async (id, val, time) => {
    CALL_API(`user-preference/${ClientId}/${id}`, "patch", {
      status_name: val,
      user_preference_time: time,
    });
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(2000);
    getPreferenceData();
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  useEffect(() => {
    setEmployeeDetails({
      userid: filteruser[0]?.userid || "",
      startdate: filteruser[0]?.startdate || "",
      dateofbirth: filteruser[0]?.dateofbirth || "",
      enddate: filteruser[0]?.enddate || "",
      bandname: filteruser[0]?.bandname || "",
      bandid: filteruser[0]?.bandid || "",
      overtimestatus: filteruser[0]?.overtimestatus || "",
      salary: filteruser[0]?.salary || "",
      profileurl: filteruser[0]?.profileurl || null,
      gender: filteruser[0]?.gender || "",
      age: filteruser[0]?.age || "",
      maritalstatus: filteruser[0]?.maritalstatus || "",
      nationality: filteruser[0]?.nationality || "",
      facerecognition: filteruser[0]?.facerecognition || "",
      roleid: filteruser[0]?.roleid || "",
      uan: filteruser[0]?.uan || "",
      pan: filteruser[0]?.pan || "",
      address: filteruser[0]?.presentaddress || "",
      phoneno: filteruser[0]?.phoneno || "",
      institutename: filteruser[0]?.institutename || "",
      degree: filteruser[0]?.degree ? filteruser[0]?.degree : [],
      specialization: filteruser[0]?.specialization || "",
      yearofcompletion: filteruser[0]?.yearofcompletion || "",
      jobtitle: filteruser[0]?.jobtitle || "",
      professionalsince: filteruser[0]?.professionalsince || "",
      hireddate: filteruser[0]?.hireddate || "",
      relevant: filteruser[0]?.relevant || "",
      national_id: filteruser[0]?.national_id || "",
      nssf: filteruser[0]?.nssf || "",
      employeesponser: filteruser[0]?.employeesponser || "",
      statusname: filteruser[0]?.statusname || "",
      jobdescription: filteruser[0]?.jobdescription || "",
      emergencycontactname: filteruser[0]?.emergencycontactname || "",
      emergencycontactnumber: filteruser[0]?.emergencycontactnumber || "",
      statusid: filteruser[0]?.statusid || "",
      employementtype: filteruser[0]?.employementtype || "",
      baselocationid: filteruser[0]?.baselocationid || "",
      employeeid: filteruser[0]?.employeeid || "",
      initial: filteruser[0]?.initial || "",
      firstname: filteruser[0]?.firstname || "",
      lastname: filteruser[0]?.lastname || "",
      salutation: filteruser[0]?.salutation || "",
      competencyQC: filteruser[0]?.competencyQC ? filteruser[0]?.competencyQC : [],
      serviceline: filteruser[0]?.serviceline ? filteruser[0]?.serviceline : [],
      subserviceline: filteruser[0]?.subserviceline
        ? filteruser[0]?.subserviceline
        : [],
      competencyrole: filteruser[0]?.competencyrole
        ? filteruser[0]?.competencyrole
        : [],
      industry: filteruser[0]?.industry ? filteruser[0]?.industry : [],
      sector: filteruser[0]?.sector ? filteruser[0]?.sector : [],
      secondaryIndustry: filteruser[0]?.secondaryindustry ? filteruser[0]?.secondaryindustry : [],
      industryrole: filteruser[0]?.industryrole ? filteruser[0]?.industryrole : [],
      expertise: filteruser[0]?.expertise || "",
      designation: filteruser[0]?.designation || "",
      immediatesupervisor: filteruser[0]?.immediatesupervisor || "",
      grade: filteruser[0]?.grade || "",
      targetchargeability: filteruser[0]?.targetchargeability || "",
      chargeoutrate: filteruser[0]?.chargeoutrate || "",
      greetings: filteruser[0]?.greetings || "",
      professionalcertification: filteruser[0]?.professionalcertification
        ? filteruser[0]?.professionalcertification
        : [],
      lastpromotionlevel: filteruser[0]?.lastpromotionlevel || "",
      dependent: filteruser[0]?.dependent || "",
      companyname: filteruser[0]?.companyname || "",
      educationdetails: filteruser[0]?.educationdetails || [],
      workexperience: filteruser[0]?.workexperience || [],
      professionalcertifications: filteruser[0]?.professionalcertifications ? filteruser[0]?.professionalcertifications : [],
      practicaltrainings: filteruser[0]?.practicaltrainings || [],
      managingoffice: filteruser[0]?.managingoffice || [],
      organisationlevel: filteruser[0]?.organisationlevel || [],
      manager: filteruser[0]?.manager || "",
      supervisor: filteruser[0]?.supervisor || "",
      lastpromotion: filteruser[0]?.lastpromotion ||  "",
      levellocationid: filteruser[0]?.levellocationid || "",
      leveldepartmentname: filteruser[0]?.leveldepartmentname || [],
      regionalsupervisor: filteruser[0]?.regionalsupervisor || "",
      officesupervisor: filteruser[0]?.officesupervisor || "",
      engagementsupervisor: filteruser[0]?.engagementsupervisor || "",
    });
  }, [userList]);

  useEffect(() => {
    getPolicyData()
    getPreferenceData();
  }, [userRoleId]);

  const personalDet = [
    {
      data: "Salutation",
      value: filteruser[0]?.salutation || "-",
    },
    {
      data: "Initial",
      value: filteruser[0]?.initial || "-",
    },
    {
      data: "First Name",
      value: filteruser[0]?.firstname || "-",
    },
    {
      data: "Last Name",
      value: filteruser[0]?.lastname || "-",
    },
    {
      data: "Employee ID",
      value: filteruser[0]?.employeeid || "-",
    },
    {
      data: "User ID",
      value: filteruser[0]?.userid || "-",
    },
    {
      data: "Phone No",
      value: filteruser[0]?.phoneno || "-",
    },
    {
      data: "No. of Dependants",
      value: filteruser[0]?.dependent || "-",
    },
    {
      data: "Date Of Birth",
      value: filteruser[0]?.dateofbirth
        ? moment(filteruser[0]?.dateofbirth).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Marital Status",
      value: filteruser[0]?.maritalstatus
        ? filteruser[0]?.maritalstatus.charAt(0).toUpperCase() +
          filteruser[0]?.maritalstatus.slice(1)
        : "-",
    },
    {
      data: "Greetings",
      value: filteruser[0]?.greetings || "-",
    },
    {
      data: "Age",
      value: filteruser[0]?.age || "-",
    },
    {
      data: "Gender",
      value: filteruser[0]?.gender
        ? filteruser[0]?.gender.charAt(0).toUpperCase() +
          filteruser[0]?.gender.slice(1)
        : "-",
    },
    {
      data: "Country",
      value: filteruser[0]?.nationality
        ? filteruser[0]?.nationality.charAt(0).toUpperCase() +
          filteruser[0]?.nationality.slice(1)
        : "-",
    },
    {
      data: "Address",
      value: filteruser[0]?.presentaddress || "-",
    },
    {
      data: "Emergency Contact Name",
      value: filteruser[0]?.emergencycontactname || "-",
    },
    {
      data: "Emergency Contact Number",
      value: filteruser[0]?.emergencycontactnumber || "-",
    },
  ];

  const WorkInfoDetails = [
    {
      data: "Competency/ QC",
      value:
        typeof filteruser[0]?.competencyQC === "string"
          ? filteruser[0]?.competencyQC
            ? [filteruser[0]?.competencyQC]
            : "-"
          : filteruser[0]?.competencyQC?.join(", ") || "-",
    },
    {
      data: "Service Line",
      value:
        typeof filteruser[0]?.serviceline === "string"
          ? filteruser[0]?.serviceline
            ? [filteruser[0]?.serviceline]
            : "-"
          : filteruser[0]?.serviceline?.join(", ") || "-",
    },
    {
      data: "Sub Serviceline",
      value:
        typeof filteruser[0]?.subserviceline === "string"
          ? filteruser[0]?.subserviceline
            ? [filteruser[0]?.subserviceline]
            : "-"
          : filteruser[0]?.subserviceline?.join(", ") || "-",
    },
    {
      data: "Competency Role",
      value:
        typeof filteruser[0]?.competencyrole === "string"
          ? filteruser[0]?.competencyrole.length
            ? [filteruser[0]?.competencyrole]
            : "-"
          : filteruser[0]?.competencyrole?.join(", ") || "-",
    },
    {
      data: "Regional Supervisor",
      value: filteruser[0]?.regionalsupervisor || "-",
    },
    {
      data: "Office Supervisor",
      value: filteruser[0]?.officesupervisor || "-",
    },
    {
      data: "Engagement Supervisor",
      value: filteruser[0]?.engagementsupervisor,
    },
    {
      data: "Contracting Company",
      value: filteruser[0]?.companyname || "-",
    },
    {
      data: "Primary Industry",
      value:
        typeof filteruser[0]?.industry === "string"
          ? filteruser[0]?.industry
            ? [filteruser[0]?.industry]
            : "-"
          : filteruser[0]?.industry?.join(", ") || "-",
    },
    {
      data: "Secondary Industry",
      value:
        typeof filteruser[0]?.secondaryindustry === "string"
          ? filteruser[0]?.secondaryindustry
            ? [filteruser[0]?.secondaryindustry]
            : "-"
          : filteruser[0]?.secondaryindustry?.join(", ") || "-",
    },
    {
      data: "Sector",
      value:
        typeof filteruser[0]?.sector === "string"
          ? filteruser[0]?.sector
            ? [filteruser[0]?.sector]
            : "-"
          : filteruser[0]?.sector?.join(", ") || "-",
    },
    {
      data: "Expertise",
      value: filteruser[0]?.expertise || "-",
    },
    {
      data: "Industry Role",
      value:
        typeof filteruser[0]?.industryrole === "string"
          ? filteruser[0]?.industryrole
            ? [filteruser[0]?.industryrole]
            : "-"
          : filteruser[0]?.industryrole?.join(", ") || "-",
    },
    // {
    //   data: "Immediate Supervisor",
    //   value: filteruser[0]?.immediatesupervisor || "-",
    // },
    {
      data: "Designation",
      value: filteruser[0]?.designation || "-",
    },
    {
      data: "Grade",
      value: filteruser[0]?.grade || "-",
    },
    {
      data: "Target Chargeability %",
      value: filteruser[0]?.targetchargeability || "-",
    },
    {
      data: "Charge Out Rate",
      value: filteruser[0]?.chargeoutrate || "-",
    },
    {
      data: "Last Promotion Date",
      value: filteruser[0]?.lastpromotionlevel
        ? moment(filteruser[0]?.lastpromotionlevel).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Professional Since",
      value: filteruser[0]?.professionalsince
        ? moment(filteruser[0]?.professionalsince).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Last Promotion Level",
      value: filteruser[0]?.lastpromotion ? filteruser[0]?.lastpromotion : "-",
    },
    {
      data: "Job Title",
      value: filteruser[0]?.jobtitle || "-",
    },
    {
      data: "Hired Date",
      value: filteruser[0]?.hireddate
        ? moment(filteruser[0]?.hireddate).format("DD-MM-YYYY")
        : "-",
    },
    {
      data: "Employee Sponsor",
      value: filteruser[0]?.employeesponser || "-",
    },
    {
      data: "Status",
      value: filteruser[0]?.statusid === "1" ? "Active" : "In Active" || "-",
    },
  ];

  const EducationList = [
    {
      instituteName: filteruser[0]?.institutename || "-",
      degreeDiploma: filteruser[0]?.degree || "-",
      specialization: filteruser[0]?.specialization || "-",
      yearofCompletion: filteruser[0]?.yearofcompletion
        ? moment(filteruser[0]?.yearofcompletion).format("DD-MM-YYYY")
        : "-",
    },
  ];

  const wrokExperenceList = [
    {
      Companyname: filteruser?.companyname || "-",
      jobTitle: filteruser?.jobtitle || "-",
      fromDate:
        moment(filteruser?.professionalsince).format("DD-MM-YYYY") || "-",
      jobDescription: filteruser?.jobdescription || "-",
      relevant: filteruser?.relevant || "-",
    },
  ];

  const EducationColumns = [
    {
      title: "Institute Name",
      dataIndex: "institutename",
      key: "institutename",
      width: "5%",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate && moment(fromdate).isValid()) {
          return fromdate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate && moment(todate).isValid()) {
          return todate;
        } else {
          return "-";
        }
      },
    },
    
    {
      title: "Exam Body",
      dataIndex: "exambody",
      key: "exambody",
      align: "center",
      width: "5%",
    },
    {
      title: "Awarded",
      dataIndex: "exambody",
      key: "exambody",
      align: "center",
      width: "5%",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      align: "center",
      width: "5%",
      render: (attachment, record) => {
        // return <p className="text-info">{attachment[0]?.name}</p>
        if (attachment[0]?.name !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(attachment[0]?.name?.split(".")?.pop());
                handlePreviewClick(
                  record?.attachment[0]?.documentId,
                  "educationdetails",
                  attachment[0]?.name
                )
              }
              }
            >
              {attachment[0]?.name}
            </button>
          );
        } else {
          return "-"
        }
      }
    },
  ];

  const handlePreviewClick = async (id, type, document) => {
    // setPrreviewType();
    const preview = document?.split(".")?.pop()
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "preview-user-document",
      documentId: id,
      identityName: type,
      userId: filteruser?.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (
        preview === "jpg" ||
        preview === "png" ||
        preview === "jpeg" ||
        preview === "svg" ||
        preview === "pdf"
      ) {
        setIsPreviewModalOpen(true);
        setPreviewURL(url);
      } else {
        download(url);
      }
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

  const PersenalcertificationColumns = [
    {
      title: "Certification Type",
      dataIndex: "certificationtype",
      key: "certificationtype",
      width: "5%",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate) {
          return fromdate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate) {
          return todate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      width: "5%",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      width: "5%",
      render: (attachment, record) => {
        // return <p className="text-info">{attachment[0]?.name}</p>
        if (attachment[0]?.name !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(attachment[0]?.name?.split(".")?.pop());
                handlePreviewClick(
                  record?.attachment[0]?.documentId,
                  "professionalcertifications",
                  attachment[0]?.name
                )
              }
              }
            >
              {attachment[0]?.name}
            </button>
          );
        } else {
          return "-"
        }
      }
    },
  ];

  const PracticalTrainingColumns = [
    {
      title: "Organisation Name",
      dataIndex: "organisationname",
      key: "organisationname",
      width: "5%",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate) {
          return fromdate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate) {
          return todate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Position Held",
      dataIndex: "positionheld",
      key: "positionheld",
      align: "center",
      width: "5%",
    },
    {
      title: "Nature of work & Tasks performed or completed",
      dataIndex: "tasksperformed",
      key: "tasksperformed",
      width: "5%",
      className : 'normal-column',
      render: (tasksperformed) => {
        return <ViewMore value={tasksperformed} count={30} />
      }
    },
  ];

  const wrokExperenceColumns = [
    {
      title: "Company name",
      dataIndex: "companyname",
      key: "companyname",
    },
    {
      title: "Job Title",
      dataIndex: "jobtitle",
      key: "jobtitle",
    },
    {
      title: "From",
      dataIndex: "fromdate",
      key: "fromdate",
      width: "5%",
      render: (fromdate) => {
        if (fromdate) {
          return fromdate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "To",
      dataIndex: "todate",
      key: "todate",
      width: "5%",
      render: (todate) => {
        if (todate) {
          return todate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Job Description",
      dataIndex: "jobdescription",
      key: "jobdescription",
      className : 'normal-column',
      render: (jobdescription) => {
        return <ViewMore value={jobdescription} />
      }
    },
    {
      title: "Relevant",
      dataIndex: "relevnt",
      key: "relevnt",
      align: "center",
    },
  ];

  const getPolicyData = async () => {
    setLoading(true);
    const response = await CALL_API(`hrpolicy/${ClientId}`, "get", {});
    setLoading(false);
    setDataSource(response);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterbyrole = dataSource?.filter((item)=>item?.roleId?.includes(userRoleId))

  const filteredDataSource = filterbyrole?.filter((item) =>
    item.policyTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const policyColumns = [
    {
      title: "Policy Name",
      dataIndex: "policyTitle",
      key: "policyName",
    },
    {
      title: "Type",
      dataIndex: "policyType",
      key: "policyType",
    },
    // {
    //   title: "Policy Effective Date",
    //   dataIndex: "policyEffectiveDate",
    //   key: "policyEffectiveDate",
    //   render: (date) => {
    //     const dateFormat = moment(date).format("DD MMM YYYY");
    //     return `${dateFormat}`;
    //   },
    // },
    // {
    //   title: "Last Updated",
    //   dataIndex: "lastUpdated",
    //   key: "lastUpdated",
    //   render: (_, record) => (
    //     <>
    //     <Icon name="edit"/>
    //     <Icon name="delete"/>
    //     </>
    //   ),
    // },
  ]

  return (
    <>
      {updateState === "" ? (
        <StyledProfileContainer>
          <div className="d-flex">
            <StyledStaffNameContainer>
              {profilePic === null ||
              profilePic === "" ||
              Array.isArray(profilePic) ? (
                <>
                  <StyledAvatar size="default">
                    {startCase(get(userInfo, "name", "").charAt(0))}
                  </StyledAvatar>
                </>
              ) : (
                <>
                  <StyledAvatar src={profilePic} size="default"></StyledAvatar>
                </>
              )}
            </StyledStaffNameContainer>
            <div className="px-4">
              <b>{filteruser[0]?.fullname}</b>
              <br></br>
              <b>Employee Id: {filteruser[0]?.employeeid}</b>
              <br></br>
              <b>User Id: </b> {filteruser[0]?.userid}
              <br></br>
              <div className="d-flex">
                <div>
                  <b>Ph: </b>
                  {filteruser[0]?.phoneno ? filteruser[0]?.phoneno : "-"}
                </div>
                <div className="mx-4">|</div>
                <div>
                  <b>Email: </b>
                  {filteruser[0]?.userid}
                </div>
              </div>
            </div>
          </div>

          <StyledTab>
            <Tabs
              defaultActiveKey={activeTab}
              className="mt-3"
              onChange={handleTabSelect}
              items={items?.map((val) => {
                return {
                  label: val?.label,
                  key: val?.id,
                  children: val?.component,
                };
              })}
            ></Tabs>
            {activeTab === "My Details" && (
              <>
                <>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <b>Personal Information</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() => setUpdateState("personalinformation")}
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <Row className="personal_details_box px-3">
                    {personalDet.map((data, index) => {
                      return (
                        <Col xs={24} lg={12} key={index}>
                          <StyledRow className="p-0 m-0">
                            <Col sm={24} md={12} className="px-2 text-wrap">
                              <b className="pb-1 pt-2">{data.data}: </b>
                            </Col>
                            <Col
                              sm={24}
                              md={12}
                              className="text-wrap px-2 p-0 m-0"
                            >
                              <p className="m-0 p-1 label_value_Color">
                                {data.value}
                              </p>
                            </Col>
                          </StyledRow>
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="my-2  d-flex justify-content-between align-items-center">
                    <b className="mt-2">Work Information</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() => setUpdateState("workinformation")}
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <Row className="personal_details_box px-3">
                    {WorkInfoDetails.map((data, index) => {
                      return (
                        <Col xs={24} lg={12} key={index}>
                          <StyledRow className="p-0 m-0">
                            <Col sm={24} md={12} className="px-2 text-wrap">
                              <b className="pb-1 pt-2">{data.data}: </b>
                            </Col>
                            <Col
                              sm={24}
                              md={12}
                              className="text-wrap px-2 p-0 m-0"
                            >
                              <p className="m-0 p-1 label_value_Color">
                                {data.value}
                              </p>
                            </Col>
                          </StyledRow>
                        </Col>
                      );
                    })}
                  </Row>

                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <b className="mt-3">Education Details:</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() => setUpdateState("educationdetails")}
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <StyledTable
                    dataSource={filteruser[0]?.educationdetails}
                    columns={EducationColumns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="role_id"
                    pagination={false}
                  />

                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <b className="mt-3">Professional Certification:</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() =>
                          setUpdateState("professionalcertifications")
                        }
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <StyledTable
                    dataSource={filteruser[0]?.professionalcertifications}
                    columns={PersenalcertificationColumns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="role_id"
                    pagination={false}
                  />

                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <b className="mt-3">Practical Training:</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() => setUpdateState("practicaltrainings")}
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <StyledTable
                    dataSource={filteruser[0]?.practicaltrainings}
                    columns={PracticalTrainingColumns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="role_id"
                    pagination={false}
                  />

                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <b className="mt-3">Work Experience:</b>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "profile" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mydetails" &&
                            subItem?.permission.edit
                        )
                    ) && (
                      <ButtonFilled
                        className="px-1"
                        onClick={() => setUpdateState("workexperience")}
                      >
                        <Icon name="edit"></Icon>
                      </ButtonFilled>
                    )}
                  </div>
                  <StyledTable
                    className="mb-5"
                    dataSource={filteruser[0]?.workexperience}
                    columns={wrokExperenceColumns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="role_id"
                    pagination={false}
                  />
                </>
              </>
            )}
            {activeTab === "Report Preference" && (
              <Row className="profile-row" gutter={24}>
                
                  <>
                    <Col md={24} sm={24} xs={24} lg={24}>
                      <StyledCard1 bordered={false}>
                        <Spin spinning={displayReportLoader}>
                          <div className="main_div">
                            <Col className=" mt-1 mb-2" lg={24}>
                              <b>Report Preferences</b>
                            </Col>

                            <StyleRow gutter={16}>
                              <Col
                                className="mb-1 sm-mb-4 row-gap"
                                xs={12}
                                lg={8}
                              >
                                Attendance Report
                              </Col>
                              <Col
                                className="align-center row-gap"
                                xs={12}
                                lg={4}
                              >
                                <Switch
                                  size="small"
                                  checked={attendanceReport}
                                  disabled={
                                    Menulist?.find(
                                      (item) =>
                                        item?.key === "profile" &&
                                        item?.subMenu?.find(
                                          (subItem) =>
                                            subItem?.key === "reportpreference" &&
                                            subItem?.permission.edit
                                        )
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    handleChange(
                                      attendanceReportId,
                                      attendanceReport === true
                                        ? "false"
                                        : "true",
                                      attendanceReportTime.format("HH:mm")
                                    );
                                    setAttendanceReport(
                                      attendanceReport === true ? false : true
                                    );
                                  }}
                                />
                              </Col>
                              <Col className="mb-1" xs={12} lg={6}>
                                Period : Daily{" "}
                              </Col>
                              <Col className="align-center" xs={12} lg={6}>
                                {attendanceReport ? (
                                  <TimePicker
                                    value={attendanceReportTime}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      handleChange(
                                        attendanceReportId,
                                        attendanceReport === true
                                          ? "true"
                                          : "false",
                                        val.format("HH:mm")
                                      );
                                      setAttendanceReportTime(
                                        moment(val, "HH:mm")
                                      );
                                    }}
                                    clearIcon={null}
                                  />
                                ) : (
                                  <TimePicker
                                    value={attendanceReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    clearIcon={null}
                                  />
                                )}
                              </Col>
                            </StyleRow>

                            <StyleRow gutter={16}>
                              <Col className="mb-1 row-gap" xs={12} lg={8}>
                                Overtime Report
                              </Col>
                              <Col
                                className="align-center row-gap"
                                xs={12}
                                lg={4}
                              >
                                <Switch
                                  size="small"
                                  checked={overtimeReport}
                                  disabled={
                                    Menulist?.find(
                                      (item) =>
                                        item?.key === "profile" &&
                                        item?.subMenu?.find(
                                          (subItem) =>
                                            subItem?.key === "reportpreference" &&
                                            subItem?.permission.edit
                                        )
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    handleChange(
                                      overtimeReportId,
                                      overtimeReport === true
                                        ? "false"
                                        : "true",
                                      overtimeReportTime.format("HH:mm")
                                    );
                                    setOvertimeReport(
                                      overtimeReport === true ? false : true
                                    );
                                  }}
                                />
                              </Col>
                              <Col className="mb-1" xs={12} lg={6}>
                                Period : Daily{" "}
                              </Col>
                              <Col className="align-center" xs={12} lg={6}>
                                {overtimeReport ? (
                                  <TimePicker
                                    value={overtimeReportTime}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      handleChange(
                                        overtimeReportId,
                                        overtimeReport === true
                                          ? "true"
                                          : "false",
                                        val.format("HH:mm")
                                      );
                                      setOvertimeReportTime(
                                        moment(val, "HH:mm")
                                      );
                                    }}
                                    clearIcon={null}
                                  />
                                ) : (
                                  <TimePicker
                                    value={overtimeReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    clearIcon={null}
                                  />
                                )}
                              </Col>
                            </StyleRow>

                            <StyleRow>
                              <Col xs={14} lg={12} className="sub_title">
                                Exception Report
                              </Col>
                            </StyleRow>

                            <StyleRow gutter={16}>
                              <Col className="mb-1 row-gap" xs={12} lg={8}>
                                Late Check-in Report
                              </Col>
                              <Col
                                className="align-center row-gap"
                                xs={12}
                                lg={4}
                              >
                                <Switch
                                  size="small"
                                  checked={lateCheckinReport}
                                  disabled={
                                    Menulist?.find(
                                      (item) =>
                                        item?.key === "profile" &&
                                        item?.subMenu?.find(
                                          (subItem) =>
                                            subItem?.key === "reportpreference" &&
                                            subItem?.permission.edit
                                        )
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    handleChange(
                                      lateCheckinReportId,
                                      lateCheckinReport === true
                                        ? "false"
                                        : "true",
                                      lateCheckinReportTime.format("HH:mm")
                                    );
                                    setLateCheckinReport(
                                      lateCheckinReport === true ? false : true
                                    );
                                  }}
                                />
                              </Col>
                              <Col className="mb-1" xs={12} lg={6}>
                                Period : Daily{" "}
                              </Col>
                              <Col className="align-center" xs={12} lg={6}>
                                {lateCheckinReportTime ? (
                                  <TimePicker
                                    value={lateCheckinReportTime}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      handleChange(
                                        lateCheckinReportId,
                                        lateCheckinReport === true
                                          ? "true"
                                          : "false",
                                        val.format("HH:mm")
                                      );
                                      setLateCheckinReportTime(
                                        moment(val, "HH:mm")
                                      );
                                    }}
                                    clearIcon={null}
                                  />
                                ) : (
                                  <TimePicker
                                    value={lateCheckinReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    clearIcon={null}
                                  />
                                )}
                              </Col>
                            </StyleRow>

                            <StyleRow gutter={16}>
                              <Col className="mb-1 row-gap" xs={12} lg={8}>
                                Early Check-out Report
                              </Col>
                              <Col className="align-center" xs={12} lg={4}>
                                <Switch
                                  size="small"
                                  checked={earlyCheckoutReport}
                                  disabled={
                                    Menulist?.find(
                                      (item) =>
                                        item?.key === "profile" &&
                                        item?.subMenu?.find(
                                          (subItem) =>
                                            subItem?.key === "reportpreference" &&
                                            subItem?.permission.edit
                                        )
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    handleChange(
                                      earlyCheckoutReportId,
                                      earlyCheckoutReport === true
                                        ? "false"
                                        : "true",
                                      earlyCheckoutReportTime.format("HH:mm")
                                    );
                                    setEarlyCheckoutReport(
                                      earlyCheckoutReport === true
                                        ? false
                                        : true
                                    );
                                  }}
                                />
                              </Col>
                              <Col className="mb-1 row-gap" xs={12} lg={6}>
                                Period : Daily{" "}
                              </Col>
                              <Col className="align-center" xs={12} lg={6}>
                                {earlyCheckoutReportTime ? (
                                  <TimePicker
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    value={earlyCheckoutReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      handleChange(
                                        earlyCheckoutReportId,
                                        earlyCheckoutReport === true
                                          ? "true"
                                          : "false",
                                        val.format("HH:mm")
                                      );
                                      setEarlyCheckoutReportTime(
                                        moment(val, "HH:mm")
                                      );
                                    }}
                                    clearIcon={null}
                                  />
                                ) : (
                                  <TimePicker
                                    value={earlyCheckoutReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    clearIcon={null}
                                  />
                                )}
                              </Col>
                            </StyleRow>

                            <StyleRow gutter={16}>
                              <Col className="mb-1 row-gap" xs={12} lg={8}>
                                Absent Report
                              </Col>
                              <Col
                                className="align-center row-gap"
                                xs={12}
                                lg={4}
                              >
                                <Switch
                                  size="small"
                                  checked={absentReport}
                                  disabled={
                                    Menulist?.find(
                                      (item) =>
                                        item?.key === "profile" &&
                                        item?.subMenu?.find(
                                          (subItem) =>
                                            subItem?.key === "reportpreference" &&
                                            subItem?.permission.edit
                                        )
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    handleChange(
                                      absentReportId,
                                      absentReport === true ? "false" : "true",
                                      absentReportTime.format("HH:mm")
                                    );
                                    setAbsentReport(
                                      absentReport === true ? false : true
                                    );
                                  }}
                                />
                              </Col>
                              <Col className="mb-1" xs={12} lg={6}>
                                Period : Daily{" "}
                              </Col>
                              <Col className="align-center" xs={12} lg={6}>
                                {absentReportTime ? (
                                  <TimePicker
                                    value={absentReportTime}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      handleChange(
                                        absentReportId,
                                        absentReport === true
                                          ? "true"
                                          : "false",
                                        val.format("HH:mm")
                                      );
                                      setAbsentReportTime(moment(val, "HH:mm"));
                                    }}
                                    clearIcon={null}
                                  />
                                ) : (
                                  <TimePicker
                                    value={absentReportTime}
                                    format={"HH:mm"}
                                    disabled={
                                      Menulist?.find(
                                        (item) =>
                                          item?.key === "profile" &&
                                          item?.subMenu?.find(
                                            (subItem) =>
                                              subItem?.key === "reportpreference" &&
                                              subItem?.permission.edit
                                          )
                                      )
                                        ? false
                                        : true
                                    }
                                    clearIcon={null}
                                  />
                                )}
                              </Col>
                            </StyleRow>
                          </div>
                        </Spin>
                      </StyledCard1>
                    </Col>
                  </>
              </Row>
            )}
            {activeTab === "Company Policy" &&(
              <>
              {!isEdit && showComponent === 1 && (
        <>
          <Row gutter={16}>
            <Col md={6} className="">
            {/* <b>Filter by</b> */}
              <Input
                className="mb-2"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                suffix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <br />
          <Table
            dataSource={filteredDataSource}
            columns={policyColumns}
            pagination={false}
            loading={loading}
            // Note: Second params needed
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                    setSelectedRow(record);
                    setShowComponent(2)
                },
              };
            }}
          />
        </>
      )}
      {!isEdit && showComponent === 2 && (
        <Details
          pagetitle = "Company Policy"
          selectedRow={selectedRow}
          ClientId={ClientId}
          userInfo={userInfo}
          goBack={goBack}
          getPolicyData={getPolicyData}
        />
      )}
            </>
            )}
          </StyledTab>
        </StyledProfileContainer>
      ) : (
        <EditProfile
          updateState={updateState}
          setUpdateState={setUpdateState}
          EmployeeDetails={EmployeeDetails}
          pageName="Profile"
        />
      )}
      <StyledDocumentModal
        centered={true}
        visible={isPreviewModalOpen}
        footer={false}
        keyboard={false}
        width="100%"
        height="100vh"
        onOk={() => setIsPreviewModalOpen(false)}
        onCancel={() => setIsPreviewModalOpen(false)}
      >
        <Spin spinning={isLoadingDocuments}>
          {(previewType === "jpg" ||
            previewType === "png" ||
            previewType === "jpeg" ||
            previewType === "svg"
          ) ? (
            <div className="img">
              <img src={previewURL} className="img_fit" alt="Preview" />
            </div>)
            :
            (<embed
              title="PDF Viewer"
              src={previewURL}
              type="application/pdf"
              width="100%"
              height="600px"
              className="pdf-viewer-embed px-5"
            />
            )}
        </Spin>
      </StyledDocumentModal>
      <ChangePasswordModal
        openResetPasswordModal={openResetPasswordModal}
        toggleResetPasswordModal={() =>
          setOpenResetPasswordModal(!openResetPasswordModal)
        }
      ></ChangePasswordModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  notificationList: getNotificationList(state),
  userRoleId: getUserRoleId(state),
  profilePic: getProfilePic(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  userList: getUserList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveProfilePic: setProfilePic,
      removeStoreData: clearStore,
      setUserLoggedIn: setIsUserLoggedIn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
