import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from 'store/configureStore';

const initialState = {
    formSectionDetail: {
        title: "Untitled form",
        description: 'Form description'
    },
    formQuestionDetail: [{ question: "Test", options: {}, formType: "", id: 1, requiredSwitch: false }]
};

export const formSlice = createSlice({
    name: 'formbuilder',
    initialState,
    reducers: {
        formtitle: (state, action) => {
            state.formSectionDetail = action.payload;
        },
        formQuestions: (state, action) => {
            state.formQuestionDetail = JSON.parse(JSON.stringify(action?.payload))
            // state.formSectionDetail = Object.freeze(action.payload);
        }
    }

});

export const selectFormDetail = (state) => state?.formbuilder?.formSectionDetail;

export const { formtitle } = formSlice.actions;

export const selectFormQuestions = (state) => state.formbuilder?.formQuestionDetail;

export const { formQuestions } = formSlice.actions;

export default formSlice.reducer;
