import {
    Typography,
    IconButton,
    Box,
} from '@mui/material';
import React from 'react';
import { Row, Col } from "react-bootstrap"
import InputAndOptions from './InputandOptions';
import { Button } from 'antd'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom"

const questionStyle = { fontSize: "16px", fontWeight: "medium" };
export default function ResponseForm({
    fullFormDetail,
    // formbasicDetail,
    // savedForm,
    setAnswerResponse,
    selectedItem
    // setSubmitedAns,
    // setSelectedTab
}) {
    const [questionDetails, setQuestionDeatils] = React.useState(selectedItem?.questions);
    const [validationResponseError, setValidationResponseError] =
        React.useState(false);
    const [requiredValidation, setRequiredValidation] = React.useState({});
    const [requiredAllAnswered, setRequiredAllAnswered] =
        React.useState(true);
    const [newAnswer, setNewAnswer] =
        React.useState(fullFormDetail);

        React.useEffect(() => {
            setNewAnswer(fullFormDetail)
        }, [fullFormDetail]);


    React.useEffect(() => {
        setQuestionDeatils(selectedItem?.questions);
    }, [selectedItem]);
    const handleOnChange = (
        event,
        questionDetails,
        indexs
    ) => {
        newAnswer.forEach((element) => {
            if (
                element.formType === 'PARAGRAPH' ||
                element.formType === 'MULIPLE_CHOICE' ||
                element.formType === 'TIME' ||
                element.formType === 'DATE' ||
                element.formType === 'SHORT_ANS' ||
                element.formType === 'DROPDOWN'
            ) {
                if (element.id == questionDetails.id) {
                    //Condition for required switch
                    if (element.requiredSwitch === true) {
                        if (event.target.value == '' || null || undefined) {
                            setRequiredValidation((prevState) => ({
                                ...prevState,
                                [element.id]: true
                            }));
                        } else {
                            element.answer = event.target.value;
                            setRequiredValidation((prevState) => ({
                                ...prevState,
                                [element.id]: false
                            }));
                        }
                    } else {
                        element.answer = event.target.value;
                    }
                }
            }

            if (element.formType === 'CHECKBOX') {
                // Condition checking the question id and the user answered id
                if (element.id == questionDetails.id) {
                    let checkboxList = element.options;
                    checkboxList.forEach(
                        (checked, chekboxidx) => {
                            if (chekboxidx === indexs) {
                                //Condition for required checkbox
                                if (element.requiredSwitch === true) {
                                    if (event.target.checked == false) {
                                        setRequiredValidation((prevState) => ({
                                            ...prevState,
                                            [element.id]: true
                                        }));
                                    } else {
                                        setRequiredValidation((prevState) => ({
                                            ...prevState,
                                            [element.id]: false
                                        }));
                                        checked.isChecked = event.target.checked;
                                        element.answer = checkboxList;
                                    }
                                } else {
                                    checked.isChecked = event.target.checked;
                                    element.answer = checkboxList;
                                }
                            }
                        }
                    );
                }
            }
        });

        setAnswerResponse(newAnswer);
    };

    const onSubmitResponse = async () => {
        console.log("newAnswer..", newAnswer)
        // setSubmitedAns(false);
        // setAnswerResponse(newAnswer);
        await newAnswer.forEach((element) => {
            if (
                element.requiredSwitch === true &&
                element.answer == ('' || undefined || null)
            ) {
                setRequiredValidation((prevState) => ({
                    ...prevState,
                    [element.id]: true
                }));
            }
        });

        //cheking full object of requiredValidation({1:true,2:true,3:false}) is their any true exist
        Object.keys(requiredValidation).filter(function (key) {
            if (requiredValidation[key] === true) {
                setRequiredAllAnswered(true);
                setValidationResponseError(true);
            } else if (requiredValidation[key] === false) {
                setRequiredAllAnswered(false);
            }
        });
        // if (requiredAllAnswered === false) {
        setAnswerResponse(newAnswer);
        console.log('success');
        console.log("newAnswer==>", JSON.stringify(newAnswer))
        // setSubmitedAns(true);
        // setSelectedTab(2);
        // }
    };

    const handleCloseSnackBar = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setValidationResponseError(false);
    };

    const validationError = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackBar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <section className='form_builder main_container'>
                <Row className='d-flex justify-content-center'>
                    <Col md={10} lg={7} sm={12}>
                        <div className='bg_light rounded-2 mb-3 mt-2'>
                            <div className="p-4">
                                <Typography variant="h5">{selectedItem?.title}</Typography>
                                <Box className='py-3'>{selectedItem?.description}</Box>
                            </div>
                        </div>

                        <div>
                            {questionDetails?.map((form, idx) => {
                                return (

                                    <div className="bg_light rounded-2 mb-3">
                                        <div className="p-4">

                                            <Typography variant="div" sx={questionStyle} >
                                                {form?.question}
                                            </Typography>
                                            {form?.requiredSwitch && (
                                                <span>*</span>
                                            )}

                                            {/* Select Option */}
                                            <InputAndOptions
                                                form={form}
                                                requiredValidation={requiredValidation}
                                                onChange={(e, form, idx) => handleOnChange(e, form, idx)}
                                                idx={idx}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            {/* <Box className='text-center mt-3 mb-5'>
                                <Link to="/form-response">
                                    <Button variant="primary" onClick={onSubmitResponse}>Submit</Button>
                                </Link>
                            </Box> */}

                            {/* <Snackbar
                                open={validationResponseError}
                                autoHideDuration={3000}
                                onClose={handleCloseSnackBar}
                                message="Please fill all the required input"
                                action={validationError}
                            /> */}
                        </div>
                    </Col>
                </Row>
            </section>
        </React.Fragment>
    );
}
