import React from 'react';
import Dashboard from 'pages/Dashboard';
import Employee from 'pages/Employee';
import Task from 'pages/Task';
import Notification from 'pages/Notification';
import Calendar from 'pages/Calendar';
import Approval from 'pages/Approval';
import AttendanceReport from 'pages/AttendanceReport';
import LeaveReport from 'pages/LeaveReport';
import BreakReport from 'pages/BreakReport';
import OvertimeReport from 'pages/OvertimeReport';
import MyProfile from 'pages/my-profile';
import Team from 'pages/Team';
import Chat from 'pages/Chat';
import Documents from 'pages/Documents';
import MyDocuments from 'pages/MyDocuments';
import Location from 'pages/Location';
import Department from 'pages/Department';
import Trends from 'pages/Trends';
import Productivity from 'pages/Productivity';
import Demographics from 'pages/Demographics';
import LateCheckIn from 'pages/LateCheckIn';
import EarlyCheckOut from 'pages/EarlyCheckOut';
import AbsendReport from 'pages/AbsendReport';
import Myleavereport from 'pages/Myleavereport';
import ReleasedNote from 'pages/ReleasedNote';
import MonthlyAttendanceReport from 'pages/MonthlyAttendanceReport';
import Roles from 'pages/Roles';
import Shift from 'pages/Shift';
import RolesAndPermissions from 'pages/RolesAndPermissions';
import Break from 'pages/Break';
import LeaveCategories from 'pages/LeaveCategories';
import Holiday from 'pages/holiday';
import CloseAccount from 'pages/CloseAccount';
import EmployeeDetails from 'organisms/EmployeeDetails';
import ManagerDashboard from 'pages/ManagerDashboard';
import HrDashboard from 'pages/HrDashboard';
import Permission from 'pages/Permission';
import ProfileDetails from 'pages/ProfileDetails';
import HRPolicy from 'pages/HRPolicy';
import Band from 'pages/Band/index';
import AITymeplus from 'pages/AITymeplus/index';
import Ownerdashboard from 'pages/OwnerDashboard';
import Appraisal from 'pages/Appraisal';
import AddAppraisal from 'pages/AddAppraisal';
import AppraisalResponse from 'pages/AppraisalResponse';
import MyAppraisal from 'pages/MyAppraisal';
import FormBuilder from 'pages/FormBuilder';
import Recruitment from 'pages/Recruitment';
import RecruitmentTab from 'pages/RecruitmentTab';
import Onboarding from 'pages/Onboarding/Onboarding';
import Contract from 'pages/Contract';
import Addjob from 'pages/AddJob';
import TemplateContract from 'pages/Contract/TemplateContract';
import IssueContract from 'pages/Contract/IssueContract';
import HRDocuments from 'pages/HRDocuments';
import FormResponse from 'pages/FormResponse';

const Routes = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    content: () => <Dashboard />
  },
  {
    path: 'employee',
    title: 'Employees',
    content: () => <Employee />
  },
  {
    path: 'task',
    title: 'Task',
    content: () => <Task />
  },
  {
    path: 'mytask',
    title: 'My Task',
    content: () => <Task />
  },
  {
    path: 'notification',
    title: 'Notification',
    content: () => <Notification />
  },
  {
    path: 'calendar',
    title: 'Calendar',
    content: () => <Calendar />
  },
  {
    path: 'location',
    title: 'Location',
    content: () => <Location />
  },
  {
    path: 'approvals',
    title: 'Approval',
    content: () => <Approval />
  },
  {
    path: 'attendance-report',
    title: 'Attendance Report',
    content: () => <AttendanceReport />
  },
  {
    path: 'leave-report',
    title: 'Leave Report',
    content: () => <LeaveReport />
  },
  {
    path: 'break-report',
    title: 'Break Report',
    content: () => <BreakReport />
  },
  {
    path: 'overtime-report',
    title: 'Overtime Report',
    content: () => <OvertimeReport />
  },
  {
    path: 'my-profile',
    title: 'my-profile',
    content: () => <MyProfile />
  },
  {
    path: 'team',
    title: 'team',
    content: () => <Team />
  },
  {
    path: 'chat/:id',
    title: 'chat',
    content: () => <Chat />
  },
  {
    path: 'documents/:id',
    title: 'documents',
    content: () => <Documents />
  },
  {
    path: 'mydocuments',
    title: 'my-documents',
    content: () => <MyDocuments />
  },
  {
    path: 'department',
    title: 'Departments',
    content: () => <Department />
  },
  {
    path: 'trends',
    title: 'Trends',
    content: () => <Trends />
  },
  {
    path: 'productivity',
    title: 'Productivity',
    content: () => <Productivity />
  },
  {
    path: 'demographics',
    title: 'Demographics',
    content: () => <Demographics />
  },
  {
    path: 'Late-check-in',
    title: 'Late check-in',
    content: () => <LateCheckIn />
  },
  {
    path: 'Early-check-out',
    title: 'Early check-out',
    content: () => <EarlyCheckOut />
  },
  {
    path: 'Absent-report',
    title: 'Absent report',
    content: () => <AbsendReport />
  }, {
    path: 'myleavereport',
    title: 'Leave History',
    content: () => <Myleavereport />
  },
  {
    path: 'released-notes',
    title: 'Released Notes',
    content: () => <ReleasedNote />
  },
  {
    path: 'attendance-report-monthly',
    title: 'Monthly Attendance Report',
    content: () => <MonthlyAttendanceReport />
  },
  {
    path: 'roles',
    title: 'Role',
    content: () => <Roles />
  },
  {
    path: 'shift',
    title: 'Shift',
    content: () => <Shift />
  },
  {
    path: 'rolesandpermissions',
    title: 'Roles and permissions',
    content: () => <RolesAndPermissions />
  },
  {
    path: 'break',
    title: 'Break',
    content: () => <Break />
  },
  {
    path: 'leave-categories',
    title: 'Leave Categories',
    content: () => <LeaveCategories />
  },
  {
    path: 'holiday',
    title: 'Holiday',
    content: () => <Holiday />
  },
  {
    path: 'close-account',
    title: 'Close Account',
    content: () => <CloseAccount />
  },
  {
    path: 'employeedetails/:id',
    title: 'employeedetails',
    content: () => <EmployeeDetails />
  },
  {
    path: 'permission/:roleId/:roleName',
    title: 'Permission',
    content: () => <Permission />
  },
  {
    path: 'managerdashboard',
    title: 'Manager Dashboard',
    content: () => <ManagerDashboard />
  },
  {
    path: 'hrdashboard',
    title: 'Hr Dashboard',
    content: () => <HrDashboard />
  },
  {
    path: 'band',
    title: 'band',
    content: () => <Band />
  },
  {
    path: 'profile-details/:id',
    title: 'Profile Details',
    content: () => <ProfileDetails />
  },
  {
    path: 'policies',
    title: 'HR Policy',
    content: () => <HRPolicy />
  },
  {
    path: 'tymeplusai',
    title: 'Tymeplus AI',
    content: () => <AITymeplus />,
  },
  {
    path: 'ownerdashboard',
    title: 'Dashboard',
    content: () => <Ownerdashboard />,
  },
  {
    path: "appraisal",
    title: "Appraisal",
    content: () => <Appraisal />,
  },
  {
    path: "myappraisal",
    title: "My Appraisal",
    content: () => <MyAppraisal />,
  },
  {
    path: "form-response",
    title: "Appraisal",
    content: () => <AppraisalResponse />,
  },
  {
    path: "add-appraisal",
    title: "Appraisal",
    content: () => <AddAppraisal />,
  },

  {
    path: "formbuilder",
    title: "Form Builder",
    content: () => <FormBuilder />,
  },
  {
    path: 'recruitment',
    title: 'Recruitment',
    content: () => <Recruitment />
  },
  {
    path: 'onboard',
    title: 'OnBoard',
    content: () => <Onboarding />
  },
  {
    path: 'recruitment-tab',
    title: 'RecruitmentTab',
    content: () => <RecruitmentTab />
  },
  {
    path: 'contract',
    title: 'Contract',
    content: () => <Contract />
  },
  {
    path: 'addjob',
    title: 'Addjob',
    content: () => <Addjob />
  },
  {
    path: 'myteam',
    title: 'My Team',
    content: () => <Team />
  },
  {
    path: 'contract-template',
    title: 'Contract Template',
    content: () => <TemplateContract />
  },
  {
    path: 'issue-contract',
    title: 'Issue Contract',
    content: () => <IssueContract />
  },
  {
    path: 'hr-documents',
    title: 'Documents',
    content: () => <HRDocuments />
  },
  {
    path: '/form/:clientId/:id',
    title: 'Form',
    content: () => <FormResponse />
  }
];

export default Routes;
