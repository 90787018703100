import React, { useRef, useState } from 'react';
import { Card, Col, Row, Spin, message, Modal } from 'antd';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RoundIcon from 'atoms/RoundIcon';
import AddEditPolicy from './AddEditPolicy';
import COLORS from 'common/Colors';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CALL_API } from 'common/API';
import { Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { STATUS_CODE } from 'common/Constants';
import Icon from 'atoms/Icon';
import styled from 'styled-components';
import download from 'downloadjs';


const StyledDocumentModal = styled(Modal)`
  height: 100vh;
  max-width: 80rem;
  padding: 1%;
  width: 100%;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 80rem;
    transform: scale(0.85);
  }

  .iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: scale(0.85);
  }
  .file_fit {
    width: 100%;
    height: 100%;
  }
  .img_fit {
    max-width: 80vw;
    max-height: 100vh;
  }
`;

const StyledCol = styled(Col)`
  margin: 10px;
  .normal-column {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }`;

const StyledIcon = styled(Icon)`
height: 60px;
width: 60px;
`;

function Details({ selectedRow, ClientId, userInfo, goBack, getPolicyData, deletePolicy, Menulist, pagetitle }) {

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const [previewURL, setPreviewURL] = useState("");
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewType, setPrreviewType] = useState("");

  const viewpolicy = async () => {
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "preview-policy-document",
      policyId: selectedRow?.policyId
    });
    if (code === STATUS_CODE.SUCCESS) {
        setPreviewURL(url);
      } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

  const deleteData = async (id) => {
    deletePolicy(id);
    goBack();
    await CALL_API(`hrpolicy/${ClientId}?policyId=${selectedRow?.policyId}`, 'delete', {});
  };
  const generatePDF = () => {
    setLoading(true);
    const reportElement = containerRef.current;
    const reportWidth = 595.28;
    const reportHeight = 841.89;
    const margin = 20;

    html2canvas(reportElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'pt', [reportWidth, reportHeight]);

      const contentWidth = reportWidth - 2 * margin;
      const contentHeight = (canvas.height / canvas.width) * contentWidth;

      let yOffset = 0;
      let remainingHeight = contentHeight;

      while (remainingHeight > 0) {
        if (yOffset > 0) {
          pdf.addPage();
        }

        const pageHeight = Math.min(remainingHeight, reportHeight - 2 * margin);

        pdf.addImage(imgData, 'PNG', margin, margin + yOffset, contentWidth, pageHeight);

        yOffset += pageHeight;
        remainingHeight -= pageHeight;
      }

      pdf.save(`${selectedRow?.policyTitle}.pdf`);
      setLoading(false);
    });
  };

  useEffect(()=>{
    viewpolicy()
  },[])



  const Menu = () => {
    const iconStyle = { color: '#000', fontSize: '18px' };
    return (
      <div>
        <Row gutter={16} className="d-flex gap-2">
          <RoundIcon onClick={generatePDF}>
            {loading ? <Spinner animation="border" variant="light" size="sm" /> : <LocalPrintshopIcon style={iconStyle} />}
          </RoundIcon>
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.edit)) && (
            <RoundIcon onClick={() => setIsEdit(true)}>
              <EditIcon style={iconStyle} />
            </RoundIcon>
          )}
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.delete)) && (
            <RoundIcon onClick={() => deleteData(selectedRow?.policyId)}>
              <DeleteIcon style={iconStyle} />
            </RoundIcon>
          )}
        </Row>
      </div>
    );
  };

  const titleStyle = { color: `${COLORS.BLACK}`, cursor: 'pointer' };

  const handlePreviewClick = async (id, policyid, name) => {
    // setPrreviewType();
    const preview = name?.split(".")?.pop()
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      
        "method": "preview-policy-document",
        "documentId": id,
        "policyId": policyid,
    
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (
        preview === "jpg" ||
        preview === "png" ||
        preview === "jpeg" ||
        preview === "svg" ||
        preview === "pdf"
      ) {
        setIsPreviewModalOpen(true);
        setPreviewURL(url);
      } else {
        download(url);
      }
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

const cursorstyle = {cursor:"pointer"}
  return (
    <div>
      <h6>
        <strong style={titleStyle} onClick={() => goBack()}>
          {pagetitle}
        </strong>{' '}
        <span> {">"} {selectedRow?.policyTitle}</span>
      </h6>
      <br />
      {!isEdit && (
        <div >
          <Card type="inner" title={selectedRow?.policyTitle} >
            {/* <div ref={containerRef} dangerouslySetInnerHTML={{ __html: selectedRow?.policyDetails }} /> */}
            {selectedRow?.policyDocument.length > 0 && (
          <StyledCol>
            <div className="inreview_boxes_container">
              <Row className="roles_location_review_wrap">
                {selectedRow?.policyDocument?.map((item, key) => {
                  return (
                    <>
                      <Col sm={24} md={8} xl={6} className="" key={key} style={cursorstyle} onClick={()=>{setPrreviewType(item?.name?.split(".")?.pop());handlePreviewClick(item?.documentId,selectedRow?.policyId,item?.name)}}>
                      <Icon name="pdf" style={{ StyledIcon }} />
                        <p className="normal-column">{item?.name}</p>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          </StyledCol>
        )}
          </Card>
          {/* <Spin spinning={isLoadingDocuments}>
          <embed
          title="PDF Viewer"
          src={previewURL}
          type="application/pdf"
          width="100%"
          height="600px"
          className="pdf-viewer-embed"
        />
        </Spin> */}
        </div>
      )}
      {isEdit && (
        <div>
          {/* <h6>
        <strong style={titleStyle} onClick={() => goBack()}>
          HR Policies
        </strong>{' '}
        <span> {">"} {selectedRow?.policyTitle}</span>
      </h6> */}
          <AddEditPolicy ClientId={ClientId} userInfo={userInfo} value={selectedRow} goBack={() => goBack()} getPolicyData={() => getPolicyData()} />
        </div>
      )}
      <StyledDocumentModal
        centered={true}
        visible={isPreviewModalOpen}
        footer={false}
        keyboard={false}
        width="100%"
        height="100%"
        onOk={() => setIsPreviewModalOpen(false)}
        onCancel={() => setIsPreviewModalOpen(false)}
      >
        <Spin spinning={isLoadingDocuments}>
          {(previewType === "jpg" ||
            previewType === "png" ||
            previewType === "jpeg" ||
            previewType === "svg"
          ) ? (
            <div className="img">
              <img src={previewURL} className="img_fit" alt="Preview" />
            </div>)
            :
            (
              <div className="iframe">
                
            <iframe className="file_fit" src={previewURL} align="middle" />
          </div>
          
            )}
        </Spin>
      </StyledDocumentModal>
    </div>
  );
}

export default Details;
