import { VALIDATE_CHARACTER } from "common/Constants";

export const getAddRoleJson = (data) => {
  const AddRoleForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Role Name",
              type: "text",
              name: "rolename",
              Placeholder: "Role Name",
              value: data["rolename"],
              maxLength: 35,
              validationRules: [
                {
                  required: true,
                  message: `Role Name is required`,
                },
                // Note : need for future
                // {
                //   validator: (rule, value, callback) => {
                //     if (value && !VALIDATE_CHARACTER.test(value)) {
                //       callback("Role Name should not contain special characters");
                //     } else {
                //       callback();
                //     }
                //   },
                // }
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Next",
              type: "button",
              position: "end",
              textColor: "white",
              event: "submit",
            },
            // {
            //   id: 2,
            //   label: "Reset",
            //   type: "button",
            //   position: "end",
            //   textColor: "white",
            //   event: "submit",
            // },
          ],
        ],
      },
    ],
  };

  return {
    AddRoleForm,
  };
};
