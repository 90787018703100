import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from '@emotion/styled';
import LeaveDonutChart from './LeaveDonutChart';
import { LEAVE_ICON, LEAVE_INFO } from 'common/Constants';
import COLORS from 'common/Colors';
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getLeaveCategoryList,
  getSelectedDepartmentId,
  getClientid
} from 'redux/selectors';
import DepartmentLeaveBalance from './DepartmentLeaveBalance';
import { find, get } from 'lodash';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import DepartmentStaffLeaveBalance from './DepartmentStaffLeaveBalance';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function LeaveBifurcationReport({
  userRoleId,
  leaveCategoryList,
  selectedDepartmentId,
  clientId
}) {

  const dummydata = [
    {
      "personalleave": [
          {
              "type": "available",
              "value": 2004
          },
          {
              "type": "consumed",
              "value": 1328
          }
      ]
  },
  {
      "annualleave": [
          {
              "type": "available",
              "value": 3006
          },
          {
              "type": "consumed",
              "value": 1105
          }
      ]
  },
  {
      "paternityleave": [
          {
              "type": "available",
              "value": 1208
          },
          {
              "type": "consumed",
              "value": 183
          }
      ]
  },
  {
      "maternityleave": [
          {
              "type": "available",
              "value": 2103
          },
          {
              "type": "consumed",
              "value": 180
          }
      ]
  },
  {
      "sickleave": [
          {
              "type": "available",
              "value": 2564
          },
          {
              "type": "consumed",
              "value": 1392
          }
      ]
  }
]

// const filterleaves = [];
// leaveCategoryList?.forEach((item) => {
//   if (item?.departmentId?.length > 0 && item?.locationId?.length > 0 ){
//         if(intersection(item?.departmentId, filterdepartmentid).length > 0 &&
//         intersection(item?.locationId, filterlocationid).length > 0){
//           filterleaves.push(item);
//         }
//       }else {
//         if(intersection(item?.locationId, filterlocationid).length > 0) {
//         filterleaves.push(item);
//       }
//     }
      
// });
  const [displayDepartmentLeaveModal, toggleDepartmentLeaveModal] = useState(false);
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] = useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState('');
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);

  const [annualLeaveData, setAnnualLeaveData] = useState([
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: COLORS.LEAVE.ANNUAL_AVAILABLE },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.ANNUAL_CONSUMED },
  ]);
  const [sickLeaveData, setSickLeaveData] = useState([
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: COLORS.LEAVE.SICK_AVAILABLE },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);
  const [ sickLeavehalfpayData, setSickLeavehalfpayData ] = useState([
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: COLORS.LEAVE.SICK_AVAILABLE },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);
  const [ restdadsData, setrestDaysData ] = useState([
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: COLORS.LEAVE.SICK_AVAILABLE },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);

  // useEffect(() => {
  //   if ((userRoleId !== 1 && selectedDepartmentId !== 0) || (userRoleId !== 6 && selectedDepartmentId !== 0) || userRoleId === 1 || userRoleId === 6) {
  //     getStatistics();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (userRoleId !== 1 || userRoleId !== 6) {
  //     getStatistics();
  //   }
  // }, [selectedDepartmentId])

  // const getStatistics = async () => {
  //   toggleLeaveReportLoader(true);
  //   if(clientId){
  //   const data = await CALL_API(`leave-service/${clientId}`, 'post', {
  //     method: 'overallTotalLeavesAvailableAndConsumed',
  //     parameters: {
  //       year: dateInDetail(getKenyanDateTime()).year,
  //       departmentId: ['']
  //     },
  //   });
  //   console.log("data", data)
  //   let annualAvailable = 0
  //   let annualConsumed = 0
  //   let sickAvailable = 0
  //   let sickConsumed = 0
  //   let sickleavehalfpayAvailable = 0
  //   let sickleavehalfpayConsumed = 0
  //   let restdaysAvailable = 0
  //   let restdaysConsumed = 0
  //   dummydata.map(list => {
  //     Object.entries(list).forEach(([key, value]) => {
  //       annualAvailable += value.annual.available
  //       annualConsumed += value.annual.consumed
  //       sickAvailable += value.sick.available
  //       sickConsumed += value.sick.consumed
  //       sickleavehalfpayAvailable += value.sickLeaveHalfpayHalfpay.available
  //       sickleavehalfpayConsumed += value.sickLeaveHalfpayHalfpay.consumed
  //       restdaysAvailable += value.restdays.available
  //       restdaysConsumed += value.restdays.consumed
  //     })
  //   })
  //   let dupeLeaveData = Array.from(annualLeaveData);
  //   dupeLeaveData[0].value = annualAvailable - annualConsumed
  //   dupeLeaveData[1].value = annualConsumed
  //   setAnnualLeaveData(dupeLeaveData);

  //   dupeLeaveData = Array.from(sickLeaveData);
  //   dupeLeaveData[0].value = sickAvailable - sickConsumed
  //   dupeLeaveData[1].value = sickConsumed
  //   setSickLeaveData(dupeLeaveData);

  //   dupeLeaveData = Array.from(sickLeavehalfpayData);
  //   dupeLeaveData[0].value = sickleavehalfpayAvailable - sickleavehalfpayConsumed
  //   dupeLeaveData[1].value = sickleavehalfpayConsumed
  //   setSickLeavehalfpayData(dupeLeaveData);

  //   dupeLeaveData = Array.from(restdadsData);
  //   dupeLeaveData[0].value = restdaysAvailable - restdaysConsumed
  //   dupeLeaveData[1].value = restdaysConsumed
  //   setrestDaysData(dupeLeaveData);

  //   toggleLeaveReportLoader(false);
  // }
  // }

  const openDetailWindow = (leaveTypeId) => {
    const leaveName = get(find(leaveCategoryList, { leave_category_id: leaveTypeId }), 'leave_category_name', '');
    setSelectedLeaveTypeId(leaveTypeId);
    setSelectedLeaveType(`Department's ${leaveName} Balance`);
    // if (userRoleId === 1 || userRoleId === 6) {
      toggleDepartmentLeaveModal(true);
    // } else {
    //   toggleStaffDepartmentLeaveModal(true);
    // }
  }


  return (
    <>
      <StyledTitle>
        { 'OVERALL LEAVE REPORT'}
      </StyledTitle>
      <Row gutter={16}>
        {leaveCategoryList.map((data) => (
          <Col
            md={24}
            sm={24}
            xs={24} 
            xl={12}
            key={data.leave_category_short_name}
            onClick={() => openDetailWindow(data?.leave_category_id)}
          >
            {/* <Spin spinning={loading}> */}
              <LeaveDonutChart
                iconName={LEAVE_ICON[7]}
                title={data.leave_category_name.toUpperCase()}
                data={
                  dummydata?.find(
                    (item) => item[data?.leave_category_short_name]
                  )?.[data?.leave_category_short_name] ?? []
                }
              />
            {/* </Spin> */}
          </Col>
        ))}
      </Row>
      <DepartmentLeaveBalance
        displayModal={displayDepartmentLeaveModal}
        toggleModal={() => toggleDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        title={selectedLeaveType}
      />
      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        selectedDepartmentId={selectedDepartmentId}
        leaveName={selectedLeaveType}
      />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  clientId: getClientid(state),
});

export default connect(mapStateToProps, null)(LeaveBifurcationReport);
