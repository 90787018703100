import React, { useEffect, useState } from "react";
import AnswerForm from "../reusableComponent/formBuilder/AnsForm";
import { Button, Card, Spin, message } from "antd";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../assets/edit-icon.svg";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { getSendEmployeeForm } from "JsonFormat/SendAppraisalToEmployee";
import { CALL_API } from "common/API";

const AppraisalForm = ({
  selectedItem,
  setPageView,
  allDepartment,
  fetchResponse,
  fetchHistoryData,
  Menulist
}) => {
  const [createFullForm, setCreatedFullForm] = useState(
    selectedItem?.questions
  );
  const [answerResponse, setAnswerResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    timePeriod: "",
    department: "",
    type: "",
  });

  useEffect(() => {
    setCreatedFullForm(selectedItem?.questions);
  }, [selectedItem]);

  const btnStyle = { borderRadius: "5px", margin: "3px 0" };
  const editStyle = { cursor: "pointer" };

  const { SendEmployeeForm } = getSendEmployeeForm(data, allDepartment);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e, name) => {
    setData({
      ...data,
      [name]: e,
    });
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const combinedData = {
      appraisalForm: data.timePeriod[0],
      appraisalTo: data.timePeriod[1],
      departmentId: data.department,
      type: data.type,
    };
    const { code, updatedForm } = await CALL_API(
      `appraisalform/${selectedItem.appraisalFormId}`,
      "post",
      combinedData
    );

    if (code === "SUCCESS") {
      setData({ timePeriod: "", department: "", type: "" });
      setLoading(false);
      setPageView(false);
      fetchHistoryData();
      message.success("Successfully sent to employee");
    }
  };

  return (
    <div>
      <Row>
        <Card>
          <Spin spinning={loading}>
            <GridBuilder
              formStructureJson={SendEmployeeForm}
              handleChange={handleFormChange}
              formSubmit={handleSubmitForm}
              handleSelectChange={handleSelectChange}
            />
          </Spin>
        </Card>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <div className="fw-bold">Form:</div>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <div className="text-end">
            {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "appraisal" && subItem?.permission.edit)) && (
              <img
                onClick={() => setPageView("edit")}
                src={EditIcon}
                alt="edit icon"
                style={editStyle}
              />
            )}

            <Button
              onClick={() => {
                setPageView("response");
                fetchResponse(selectedItem.appraisalFormId);
              }}
              style={btnStyle}
              type="primary"
            >
              View Response
            </Button>
          </div>
        </Col>
      </Row>
      <AnswerForm
        fullFormDetail={createFullForm}
        selectedItem={selectedItem}
        setAnswerResponse={setAnswerResponse}
        answerResponse={answerResponse}
      />
    </div>
  );
};

export default AppraisalForm;
