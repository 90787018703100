import React, { useState } from "react"
import Wrapper from "./style"

import GridBuilder from "UIBuilderEngine/GridBuilder"
import OTPVerification from "organisms/OTPVerification"

import axios from "axios"


import { ForgotForm } from "JsonFormat/SendOTPJson"
import ChangePasswordJson from "JsonFormat/ChangePasswordJson"
import { LOGIN_BASE_URL } from "common/Constants"
import { message } from "antd"

function ForgotPassword({onChange}) {
  const [data, setData] = useState({})
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showComponent, setShowComponent] = useState(1)

  const handleSubmitForm = async (form) => {
    if (showComponent === 1) {
      setEmail(form.email)
      forgotPasswordAPI(form.email)
      return
    }
    if (showComponent === 3) {
      const payload = {
        email: email?.toLowerCase(),
        password: form.newPassword,
      }
      resetPassword(payload)
      return
    }
   
  }
  const { NewPasswordForm } = ChangePasswordJson(data)
  const handleFormChange = (e) => {
    console.log("e", e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleForgotPassword = () => {
    setShowComponent(2)
  }

  const handleOtpVerification = async (otp) => {
    const payload = {
      email: email,
      otp: otp,
    }
    try {
      setLoading(true)
      const response = await axios.post(`${LOGIN_BASE_URL}user/verify-otp`, payload)
      if (response.data.status === true) {
        setLoading(false)
        setShowComponent(3)
      } else {
        setLoading(false)
        message.error("OTP Invalid! Please enter the correct OTP")
      }
    } catch {
      setLoading(false)
    }
  }

  const resetPassword = async (payload) => {
    try {
      setLoading(true)
      const response = await axios.post(`${LOGIN_BASE_URL}user/reset-password`, payload)
      if (response.data.status === true) {
        setLoading(false)
        // setShowComponent(1)
        onChange()
        message.success("Password changed successfully")
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const sendOTP = async (email) => {
    const payload = {
      email: email?.toLowerCase(),
    }
    try {
      setLoading(true)
      const response = await axios.post(`${LOGIN_BASE_URL}user/send-otp`, payload)
      if (response.data.status === true) {
        setLoading(false)
        setShowComponent(3)
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const forgotPasswordAPI = async (email) => {
    const payload = {
      email: email?.toLowerCase(),
    }
    try {
      setLoading(true)
      const response = await axios.post(`${LOGIN_BASE_URL}user/reset-otp`, payload)
      if (response.data.status === true) {
        setLoading(false)
        setShowComponent(2)
      } else {
        setLoading(false)
        message.error(response.data.message)
        // message.error('OTP Invalid! Please enter the correct OTP');
      }
    } catch {
      setLoading(false)
    }
  }

  const goback = () => {
    if (showComponent > 1) {
      setShowComponent(showComponent - 1)
    }
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='main-container'>
          <div className='form-container'>
           
            <h3>
              {showComponent === 1
                ? `Forgot Password`
                : showComponent === 2
                ? "Verification"
                : "Reset Password"}
            </h3>
            {showComponent === 1 && (
              <p className='sub-title'>
                Enter your registered email address to change your password.
              </p>
            )}
            {showComponent !== 2 && (
              <GridBuilder
                formStructureJson={
                  showComponent === 1
                    ? ForgotForm
                    :  NewPasswordForm
                }
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                loading={loading}
                //  handleClick={handleGoBack}
                handleForgotPassword={handleForgotPassword}
              />
            )}
            {showComponent === 2 && (
              <OTPVerification
                handleOtpVerification={handleOtpVerification}
                email={data?.email}
                resendOtp={sendOTP}
                loading={loading}
              />
            )}
            <br />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ForgotPassword
