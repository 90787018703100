import React from 'react'
import { useParams } from "react-router-dom";
import { Row, Col, Card, Avatar,} from "antd";
import COLORS from "common/Colors";
import styled from "styled-components";
import { connect } from "react-redux";
import {
    getUserList,
    getClientid,
    getAllDepartmentList,
    getLocations,
} from "redux/selectors";
import {startCase } from "lodash";
import moment from "moment";

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  .main_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 912px) {
    .second_half_info {
      margin-top: -25px;
    }
  }
`;

const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 100px;
  width: 100px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const EmployeeDetails =({userList,ClientId,departmentList,locationList}) =>{
    const { id } = useParams();
    const filteruser = userList?.find((item)=>item?.userid === id);
    const filterdepartment = departmentList?.find((item)=>item?.department_id===filteruser?.departmentId);
    const filterlocation = locationList?.find((item)=>item?.location_id===filteruser?.locationid);



    return(
        <>
        <Row className="profile-row" gutter={24}>
          <Col md={24} sm={24} xs={24} lg={24}>
            <StyledCard>
              <Row className="main_row">
                <Col md={24} sm={24} xs={24} lg={4}>
                  <StyledStaffNameContainer>
                    {filteruser.profileurl === null ? (
                      <>
                        <StyledAvatar size="default">
                          {startCase(filteruser.fullname).charAt(0)}
                        </StyledAvatar>
                      </>
                    ) : (
                      <>
                        <StyledAvatar
                          src={filteruser.profileurl}
                          size="default"
                        ></StyledAvatar>
                      </>
                    )}
                  </StyledStaffNameContainer>
                </Col>
                <Col md={24} sm={24} xs={24} lg={10}>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Name</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {filteruser?.fullname}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>User Id</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {filteruser?.userid}
                    </Col>
                  </StyledRow>
                
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Joining date</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {moment(filteruser?.startdate).format("DD-MMMM-YYYY")}
                    </Col>
                  </StyledRow>
                </Col>
                <Col
                  md={24}
                  sm={24}
                  xs={24}
                  lg={10}
                  className="second_half_info"
                >
                  
                  <StyledRow className="mb-2 my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Department</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {filterdepartment?.department_name}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Client Id</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {ClientId}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Location</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {filterlocation?.location_name}
                    </Col>
                  </StyledRow>
                 
                </Col>
                
              </Row>
            </StyledCard>
          </Col>
          </Row>
          </>
    )
}
const mapStateToProps = (state) => ({
    userList: getUserList(state),
    ClientId: getClientid(state),
    departmentList: getAllDepartmentList(state),
    locationList: getLocations(state),
  });
export default connect(mapStateToProps)(EmployeeDetails);