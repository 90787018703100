import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Input } from 'antd';

import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { NON_EMPTY_REGEX } from 'common/Constants';

const StyledSuccessModal = styled(Modal)`
  .ant-modal-body {
    font-weight: bold;
    padding: 15px 20px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0px;
  }
  .anticon-check-circle {
    font-size: 4rem;
    display: block;
    padding: 10px;
    color: #2CFF00;
  }
  label {
    font-size: 1.1rem;
    padding-bottom: 15px;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 5px;
  color: ${COLORS.PRIMARY};
  font-weight: bold;
  width: initial;
  font-size: larger;
  :hover {
    color: ${COLORS.PRIMARY};
    font-weight: bold;
    border: none;
  }
`;

function RejectReasonModal({
  isOpenModal,
  closeModal,
}) {
  const [ showLoader, toggleLoader ] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const onFinish = async (values) => {
    toggleLoader(true);
    const { decline_reason } = values;
    closeModal(decline_reason);
  };

  useEffect(() => {
    if (isOpenModal) toggleLoader(false);
  }, [ isOpenModal ])

  return (
    <StyledSuccessModal
      title=""
      visible={isOpenModal}
      centered
      keyboard={false}
      maskClosable={false}
      width={400}
      footer={[]}
      onCancel={() => closeModal(false)}
    >
      <Form
        form={form}
        name='reasonform'
        layout='vertical'
        initialValues={{
          reason: '',
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label='Reason for decline'
          name='decline_reason'
          rules={[
            {
              required: true,
              pattern: NON_EMPTY_REGEX,
              message: 'Reject reason is required',
            },
          ]}
        >
          <TextArea rows={4} placeholder='Type something..' maxLength={500} />
        </Form.Item>
        <div className='align-right'>
          <Button type='primary' htmlType='submit' loading={showLoader}>
            { showLoader ? 'Processing' : 'Decline' }
          </Button>
        </div>
      </Form>
    </StyledSuccessModal>
  );
}

export default RejectReasonModal;
