import React from "react";
import {Row,Col, Form,Checkbox} from "antd"
import {
    getUserName,
    getUserInfo,
    getClientid,
  } from "redux/selectors";
import { connect } from "react-redux";
import {get} from "lodash";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";


const CloseAccount = ({clientId,userName,userInfo}) => {
   
    const onFinish = async() =>{
        const {code} = await CALL_API(
            `close-account/${clientId}`,
            "post",
            {
                "userId": get(userInfo, "userid", "")
            }
          );
          if(code === STATUS_CODE.SUCCESS){
            setTimeout(() => {
              // window.localStorage.removeItem("token");
              //   window.localStorage.removeItem("login_reminder");
                window.location.href = "https://www.waila.co/";
              }, 500);
          }
    }
    
    return(
       <div className="bg-white m-3 p-5">
       <h5>Account Settings</h5>
       <div className="p-2">
        <Row>
            <Col xs={6} sm={6} md={4} xl={2}>Client ID</Col>
            <Col>{clientId}</Col>
        </Row>
        <Row>
            <Col xs={6} sm={6} md={4} xl={2}>User ID</Col>
            <Col>{get(userInfo, "userid", "")}</Col>
        </Row>
        <Row>
            <Col xs={6} sm={6} md={4} xl={2}>Name</Col>
            <Col>{userName}</Col>
        </Row>
        </div>
        <h5>Close Account</h5>
        <Form
        name="closeaccount"
        layout="vertical"
        initialValues={{
        }}
        onFinish={onFinish}
        >
            <Col xs={24} sm={24} md={24} className="mb-2">
              <Form.Item name="closeaccount" valuePropName="checked" rules={[
                  {
                    required: true,
                    message:"You must agree before submitting."
                  },
                ]}>
                <Checkbox>I understand that by clicking this checkbox, I am closing my tymeplus account, The closure of my tymeplus account serves as notice to tymeplus that I wish to terminate the tymeplus customer agreement or any other agreement with tymeplus that governs my tymeplus account. solety with respect to that account.</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-2">
               Monthly usage of certain tymeplus sevices is calculated and billed at the beginning of the following month. If I have used these type of services this month. then at the beginning of next month I will receive a bill for usage that occurred prior to termination of my account. In addition , If I have any active subscriptions, then even after my account is closed I may continue to be billed for subscription untill the subscription expires or sold in accordance with theterms governing the subscription
            </Col>

            <Col xs={24} sm={24} md={24} className="mb-2">
            I acknowledge that i may reopen my tymeplus account only within 60 days of my account (the "Post-Closure period"). If I reopen my account during the Post-Closure Period, I may be charged for any tymeplus services that were not terminated befor I closed my account. If I reopen my tymeplus account. I agree that the same terms will govern my access to and use of tymeplus services through my reopened tymeplus account
            </Col>

            <Col xs={24} sm={24} md={24} className="mb-2">
            If I choose not to reopen my account after the Post-closure period, any content remaining in my tymeplus account will be deleted, For more information, Please see then tymeplus Website account Closure page
            </Col>
            
            <Col xs={24} sm={24} md={24} className="mb-2">
              <Form.Item name="close" valuePropName="checked" rules={[
                  {
                    required: true,
                    message:"You must agree before submitting."
                  },
                ]}>
                <Checkbox>I understand that by clicking this checkbox, I am closing my tymeplus account, The closure of my tymeplus account serves as notice to tymeplus that I wish to terminate the tymeplus customer agreement or any other agreement with tymeplus that governs my tymeplus account. solety with respect to that account.</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} className="mb-2">
              <Form.Item name="reopen" valuePropName="checked" rules={[
                  {
                    required: true,
                    message:"You must agree before submitting."
                  },
                ]}>
                <Checkbox>I understand not to reopen after the Post-Closure period I will not no longer be able to reoprn my closed account.</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} className="mb-2">
              <Form.Item name="billing" valuePropName="checked" rules={[
                  {
                    required: true,
                    message:"You must agree before submitting."
                  },
                ]}>
                <Checkbox>I understand that after the Post-Closure I will no longer be able to access the Billing console to download past bills and tax invoices</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} className="mb-2">
              <Form.Item name="closeperiod" valuePropName="checked" rules={[
                  {
                    required: true,
                    message:"You must agree before submitting."
                  },
                ]}>
                <Checkbox>I understand that after the Post-Closure period I will not be able to create a new tymeplus account with the email address currently associated withthis account.</Checkbox>
              </Form.Item>
            </Col>
            <button type="submit" className="btn btn-primary">Close Account</button>
        </Form>
       </div>
    )
}
const mapStateToProps = (state) => ({
    userName: getUserName(state),
    userInfo: getUserInfo(state),
    clientId: getClientid(state),
  });
export default connect(mapStateToProps)(CloseAccount);