import React, { useEffect, useRef, useState } from 'react';
import { Avatar as AntAvatar, Tooltip } from 'antd';
import { useHistory } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUserName,
  getChatMessageList,
  getChatSelectedStaffId,
  getChatMessageQueue,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
  getClientid,
} from 'redux/selectors';
import {
  setChatMessageList,
  setChatSelectedStaffId,
  setChatMessageQueue,
  setUnreadMessageIndicator
} from 'redux/actions';
import { concat, filter, find, findIndex, get, includes, lowerCase, map, size, startCase, uniqBy, result } from 'lodash';
import { useParams } from "react-router-dom";
import {
  Avatar,
  ChatContainer,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  Search,
  Sidebar
} from '@chatscope/chat-ui-kit-react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import COLORS from 'common/Colors';
import styled from 'styled-components';
import EventBus from 'utils/EventBus';
import { CALL_API } from 'common/API';

const StyledChatContainer = styled.div`
  .cs-conversation__name {
    padding-top: 8px;
  }
  .cs-search {
    margin: 1em !important;
    background: #f2f2f2;
    input {
      background: #f2f2f2;
    }
  }
  .cs-sidebar {
    box-shadow: inset 2px 0px 5px #b9babc;
  }
  .cs-conversation {
    border-top: solid 1px #d1dbe3;
    margin-left: 3px;
  }
  .cs-avatar {
    padding-top: 5px;
  }
  .cs-message-input {
    padding: 10px;
  }
  .cs-message--outgoing .cs-message__content {
    background-color: #ececec;
  }
  .cs-message:first-child {
    margin-top: 10px;
  }
  .cs-conversation__content {
    display: block !important;
  }
`;

function Chat({
  userName,
  userList,
  userRoleId,
  departmentUserList,
  selectedDepartmentId,
  updateSuccessModal,
  saveChatMessageList,
  saveChatSelectedStaffId,
  chatMessageList,
  chatSelectedStaffId,
  chatMessageQueue,
  saveChatMessageQueue,
  newMessageIndicatorList,
  saveUnreadMessageIndicator,
  userInfo,
  departmentList,
  clientId
}) {
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [employeeNameFilter, setEmployeeNameFilter] = useState('');
  const [messageInputValue, setMessageInputValue] = useState('');
  const [height, setHeight] = useState(500);
  const [selectedStaffName, setSelectedStaffName] = useState('');
  const [selectedStaffProfile, setSelectedStaffProfile] = useState('');
  const [messageListLoading, toggleMessageListLoading] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
  // const [ newMessageIndicatorList, setNewMessageIndicatorList ] = useState([]);
  
  const url = window.location.href;
  const parts = url.split('/');
  const staffId = parts[parts.length - 1];
  const inputRef = useRef();
  let history = useHistory();

  useEffect(() => {
    if (userRoleId === 1 || userRoleId === 6) {
      setSelectedDepartment('');
    }
    else {
      setSelectedDepartment(selectedDepartmentId);
    } 
  }, [selectedDepartmentId]);

  useEffect(() => {
    const notifications = filter(chatMessageQueue, ({ action }) => action === 'NOTIFICATION');
    if (size(notifications)) {
      for (let ind = 0, len = size(notifications); ind < len; ind++) {
        handleReceivedMessage(notifications[ind]);
      }
      saveChatMessageQueue(filter(chatMessageQueue, ({ action }) => action !== 'NOTIFICATION'));
    }
  }, [chatMessageQueue]);

  const handleReceivedMessage = (payload) => {
    if (payload.senderId == chatSelectedStaffId) {
      const msgList = Array.from(chatMessageList);
      msgList.push({
        message: payload.message,
        sentTime: "",
        sender: payload.sender,
        direction: "incoming",
        position: "single"
      })
      saveChatMessageList(msgList);
    } else {
      if (!includes(newMessageIndicatorList, payload.senderId)) {
        saveUnreadMessageIndicator(concat(
          newMessageIndicatorList,
          payload.senderId
        ));
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 2000);
    setHeight(window.innerHeight - document.querySelector('.ant-layout-header').getBoundingClientRect().height);
    saveChatSelectedStaffId(staffId)
    setSelectedStaffName(get(find(userList, { userid: staffId}), 'fullname'));
    getMessageList(staffId);
    // getUnreadMessageSenders();
    // const timer = setInterval(() => getUnreadMessageSenders(),
    //   10000
    // );
    // return () => clearInterval(timer);
  }, [])

  // const getUnreadMessageSenders = async () => {
  //   const { unread } = await CALL_API('fetch-unread-chat-message', 'post', {});
  //   if (size(unread) > 0) {
  //     setNewMessageIndicatorList(map(unread, 'sender_user_id'));
  //   }
  // }

  const getStaffList = () => {
    // const allStaffList = userRoleId === 1 ? userList : departmentUserList;
    // let staffList = selectedDepartment !== '' ? filter(allStaffList, { department_id: selectedDepartment }) : allStaffList;
    // if (userRoleId !== 1) {
    //   staffList = concat(staffList, filter(userList, { role_id: 1 }))
    // }

    // let filteredStaff = uniqBy(employeeNameFilter !== '' ? filter(staffList, (staff) => { return lowerCase(staff.name).indexOf(lowerCase(employeeNameFilter)) > -1 }) : staffList, 'user_id');

    // if (size(filteredStaff)) {
    //   let newIndex = 0;
    //   for (let ind=0, len=newMessageIndicatorList.length; ind<len; ind++) {
    //     if (newMessageIndicatorList[ind]) {
    //       const index = findIndex(filteredStaff, ({ user_id }) => user_id === newMessageIndicatorList[ind]);
    //       if (filteredStaff[index]) {
    //         filteredStaff = insert(filteredStaff, ++newIndex, filteredStaff[index]);
    //       }
    //     }
    //   }
    // }

    // return filteredStaff;
    const allStaffList = userList;
    console.log('allStaffList--->',allStaffList)
    var staffList
    if (userRoleId == 1 || userRoleId == 6) {

      staffList = selectedDepartment !== '' ? filter(allStaffList, { departmentid: selectedDepartment }) : allStaffList;
      staffList = filter(staffList, (v) => v.userid !== get(userInfo, 'userid', ''))
      return uniqBy(employeeNameFilter !== '' ? filter(staffList, (staff) => { return lowerCase(staff.fullname).indexOf(lowerCase(employeeNameFilter)) > -1 }) : staffList, 'userid');
    }
    else {

      if (userRoleId == 2) {
        //  staffList = filter(allStaffList, { department_id: get(userInfo, 'staff_department_id', '') })
        // staffList = map(departmentList, 'department_id') !== [] ? filter(allStaffList, (v) => includes(map(departmentList, 'department_id'), v.department_id)) : allStaffList;
        staffList = filter(allStaffList, (v) => get(userInfo, 'staff_department_id', '') == v.departmentid);
        // staffList = concat(staffList, filter(userList, { role_id: 2 }))
        staffList = filter(staffList, (v) => v.userid !== get(userInfo, 'userid', ''))
      } else {
        // staffList = map(departmentList, 'department_id') !== [] ? filter(allStaffList, (v) => includes(map(departmentList, 'department_id'), v.department_id) && v.user_id !==  get(userInfo, 'employer_id', '')) : allStaffList; 
        staffList = selectedDepartment.length > 0 ? filter(allStaffList, (v) => includes(selectedDepartment, v.departmentid) && v.userid !== get(userInfo, 'userid', '')) : allStaffList
      }

      // let staffList = selectedDepartment !== [] ? filter(allStaffList, (v) => includes(selectedDepartment, v.department_id)) : allStaffList
      if (userRoleId !== 1 || userRoleId !== 6) {
        staffList = concat(staffList, filter(userList, user => user.role_id == 1 || user.role_id == 6))
      }

      return uniqBy(employeeNameFilter !== '' ? filter(staffList, (staff) => { return lowerCase(staff.fullname).indexOf(lowerCase(employeeNameFilter)) > -1 }) : staffList, 'userid');
    }
  }

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ]

  const staffSelected = (staff) => {
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    setSelectedStaffName(staff.fullname);
    setSelectedStaffProfile(staff.profileurl);
    saveChatSelectedStaffId(staff.userid);
    getMessageList(staff.userid);
    history.push(`/chat/${staff.clientid}/${staff.userid}`)
  }

  const getMessageList = async (staff_Id) => {
    markChatConversationAsRead(staff_Id);
    setSelectedStaffProfile(result(find(userList, { 'user_id': staff_Id }), 'profileurl'))
    toggleMessageListLoading(true);
    saveChatMessageList([]);
    if (includes(newMessageIndicatorList, staff_Id)) {
      saveUnreadMessageIndicator(filter(newMessageIndicatorList, (sid) => sid !== staff_Id));
    }
    // const { messages, user_id } = await CALL_API(`chat-message-list/${clientId}`, 'post', {
    //   senderId: staff_Id 
    // });
    // if (size(messages) > 0) {
    //   const msgList = [];
    //   map(messages, ({ dateTime, message, senderId, senderName }) => {
    //     msgList.push({
    //       message,
    //       sentTime: dateTime,
    //       sender: senderName,
    //       direction: senderId == user_id ? "outgoing" : "incoming",
    //       position: "single",
    //       profile: result(find(userList, { 'user_id': senderId }), 'profileurl')
    //     });
    //   });
    //   saveChatMessageList(msgList);
    // }
    toggleMessageListLoading(false);
  }

  const markChatConversationAsRead = async (staff_Id) => {
    // await CALL_API(`chat-message-list/${clientId}/${staff_Id}`, 'patch');
  }

  const sendMessage = () => {
    const message = Array.from(chatMessageList);
    message.push({
      message: messageInputValue,
      sentTime: "",
      sender: userName,
      direction: "outgoing",
      position: "single",
      profile: result(find(userList, { 'user_id': get(userInfo, "employer_id", "") }), 'profileurl')
    })
    EventBus.$dispatch('sendMessage', {
      action: 'NOTIFICATION',
      payload: {
        message: messageInputValue,
        receiverUserId: chatSelectedStaffId,
        receiverName: selectedStaffName
      }
    });
    saveChatMessageList(message);
    setMessageInputValue("");
  }

  const checkForNewMessage = (staff_Id) => includes(newMessageIndicatorList, staff_Id);

  const handleBackClick = () => setSidebarVisible(!sidebarVisible);

  useEffect(() => {
    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%"
      });
      setConversationContentStyle({
        display: "flex"
      });
      setConversationAvatarStyle({
        marginRight: "1em"
      });
      setChatContainerStyle({
        display: "none"
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
  }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);
  return (
    <>
      <StyledChatContainer>
        <div style={{ height: `${height}px`, position: "relative" }}>
          <MainContainer responsive>
            <Sidebar position="left" scrollable={false} style={sidebarStyle}>
              <Search placeholder="Search..." onChange={(val) => setEmployeeNameFilter(val)} />
              <ConversationList>
                {
                  map(getStaffList(), (staff, index) => (
                    <Conversation
                      name={get(staff, 'fullname', '')}
                      key={index}
                      unreadDot={checkForNewMessage(get(staff, 'userid', ''))}
                      onClick={() => staffSelected(staff)}
                    >
                      <Avatar name={staff.fullname} status="available">
                        <Tooltip title={staff.fullname}>
                          {staff.profileurl === null || staff.profileurl == "null" || staff.profileurl === undefined || staff.profileurl === "" ?
                            <AntAvatar
                              style={{
                                backgroundColor: COLORS.PRIMARY,
                                verticalAlign: 'middle',
                              }}
                            >
                              {startCase(staff.fullname.charAt(0))}
                            </AntAvatar>
                            :
                            <AntAvatar
                              src={staff.profileurl}
                            >
                            </AntAvatar>
                          }
                        </Tooltip>
                      </Avatar>
                    </Conversation>
                  ))
                }
                {/* <Conversation name="Joe" lastSenderName="Joe" info="Yes i can do it for you" unreadCnt={3} unreadDot>
                  <Avatar src={UserTeam} name="Joe" status="dnd" />
                </Conversation> */}
              </ConversationList>
            </Sidebar>

            {
              selectedStaffName && (
                <ChatContainer style={chatContainerStyle}>
                  <ConversationHeader>
                    <ConversationHeader.Back style={{ display: 'inline' }} onClick={handleBackClick} />
                    <Avatar name={selectedStaffName}>
                      <Tooltip title={selectedStaffName}>
                        {selectedStaffProfile === null || selectedStaffProfile == "null" || selectedStaffProfile == undefined || selectedStaffProfile === "" ?
                          <AntAvatar
                            style={{
                              backgroundColor: COLORS.PRIMARY,
                              verticalAlign: 'middle',
                            }}
                          >
                            {startCase(selectedStaffName.charAt(0))}
                          </AntAvatar>
                          :
                          <AntAvatar src={selectedStaffProfile} />
                        }
                      </Tooltip>
                    </Avatar>
                    <ConversationHeader.Content userName={selectedStaffName} info="" />
                    {/* <ConversationHeader.Actions>
                      <InfoButton />
                    </ConversationHeader.Actions>           */}
                  </ConversationHeader>
                  {/* typingIndicator={<TypingIndicator content="Zoe is typing" />} */}
                  <MessageList loading={messageListLoading}>
                    {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}
                    {
                      map(chatMessageList, (message, index) => (
                        <Message model={message} key={index}>
                          <Avatar name={message.sender}>
                            <Tooltip title={message.sender}>
                              {message.profile === null || message.profile == "null" || message.profile === undefined || message.profile === "" ?
                                <AntAvatar
                                  style={{
                                    backgroundColor: COLORS.PRIMARY,
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {startCase(message.sender.charAt(0))}
                                </AntAvatar>
                                :
                                <AntAvatar src={message.profile} />
                              }
                            </Tooltip>
                          </Avatar>
                        </Message>
                      ))
                    }
                  </MessageList>
                  <MessageInput
                    attachButton={false}
                    placeholder="Type message here"
                    value={messageInputValue}
                    onChange={val => setMessageInputValue(val)}
                    onSend={sendMessage}
                    ref={inputRef}
                  />
                </ChatContainer>
              )
            }
          </MainContainer>
        </div>
      </StyledChatContainer>
    </>
  );
}

const mapStateToProps = state => ({
  userName: getUserName(state),
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  chatMessageList: getChatMessageList(state),
  chatSelectedStaffId: getChatSelectedStaffId(state),
  chatMessageQueue: getChatMessageQueue(state),
  newMessageIndicatorList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
  clientId: getClientid(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    saveChatMessageList: setChatMessageList,
    saveChatSelectedStaffId: setChatSelectedStaffId,
    saveChatMessageQueue: setChatMessageQueue,
    saveUnreadMessageIndicator: setUnreadMessageIndicator,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
