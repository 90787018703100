import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AttendFormSection from "./AttendFormSection";

const AttendAppraisal = ({
  pageView,
  selectedItem,
  setPageView,
  fetchData,
  attend,
  myappraisalData,
  userList,
}) => {
  const [fullFormDetail, setFullFormDetail] = useState(selectedItem?.questions);
  const [formbasicDetail, setFormBasicDetail] = useState({
    title: selectedItem?.title,
    description: selectedItem?.description,
  });
  const [savedForm, setSavedForm] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormBasicDetail({
        title: selectedItem?.title,
        description: selectedItem?.description,
      });
      setFullFormDetail(selectedItem?.questions);
    }
  }, [selectedItem]);

  return (
    <div>
      <Row className="page-title">
        <section className="form_builder main_container">
          <Row className="d-flex justify-content-center">
            <Col md={10} lg={7} sm={12}>
              <Container className="bg_light my-3 p-4 rounded-2">
                <h6>{selectedItem?.title}</h6>
                <p className="mt-3">{selectedItem?.description}</p>
              </Container>

              <div className="my-3">
                <AttendFormSection
                  attend={attend}
                  pageView={pageView}
                  formbasicDetail={formbasicDetail}
                  fullFormDetail={fullFormDetail}
                  setFullFormDetail={setFullFormDetail}
                  setSavedForm={setSavedForm}
                  selectedItem={selectedItem}
                  setPageView={setPageView}
                  fetchData={fetchData}
                  myappraisalData={myappraisalData}
                  userList={userList}
                />
              </div>
            </Col>
          </Row>
        </section>
      </Row>
    </div>
  );
};

export default AttendAppraisal;
