import COLORS from "common/Colors";
import Styled from "styled-components"


const Wrapper = Styled.div`
    .main-container{
        margin: 3%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }
    .form-container{
        position: relative;
        h3{
            text-align: center;
        }
        .sub-title{
            padding-top: 5%;
            text-align: center;
        }
        .back-btn{
            left: 5px;
            top: 5px;
            position: absolute;
        }
        p{
            text-align: center;
            font-size: 15px;
            a{
                color: ${COLORS?.PRIMARY};
                cursor: pointer;
                font-weight: 600;
            }
            a:hover{
                color: ${COLORS?.PRIMARY}
            }
        }
     
        border-radius: 10px;

        @media (max-width: 1000px) {
            min-width: 100%; 
            padding: 10%;
        }
    }
   
`;

export default Wrapper
