import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Table, Spin } from 'antd';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
} from 'redux/selectors';
import { addDaysinDate, dateInDetail, getKenyanDateTime, getMySqlDate, getinitialdate } from 'utils/Date';
import { STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';
import { includes, orderBy } from 'lodash';
import COLORS from 'common/Colors';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  margin-bottom: 25px;
  /* min-height: 350px;
  max-height: 350px; */
  // overflow-y: auto;
`;

const StyledTable = styled(Table)`
.ant-table-content {
  overflow-x: auto;
  min-height: 420px;
  max-height: 420px;
  white-space: nowrap;
}
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function DashboardPendingLeaves({
  userRoleId,
  selectedDepartmentId,
  ClientId
}) {

  const dummydata = [
    {
        "holiday": "No",
        "number_of_days": 2,
        "status_id": "4",
        "end_date": "2024-07-25",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "WASZS4S",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "19",
        "employeeid": "Demouser1",
        "created_at": "2024-07-05T05:25:24.863Z",
        "department_name": "Tax-C",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "18",
        "reason": "i have a personal work",
        "updated_at": "2024-07-05T05:27:01.803Z",
        "baselocationid": "3",
        "start_date": "2024-07-24",
        "userid": "demo@insyts.co",
        "leave_category_name": "Personal Leave",
        "fullname": "Emma Brown",
        "leave_category_id": "2",
        "new_end_date": "",
        "name": "Emma Brown",
        "status_name": "Waiting For Approval"
    },
    {
        "holiday": "No",
        "number_of_days": 3,
        "status_id": "2",
        "end_date": "2024-07-18",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "ABCD123",
        "approved_rejected_by": "manager1",
        "approve_decline_status": true,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "20",
        "employeeid": "User123",
        "created_at": "2024-06-15T10:00:00.000Z",
        "department_name": "Finance",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "12",
        "reason": "medical checkup",
        "updated_at": "2024-06-15T10:05:00.000Z",
        "baselocationid": "1",
        "start_date": "2024-07-15",
        "userid": "user123@insyts.co",
        "leave_category_name": "Medical Leave",
        "fullname": "Oliver Smith",
        "leave_category_id": "1",
        "new_end_date": "",
        "name": "Oliver Smith",
        "status_name": "Approved"
    },
    {
        "holiday": "Yes",
        "number_of_days": 1,
        "status_id": "3",
        "end_date": "2024-08-01",
        "is_cancel_request": true,
        "weekend": "Yes",
        "new_number_of_days": "",
        "clientid": "XYZ987",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "1",
        "decline_reason": "Work urgency",
        "user_leave_id": "21",
        "employeeid": "User456",
        "created_at": "2024-07-01T12:30:00.000Z",
        "department_name": "HR",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": true,
        "department_id": "14",
        "reason": "family event",
        "updated_at": "2024-07-01T12:35:00.000Z",
        "baselocationid": "2",
        "start_date": "2024-07-31",
        "userid": "user456@insyts.co",
        "leave_category_name": "Casual Leave",
        "fullname": "Charlotte Johnson",
        "leave_category_id": "3",
        "new_end_date": "",
        "name": "Charlotte Johnson",
        "status_name": "Declined"
    },
    {
        "holiday": "No",
        "number_of_days": 5,
        "status_id": "1",
        "end_date": "2024-09-10",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "LMNOP456",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "22",
        "employeeid": "User789",
        "created_at": "2024-07-10T08:00:00.000Z",
        "department_name": "IT",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "16",
        "reason": "vacation",
        "updated_at": "2024-07-10T08:05:00.000Z",
        "baselocationid": "4",
        "start_date": "2024-09-05",
        "userid": "user789@insyts.co",
        "leave_category_name": "Annual Leave",
        "fullname": "Jack Williams",
        "leave_category_id": "4",
        "new_end_date": "",
        "name": "Jack Williams",
        "status_name": "Pending"
    },
    {
        "holiday": "No",
        "number_of_days": 2,
        "status_id": "2",
        "end_date": "2024-08-20",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "GHJK123",
        "approved_rejected_by": "manager2",
        "approve_decline_status": true,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "23",
        "employeeid": "User234",
        "created_at": "2024-07-02T14:20:00.000Z",
        "department_name": "Marketing",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "13",
        "reason": "personal reasons",
        "updated_at": "2024-07-02T14:25:00.000Z",
        "baselocationid": "1",
        "start_date": "2024-08-18",
        "userid": "user234@insyts.co",
        "leave_category_name": "Personal Leave",
        "fullname": "Amelia Jones",
        "leave_category_id": "2",
        "new_end_date": "",
        "name": "Amelia Jones",
        "status_name": "Approved"
    },
    {
        "holiday": "Yes",
        "number_of_days": 4,
        "status_id": "4",
        "end_date": "2024-07-12",
        "is_cancel_request": false,
        "weekend": "Yes",
        "new_number_of_days": "",
        "clientid": "QWER567",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "24",
        "employeeid": "User345",
        "created_at": "2024-06-25T09:00:00.000Z",
        "department_name": "Operations",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "17",
        "reason": "attending conference",
        "updated_at": "2024-06-25T09:05:00.000Z",
        "baselocationid": "3",
        "start_date": "2024-07-08",
        "userid": "user345@insyts.co",
        "leave_category_name": "Work Leave",
        "fullname": "Sophia Taylor",
        "leave_category_id": "5",
        "new_end_date": "",
        "name": "Sophia Taylor",
        "status_name": "Waiting For Approval"
    },
    {
        "holiday": "No",
        "number_of_days": 1,
        "status_id": "2",
        "end_date": "2024-07-15",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "TYUI890",
        "approved_rejected_by": "manager3",
        "approve_decline_status": true,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "25",
        "employeeid": "User567",
        "created_at": "2024-07-05T07:00:00.000Z",
        "department_name": "Sales",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "11",
        "reason": "family function",
        "updated_at": "2024-07-05T07:05:00.000Z",
        "baselocationid": "2",
        "start_date": "2024-07-14",
        "userid": "user567@insyts.co",
        "leave_category_name": "Casual Leave",
        "fullname": "Liam Gray",
        "leave_category_id": "3",
        "new_end_date": "",
        "name": "Liam Gray",
        "status_name": "Approved"
    },
    {
        "holiday": "No",
        "number_of_days": 7,
        "status_id": "1",
        "end_date": "2024-08-15",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "UIOP123",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "26",
        "employeeid": "User678",
        "created_at": "2024-07-08T11:00:00.000Z",
        "department_name": "Admin",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "15",
        "reason": "family trip",
        "updated_at": "2024-07-08T11:05:00.000Z",
        "baselocationid": "1",
        "start_date": "2024-08-09",
        "userid": "user678@insyts.co",
        "leave_category_name": "Annual Leave",
        "fullname": "Ava Campbell",
        "leave_category_id": "4",
        "new_end_date": "",
        "name": "Ava Campbell",
        "status_name": "Pending"
    },
    {
        "holiday": "Yes",
        "number_of_days": 3,
        "status_id": "3",
        "end_date": "2024-07-21",
        "is_cancel_request": true,
        "weekend": "Yes",
        "new_number_of_days": "",
        "clientid": "ZXCV456",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "1",
        "decline_reason": "Project deadlines",
        "user_leave_id": "27",
        "employeeid": "User789",
        "created_at": "2024-07-10T15:00:00.000Z",
        "department_name": "Legal",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": true,
        "department_id": "18",
        "reason": "relocation",
        "updated_at": "2024-07-10T15:05:00.000Z",
        "baselocationid": "4",
        "start_date": "2024-07-19",
        "userid": "user789@insyts.co",
        "leave_category_name": "Emergency Leave",
        "fullname": "Mia Foster",
        "leave_category_id": "6",
        "new_end_date": "",
        "name": "Mia Foster",
        "status_name": "Declined"
    },
    {
        "holiday": "No",
        "number_of_days": 2,
        "status_id": "2",
        "end_date": "2024-07-27",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "BNML987",
        "approved_rejected_by": "manager4",
        "approve_decline_status": true,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "28",
        "employeeid": "User890",
        "created_at": "2024-07-06T06:00:00.000Z",
        "department_name": "Research",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "19",
        "reason": "doctor appointment",
        "updated_at": "2024-07-06T06:05:00.000Z",
        "baselocationid": "3",
        "start_date": "2024-07-25",
        "userid": "user890@insyts.co",
        "leave_category_name": "Medical Leave",
        "fullname": "Noah Brooks",
        "leave_category_id": "1",
        "new_end_date": "",
        "name": "Noah Brooks",
        "status_name": "Approved"
    },
    {
        "holiday": "No",
        "number_of_days": 4,
        "status_id": "1",
        "end_date": "2024-08-05",
        "is_cancel_request": false,
        "weekend": "No",
        "new_number_of_days": "",
        "clientid": "ASDF123",
        "approved_rejected_by": "",
        "approve_decline_status": false,
        "having_cancel_request": "0",
        "decline_reason": "",
        "user_leave_id": "29",
        "employeeid": "User901",
        "created_at": "2024-07-07T13:00:00.000Z",
        "department_name": "Development",
        "user_absent_action_id": "",
        "new_start_date": "",
        "cancel_leave": false,
        "department_id": "20",
        "reason": "vacation",
        "updated_at": "2024-07-07T13:05:00.000Z",
        "baselocationid": "2",
        "start_date": "2024-08-01",
        "userid": "user901@insyts.co",
        "leave_category_name": "Annual Leave",
        "fullname": "Isabella Cooper",
        "leave_category_id": "4",
        "new_end_date": "",
        "name": "Isabella Cooper",
        "status_name": "Pending"
    }
]

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [displayPendingApprovalLoader, togglePendingApprovalLoader] = useState(false);

  const [searchterm1, setSearchterm1] = useState(pendingApprovals);

  const data1 = pendingApprovals.filter(val => {
    if (searchterm1 === '') {
      return val;
    }
    else if (val.status_name.toLowerCase().includes(("wait").toLowerCase())) {
      return val;
    }
    else if (val.status_name.toLowerCase().includes(("Approved").toLowerCase())) {
      if (JSON.stringify(val.having_cancel_request).toLowerCase().includes(("1").toLowerCase())) {
        return val;
      }
      // return val;
    }

  })
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'Department',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '20%',
      render: (_, record) => {
        const dateToDisplay = record.new_start_date === null || record.new_start_date === ""
          ? record.start_date
          : record.new_start_date;
        const dateFormat = moment(dateToDisplay).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
      sorter: (a, b) => {
        const dateA = a.new_start_date === null || a.new_start_date === ""
          ? moment(a.start_date)
          : moment(a.new_start_date);
        const dateB = b.new_start_date === null || b.new_start_date === ""
          ? moment(b.start_date)
          : moment(b.new_start_date);
        return dateA - dateB;
      }
    },
    // {
    //   title: 'Start Day',
    //   dataIndex: 'start_date',
    //   key: 'start_date',
    //   width: '10%',
    //   align: 'center',
    //   render: (startDate) => {
    //     const getdayAlone = moment(startDate).format("dddd");
    //     return `${getdayAlone}`;
    //   }
    // },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '20%',
      render: (_, record) => {
        const dateToDisplay = record.new_end_date === null || record.new_end_date === ""
          ? record.end_date
          : record.new_end_date;
        const dateFormat = moment(dateToDisplay).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
      sorter: (a, b) => {
        const dateA = a.new_end_date === null || a.new_end_date === ""
          ? moment(a.end_date)
          : moment(a.new_end_date);
        const dateB = b.new_end_date === null || b.new_end_date === ""
          ? moment(b.end_date)
          : moment(b.new_end_date);
        return dateA - dateB;
      }
    },    
    // {
    //   title: 'End Day',
    //   dataIndex: 'end_date',
    //   key: 'end_date',
    //   width: '5%',
    //   align: 'center',
    //   render: (endDate) => {
    //     const getdayAlone = moment(endDate).format("dddd");
    //     return `${getdayAlone}`;
    //   }
    // },
    {
      title: 'Leave Type',
      dataIndex: 'leave_category_name',
      key: 'leave_category_name',
      width: '20%',
      // align: 'center'
    },
    {
      title: 'Amended Leave',
      dataIndex: 'having_cancel_request',
      key: 'having_cancel_request',
      // align: 'center',
      render: (having_cancel_request) => {
        if (having_cancel_request === 1) {
          return "Yes";

        } else {
          return "No"
        }
      }
    },
  ];


  const getPendingLeaves = async () => {
    const initialdate = getinitialdate();
    const startDate = getKenyanDateTime();
    const endDate = addDaysinDate(startDate, 365);
    togglePendingApprovalLoader(true);
    const {
      code,
      leaves = []
    } = await CALL_API(`leave-list/${ClientId}`, 'post', {
      status_id: '',
      department_id: (userRoleId == 1 || userRoleId === 6) ? [''] : selectedDepartmentId,
      selected_role_id: userRoleId,
      start_date: getMySqlDate(initialdate),
      end_date: getMySqlDate(endDate),
    });
    
    togglePendingApprovalLoader(false);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      setPendingApprovals(orderBy(leaves, ["start_date"], ["asc"]));
    }
  }

  useEffect(() => {
    if ((userRoleId !== 1 && selectedDepartmentId !== 0) || userRoleId === 1 || userRoleId === 6) {
      getPendingLeaves();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId === 6) {
      getPendingLeaves();
    }
  }, [selectedDepartmentId]);

  return (
    <>
      <StyledTitle className='mt-2'>
        { 'OVERALL WAITING FOR APPROVAL LIST'}
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayPendingApprovalLoader}>
          <StyledTable
            dataSource={orderBy(dummydata, ["start_date"], ["desc"])}
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
            rowKey='user_leave_id'
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps, null)(DashboardPendingLeaves);