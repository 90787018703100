import React from "react";
import styled from "@emotion/styled";
import { Row, Col, Button } from "antd";
import {
    getLocations,
    getAllDepartmentList,
    getRoleList,
} from "redux/selectors";
import { connect } from "react-redux";
import moment from "moment";
import COLORS from "common/Colors";
const StyledButton = styled(Button)`
    background: ${COLORS.PRIMARY};
    border-radius: 5px;
    color: #FFFFFF;
    width: 100px;
    font-weight: bold;
`;
const StyledCol = styled(Col)`
    margin: 10px 0;
    .info_heading{
        font-size: 14px;
        font-weight: 400;
        color: #636980;
    }
    .info_datas{
        font-weight: 500;
        color: #1F243B;
    }
    .inreview_title{
        font-size: 16px;
        font-weight: 600;
        width: 26%;
    }
    .inreview_boxes_container{
        display: flex;
        gap: 1rem;
    }
    .roles_location_review{
        width: max-content;
        min-height: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #1F243B;
        padding: 5px 12px;
        border-radius: 4px;
        color: #1F243B;
        background-color: #F4F4F4;
        margin-bottom: 0;
    }
    .roles_location_review_section{
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
    .roles_location_review_wrap{
        width: 100%;
        display: flex;
        gap: 10px;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        .inreview_col_section{
            width: 30%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    @media(min-width: 768px) and (max-width: 991px){
        .inreview_col_section{
            width: 50%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    @media(min-width: 481px) and (max-width: 767px){
        .inreview_col_section{
            width: 50%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    @media(min-width: 320px) and (max-width: 480px){
        .inreview_boxes_container{
           flex-direction: column; 
        }
        .inreview_col_section{
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }

`;
const InReview = ({
    BasicInformation,
    department,
    location,
    role,
    departmentList,
    WorkInformation,
    EducationInformation,
    locationList,
    roleList,
    setStep,
    step,
    handleCreate,
    loading,
    baseLocation

}) => {
    const departmentdata =
        departmentList?.filter((item) =>
            department?.includes(item.department_id)
        ) || null;
    const locationdata =
        locationList?.filter((item) => location?.includes(item.location_id)) ||
        null;
    const roledata =
        roleList?.filter((item) => role?.includes(item.roleId)) || null;

    const baseLocationName = locationList?.filter((item) => item.location_id === baseLocation)

    const basicMappings = {
        user_id: 'User ID',
        date_of_birth: 'Date of Birth',
        marital_status: 'Marital Status',
        start_date: 'Start Date',
        nssf: "NSSF",
        national_id: "National ID",
        EmergencyContactName: "Emergency Contact Name",
        EmergencyContactNumber: "Emergency Contact Number",
        employementtype: "Employement Type",
        timezone: "Time Zone",
        dependent: "No. of Dependents",
        start_date: "Date of Joining",
        nationality: "Country",
        supervisor: "Supervisor / Manager",
        subserviceline: "Team"
    };

    const EducationMappings = {
        degree: "Degree",
        specialization: "Specialization",
        institute_Name: 'Institute Name',
        year_of_completion: 'Year of Completion',
    };

    const WorkMappings = {
        job_Title: 'Job Title',
        employee_sponser: 'Employee Sponser',
        employee_status: 'Employee Status',
        professional_since: 'Professional Since',
        hired_date: 'Hired Date',
        relevent: "Relevant",
        secondaryindustry: "Secondary Industry",
        company_Name: "Contracting Company"
    };

    function formatDisplayValue(value) {
        if (typeof value === 'string' && value.includes('@')) {
            return value;
        }
        else if (Array.isArray(value)) { // Corrected to 'object' from 'array'
            return (
                <Row className="roles_location_review_wrap">
                    {value.map((item, key) => (
                        <Col className="roles_location_review_section" key={key}>
                            <p className="roles_location_review" key={item}>{item}</p>
                        </Col>
                    ))}
                </Row>
            );
        }
        return value ? value.charAt(0).toUpperCase() + value.slice(1) : "-";
    }

    return (
        <>
            <Row className="p-2 flex-column">
                <StyledCol>
                    <div className="inreview_boxes_container">
                        <h5 className="inreview_title">Basic Information</h5>
                        <Row className="w-100" >
                            {BasicInformation && Object.entries(BasicInformation).map(([key, value]) => {
                                const displayKey = basicMappings[key] || (key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '));
                                const displayValue = formatDisplayValue(value);
                                return (
                                    <>
                                        <Col className="inreview_col_section" xl={8} key={key}>
                                            <b className="info_heading">{displayKey}</b>
                                            <p className="info_datas">{(key === "start_date" || key === "end_date" || key === "year_of_completion" || key ==="date_of_birth") ? moment(value).format('DD-MM-YYYY') : displayValue}</p>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </div>
                </StyledCol>

                {roledata.length > 0 && (
                    <StyledCol>
                        <div className="inreview_boxes_container">
                            <h5 className="inreview_title">Role</h5>
                            <Row className="roles_location_review_wrap">
                                {roledata?.map((item, key) => {
                                    return (
                                        <>
                                            <Col className="roles_location_review_section" key={key}>
                                                <p className="roles_location_review" key={item}>{item?.roleName}</p>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </div>
                    </StyledCol>
                )}

                {baseLocation && baseLocationName?.length !==0 && (
                    <StyledCol>
                        <div className="inreview_boxes_container">
                            <h5 className="inreview_title">Base Location</h5>
                            <Row className="roles_location_review_wrap">
                                <Col className="roles_location_review_section">
                                    <p className="roles_location_review">{baseLocationName[0]?.location_name}</p>
                                </Col>
                            </Row>
                        </div>
                    </StyledCol>
                )}

                {/* <StyledCol>
                    <div className="inreview_boxes_container">
                        <h5 className="inreview_title">Education Information</h5>
                        <Row className="w-100">
                            {EducationInformation && Object.entries(EducationInformation).map(([key, value]) => {
                                const displayKey = EducationMappings[key] || (key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '));
                                const displayValue = formatDisplayValue(value);
                                return (
                                    <>
                                        <Col className="inreview_col_section" xl={8} key={key}>
                                            <b className="info_heading">{displayKey}</b>
                                            <p className="info_datas">{(key === "start_date" || key === "year_of_completion") ? moment(value).format('DD-MM-YYYY') : value === false ? "false" : value === true ? "true" : displayValue}</p>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </div>
                </StyledCol> */}

                <StyledCol>
                    <div className="inreview_boxes_container">
                        <h5 className="inreview_title">Work Information</h5>
                        <Row className="w-100">
                            {WorkInformation && Object.entries(WorkInformation).map(([key, value]) => {
                                const displayKey = WorkMappings[key] || (key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '));
                                const displayValue = formatDisplayValue(value);
                                return (
                                    <>
                                        <Col className="inreview_col_section" xl={8} key={key}>
                                            <b className="info_heading">{displayKey}</b>
                                            <p className="info_datas">{(key === "start_date" || key === "hired_date" || key === "lastpromotionlevel") ? moment(value).format('DD-MM-YYYY') : value === false ? "false" : value === true ? "true" : displayValue}</p>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </div>
                </StyledCol>

                {locationdata?.length > 0 && (
                    <StyledCol>
                        <div className="inreview_boxes_container">
                            <h5 className="inreview_title">Location</h5>
                            <Row className="roles_location_review_wrap">
                                {locationdata?.map((item, key) => {
                                    return (
                                        <>
                                            <Col className="roles_location_review_section" key={key}>
                                                <p className="roles_location_review" key={item}>{item?.location_name}</p>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </div>
                    </StyledCol>
                )}
                {departmentdata?.length > 0 && (
                    <StyledCol>
                        <div className="inreview_boxes_container">
                            <h5 className="inreview_title">Department</h5>
                            <Row className="roles_location_review_wrap">
                                {departmentdata?.map((item, key) => {
                                    return (
                                        <>
                                            <Col className="roles_location_review_section" key={key}>
                                                <p className="roles_location_review" key={item}>{item?.department_name}</p>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </div>
                    </StyledCol>
                )}

            </Row>
            <div className='w-100 text-end'>
                <StyledButton type='primary' className='mt-3 mx-2' onClick={() => { setStep(step - 1) }}>Back</StyledButton>
                <StyledButton type="primary" loading={loading} className='mt-3' onClick={handleCreate}>
                    Create
                </StyledButton>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    locationList: getLocations(state),
    departmentList: getAllDepartmentList(state),
    roleList: getRoleList(state),
});
export default connect(mapStateToProps)(InReview);