import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CurrentAppraisal from "molecules/CurrentAppraisal";
import AppraisalHistory from "molecules/AppraisalHistory";
import TabsList from "molecules/TabsList";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { getDepartmentList, getUserList, getUserRoleId, getSelectedDepartmentId, getUserInfo } from "redux/selectors";
import AttendAppraisal from "organisms/AttendAppraisal";
import ViewAppraisalResponse from "organisms/ViewAppraisalResponse";
import { Spin } from "antd";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MyAppraisal = ({ departmentList, userList, UserRoleId, selectedDepartmentId, userInfo }) => {
  const [myAppraisal, setMyAppraisal] = useState([]);
  const [appraisal, setAppraisal] = useState([]);
  const [history, setHistory] = useState([]);
  const [viewResponseData, setViewResponseData] = useState([]);
  const [pageView, setPageView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectItem, setSelectItem] = useState(false);


  const filterMyAppraisal = myAppraisal?.filter((item)=>selectedDepartmentId?.includes(item?.departmentId) && !item?.usersCompleted?.includes(userInfo?.userid));

  let roleId = Array.isArray(UserRoleId) ? UserRoleId[0] : UserRoleId;

  let filteredDepartments =
    userList[0].userdepartmentid[roleId.toString()] || [];
  let matchedAppraisals = [];

  for (let i = 0; i < myAppraisal?.length; i++) {
    if (
      myAppraisal?.length &&
      filteredDepartments[0]?.includes(myAppraisal[i].departmentId) &&
      !myAppraisal[i].usersCompleted?.includes(userList[0].userid)
    ) {
      matchedAppraisals.push(myAppraisal[i]);
    }
  }

  const backStyle = { color: "#ccc", cursor: "pointer" };
  const pointerStyle = { cursor: "pointer" };
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await CALL_API(`appraisalform/appraisalhistory`, "get");
      setMyAppraisal(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchALlData = async () => {
    try {
      const res = await CALL_API(`appraisalform`, "get");
      setAppraisal(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchHistoryData();
    fetchALlData();
  }, []);

  const fetchHistoryData = async () => {
    try {
      const res = await CALL_API(`appraisalform/completedappraisal`, "get");
      setHistory(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let items = [
    {
      id: 1,
      label: "Current Appraisal",
      component: (
        <CurrentAppraisal
          myappraisal={true}
          myappraisalData={filterMyAppraisal}
          appraisal={appraisal}
          setPageView={setPageView}
          setSelectedItem={setSelectedItem}
          fetchData={fetchData}
          fetchALlData={fetchALlData}
        />
      ),
    },
    {
      id: 2,
      label: "Appraisal History",
      component: (
        <AppraisalHistory
          myappraisal={true}
          appraisal={appraisal}
          history={history}
          myAppraisal={myAppraisal}
          setPageView={setPageView}
          setViewResponseData={setViewResponseData}
        />
      ),
    },
  ];
  return (
    <div>
      <section className="tabs_and_tableui">
        {pageView === "Attend" || pageView === "historyResponse" ? (
          <Row className="page-title">
            <Col sm={12} md={12}>
              <label className="common_title mb-2">
                <span
                  onClick={() => {
                    setPageView(false);
                  }}
                  className="back-btn"
                  style={backStyle}
                >
                  Back  <ArrowForwardIosIcon style={{fontSize:"14px"}}/>
                </span>{" "}
                {pageView === "Attend" ? selectedItem?.title : selectItem}
              </label>
            </Col>
          </Row>
        ) : (
          <Row className="page-title">
            <Col sm={12} md={12}>
              <h5 className="common_title">
                <span style={pointerStyle} onClick={() => setPageView(false)}>
                 My Appraisal{" "}
                </span>{" "}
              </h5>
            </Col>
          </Row>
        )}

        {pageView === "Attend" ? (
          <AttendAppraisal
            selectedItem={selectedItem}
            pageView={pageView}
            setPageView={setPageView}
            myappraisalData={filterMyAppraisal}
            attend={true}
            userList={userList[0]}
            fetchALlData={fetchALlData}
            fetchData={fetchData}
          />
        ) : pageView === "historyResponse" ? (
          <ViewAppraisalResponse
            pageView={pageView}
            setPageView={setPageView}
            response={viewResponseData}
            appraisal={appraisal}
            setSelectedItem={setSelectItem}
          />
        ) : (
          <Spin spinning={loading}>
            <TabsList items={items} />
          </Spin>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  departmentList: getDepartmentList(state),
  userList: getUserList(state),
  UserRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps)(MyAppraisal);
