export const ForgotForm = {
    totalpages: 1,
    pages: [
        {
            modalView: false, // For showing modal in Popup
            colSpan: [12, 12, 12], // for 1 column 12, 2 columns 6, 3 columns 4, 4 columns 3 (max 4 columns)
            fields: [
                // Row 1
                [
                    {
                        id: 1,
                        label: "Email",
                        type: "text",
                        name: "email",
                        Placeholder: "Email",
                        value:"",
                        validationRules: [
                            {
                              required: true,
                              message: `Email is required`,
                            },
                            {
                                pattern: /^\S+@\S+\.\S+$/,
                                message: "valid email address is required",
                            }
                        ]
                    },
                ],
                [
                    {
                        id: 3,
                        label: "Send OTP",
                        type: "button",
                        position: "center", // position options (end, start, center)
                        textColor: "white",
                        event: "submit",
                    },
                ]
            ],
        },
    ],
};