import COLORS from "common/Colors";
import Styled from "styled-components"


const Wrapper = Styled.div`
     position: relative;
     height: var(--antd-header-height, 0);
     .styled-div{
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        height: 100%;
     }
     .chat_bot{
        display: flex;
        height: 80%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        img{
           height: 200px;
        }
     }
     .content-container{
        padding: 20px;
        height: 90%;
        overflow-y: auto;
       
     }
     .content{
        height: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        flex-direction: column;
        .items{
            margin-top: 1%;
        }    
        .cards{
            min-width: 250px;
            border-radius: 10px;
            background: ${COLORS.SECONDARY};
            cursor: pointer;
        } 
     }
    .text_container{
        height: auto;
        padding:20px;
        background: transparent;
        width: 100%;
        .text_area{
            border: 1px solid #ccc;
            padding: 5px;
            border-radius: 15px;
            width: 100%;
            background: white;
        }
        .input_area{
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
            .input_box{
                padding: 10px;
                padding-left: 20px;
                border: none;
                height: 100%;
            }
            .input_box:focus {
               outline: none; 
               border-color: none; 
               box-shadow: none; 
            }

        }
    }
    .conversation{
        strong{
            font-size: 18px;
            margin-top: 10px;
        }
        p{
            font-size: 16px;
        }
        .typist{
            width: 100%;
            margin-top: 10px;
            font-size: 16px;
        }
    }
`;

export default Wrapper
