import { Input } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { generateOrganization, getOrganizationLevels } from "./data";
import COLORS from "common/Colors";
// import EmployeeIcon from "assets/employee_box.png";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { useSelector } from "react-redux";
import { getUserList } from "redux/selectors";
import { Card } from "./Card";

export const OrganizationContext = React.createContext();

// Function to calculate the number of reportees for an employee
export function calculateNumberOfReportees(employee) {
  if (!employee || !employee.reportees) return 0;
  return employee.reportees.length;
}

const Organization = ({ type }) => {
  // write a state in which each level is and object with the active person for that level

  const userList = useSelector(getUserList);

  const userListData =
    userList &&
    userList
      ?.filter((user) => user.statusid == "1") //? Those who are active will only be shown in the organization chart
      ?.map((user) => {
        return {
          id: user.employeeid,
          OfficeSupervisorId: user.OfficeSupervisorId,
          fullname: user.fullname,
          profileurl: user.profileurl,
          grade: user.grade,
          RegionalSupervisorId: user.RegionalSupervisorId,
          userid: user.userid,
        };
      });

  const employeeOrganization = generateOrganization(userListData, type);

  const peopleInLevel = (people) => {
    return people.map((personId) => {
      const person = findEmployeeById(personId);
      const numberOfReportees = calculateNumberOfReportees(person);
      return {
        ...person,
        numberOfReportees,
      };
    });
  };

  function findEmployeeById(id) {
    return employeeOrganization.find((emp) => emp.id === id);
  }

  const organizationLevels = getOrganizationLevels(employeeOrganization);

  const initialOrganization = organizationLevels.map((level) => {
    return {
      levelName: level.name,
      levelid: level.levelid,
      people: peopleInLevel(level.people),
      show: level.levelid <= 3,
    };
  });

  const [organization, setOrganization] = useState(initialOrganization);

  // const CEOId = "EA-HO-0001";

  const initialActivePerson = organization.map((level, index) => {
    return {
      levelid: level.levelid,
      activePerson: level.people[0] || null,
      people: level.people,
    };
  });

  const [activePerson, setActivePerson] = useState(initialActivePerson);
  console.log("organization-->", organization);

  return (
    <OrganizationContext.Provider
      value={{
        activePerson,
        setActivePerson,
        organizationLevels,
        organization,
        findEmployeeById,
        setOrganization,
      }}
    >
      <OrganizationWrap>
        {/* <div>
          {" "}
          <Input
            placeholder="Search by name"
            style={{
              width: 300,
            }}
            prefix={<SearchIcon />}
          />
        </div> */}

        <div className="organization">
          {organization.map((level, levelIndex) => {
            // if (!level.show) return null;
            return (
              <div key={level.levelid} className={`level ${levelIndex + 1}`}>
                <div className="level-box">{level.levelName}</div>
                <div className="people">
                  {level.people.map((person) => {
                    const rootPerson =
                      activePerson[levelIndex]?.activePerson?.reportsTo ===
                      "none";

                    if (rootPerson) {
                      return (
                        <React.Fragment key={person.id}>
                          <Card
                            linked={
                              activePerson[levelIndex]?.activePerson.id ===
                              person.id
                            }
                            person={person}
                          />
                        </React.Fragment>
                      );
                    }

                    const previousLevelActivePersonId =
                      activePerson[levelIndex - 1]?.activePerson?.id;

                    if (
                      previousLevelActivePersonId &&
                      person.reportsTo === previousLevelActivePersonId
                    ) {
                      return (
                        <React.Fragment key={person.id}>
                          <Card
                            person={person}
                            linked={
                              activePerson[levelIndex]?.activePerson?.id ===
                              person.id
                            }
                          />
                        </React.Fragment>
                      );
                    }

                    return null; // Add a default return statement to handle unmatched conditions
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </OrganizationWrap>
    </OrganizationContext.Provider>
  );
};

export default Organization;

const OrganizationWrap = styled.div`
  .organization {
    margin-top: 1rem;

    .level {
      display: flex;
      justify-content: start;
      align-items: start;
      gap: 1rem;

      .level-box {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        color: white;
        background-color: ${COLORS.BLUE};
        border-radius: 5px;
        position: relative;
        cursor: pointer;
      }

      .people {
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(210px, 1fr)); */
        grid-auto-flow: column;
        gap: 1rem;
      }
    }
  }
`;
