import React from "react";
import { Button } from "antd";
import styled from "@emotion/styled";
import { getRoleList } from "redux/selectors";
import COLORS from "common/Colors";
import { connect } from "react-redux";
import Table from 'atoms/Table';

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #f0f0f0;
  }
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f4f4f4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 150px;
`;



const SelectTable = ({ columns, tabledata, setOpenModal, Name }) => {

  const customLocale = {
    emptyText:(<StyledButton type="primary" className="m-3" onClick={() => setOpenModal(true)}>
    {Name}
  </StyledButton>),
  };

  return (
    <>
      <div className="styled-card">
        <Table
          className="mb-4"
          dataSource={tabledata}
          columns={columns}
          rowKey="role_id"
          pagination={false}
          locale={customLocale}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(SelectTable);
