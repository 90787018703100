import React, { useState } from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { isNaN, map, max } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledChartContainer = styled.div`
  height:330px;
  overflow:auto;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeesPerLocation({ report }) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
 
  const config = {
    padding: 'auto',
    data: report,
    height: 300,
    xField: 'locationname',
    yField: 'users',
    seriesField: 'locationname',
    padding:'auto',
    pixelRatio:10,
    legend: false,
    label: {
      position: 'middle',
      content:'',
      style:{
        fill:'white'
      }
    },
    columnStyle: {
      cursor: 'pointer',
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
      
    },
    yAxis: {
      // max: isNaN(max(map(report, 'users'))) ? 100 : max(map(report, 'users')) + 40,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    theme: {
      colors10: [
        '#0F1C73',
        '#1A449F',
        '#2B67BA',
        '#3C88C0',
        '#4BA7B3',
        '#5AC39B',
        '#6ADD7F',
        '#A8DD6A',
        '#DDDD6A',
      ],
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
  };
  return (
    <>      
      <StyledTitle>
         Number Of Users Per Location
      </StyledTitle>
      <StyledChartContainer >
       
        <Spin spinning={displayReportLoader} >
          <Column
            {...config}
             />
        </Spin>
      
      </StyledChartContainer>
    </>
  );
}


export default EmployeesPerLocation