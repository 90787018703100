import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
  Spin,
} from "antd";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { getLeaveCategoryList, getUserInfo } from "redux/selectors";
import styled from "@emotion/styled";
import { NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";
import Button from "atoms/Button";
import {
  addDaysinDate,
  getKenyanDateTime,
  getMySqlDate,
  momentDate,
} from "utils/Date";
import { displayErrorModal } from "common/common";
import { CALL_API } from "common/API";
import { get } from "lodash";
import RejectReasonModal from "molecules/RejectReasonModal";
import { toggleProcessingModal, toggleSuccessModal } from "redux/actions";
import { bindActionCreators } from "redux";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 5px;
  width: initial;
  font-size: 14px;
`;
const StyledButton1 = styled(Button)`
  float: right;
  width: 120px;
  border-radius: 5px;
  width: initial;
  font-size: 14px;
`;

function LeaveFormModel({
  ClientId,
  userRoleId,
  isModalVisible,
  closeModal,
  leaveCategoryList,
  leaveDetails,
  getLeaveList,
  toggleDisplayLeaveModal,
  setIsModalOpen,
  setLeaveID,
  userInfo,
  sethavingCancel,
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [showDeclineLoader, toggleDelcineLoader] = useState(false);
  const [showLeaveValidationLoader, toggleLeaveValidationLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isCancelRequest, setCancelRequest] = useState(false);
  const [form] = Form.useForm();
  const [halfDay, setHalfDay] = useState(false);
  // const [leaveTypeData, setLeaveTypeData] = useState([])
  const { TextArea } = Input;
  useEffect(() => {
    resetForm();
  }, []);


  const resetForm = () => {
    form.setFieldsValue({
      leave_id: "",
      start_date: momentDate(new Date()),
      end_date: momentDate(new Date()),
      number_of_days: 1,
      leave_type: "",
      description: "",
    });
  };
  const updateHalfDay = () => {
    const { number_of_days } = form.getFieldsValue();

    if (halfDay === true) {
      if (number_of_days >= 0.5) {
        const roundedDays = number_of_days;
        form.setFieldsValue({
          number_of_days: roundedDays - 0.5,
        });
      }
    } else {
      if (number_of_days >= 0.5) {
        const roundedDays = number_of_days;
        form.setFieldsValue({
          number_of_days: roundedDays + 0.5,
        });
      }
    }
  };

  useEffect(() => {
    if (isModalVisible) resetForm();
  }, [isModalVisible]);

  useEffect(() => {
    updateHalfDay();
  }, [halfDay]);

  useEffect(() => {
    const {
      user_leave_id = "",
      reason = "",
      leave_category_id,
      isViewOnly,
      isCancel = false,
    } = leaveDetails;
    const start_date =
      (leaveDetails?.new_start_date === null || leaveDetails?.new_start_date === '')
        ? leaveDetails?.start_date
        : leaveDetails?.new_start_date;
    const end_date =
      (leaveDetails?.new_end_date === null || leaveDetails?.new_end_date === '')
        ? leaveDetails?.end_date
        : leaveDetails?.new_end_date;
    const number_of_days =
      (leaveDetails?.new_number_of_days === null || leaveDetails?.new_number_of_days === '')
        ? leaveDetails?.number_of_days
        : leaveDetails?.new_number_of_days;
    if (user_leave_id !== "") {
      setIsViewOnly(isViewOnly);
      setCancelRequest(isCancel);
      form.setFieldsValue({
        leave_id: user_leave_id,
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        number_of_days,
        leave_type: leave_category_id,
        description: reason,
        remark: "",
      });
    } else if (start_date && end_date) {
      form.setFieldsValue({
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
      });
    }
  }, [leaveDetails]);

  const onDecline = async (values) => {
    toggleDelcineLoader(true);
    const { decline_reason } = values;
    if (decline_reason)
      await approveRejectCancelLeave2(
        6,
        decline_reason,
        leaveDetails.having_cancel_request === "1" ? true : false
      );
    form.resetFields();
    message.success(`leave application has been cancelled.`);
    toggleDeclineReasonModalVisibility(false);
  };

  // first parameter is needed
  const onFinish = async (values, saveLeave = true) => {
    if (saveLeave) toggleLoader(true);
    else toggleLeaveValidationLoader(true);

    const {
      start_date,
      end_date,
      number_of_days,
      leave_type,
      description,
      remark,
    } = form.getFieldsValue();
    const { user_leave_id = "" } = leaveDetails;
    const { code, leaveBalance, totalNumberOfDaysInLeave } = await CALL_API(
      "save-leave",
      "post",
      {
        leave_id: user_leave_id,
        start_date: getMySqlDate(start_date),
        end_date: getMySqlDate(end_date),
        number_of_days,
        leave_category_id: leave_type,
        reason: description,
        decline_reason: isCancelRequest ? remark : "",
        is_cancel_request: isCancelRequest,
        isSaving: saveLeave,
        department_id: get(userInfo, "staff_department_id", ""),
      }
    );
    if (saveLeave) toggleLoader(false);
    else toggleLeaveValidationLoader(false);
    if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
      message.error(`Oops!! something went wrong`);
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      message.error(`Invalid payload. Please enter correct data`);
    } else if (code === STATUS_CODE.RECORD_EXIST) {
      message.error(`Leave already exists`);
    } else if (code === STATUS_CODE.ANNUAL_LEAVE_AVAILABLE) {
      message.error(
        `You can not apply compassionate leave. You still have annual leaves.`
      );
    } else if (code === STATUS_CODE.LEAVE_EXCEED) {
      form.setFieldsValue({
        number_of_days: totalNumberOfDaysInLeave,
      });
      displayErrorModal(
        `You are exceeding your leave quota. your current leave balance is ${leaveBalance}`
      );
    } else if (code === STATUS_CODE.SUCCESS) {
      if (saveLeave) {
        message.success(
          isCancelRequest
            ? "Leave application request has been submitted"
            : `Leave has been ${user_leave_id ? "updated" : "applied"}`
        );
        form.resetFields();
        closeModal(true);
      } else {
        form.setFieldsValue({
          number_of_days: totalNumberOfDaysInLeave,
        });
      }
    }
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    } else {
      onFinish({}, false);
    }
  };

  const dateChange = () => {
    onFinish({}, false);
    updateHalfDay();
  };

  const approveConfirm = () => {
    if (leaveDetails.having_cancel_request === "1") {
      sethavingCancel(true);
    }
    setIsModalOpen(true);
    setLeaveID(user_leave_id);
    toggleDisplayLeaveModal(false);
  };

  const updateEndDate = () => {
    const { start_date, number_of_days } = form.getFieldsValue();

    if (number_of_days >= 1) {
      const roundedDays = Math.ceil(number_of_days);
      form.setFieldsValue({
        number_of_days: roundedDays,
        end_date: momentDate(addDaysinDate(start_date, roundedDays - 1)),
      });
    }
  };

  const [displayDeclineReasonModal, toggleDeclineReasonModalVisibility] = useState(false);
  const { user_leave_id, start_date, end_date, new_start_date, new_end_date } = leaveDetails;
  const approveRejectCancelLeave2 = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request = false
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(`approve-reject-cancel-leave/${ClientId}`, "post", {
        user_leave_id,
        status_id: String(statusId),
        declined_reason,
        is_cancel_request: is_leave_cancel_request,
        start_date: new_start_date
          ? getMySqlDate(new_start_date)
          : getMySqlDate(start_date),
        end_date: new_end_date
          ? getMySqlDate(new_end_date)
          : getMySqlDate(end_date),
      });
      if (code === STATUS_CODE.SUCCESS) {
        toggleDeclineReasonModalVisibility(false);
        toggleDisplayLeaveModal(false);
        toggleDelcineLoader(false);
        toggleLoader(false);
        getLeaveList();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
      resolve();
    });
  };

  const closeDeclineReasonModal = async (reason) => {
    if (reason) await approveRejectCancelLeave2(6, reason);
    message.success(`Your leave application has been cancelled.`);
    toggleDeclineReasonModalVisibility(false);
  };

  return (
    <StyledModal
      title="Leave Details"
      visible={isModalVisible}
      onCancel={() => closeModal(false)}
      width={600}
      footer={[]}
    >
      <Form
        form={form}
        name="leaveform"
        layout="vertical"
        initialValues={{
          leave_id: "",
          start_date: momentDate(getKenyanDateTime()),
          end_date: momentDate(getKenyanDateTime()),
          number_of_days: 1,
          leave_type: "",
          description: "",
          remark: "",
        }}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Start date is required",
                },
                () => ({
                  // first parameter is needed
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid()
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <DatePicker format="DD-MM-YYYY" onChange={validateDateRange} disabled={isViewOnly} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.start_date !== currentValues.start_date
              }
            >
              {() => (
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "End date is required",
                    },
                    () => ({
                      // first parameter is needed
                      validator(rule, value) {
                        const date = moment(value);
                        return date.isValid()
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={() => {
                      dateChange();
                      setHalfDay(false);
                    }}
                    disabled={isViewOnly}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Spin spinning={showLeaveValidationLoader}>
              <Form.Item label="No. of Days" name="number_of_days">
                <InputNumber min={0.5} onChange={updateEndDate} disabled />
              </Form.Item>
            </Spin>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Leave Type"
              name="leave_type"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Leave type is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search leave type"
                name="leave_type"
                optionFilterProp="leave_category_name"
                filterOption={(input, option) =>
                  option.leave_category_name
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.leave_category_name
                    .toLowerCase()
                    .localeCompare(optionB.leave_category_name.toLowerCase());
                }}
                options={leaveCategoryList}
                fieldNames={{
                  label: "leave_category_name",
                  value: "leave_category_id",
                }}
                disabled={isViewOnly}
                onChange={() => onFinish({}, false)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const leave_type = getFieldValue("leave_type");
            return (
              leave_type !== true && (
                <Form.Item label="Detailed Reason" name="description">
                  <TextArea
                    placeholder="Type something.."
                    maxLength={500}
                    disabled={isViewOnly}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form
          form={form}
          name="reasonform"
          layout="vertical"
          initialValues={{
            decline_reason: "",
          }}
          onFinish={onDecline}
        >
          <Form.Item
            label="Reason for decline"
            name="decline_reason"
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: "Reason is required",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Type something.." maxLength={500} />
          </Form.Item>
          <>
            <StyledButton1
              type="primary"
              loading={showDeclineLoader}
              htmlType="submit"
            >
              DECLINE
            </StyledButton1>
          </>
        </Form>
        <>
          <StyledButton
            type="primary"
            onClick={() => {
              approveConfirm();
            }}
            loading={showLoader}
          >
            APPROVE
          </StyledButton>
        </>

        <RejectReasonModal
          isOpenModal={displayDeclineReasonModal}
          closeModal={closeDeclineReasonModal}
        />
      </Form>
    </StyledModal>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(LeaveFormModel);
