import { Card, Col, Input, Row, Spin } from 'antd'
import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux';
import { getClientid, getMenuList, getUserList, getUserRoleId } from 'redux/selectors';
import Wrapper, { CardImage } from './style';
import folder from 'assets/folder.svg'
import { SearchOutlined } from '@ant-design/icons';
import { DOCUMENTFOLDER } from 'common/Constants';
import DocumentTableView from './DocumentTableView';


const { Meta } = Card;

function DocumentFolders({ userList, menuList, userRoleId, IdentityInfoColumns, data, filteruser, fetchMasterData, clientId }) {
    const [showComponent, setShowComponent] = useState(1)
    const [selectedItem, setSelectedItem] = useState({})
    const [searchTerm, setSearchTerm] = useState('');
    const [dataSource, setDataSource] = useState(userList)

    const Menulist = menuList[userRoleId] || Object.values(menuList)[0];


    const handleClick = (item) => {
        setShowComponent(2)
        setSelectedItem(item)
    }


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredDataSource = dataSource?.filter((item) =>
        item.fullname.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const MemoizedCard = useMemo(() => {
        return React.memo(({ item, description = false }) => (

            <Wrapper>
                <Card
                    key={item}
                    hoverable
                    cover={
                        <CardImage className='px-4'>
                            <img
                                alt="example"
                                src={folder}
                            />
                        </CardImage>
                    }
                    bordered={true}
                    className='cards'
                    onClick={() => handleClick(item)}
                >
                    <Meta
                        title={<small>{item.label}</small>}
                    // description={`Created on ${moment(item?.insertedAt).format("DD MMM YYYY")}`}
                    />
                </Card>
            </Wrapper>
        ));
    }, []);

    const searchStyle = { margin: '10px', maxWidth: '400px' }

    const goBack = () => {
        setShowComponent(1)
    }
    return (
        <Wrapper>
            {showComponent === 1 && (
                <>
                    {/* <h5>Documents</h5>
                    <Input
                        placeholder="Search"
                        onChange={handleSearchChange}
                        suffix={<SearchOutlined />}
                        style={searchStyle}
                    /> */}
                    <div className='card-container'>
                        {DOCUMENTFOLDER?.map((item, index) => (
                            <>
                                <MemoizedCard item={item} />
                            </>

                        ))}
                    </div>
                </>
            )}
            {showComponent === 2 && (
                <>
                    <DocumentTableView
                        dataSource={data}
                        IdentityInfoColumns={IdentityInfoColumns}
                        goBack={goBack}
                        selectedItem={selectedItem}
                        filteruser={filteruser}
                        fetchMasterData={fetchMasterData}
                        clientId={clientId}
                    />
                </>
            )}

        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    userList: getUserList(state),
    menuList: getMenuList(state),
    userRoleId: getUserRoleId(state),
    clientId: getClientid(state)
});

export default connect(mapStateToProps, null)(DocumentFolders);
