import React, { useEffect, useState } from 'react';
import { getAddEditPolicyJson } from './JsonFormat';
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import { CALL_API } from 'common/API';
import { Checkbox, message, Switch } from 'antd';
import StepsProgress from 'organisms/StepsProgress';
import FormModal from 'organisms/FormModal';
import { getRoleList } from 'redux/selectors';
import SelectTable from "molecules/SelectTable";
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import InReview from 'molecules/InReview';
import moment from 'moment';
import EditSelectTable from 'molecules/EditSelectTable';
import TabsList from 'molecules/TabsList';
import COLORS from 'common/Colors';
import { STATUS_CODE } from 'common/Constants';
import axios from 'axios';

function AddEditPolicy({ value = "", ClientId, userInfo, goBack, getPolicyData, roleList }) {
  const transformDetails = (data) => ({
    policyTitle: data?.policyTitle,
    policyId: data?.policyId,
    policyEffectiveDate: data?.policyEffectiveDate ? moment(data?.policyEffectiveDate) : "",
    createdUserId: data?.createdUserId,
    policyType: data?.policyType,
    clientId: data?.clientId,
    roleId: data?.roleId,
    policyDocument: data?.policyDocument?.map((doc) => {
      const { name, statusId, documentId, createdon, documentPath, s3BucketObjectName, createdtime, uid, type } = doc;
      const uniquevalue = s3BucketObjectName;
const parts = uniquevalue.split('/');
const uniqueId = parts[parts.length - 1];
      return {
        name,
        statusId,
        documentId,
        createdon,
        documentPath,
        uniqueId: uniqueId,
        createdtime,
        uid,
        type
      };
    })
  });
  

  const [data, setData] = useState(transformDetails(value));
  const [datas, setDatas] =useState(value);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [submit, setsubmit] = useState(false);

  useEffect(()=>{
    setData(transformDetails(value))
  },[value])


  useEffect(() => {
    setSelectAllRoleChecked(
      selectedRoleId.length === roleList.length
    );
  }, [value, selectedRoleId, roleList]);

  const { addPolicyForm } = getAddEditPolicyJson(data, value);

  useEffect(() => {
    if(value){
      setSelectedRoleId(value?.roleId);
    }
    
  }, [value]);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setsubmit(true)
  };

  const handleSelectChange = (e, name) => {
    setData({
      ...data,
      [name]: e,
    });
    setsubmit(true);
  };

//   const handleSelectChange = (e, name) => {
//     setData({
//         ...data,
//         [name]: e,
//     });
// };


  const handleClick = () => {
    goBack();
  };

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        const { code, uploadURL } = await CALL_API(
          `upload-userdocuments/${ClientId}`,
          "post",
          {
            userId: userInfo?.employer_id,
            IdentityName: "policyDocuments",
            name: file.name,
            type: file.type,
            uniqueId: file.response.uniqueId,
            // content: file.response.base64Data
          }
        );
        if (code === STATUS_CODE.SUCCESS) {
          const url = uploadURL;
          const response = await axios.put(url, file?.response?.file, { headers: { 'Content-Type': file.type }}
          );
          console.log("[uploadFile] response -->", response);
        } else {
          console.error("Failed to get upload URL. Response code:", code);
        }
      } else {
        console.error("File is empty or invalid:", file);
      }
    } catch (error) {
      console.error("Error occurred during file upload:", error);
    }
  };
  
  

  const onFinish = async () => {
    setLoading(true);
    if (value) {
      // const { fileList } = data?.policyDocument;
    const UpdateuploadedFiles = data?.policyDocument?.map((file) => ({
      name: file.name,
      type: file.type,
      url: file?.response?.base64Data,
      uniqueId: file?.response?.uniqueId ? file?.response?.uniqueId : file?.uniqueId,
      uid: file?.uid
    }));
    const transformDetailsData = UpdateuploadedFiles?.map((att) => ({
      name: att?.name,
      type: att?.type,
      uniqueId: att?.uniqueId,
      uid: att?.uid
    }));

    if (!transformDetailsData.every(file => file.type === 'application/pdf')) {
      message.error("Only PDF files are allowed");
      setLoading(false);
      return ;
    }

      const { code } = await CALL_API(`hrpolicy/${ClientId}?policyId=${value?.policyId}`, "patch", {
        policyTitle: data?.policyTitle,
        policyDetails: data?.policyDetails,
        createdUserId: userInfo?.employer_id,
        lastUpdated: new Date(),
        policyEffectiveDate: data?.policyEffectiveDate,
        policyType: data?.policyType,
        policyDocument: transformDetailsData,
        roleId: selectedRoleId
      });
      if(code === STATUS_CODE.SUCCESS){
        getPolicyData();
        setStep(1);
        setLoading(false);
        goBack();
        message.success('Policy Updated Successfully');
        setsubmit(false);
        return;
      }else{
        message.error("Something went wrong!! Please try again.");
      }
    }

    // const { fileList } = data?.policyDocument;
    const uploadedFiles = data?.policyDocument?.map((file) => ({
      name: file.name,
      type: file.type,
      url: file?.response?.base64Data,
      uniqueId: file?.response?.uniqueId ? file?.response?.uniqueId : file?.uniqueId,
      uid: file?.uid
    }));
    const transformDetailData = uploadedFiles?.map((att) => ({
      name: att?.name,
      type: att?.type,
      uniqueId: att?.uniqueId,
      uid: att?.uid
    }));

    if (!transformDetailData.every(file => file.type === 'application/pdf')) {
      message.error("Only PDF files are allowed");
      setLoading(false);
      return ;
    }

    const {code} = await CALL_API(`/hrpolicy/${ClientId}`, "post", {
      policyTitle: data?.policyTitle,
      policyDetails: data?.policyDetails,
      createdUserId: userInfo?.employer_id,
      lastUpdated: new Date(),
      policyEffectiveDate: data?.policyEffectiveDate,
      policyType: data?.policyType,
      policyDocument: transformDetailData,
      roleId: selectedRoleId
    });
    if(code === STATUS_CODE.SUCCESS){
      goBack();
      getPolicyData();
      setLoading(false);
      message.success("Successfully Added");
      setsubmit(false);
      return;
    }else{
      message.error("Something went wrong!! Please try again.");
    }
      
  };

  const handleSubmitForm = async (form) => {
    setData({
      ...data,
      policyDetails: form?.policyDetails,
    });
    if(value){
      onFinish();
    }else{
      setStep(1);
    }
    
  };

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Role" },
    { id: 3, title: "Review" },
  ];

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    setsubmit(true)
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setsubmit(true)
  };

  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          
            <GridBuilder
      formStructureJson={addPolicyForm}
      handleChange={handleFormChange}
      handleSelectChange={handleSelectChange}
      formSubmit={handleSubmitForm}
      initialValues={data}
      loading={loading}
      handleClick={handleClick}
      uploadFile={uploadFile}
    />
          
        </>
      ),
    },
    {
      id: 2,
      label: "Role",
      component: (
        <>
          <EditSelectTable
            columns={rolecolumns}
            tabledata={roleList}
            setOpenModal={() => setOpenRoleModal(true)}
            Name="Add Role"
          />
          <FormModal
            isModalVisible={openRoleModal}
            closeModal={() => setOpenRoleModal(false)}
            Name="Role"
          />
        </>
      ),
    },
  ];

  useEffect(()=>{
    if(submit && value){
      onFinish()
    }
  },[selectedRoleId]);

  const titleStyle = { color: `${COLORS.BLACK}`, cursor: 'pointer' };

  return (<>
  {value ? (<>
    <h6>
        <strong style={titleStyle} onClick={() => goBack()}>
          HR Policies
        </strong>{' '}
        <span> {">"} {value?.policyTitle}</span>
      </h6>
    <TabsList items={items} />
  </>):
    <div className='p-2'>
    <StepsProgress stepdata={stepdata} title="" step={step} />
    {step === 0 &&(
      <GridBuilder
      formStructureJson={addPolicyForm}
      handleChange={handleFormChange}
      handleSelectChange={handleSelectChange}
      formSubmit={handleSubmitForm}
      initialValues={data}
      loading={loading}
      handleClick={handleClick}
      uploadFile={uploadFile}
    />
    )}
    {step === 1 &&(
      <>
      {roleList.length > 0 && (
        <Checkbox
          className="mt-3"
          checked={selectAllRoleChecked}
          onChange={handleSelectAllRole}
        >
          Select All
        </Checkbox>
      )}
      <SelectTable
        step={step}
        setStep={setStep}
        columns={rolecolumns}
        tabledata={roleList}
        setOpenModal={() => setOpenRoleModal(true)}
        Name="Add Role"
        disable={selectedRoleId.length === 0}
      />
      <FormModal
        isModalVisible={openRoleModal}
        closeModal={() => setOpenRoleModal(false)}
        Name="Role"
      />
    </>
    )}
    {step === 2 && (
          <InReview
            step={step}
            setStep={setStep}
            BasicInformation={{
              "Policy Title": data?.policyTitle,
              "Policy Effective Date": data?.policyEffectiveDate,
              "Policy Details":data?.policyDetails,
              "PolicyType": data?.policyType
            }}
            role={selectedRoleId}
            document={data?.policyDocument}
            create={onFinish}
            loading={loading}
          />
        )}
    
  </div>}
  </>
   
    
  )
}
const mapStateToProps = (state) => ({
  roleList: getRoleList(state),
});

export default connect(mapStateToProps)(AddEditPolicy)