import React from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';
import { forEach } from 'lodash';
import Icon from 'atoms/Icon';

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 16px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
`;

function ProcessListModal({ isModalOpen, handleCancel, handleOk, loading, name, selectedItem, onEdit, onDelete, MenuList }) {
    const styleDiv = { display: "flex", justifyContent: "end" }

    return (
        <div>
            <StyledModal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={null}

            >
                <div className='mt-3 text-left'>
                    <div className='w-100 d-flex justify-content-space-between mt-4'>
                        <h5>{selectedItem?.title}</h5>
                        {selectedItem?.title !== "Default" && (
                            <div className='w-100 d-flex gap-4' style={styleDiv}>
                                {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.edit)) && (
                                    <Icon name='edit' onClick={() => onEdit(selectedItem)} />
                                )}
                               {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.delete)) && (
                                   <Icon name='trash' onClick={() => { onDelete(selectedItem?.processId) }} />
                               )}         
                            </div>
                        )}
                    </div>

                    <div className='mt-3 px-2'>
                        {selectedItem?.steps?.map((step, index) => {
                            const [key, status] = Object.entries(step)[0];
                            return <p key={index}>{index + 1}. {status}</p>;
                        })}
                    </div>
                </div>
            </StyledModal>
        </div>
    )
}

export default ProcessListModal