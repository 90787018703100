import React, { useEffect, useState } from "react";
import TeamCard from "molecules/TeamCard";
import { Row, Col, Input, Select, Button, Modal, Spin, Form } from "antd";
import { DEVICE } from "common/Device";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
  getClientid,
  getlevelList,
  getSupervisorList,
  getRoleList,
} from "redux/selectors";
import { toggleSuccessModal } from "redux/actions";
import styled from "@emotion/styled";
import {
  map,
  concat,
  filter,
  size,
  uniqBy,
  includes,
  find,
  result,
  uniq,
  flatMap,
  get,
} from "lodash";
import COLORS from "common/Colors";
import EventBus from "utils/EventBus";
import { useRef } from "react";
import { CompetencyRole, STATUS_CODE, SUPERVISOR } from "common/Constants";
import { CALL_API } from "common/API";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 10px;
  .people_font_style {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
  }
  .selectbox {
    width: 100%;
    border-radius: 10px !important;
  }
  .ant-select-selector {
    border-radius: 10px !important;
  }
  .broadcast {
    width: 60px;
    height: 60px;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledFooterDiv = styled.div`
  text-align: right;
  padding-top: 10px;
  .ant-btn {
    font-weight: bold !important;
    color: ${COLORS.PRIMARY};
    padding-right: 0px !important;
  }
  .ant-btn:hover {
    border: none;
  }
`;

function Team({
  userList,
  allDepartmentList,
  updateSuccessModal,
  unreadChatMessageList,
  selectedDepartmentId,
  levelList,
  supervisorList,
  userRoleId,
  roleList,
  userInfo,
}) {
  const [isBroadcastModalVisible, toggleBroadcastModalVisible] =
    useState(false);
  const [sendingMessageLoader, ToggleSendingMessageLoader] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const { TextArea } = Input;
  const selectref = useRef(null);
  const containerRef = useRef(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const filteredUserName = userList
    ?.filter((item) => item.roleid.includes("4"))
    ?.map((item) => item.fullname);

  let currentPath = window.location.pathname;
  let pathSegments = currentPath.split("/");
  let url = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  useEffect(() => {
    getStaffList();
  }, [selectedDepartment, url]);

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const sendMessage = () => {
    const staffIds = map(getStaffList(), "userid");
    if (size(staffIds) && broadcastMessage) {
      ToggleSendingMessageLoader(true);
      EventBus.$dispatch("sendMessage", {
        action: "BROADCAST",
        payload: {
          message: broadcastMessage,
          receiverUserIds: map(getStaffList(), "userid"),
          receiverName: map(getStaffList(), "fullname"),
        },
      });
      setTimeout(() => {
        toggleBroadcastModalVisible(false);
        ToggleSendingMessageLoader(false);
      }, 1000);
      setTimeout(() => {
        updateSuccessModal({
          visibility: true,
          message: `Message has been broadcasted.`,
        });
        setBroadcastMessage("");
      }, 1000);
    }
  };

  const StaffList = () => {
    return concat(
      url === "myteam"
        ? userList?.filter((val) => {
            let result = [];
            const concatenatedArray = flatMap(val?.userdepartmentid);
            const uniqueArray = uniq(concatenatedArray);
            result = result?.concat(uniqueArray);
            if (
              val?.statusid?.toString() === "1" &&
              result.some((id) => selectedDepartment?.includes(id))
            ) {
              return val;
            }
          })
        : userList?.filter((item) => item?.statusid === "1")
    );
  };
  const getStaffList = () => {
    const allStaffList = userList;

    const filteruser = allStaffList?.filter(
      (item) => item?.userid === get(userInfo, "userid", "")
    );
    console.log("filteruser-->", filteruser[0]?.leveldepartmentname);
    console.log("filteruser-->", filteruser[0]?.baselocationid);

    const filterByRole = roleList?.filter(
      (item) => item?.roleId === userRoleId
    );

    var staffList;
    staffList =
      url === "myteam"
        ? allStaffList?.filter((val) => {
            let result = [];
            const concatenatedArray = flatMap(val?.userdepartmentid);
            const uniqueArray = uniq(concatenatedArray);
            result = result?.concat(uniqueArray);

            return (
              val?.statusid?.toString() === "1" &&
              val?.baselocationid?.toString() ===
                filteruser[0]?.baselocationid?.toString() &&
              filteruser[0]?.leveldepartmentname?.some((dept) =>
                val?.leveldepartmentname?.includes(dept)
              )
            );
          })
        : allStaffList?.filter((item) => item?.statusid === "1");

    return uniqBy(staffList, "fullname");
  };

  console.log(
    "stafflist-->",
    getStaffList()?.map((item) => item?.baselocationid)
  );

  const getFilterList = () => {
    if (
      form.getFieldValue("employee") !== "" ||
      form.getFieldValue("supervisor") !== "All" ||
      form.getFieldValue("competencyrole") !== "" ||
      form.getFieldValue("managingoffice")?.length !== 0 ||
      form.getFieldValue("leveldepartmentname")?.length !== 0 ||
      form.getFieldValue("competencyQC")?.length !== 0 ||
      form.getFieldValue("serviceline")?.length !== 0 ||
      form.getFieldValue("team")?.length !== 0
    ) {
      return getStaffList()
        .filter((data) => {
          if (form.getFieldValue("employee") !== "") {
            return data?.fullname === form.getFieldValue("employee");
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("supervisor") !== "All") {
            return data?.officesupervisor === form.getFieldValue("supervisor");
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyrole") !== "") {
            return data?.competencyrole?.includes(
              form.getFieldValue("competencyrole")
            );
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("managingoffice")?.length !== 0) {
            return form
              .getFieldValue("managingoffice")
              ?.some((office) => data?.managingoffice?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("leveldepartmentname")?.length !== 0) {
            return form
              .getFieldValue("leveldepartmentname")
              ?.some((office) => data?.leveldepartmentname?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyQC")?.length !== 0) {
            return form
              .getFieldValue("competencyQC")
              .some((office) => data?.competencyQC?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("serviceline")?.length !== 0) {
            return form
              .getFieldValue("serviceline")
              ?.some((office) => data?.serviceline?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("team")?.length !== 0) {
            return form
              .getFieldValue("team")
              ?.some((office) => data?.subserviceline?.includes(office));
          }
          return data;
        });
    } else {
      return getStaffList();
    }
  };

  const STAFF_LIST = uniqBy(StaffList(), "fullname")?.map((val) => {
    return { user_id: val?.fullname, name: val?.fullname };
  });

  useEffect(() => {
    if (levelList?.length > 0) {
      let level1_values = [];
      let level2_values = [];
      let level3_values = [];
      let level4_values = [];
      let level5_values = [];

      levelList.forEach((level) => {
        if (level.levelName === "Level1") {
          level.value.forEach((value) => {
            level1_values.push(...value.value);
          });
        } else if (level.levelName === "Level2") {
          level.value.forEach((value) => {
            level2_values.push(...value.value);
          });
        } else if (level.levelName === "Level3") {
          level.value.forEach((value) => {
            level3_values.push(...value.value);
          });
        } else if (level.levelName === "Level4") {
          level.value.forEach((value) => {
            level4_values.push(...value.value);
          });
        } else if (level.levelName === "Level5") {
          level.value.forEach((value) => {
            level5_values.push(...value.value);
          });
        }
      });
      setManagingOfficeData(level1_values);
      setCompetencyQCfetchData(level2_values);
      setServicelinefetchData(level3_values);
      setsubservicelineData(level4_values);
      setDepartmentLevelData(level5_values);
    }
  }, []);

  return (
    <>
      <StyledTitle className="page-title-head">
        {url === "myteam" ? "My Team Member" : "List of Employee"}
      </StyledTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          competencyQC: [],
          serviceline: [],
          managingoffice: [],
          leveldepartmentname: [],
          serviceline: [],
          team: [],
          competencyrole: "",
          employee: "",
          supervisor: "All",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Form.Item name="employee" label="People">
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="name"
                getPopupContainer={() => containerRef.current}
                options={concat(
                  {
                    user_id: "",
                    name: "All Employee",
                  },
                  STAFF_LIST
                )}
                fieldNames={{
                  label: "name",
                  value: "user_id",
                }}
              />
            </Form.Item>
          </Col>
          {url !== "myteam" && (
            <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
              <Form.Item name="managingoffice" label="Country">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="value"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                >
                  {managingOfficeData?.map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="competencyQC" label="Competency/ QC">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="value"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={competencyQCData?.length === 0}
              >
                {competencyQCData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="serviceline" label="Serviceline">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={servicelineData?.length === 0}
              >
                {servicelineData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="team" label="Sub Serviceline">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={subservicelineData?.length == 0}
              >
                {subservicelineData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {url !== "myteam" && (
            <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
              <Form.Item name="leveldepartmentname" label="Department">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={departmentLevelData?.length == 0}
                >
                  {departmentLevelData?.map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="competencyrole" label="Competency role">
              <Select
                showSearch
                placeholder="Competency role"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="">All</Option>
                {CompetencyRole?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Form.Item name="supervisor" label="Supervisor / Manager">
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="All">All</Option>
                {supervisorList?.map((value, index) => (
                  <Select.Option
                    key={index}
                    value={value?.name}
                    label={value?.name}
                  >
                    {value?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <StyledFilterRow gutter={16}>
        <Col className="" lg={6} md={12} sm={24} xs={24}>
          <div className="people_font_style mb-2">
            Total People: {getFilterList().length}
          </div>
        </Col>
      </StyledFilterRow>

      <Row>
        {map(
          getFilterList(),
          (
            {
              clientid,
              userid,
              fullname,
              departmentid,
              profileurl,
              employeeid,
              jobtitle,
              usertype,
              employementtype,
            },
            index
          ) => (
            <StyledCol key={index} xs={24} sm={12} md={12} lg={8} xl={6}>
              <Link
                to={
                  url === "myteam"
                    ? "/myteam"
                    : `/profile-details/${userid}?type=hr`
                }
              >
                <TeamCard
                  id={userid}
                  clientid={clientid}
                  name={fullname}
                  email_id={userid}
                  employeeid={employeeid}
                  jobtitle={jobtitle}
                  usertype={usertype}
                  employementtype={employementtype}
                  hasUnreadMessage={
                    includes(unreadChatMessageList, userid) > 0 ? true : false
                  }
                  dept_name={result(
                    find(allDepartmentList, (val) => {
                      return val.department_id === departmentid;
                    }),
                    "department_name"
                  )}
                  profile={profileurl}
                  url={url}
                />
              </Link>
            </StyledCol>
          )
        )}
      </Row>
      <Modal
        centered
        visible={isBroadcastModalVisible}
        closable={false}
        footer={false}
        keyboard={false}
        title="Broadcast Message"
        onCancel={() => toggleBroadcastModalVisible(false)}
      >
        <TextArea
          rows={4}
          placeholder="Type a message."
          maxLength={500}
          value={broadcastMessage}
          onChange={(e) => setBroadcastMessage(e.target.value)}
        />
        <StyledFooterDiv>
          <Button
            type="link"
            onClick={sendMessage}
            loading={sendingMessageLoader}
          >
            Send to all
          </Button>
        </StyledFooterDiv>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
  levelList: getlevelList(state),
  supervisorList: getSupervisorList(state),
  roleList: getRoleList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Team);
