import React, { useEffect, useRef, useState } from 'react';
import { Modal, Row, Col, Form, Input, DatePicker, Select, message } from 'antd';
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import { getUserList, getClientid, getUserInfo } from 'redux/selectors';
import styled from '@emotion/styled';
import { NON_EMPTY_REGEX, STATUS_CODE } from 'common/Constants';

import Button from 'atoms/Button';
import { getMySqlDate, momentDate } from 'utils/Date';
import { displayErrorModal } from 'common/common';
import { CALL_API } from 'common/API';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 1);
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 5px;
`;

function EditTask({
  title,
  isModalVisible,
  closeModal,
  userList,
  taskDetails,
  getTaskList,
  ClientId,
  userInfo
}) {
  const [ showLoader, toggleLoader ] = useState(false);
  const [ taskId, setTaskId ] = useState('');
  const [ isViewOnly, setIsViewOnly ] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const containerRef = useRef(null);
  const getAllUser = userList?.filter((item) => item.departmentid === userInfo.staff_department_id);

  useEffect(() => {
    const {
      taskId = '',
      title = '',
      startDate = '',
      endDate = '',
      description = '',
      userId = '',
      isViewOnly
    } = taskDetails;
      setIsViewOnly(isViewOnly);
      form.setFieldsValue({
        'task_id': taskId,
        'task_title': title,
        'created_date': momentDate(startDate),
        'end_date': momentDate(endDate),
        'description': description,
        'assigned_to': userId
      });
  }, [ taskDetails ]);

  useEffect(() => {
    if (!isModalVisible) setTaskId('');
  }, [ isModalVisible ])

  const onFinish = async (values) => {
    toggleLoader(true);
    const {
      task_id,
      task_title,
      created_date,
      end_date,
      assigned_to,
      description
    } = values;
    const {
      code,
    } = await CALL_API(`save-task/${ClientId}`, 'post', {
      task_id: task_id ? task_id : taskDetails.taskId,
      task_title,
      start_date: getMySqlDate(created_date) ,
      end_date: getMySqlDate(end_date),
      assigned_to,
      description
    });
    toggleLoader(false);
    if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
      displayErrorModal(`Oops!! something went wrong.`);
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      displayErrorModal(`Invalid payload. Please enter correct data.`);
    } else if (code === STATUS_CODE.TASK_SAVED) {
      message.success(taskId ? 'Task has been updated.' : `Task has been added and assigned to staff.`);
      form.resetFields();
      setTaskId('');
      closeModal(true);
    }
    closeModal(true);
    getTaskList();
  }

  const restrictPastDate = (current, element) => {
    let customDate = moment().format('YYYY-MM-DD');
    if (element === 'end_date') {
      return current < moment(form.getFieldValue('created_date'));
    }
    return current && current < moment(customDate, 'YYYY-MM-DD');
  }

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }

  return (
    <StyledModal
      title={isViewOnly ? "View Task" : title}
      visible={isModalVisible}
      onCancel={() => closeModal(false)}
      width={800}
      footer={[]}
    >
      <Form
        form={form}
        name='addedittask'
        layout='vertical'
        initialValues={{
          task_id: '',
          task_title: '',
          created_date: momentDate(new Date()),
          end_date: momentDate(new Date()),
          assigned_to: '',
          description: '',
        }}
        onFinish={onFinish}
        // onValuesChange={onRequiredTypeChange}
        // requiredMark={requiredMark}
      >
        <Form.Item
          label='Task Title'
          name='task_title'
          rules={[
            {
              required: true,
              pattern: NON_EMPTY_REGEX,
              message: 'Please enter task title!',
            },
          ]}
        >
          <Input placeholder='Enter the task title' disabled={isViewOnly} maxLength={30}/>
        </Form.Item>

        <Row>
          <Col xs={24} sm={24} md={10} ref={containerRef}>
            <Form.Item
              label='Created Date'
              name='created_date'
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter created date!',
                },
                () => ({
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid() ? Promise.resolve() : Promise.reject();
                  }
                })
              ]}
            >
              <DatePicker
                disabledDate={(current) => restrictPastDate(current, 'created_date')}
                format="DD-MM-YYYY"
                onChange={validateDateRange}
                disabled={isViewOnly}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={{ offset: 4, span: 10 }} ref={containerRef}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.created_date !== currentValues.created_date}
            >
              {() =>
                 (
                  <Form.Item
                    label='End Date'
                    name='end_date'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: 'Please enter end date!',
                      },
                      () => ({
                        validator(rule, value) {
                          const date = moment(value);
                          return date.isValid() ? Promise.resolve() : Promise.reject();
                        }
                      })
                    ]}
                  >
                    <DatePicker
                      disabledDate={(current) => restrictPastDate(current, 'end_date')} 
                      format="DD-MM-YYYY"
                      disabled={isViewOnly}
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Assigned To'
          name='assigned_to'
          rules={[
            {
              required: true,
              pattern: NON_EMPTY_REGEX,
              message: 'Please select staff!',
            },
          ]}
          ref={containerRef}
        >
          <Select
            showSearch
            style={{
              width: '100%',
            }}
            placeholder='Search staff'
            name='assigned_to'
            optionFilterProp='name'
            filterOption={(input, option) => option.name.includes(input)}
            
            options={getAllUser}
            fieldNames={{
              label: 'fullname',
              value: 'userid'
            }}
            disabled={isViewOnly}
            getPopupContainer={() => containerRef.current}
          />
        </Form.Item>

        <Form.Item
          label='Description'
          name='description'
          rules={[
            {
              required: true,
              pattern: NON_EMPTY_REGEX,
              message: 'Please enter task description!',
            },
          ]}
        >
          <TextArea placeholder='Type something..' maxLength={500} disabled={isViewOnly} autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        {
          !isViewOnly && (
            <div className='align-center'>
              <StyledButton loading={showLoader} type='primary' htmlType='submit'>
                { showLoader ? 'Saving' : 'Save' }
              </StyledButton>
            </div>
          )
        }
      </Form>
    </StyledModal>
  );
}

const mapStateToProps = state => ({
  userList: getUserList(state),
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(EditTask);
