import React, { useEffect, useState } from "react";
import { Column, Bar } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getLeaveCategoryList,
  getAllDepartmentList,
  getUserList,
  getIsCheckedIn
} from "redux/selectors";
import DepartmentAttendanceReport from "./DepartmentAttendanceReport";
import AttendanceStaffList from "./AttendanceStaffList";
import { CALL_API } from "common/API";
import { concat, includes, isNaN, map, max, size, filter } from "lodash";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import { bottomNavigationActionClasses } from "@mui/material";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 10px;
  padding-bottom: 35px;
  padding-top: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function AttendanceBarChart({
  userRoleId,
  selectedDepartmentId,
  ClientId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  isCheckedIn,
  isHrDashboard = false
}) {

  const dummydata = [
    {
        "absent": [
            {
                "userdepartmentid": {
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Siva Siv",
                "userid": "akash12@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Akass Akash",
                "userid": "akash@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Anbu A",
                "userid": "anbu@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Jeni sha",
                "userid": "anvar@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Aravind R",
                "userid": "aravind@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Chris lin",
                "userid": "chrislin@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Akash A",
                "userid": "gadribesto@gufum.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Gun Gung",
                "userid": "gungung@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Arun A",
                "userid": "gutrepifyi@gufum.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Harish H",
                "userid": "harish@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Mohamad Asif",
                "userid": "hirzecorto@gufum.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Gino Jasmin",
                "userid": "jasmin@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Shivani Shivu",
                "userid": "jasmingj2001@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Jas min",
                "userid": "jasmingj@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "jenison R",
                "userid": "jenison@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Aisha sha",
                "userid": "jexeda9353@facais.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Unis Mariya",
                "userid": "jiydukolte@gufum.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "John R",
                "userid": "john@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Rubi Rubi",
                "userid": "keerthana@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "5": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Elgin Rio",
                "userid": "kidoci8267@jadsys.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "5": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Kivi Kivi",
                "userid": "kiveh54976@cutxsew.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "5": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Radha Radha",
                "userid": "radha@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Jiji in",
                "userid": "riya@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Sanjai S",
                "userid": "sanjai@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Saran Developer",
                "userid": "saran@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "abdhul malik",
                "userid": "sivasmk54@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Surya kannan",
                "userid": "surya@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "John Mark",
                "userid": "test@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "ajay raj",
                "userid": "vikkayepsi@gufum.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "3": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ],
                    "4": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Theju Shravani",
                "userid": "vinothjerom23@gmail.com",
                "usertype": "Employee"
            },
            {
                "userdepartmentid": {
                    "2": [
                        "18",
                        "16",
                        "2",
                        "13",
                        "8",
                        "9",
                        "1",
                        "6",
                        "5",
                        "4",
                        "19",
                        "7",
                        "11",
                        "3",
                        "20",
                        "21",
                        "12",
                        "17",
                        "10",
                        "15",
                        "14"
                    ]
                },
                "fullname": "Henry   Shah",
                "userid": "yitil74582@bsomek.com",
                "usertype": "Employee"
            }
        ],
        "late": [
          {
            "checkinTime": "11:20:28",
            "departmentId": "15",
            "userId": "sivasmk53@gmail.com",
            "loginLatitude": 8.1865083,
            "fullName": "bharath lin",
            "loginLocationName": "Other",
            "logoutLongitude": 77.4192646,
            "departmentName": "IT"
        },
        {
            "checkinTime": "10:15:00",
            "departmentId": "18",
            "userId": "user123@gmail.com",
            "loginLatitude": 12.9715987,
            "fullName": "Alice Smith",
            "loginLocationName": "Office A",
            "logoutLongitude": 77.5945667,
            "departmentName": "Tax-C"
        },
        {
            "checkinTime": "09:30:45",
            "departmentId": "16",
            "userId": "john.doe@example.com",
            "loginLatitude": 37.7749295,
            "fullName": "John Doe",
            "loginLocationName": "Headquarters",
            "logoutLongitude": -122.4194155,
            "departmentName": "Audit-B"
        },
        {
            "checkinTime": "12:00:00",
            "departmentId": "2",
            "userId": "emma.jones@email.com",
            "loginLatitude": 51.5073509,
            "fullName": "Emma Jones",
            "loginLocationName": "Remote",
            "logoutLongitude": -0.1277583,
            "departmentName": "Technology"
        },
        {
            "checkinTime": "09:45:20",
            "departmentId": "13",
            "userId": "samuel.miller@company.org",
            "loginLatitude": 40.712776,
            "fullName": "Samuel Miller",
            "loginLocationName": "Conference Room",
            "logoutLongitude": -74.005974,
            "departmentName": "Audit-C"
        },
        {
            "checkinTime": "08:30:15",
            "departmentId": "8",
            "userId": "laura.wong@example.org",
            "loginLatitude": -33.8688197,
            "fullName": "Laura Wong",
            "loginLocationName": "Site B",
            "logoutLongitude": 151.2092955,
            "departmentName": "Deal Advisory"
        },
        {
            "checkinTime": "11:10:55",
            "departmentId": "9",
            "userId": "kevin.anderson@workplace.com",
            "loginLatitude": 34.052235,
            "fullName": "Kevin Anderson",
            "loginLocationName": "Desk C",
            "logoutLongitude": -118.243683,
            "departmentName": "Finance"
        },
        {
            "checkinTime": "10:05:30",
            "departmentId": "1",
            "userId": "sarah.johnson@example.net",
            "loginLatitude": 52.5200066,
            "fullName": "Sarah Johnson",
            "loginLocationName": "Remote Office",
            "logoutLongitude": 13.404954,
            "departmentName": "Development"
        },
        {
            "checkinTime": "09:20:10",
            "departmentId": "6",
            "userId": "michael.smith@techfirm.com",
            "loginLatitude": 48.856614,
            "fullName": "Michael Smith",
            "loginLocationName": "Lab",
            "logoutLongitude": 2.3522219,
            "departmentName": "Audit"
        },
        {
            "checkinTime": "12:30:45",
            "departmentId": "5",
            "userId": "julia.davis@company.biz",
            "loginLatitude": 35.6894875,
            "fullName": "Julia Davis",
            "loginLocationName": "Floor 5",
            "logoutLongitude": 139.6917064,
            "departmentName": "Tax-A"
        },
        {
            "checkinTime": "11:55:00",
            "departmentId": "4",
            "userId": "peter.wilson@example.edu",
            "loginLatitude": 37.7749295,
            "fullName": "Peter Wilson",
            "loginLocationName": "Workshop",
            "logoutLongitude": -122.4194155,
            "departmentName": "Testing Dept"
        },
        {
            "checkinTime": "08:45:20",
            "departmentId": "19",
            "userId": "jennifer.brown@techcorp.org",
            "loginLatitude": 51.5073509,
            "fullName": "Jennifer Brown",
            "loginLocationName": "Testing Room",
            "logoutLongitude": -0.1277583,
            "departmentName": "HR"
        },
        {
            "checkinTime": "10:25:30",
            "departmentId": "7",
            "userId": "david.jones@company.com",
            "loginLatitude": 34.052235,
            "fullName": "David Jones",
            "loginLocationName": "Desk A",
            "logoutLongitude": -118.243683,
            "departmentName": "RAS"
        },
        {
            "checkinTime": "09:15:10",
            "departmentId": "11",
            "userId": "alexandra.smith@example.net",
            "loginLatitude": 40.712776,
            "fullName": "Alexandra Smith",
            "loginLocationName": "Cafeteria",
            "logoutLongitude": -74.005974,
            "departmentName": "Audit-E"
        },
        {
            "checkinTime": "12:15:45",
            "departmentId": "3",
            "userId": "matthew.white@company.org",
            "loginLatitude": 52.5200066,
            "fullName": "Matthew White",
            "loginLocationName": "Gym",
            "logoutLongitude": 13.404954,
            "departmentName": "Operations"
        },
        {
            "checkinTime": "11:40:20",
            "departmentId": "20",
            "userId": "emily.jackson@example.com",
            "loginLatitude": 48.856614,
            "fullName": "Emily Jackson",
            "loginLocationName": "Warehouse",
            "logoutLongitude": 2.3522219,
            "departmentName": "Tax"
        },
        {
            "checkinTime": "08:55:30",
            "departmentId": "21",
            "userId": "jason.miller@techfirm.biz",
            "loginLatitude": 35.6894875,
            "fullName": "Jason Miller",
            "loginLocationName": "Meeting Room",
            "logoutLongitude": 139.6917064,
            "departmentName": "Services"
        },
        {
            "checkinTime": "09:35:15",
            "departmentId": "12",
            "userId": "olivia.wilson@company.edu",
            "loginLatitude": 37.7749295,
            "fullName": "Olivia Wilson",
            "loginLocationName": "Training Room",
            "logoutLongitude": -122.4194155,
            "departmentName": "Audit-D"
        },
        {
            "checkinTime": "10:50:00",
            "departmentId": "17",
            "userId": "william.thomas@techcorp.org",
            "loginLatitude": 51.5073509,
            "fullName": "William Thomas",
            "loginLocationName": "Desk B",
            "logoutLongitude": -0.1277583,
            "departmentName": "Marketing"
        },
        {
            "checkinTime": "12:05:20",
            "departmentId": "10",
            "userId": "natalie.green@company.com",
            "loginLatitude": 34.052235,
            "fullName": "Natalie Green",
            "loginLocationName": "Server Room",
            "logoutLongitude": -118.243683,
            "departmentName": "Advisory"
        },
        {
            "checkinTime": "11:50:30",
            "departmentId": "16",
            "userId": "chris.evans@company.net",
            "loginLatitude": 40.712776,
            "fullName": "Chris Evans",
            "loginLocationName": "Conference Room",
            "logoutLongitude": -74.005974,
            "departmentName": "Audit-B"
        },
        {
            "checkinTime": "10:10:40",
            "departmentId": "18",
            "userId": "rebecca.taylor@company.biz",
            "loginLatitude": 52.5200066,
            "fullName": "Rebecca Taylor",
            "loginLocationName": "Office C",
            "logoutLongitude": 13.404954,
            "departmentName": "Tax-C"
        },
        {
            "checkinTime": "09:25:50",
            "departmentId": "2",
            "userId": "daniel.martin@company.org",
            "loginLatitude": 48.856614,
            "fullName": "Daniel Martin",
            "loginLocationName": "Remote",
            "logoutLongitude": 2.3522219,
            "departmentName": "Technology"
        },
        {
            "checkinTime": "12:20:00",
            "departmentId": "13",
            "userId": "molly.watson@company.com",
            "loginLatitude": 35.6894875,
            "fullName": "Molly Watson",
            "loginLocationName": "Office D",
            "logoutLongitude": 139.6917064,
            "departmentName": "Audit-C"
        },
        {
            "checkinTime": "08:40:10",
            "departmentId": "8",
            "userId": "luke.baker@company.biz",
            "loginLatitude": 37.7749295,
            "fullName": "Luke Baker",
            "loginLocationName": "Floor 4",
            "logoutLongitude": -122.4194155,
            "departmentName": "Deal Advisory"
        },
        {
            "checkinTime": "09:50:20",
            "departmentId": "9",
            "userId": "sophia.hill@company.org",
            "loginLatitude": 51.5073509,
            "fullName": "Sophia Hill",
            "loginLocationName": "Desk E",
            "logoutLongitude": -0.1277583,
            "departmentName": "Finance"
        },
        {
            "checkinTime": "10:35:30",
            "departmentId": "1",
            "userId": "amelia.clark@company.com",
            "loginLatitude": 34.052235,
            "fullName": "Amelia Clark",
            "loginLocationName": "Meeting Room B",
            "logoutLongitude": -118.243683,
            "departmentName": "Development"
        },
        {
            "checkinTime": "09:45:40",
            "departmentId": "6",
            "userId": "ethan.lewis@company.net",
            "loginLatitude": 40.712776,
            "fullName": "Ethan Lewis",
            "loginLocationName": "Lab B",
            "logoutLongitude": -74.005974,
            "departmentName": "Audit"
        },
        {
            "checkinTime": "11:30:50",
            "departmentId": "5",
            "userId": "zoe.ward@company.biz",
            "loginLatitude": 52.5200066,
            "fullName": "Zoe Ward",
            "loginLocationName": "Workshop A",
            "logoutLongitude": 13.404954,
            "departmentName": "Tax-A"
        },
        {
            "checkinTime": "10:20:00",
            "departmentId": "4",
            "userId": "logan.king@company.org",
            "loginLatitude": 48.856614,
            "fullName": "Logan King",
            "loginLocationName": "Office F",
            "logoutLongitude": 2.3522219,
            "departmentName": "Testing Dept"
        },
        {
            "checkinTime": "09:05:10",
            "departmentId": "19",
            "userId": "ella.wright@company.com",
            "loginLatitude": 35.6894875,
            "fullName": "Ella Wright",
            "loginLocationName": "Site C",
            "logoutLongitude": 139.6917064,
            "departmentName": "HR"
        },
        {
            "checkinTime": "12:10:20",
            "departmentId": "7",
            "userId": "jayden.turner@company.biz",
            "loginLatitude": 37.7749295,
            "fullName": "Jayden Turner",
            "loginLocationName": "Training Room B",
            "logoutLongitude": -122.4194155,
            "departmentName": "RAS"
        },
        {
            "checkinTime": "10:25:30",
            "departmentId": "11",
            "userId": "madison.james@company.org",
            "loginLatitude": 51.5073509,
            "fullName": "Madison James",
            "loginLocationName": "Server Room B",
            "logoutLongitude": -0.1277583,
            "departmentName": "Audit-E"
        },
        {
            "checkinTime": "09:40:40",
            "departmentId": "3",
            "userId": "oliver.harris@company.com",
            "loginLatitude": 34.052235,
            "fullName": "Oliver Harris",
            "loginLocationName": "Office G",
            "logoutLongitude": -118.243683,
            "departmentName": "Operations"
        },
        {
            "checkinTime": "12:30:50",
            "departmentId": "20",
            "userId": "harper.evans@company.net",
            "loginLatitude": 40.712776,
            "fullName": "Harper Evans",
            "loginLocationName": "Floor 3",
            "logoutLongitude": -74.005974,
            "departmentName": "Tax"
        },
        {
            "checkinTime": "11:20:00",
            "departmentId": "21",
            "userId": "jackson.scott@company.biz",
            "loginLatitude": 52.5200066,
            "fullName": "Jackson Scott",
            "loginLocationName": "Office H",
            "logoutLongitude": 13.404954,
            "departmentName": "Services"
        },
        {
            "checkinTime": "09:10:10",
            "departmentId": "12",
            "userId": "scarlett.young@company.org",
            "loginLatitude": 48.856614,
            "fullName": "Scarlett Young",
            "loginLocationName": "Remote",
            "logoutLongitude": 2.3522219,
            "departmentName": "Audit-D"
        },
        {
            "checkinTime": "12:45:20",
            "departmentId": "17",
            "userId": "dylan.reed@company.com",
            "loginLatitude": 35.6894875,
            "fullName": "Dylan Reed",
            "loginLocationName": "Office J",
            "logoutLongitude": 139.6917064,
            "departmentName": "Marketing"
        },
        {
            "checkinTime": "11:55:30",
            "departmentId": "10",
            "userId": "brooklyn.bailey@company.biz",
            "loginLatitude": 37.7749295,
            "fullName": "Brooklyn Bailey",
            "loginLocationName": "Desk K",
            "logoutLongitude": -122.4194155,
            "departmentName": "Advisory"
        },
        {
            "checkinTime": "10:15:40",
            "departmentId": "15",
            "userId": "alex.walker@company.org",
            "loginLatitude": 51.5073509,
            "fullName": "Alex Walker",
            "loginLocationName": "Floor 2",
            "logoutLongitude": -0.1277583,
            "departmentName": "IT"
        },
        {
            "checkinTime": "09:30:50",
            "departmentId": "14",
            "userId": "hazel.gray@company.com",
            "loginLatitude": 34.052235,
            "fullName": "Hazel Gray",
            "loginLocationName": "Desk L",
            "logoutLongitude": -118.243683,
            "departmentName": "BSO"
        },
        {
            "checkinTime": "12:00:00",
            "departmentId": "1",
            "userId": "leo.green@company.net",
            "loginLatitude": 40.712776,
            "fullName": "Leo Green",
            "loginLocationName": "Workshop B",
            "logoutLongitude": -74.005974,
            "departmentName": "Development"
        },
        {
            "checkinTime": "11:45:10",
            "departmentId": "5",
            "userId": "sophia.carter@company.biz",
            "loginLatitude": 52.5200066,
            "fullName": "Sophia Carter",
            "loginLocationName": "Office M",
            "logoutLongitude": 13.404954,
            "departmentName": "Tax-A"
        },
        {
            "checkinTime": "10:05:20",
            "departmentId": "9",
            "userId": "mason.brown@company.org",
            "loginLatitude": 48.856614,
            "fullName": "Mason Brown",
            "loginLocationName": "Desk N",
            "logoutLongitude": 2.3522219,
            "departmentName": "Finance"
        },
        {
            "checkinTime": "08:20:30",
            "departmentId": "4",
            "userId": "emily.johnson@company.com",
            "loginLatitude": 35.6894875,
            "fullName": "Emily Johnson",
            "loginLocationName": "Remote",
            "logoutLongitude": 139.6917064,
            "departmentName": "Testing Dept"
        },
        {
            "checkinTime": "09:40:40",
            "departmentId": "7",
            "userId": "ava.thomas@company.biz",
            "loginLatitude": 37.7749295,
            "fullName": "Ava Thomas",
            "loginLocationName": "Desk O",
            "logoutLongitude": -122.4194155,
            "departmentName": "RAS"
        },
            {
        "checkinTime": "11:20:28",
        "departmentId": "15",
        "userId": "sivasmk53@gmail.com",
        "loginLatitude": 8.1865083,
        "fullName": "bharath lin",
        "loginLocationName": "Other",
        "logoutLongitude": 77.4192646,
        "departmentName": "IT"
    },
    {
        "checkinTime": "10:15:00",
        "departmentId": "10",
        "userId": "user123@gmail.com",
        "loginLatitude": 12.9715987,
        "fullName": "Alice Smith",
        "loginLocationName": "Office A",
        "logoutLongitude": 77.5945667,
        "departmentName": "HR"
    },
    {
        "checkinTime": "09:30:45",
        "departmentId": "8",
        "userId": "john.doe@example.com",
        "loginLatitude": 37.7749295,
        "fullName": "John Doe",
        "loginLocationName": "Headquarters",
        "logoutLongitude": -122.4194155,
        "departmentName": "Finance"
    },
    {
        "checkinTime": "12:00:00",
        "departmentId": "12",
        "userId": "emma.jones@email.com",
        "loginLatitude": 51.5073509,
        "fullName": "Emma Jones",
        "loginLocationName": "Remote",
        "logoutLongitude": -0.1277583,
        "departmentName": "Marketing"
    },
    {
        "checkinTime": "09:45:20",
        "departmentId": "18",
        "userId": "samuel.miller@company.org",
        "loginLatitude": 40.712776,
        "fullName": "Samuel Miller",
        "loginLocationName": "Conference Room",
        "logoutLongitude": -74.005974,
        "departmentName": "Operations"
    },
    {
        "checkinTime": "08:30:15",
        "departmentId": "21",
        "userId": "laura.wong@example.org",
        "loginLatitude": -33.8688197,
        "fullName": "Laura Wong",
        "loginLocationName": "Site B",
        "logoutLongitude": 151.2092955,
        "departmentName": "Sales"
    },
    {
        "checkinTime": "11:10:55",
        "departmentId": "14",
        "userId": "kevin.anderson@workplace.com",
        "loginLatitude": 34.052235,
        "fullName": "Kevin Anderson",
        "loginLocationName": "Desk C",
        "logoutLongitude": -118.243683,
        "departmentName": "Customer Support"
    },
    {
        "checkinTime": "10:05:30",
        "departmentId": "9",
        "userId": "sarah.johnson@example.net",
        "loginLatitude": 52.5200066,
        "fullName": "Sarah Johnson",
        "loginLocationName": "Remote Office",
        "logoutLongitude": 13.404954,
        "departmentName": "Research"
    },
    {
        "checkinTime": "09:20:10",
        "departmentId": "11",
        "userId": "michael.smith@techfirm.com",
        "loginLatitude": 48.856614,
        "fullName": "Michael Smith",
        "loginLocationName": "Lab",
        "logoutLongitude": 2.3522219,
        "departmentName": "Engineering"
    },
    {
        "checkinTime": "12:30:45",
        "departmentId": "17",
        "userId": "julia.davis@company.biz",
        "loginLatitude": 35.6894875,
        "fullName": "Julia Davis",
        "loginLocationName": "Floor 5",
        "logoutLongitude": 139.6917064,
        "departmentName": "Production"
    },
    {
        "checkinTime": "11:55:00",
        "departmentId": "16",
        "userId": "peter.wilson@example.edu",
        "loginLatitude": 37.7749295,
        "fullName": "Peter Wilson",
        "loginLocationName": "Workshop",
        "logoutLongitude": -122.4194155,
        "departmentName": "Quality Assurance"
    },
    {
        "checkinTime": "08:45:20",
        "departmentId": "13",
        "userId": "jennifer.brown@techcorp.org",
        "loginLatitude": 51.5073509,
        "fullName": "Jennifer Brown",
        "loginLocationName": "Testing Room",
        "logoutLongitude": -0.1277583,
        "departmentName": "IT Support"
    },
    {
        "checkinTime": "10:25:30",
        "departmentId": "20",
        "userId": "david.jones@company.com",
        "loginLatitude": 34.052235,
        "fullName": "David Jones",
        "loginLocationName": "Desk A",
        "logoutLongitude": -118.243683,
        "departmentName": "Legal"
    },
    {
        "checkinTime": "09:15:10",
        "departmentId": "19",
        "userId": "alexandra.smith@example.net",
        "loginLatitude": 40.712776,
        "fullName": "Alexandra Smith",
        "loginLocationName": "Cafeteria",
        "logoutLongitude": -74.005974,
        "departmentName": "Security"
    },
    {
        "checkinTime": "12:15:45",
        "departmentId": "22",
        "userId": "matthew.white@company.org",
        "loginLatitude": 52.5200066,
        "fullName": "Matthew White",
        "loginLocationName": "Gym",
        "logoutLongitude": 13.404954,
        "departmentName": "Maintenance"
    },
    {
        "checkinTime": "11:40:20",
        "departmentId": "23",
        "userId": "emily.jackson@example.com",
        "loginLatitude": 48.856614,
        "fullName": "Emily Jackson",
        "loginLocationName": "Warehouse",
        "logoutLongitude": 2.3522219,
        "departmentName": "Logistics"
    },
    {
        "checkinTime": "08:55:30",
        "departmentId": "24",
        "userId": "jason.miller@techfirm.biz",
        "loginLatitude": 35.6894875,
        "fullName": "Jason Miller",
        "loginLocationName": "Meeting Room",
        "logoutLongitude": 139.6917064,
        "departmentName": "Training"
    },
    {
        "checkinTime": "09:35:15",
        "departmentId": "25",
        "userId": "olivia.wilson@company.edu",
        "loginLatitude": 37.7749295,
        "fullName": "Olivia Wilson",
        "loginLocationName": "Training Room",
        "logoutLongitude": -122.4194155,
        "departmentName": "Consulting"
    },
    {
        "checkinTime": "10:50:00",
        "departmentId": "26",
        "userId": "william.thomas@techcorp.org",
        "loginLatitude": 51.5073509,
        "fullName": "William Thomas",
        "loginLocationName": "Desk B",
        "logoutLongitude": -0.1277583,
        "departmentName": "Communications"
    },
    {
        "checkinTime": "12:05:20",
        "departmentId": "27",
        "userId": "natalie.green@company.com",
        "loginLatitude": 34.052235,
        "fullName": "Natalie Green",
        "loginLocationName": "Server Room",
        "logoutLongitude": -118.243683,
        "departmentName": "Architecture"
    }        ],
        "onTime": [
          {
            "checkinTime": "07:30:00",
            "departmentId": "18",
            "userId": "johndoe@example.com",
            "loginLatitude": 12.9716,
            "fullName": "John Doe",
            "loginLocationName": "Office",
            "logoutLongitude": "",
            "departmentName": "Tax-C"
        },
        {
            "checkinTime": "07:32:00",
            "departmentId": "16",
            "userId": "alice.smith@example.com",
            "loginLatitude": 51.5074,
            "fullName": "Alice Smith",
            "loginLocationName": "Headquarters",
            "logoutLongitude": "",
            "departmentName": "Audit-B"
        },
        {
            "checkinTime": "07:34:00",
            "departmentId": "2",
            "userId": "robert.wong@example.com",
            "loginLatitude": 34.0522,
            "fullName": "Robert Wong",
            "loginLocationName": "Remote",
            "logoutLongitude": "",
            "departmentName": "Technology"
        },
        {
            "checkinTime": "07:36:00",
            "departmentId": "13",
            "userId": "emily.jones@example.com",
            "loginLatitude": 40.7128,
            "fullName": "Emily Jones",
            "loginLocationName": "Home Office",
            "logoutLongitude": "",
            "departmentName": "Audit-C"
        },
        {
            "checkinTime": "07:38:00",
            "departmentId": "8",
            "userId": "michael.nguyen@example.com",
            "loginLatitude": 37.7749,
            "fullName": "Michael Nguyen",
            "loginLocationName": "Other",
            "logoutLongitude": "",
            "departmentName": "Deal Advisory"
        },
        {
            "checkinTime": "07:40:00",
            "departmentId": "9",
            "userId": "sophia.kim@example.com",
            "loginLatitude": 25.7617,
            "fullName": "Sophia Kim",
            "loginLocationName": "City Center",
            "logoutLongitude": "",
            "departmentName": "Finance"
        },
        {
            "checkinTime": "07:42:00",
            "departmentId": "1",
            "userId": "david.garcia@example.com",
            "loginLatitude": 45.4215,
            "fullName": "David Garcia",
            "loginLocationName": "Downtown",
            "logoutLongitude": "",
            "departmentName": "Development"
        },
        {
            "checkinTime": "07:44:00",
            "departmentId": "6",
            "userId": "susan.miller@example.com",
            "loginLatitude": 32.7157,
            "fullName": "Susan Miller",
            "loginLocationName": "Office Park",
            "logoutLongitude": "",
            "departmentName": "Audit"
        },
        {
            "checkinTime": "07:46:00",
            "departmentId": "5",
            "userId": "kevin.clark@example.com",
            "loginLatitude": 38.9072,
            "fullName": "Kevin Clark",
            "loginLocationName": "Suburb",
            "logoutLongitude": "",
            "departmentName": "Tax-A"
        },
        {
            "checkinTime": "07:48:00",
            "departmentId": "4",
            "userId": "linda.wilson@example.com",
            "loginLatitude": 33.749,
            "fullName": "Linda Wilson",
            "loginLocationName": "Campus",
            "logoutLongitude": "",
            "departmentName": "Testing Dept"
        },
        {
            "checkinTime": "07:50:00",
            "departmentId": "19",
            "userId": "steven.nguyen@example.com",
            "loginLatitude": 47.6062,
            "fullName": "Steven Nguyen",
            "loginLocationName": "Home",
            "logoutLongitude": "",
            "departmentName": "HR"
        },
        {
            "checkinTime": "07:52:00",
            "departmentId": "7",
            "userId": "jennifer.white@example.com",
            "loginLatitude": 39.9526,
            "fullName": "Jennifer White",
            "loginLocationName": "Business District",
            "logoutLongitude": "",
            "departmentName": "RAS"
        },
        {
            "checkinTime": "07:54:00",
            "departmentId": "11",
            "userId": "matthew.thomas@example.com",
            "loginLatitude": 42.3601,
            "fullName": "Matthew Thomas",
            "loginLocationName": "Commercial Area",
            "logoutLongitude": "",
            "departmentName": "Audit-E"
        },
        {
            "checkinTime": "07:56:00",
            "departmentId": "3",
            "userId": "amanda.scott@example.com",
            "loginLatitude": 36.1627,
            "fullName": "Amanda Scott",
            "loginLocationName": "Technology Park",
            "logoutLongitude": "",
            "departmentName": "Operations"
        },
        {
          "checkinTime": "07:02:00",
          "departmentId": "1",
          "userId": "alexander.smith@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Alexander Smith",
          "loginLocationName": "Tech Park",
          "logoutLongitude": "",
          "departmentName": "Development"
      },
      {
          "checkinTime": "07:04:00",
          "departmentId": "2",
          "userId": "sophia.johnson@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Sophia Johnson",
          "loginLocationName": "Innovation Center",
          "logoutLongitude": "",
          "departmentName": "Technology"
      },
      {
          "checkinTime": "07:06:00",
          "departmentId": "3",
          "userId": "william.williams@example.com",
          "loginLatitude": 34.0522,
          "fullName": "William Williams",
          "loginLocationName": "Business Park",
          "logoutLongitude": "",
          "departmentName": "Operations"
      },
      {
          "checkinTime": "07:08:00",
          "departmentId": "4",
          "userId": "emma.jones@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Emma Jones",
          "loginLocationName": "Financial Hub",
          "logoutLongitude": "",
          "departmentName": "Testing Dept"
      },
      {
          "checkinTime": "07:10:00",
          "departmentId": "5",
          "userId": "daniel.brown@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Daniel Brown",
          "loginLocationName": "Corporate Campus",
          "logoutLongitude": "",
          "departmentName": "Tax-A"
      },
      {
          "checkinTime": "07:12:00",
          "departmentId": "6",
          "userId": "olivia.davis@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Davis",
          "loginLocationName": "Marketing Tower",
          "logoutLongitude": "",
          "departmentName": "Audit"
      },
      {
          "checkinTime": "07:14:00",
          "departmentId": "7",
          "userId": "noah.miller@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Noah Miller",
          "loginLocationName": "Customer Support",
          "logoutLongitude": "",
          "departmentName": "RAS"
      },
      {
          "checkinTime": "07:16:00",
          "departmentId": "8",
          "userId": "olivia.wilson@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Wilson",
          "loginLocationName": "Legal Office",
          "logoutLongitude": "",
          "departmentName": "Deal Advisory"
      },
      {
          "checkinTime": "07:18:00",
          "departmentId": "9",
          "userId": "jacob.garcia@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Jacob Garcia",
          "loginLocationName": "Research Campus",
          "logoutLongitude": "",
          "departmentName": "Finance"
      },
      {
          "checkinTime": "07:20:00",
          "departmentId": "10",
          "userId": "sophia.thomas@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Sophia Thomas",
          "loginLocationName": "Advisory Center",
          "logoutLongitude": "",
          "departmentName": "Advisory"
      },
      {
          "checkinTime": "07:22:00",
          "departmentId": "11",
          "userId": "ethan.johnson@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Ethan Johnson",
          "loginLocationName": "Tech Hub",
          "logoutLongitude": "",
          "departmentName": "Audit-E"
      },
      {
          "checkinTime": "07:24:00",
          "departmentId": "12",
          "userId": "olivia.martinez@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Martinez",
          "loginLocationName": "Tech Park",
          "logoutLongitude": "",
          "departmentName": "Audit-D"
      },
      {
          "checkinTime": "07:26:00",
          "departmentId": "13",
          "userId": "william.jones@example.com",
          "loginLatitude": 34.0522,
          "fullName": "William Jones",
          "loginLocationName": "Innovation Park",
          "logoutLongitude": "",
          "departmentName": "Audit-C"
      },
      {
          "checkinTime": "07:28:00",
          "departmentId": "14",
          "userId": "olivia.brown@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Brown",
          "loginLocationName": "Marketing Tower",
          "logoutLongitude": "",
          "departmentName": "BSO"
      },
      {
          "checkinTime": "07:30:00",
          "departmentId": "15",
          "userId": "james.garcia@example.com",
          "loginLatitude": 34.0522,
          "fullName": "James Garcia",
          "loginLocationName": "Financial Hub",
          "logoutLongitude": "",
          "departmentName": "IT"
      },
      {
          "checkinTime": "07:32:00",
          "departmentId": "16",
          "userId": "olivia.taylor@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Taylor",
          "loginLocationName": "Corporate Campus",
          "logoutLongitude": "",
          "departmentName": "Audit-B"
      },
      {
          "checkinTime": "07:34:00",
          "departmentId": "17",
          "userId": "william.thomas@example.com",
          "loginLatitude": 34.0522,
          "fullName": "William Thomas",
          "loginLocationName": "Tech Campus",
          "logoutLongitude": "",
          "departmentName": "Marketing"
      },
      {
          "checkinTime": "07:36:00",
          "departmentId": "18",
          "userId": "olivia.moore@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Moore",
          "loginLocationName": "Innovation Center",
          "logoutLongitude": "",
          "departmentName": "Tax-C"
      },
      {
          "checkinTime": "07:38:00",
          "departmentId": "19",
          "userId": "james.rodriguez@example.com",
          "loginLatitude": 34.0522,
          "fullName": "James Rodriguez",
          "loginLocationName": "Financial District",
          "logoutLongitude": "",
          "departmentName": "HR"
      },
      {
          "checkinTime": "07:40:00",
          "departmentId": "20",
          "userId": "olivia.hernandez@example.com",
          "loginLatitude": 34.0522,
          "fullName": "Olivia Hernandez",
          "loginLocationName": "Tech Hub",
          "logoutLongitude": "",
          "departmentName": "Tax"
      },
      {
        "checkinTime": "07:42:00",
        "departmentId": "1",
        "userId": "olivia.adams@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Adams",
        "loginLocationName": "Tech Park",
        "logoutLongitude": "",
        "departmentName": "Development"
    },
    {
        "checkinTime": "07:44:00",
        "departmentId": "2",
        "userId": "ethan.white@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Ethan White",
        "loginLocationName": "Innovation Center",
        "logoutLongitude": "",
        "departmentName": "Technology"
    },
    {
        "checkinTime": "07:46:00",
        "departmentId": "3",
        "userId": "olivia.scott@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Scott",
        "loginLocationName": "Business Park",
        "logoutLongitude": "",
        "departmentName": "Operations"
    },
    {
        "checkinTime": "07:48:00",
        "departmentId": "4",
        "userId": "jack.anderson@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Jack Anderson",
        "loginLocationName": "Financial Hub",
        "logoutLongitude": "",
        "departmentName": "Testing Dept"
    },
    {
        "checkinTime": "07:50:00",
        "departmentId": "5",
        "userId": "olivia.lewis@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Lewis",
        "loginLocationName": "Corporate Campus",
        "logoutLongitude": "",
        "departmentName": "Tax-A"
    },
    {
        "checkinTime": "07:52:00",
        "departmentId": "6",
        "userId": "daniel.thomas@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Daniel Thomas",
        "loginLocationName": "Marketing Tower",
        "logoutLongitude": "",
        "departmentName": "Audit"
    },
    {
        "checkinTime": "07:54:00",
        "departmentId": "7",
        "userId": "olivia.harris@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Harris",
        "loginLocationName": "Customer Support",
        "logoutLongitude": "",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "07:56:00",
        "departmentId": "8",
        "userId": "william.clark@example.com",
        "loginLatitude": 34.0522,
        "fullName": "William Clark",
        "loginLocationName": "Legal Office",
        "logoutLongitude": "",
        "departmentName": "Deal Advisory"
    },
    {
        "checkinTime": "07:58:00",
        "departmentId": "9",
        "userId": "olivia.allen@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Allen",
        "loginLocationName": "Research Campus",
        "logoutLongitude": "",
        "departmentName": "Finance"
    },
    {
        "checkinTime": "08:00:00",
        "departmentId": "10",
        "userId": "jacob.davis@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Jacob Davis",
        "loginLocationName": "Advisory Center",
        "logoutLongitude": "",
        "departmentName": "Advisory"
    },
    {
        "checkinTime": "08:02:00",
        "departmentId": "11",
        "userId": "olivia.thompson@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Thompson",
        "loginLocationName": "Tech Hub",
        "logoutLongitude": "",
        "departmentName": "Audit-E"
    },
    {
        "checkinTime": "08:04:00",
        "departmentId": "12",
        "userId": "william.rodriguez@example.com",
        "loginLatitude": 34.0522,
        "fullName": "William Rodriguez",
        "loginLocationName": "Tech Park",
        "logoutLongitude": "",
        "departmentName": "Audit-D"
    },
    {
        "checkinTime": "08:06:00",
        "departmentId": "13",
        "userId": "olivia.gonzalez@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Gonzalez",
        "loginLocationName": "Innovation Park",
        "logoutLongitude": "",
        "departmentName": "Audit-C"
    },
    {
        "checkinTime": "08:08:00",
        "departmentId": "14",
        "userId": "noah.hernandez@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Noah Hernandez",
        "loginLocationName": "Marketing Tower",
        "logoutLongitude": "",
        "departmentName": "BSO"
    },
    {
        "checkinTime": "08:10:00",
        "departmentId": "15",
        "userId": "olivia.morris@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Morris",
        "loginLocationName": "Financial Hub",
        "logoutLongitude": "",
        "departmentName": "IT"
    },
    {
        "checkinTime": "08:12:00",
        "departmentId": "16",
        "userId": "james.young@example.com",
        "loginLatitude": 34.0522,
        "fullName": "James Young",
        "loginLocationName": "Corporate Campus",
        "logoutLongitude": "",
        "departmentName": "Audit-B"
    },
    {
        "checkinTime": "08:14:00",
        "departmentId": "17",
        "userId": "olivia.nguyen@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia Nguyen",
        "loginLocationName": "Tech Campus",
        "logoutLongitude": "",
        "departmentName": "Marketing"
    },
    {
        "checkinTime": "08:16:00",
        "departmentId": "18",
        "userId": "william.lee@example.com",
        "loginLatitude": 34.0522,
        "fullName": "William Lee",
        "loginLocationName": "Innovation Center",
        "logoutLongitude": "",
        "departmentName": "Tax-C"
    },
    {
        "checkinTime": "08:18:00",
        "departmentId": "19",
        "userId": "olivia.king@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Olivia King",
        "loginLocationName": "Financial District",
        "logoutLongitude": "",
        "departmentName": "HR"
    },
    {
        "checkinTime": "08:20:00",
        "departmentId": "20",
        "userId": "william.scott@example.com",
        "loginLatitude": 34.0522,
        "fullName": "William Scott",
        "loginLocationName": "Tech Hub",
        "logoutLongitude": "",
        "departmentName": "Tax"
    },
    {
      "checkinTime": "07:45:00",
      "departmentId": "1",
      "userId": "emma.smith@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Emma Smith",
      "loginLocationName": "Tech Park",
      "logoutLongitude": "",
      "departmentName": "Development"
  },
  {
      "checkinTime": "07:50:00",
      "departmentId": "1",
      "userId": "daniel.miller@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Daniel Miller",
      "loginLocationName": "Innovation Center",
      "logoutLongitude": "",
      "departmentName": "Development"
  },
  {
      "checkinTime": "07:55:00",
      "departmentId": "1",
      "userId": "olivia.wilson@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Wilson",
      "loginLocationName": "Business Park",
      "logoutLongitude": "",
      "departmentName": "Development"
  },
  {
      "checkinTime": "08:00:00",
      "departmentId": "1",
      "userId": "william.jones@example.com",
      "loginLatitude": 34.0522,
      "fullName": "William Jones",
      "loginLocationName": "Financial Hub",
      "logoutLongitude": "",
      "departmentName": "Development"
  },
  {
      "checkinTime": "08:05:00",
      "departmentId": "1",
      "userId": "olivia.brown@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Brown",
      "loginLocationName": "Corporate Campus",
      "logoutLongitude": "",
      "departmentName": "Development"
  },
  {
      "checkinTime": "07:48:00",
      "departmentId": "6",
      "userId": "jack.thomas@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Jack Thomas",
      "loginLocationName": "Marketing Tower",
      "logoutLongitude": "",
      "departmentName": "Audit"
  },
  {
      "checkinTime": "07:53:00",
      "departmentId": "6",
      "userId": "olivia.rodriguez@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Rodriguez",
      "loginLocationName": "Customer Support",
      "logoutLongitude": "",
      "departmentName": "Audit"
  },
  {
      "checkinTime": "07:58:00",
      "departmentId": "6",
      "userId": "william.garcia@example.com",
      "loginLatitude": 34.0522,
      "fullName": "William Garcia",
      "loginLocationName": "Legal Office",
      "logoutLongitude": "",
      "departmentName": "Audit"
  },
  {
      "checkinTime": "08:03:00",
      "departmentId": "6",
      "userId": "olivia.hernandez@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Hernandez",
      "loginLocationName": "Research Campus",
      "logoutLongitude": "",
      "departmentName": "Audit"
  },
  {
      "checkinTime": "08:08:00",
      "departmentId": "6",
      "userId": "james.nguyen@example.com",
      "loginLatitude": 34.0522,
      "fullName": "James Nguyen",
      "loginLocationName": "Advisory Center",
      "logoutLongitude": "",
      "departmentName": "Audit"
  },
  {
      "checkinTime": "07:50:00",
      "departmentId": "17",
      "userId": "olivia.kim@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Kim",
      "loginLocationName": "Tech Campus",
      "logoutLongitude": "",
      "departmentName": "Marketing"
  },
  {
      "checkinTime": "07:55:00",
      "departmentId": "17",
      "userId": "william.park@example.com",
      "loginLatitude": 34.0522,
      "fullName": "William Park",
      "loginLocationName": "Innovation Center",
      "logoutLongitude": "",
      "departmentName": "Marketing"
  },
  {
      "checkinTime": "08:00:00",
      "departmentId": "17",
      "userId": "olivia.lee@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Lee",
      "loginLocationName": "Financial District",
      "logoutLongitude": "",
      "departmentName": "Marketing"
  },
  {
      "checkinTime": "08:05:00",
      "departmentId": "17",
      "userId": "james.yang@example.com",
      "loginLatitude": 34.0522,
      "fullName": "James Yang",
      "loginLocationName": "Tech Hub",
      "logoutLongitude": "",
      "departmentName": "Marketing"
  },
  {
      "checkinTime": "08:10:00",
      "departmentId": "17",
      "userId": "olivia.chang@example.com",
      "loginLatitude": 34.0522,
      "fullName": "Olivia Chang",
      "loginLocationName": "Marketing Tower",
      "logoutLongitude": "",
      "departmentName": "Marketing"
  },
        {
            "checkinTime": "07:58:00",
            "departmentId": "20",
            "userId": "james.harris@example.com",
            "loginLatitude": 29.7604,
            "fullName": "James Harris",
            "loginLocationName": "Financial Center",
            "logoutLongitude": "",
            "departmentName": "Tax"
        },
        {
            "checkinTime": "08:00:00",
            "departmentId": "21",
            "userId": "sarah.lewis@example.com",
            "loginLatitude": 32.7767,
            "fullName": "Sarah Lewis",
            "loginLocationName": "Central Business District",
            "logoutLongitude": "",
            "departmentName": "Services"
        },
{
        "checkinTime": "07:59:17",
        "departmentId": "2",
        "userId": "johndoe@example.com",
        "loginLatitude": 12.9716,
        "fullName": "John Doe",
        "loginLocationName": "Office",
        "logoutLongitude": "",
        "departmentName": "Marketing"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "3",
        "userId": "alice.smith@example.com",
        "loginLatitude": 51.5074,
        "fullName": "Alice Smith",
        "loginLocationName": "Headquarters",
        "logoutLongitude": "",
        "departmentName": "HR"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "4",
        "userId": "robert.wong@example.com",
        "loginLatitude": 34.0522,
        "fullName": "Robert Wong",
        "loginLocationName": "Remote",
        "logoutLongitude": "",
        "departmentName": "Operations"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "5",
        "userId": "emily.jones@example.com",
        "loginLatitude": 40.7128,
        "fullName": "Emily Jones",
        "loginLocationName": "Home Office",
        "logoutLongitude": "",
        "departmentName": "Finance"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "6",
        "userId": "michael.nguyen@example.com",
        "loginLatitude": 37.7749,
        "fullName": "Michael Nguyen",
        "loginLocationName": "Other",
        "logoutLongitude": "",
        "departmentName": "Sales"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "7",
        "userId": "sophia.kim@example.com",
        "loginLatitude": 25.7617,
        "fullName": "Sophia Kim",
        "loginLocationName": "City Center",
        "logoutLongitude": "",
        "departmentName": "Customer Support"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "8",
        "userId": "david.garcia@example.com",
        "loginLatitude": 45.4215,
        "fullName": "David Garcia",
        "loginLocationName": "Downtown",
        "logoutLongitude": "",
        "departmentName": "IT"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "9",
        "userId": "susan.miller@example.com",
        "loginLatitude": 32.7157,
        "fullName": "Susan Miller",
        "loginLocationName": "Office Park",
        "logoutLongitude": "",
        "departmentName": "Legal"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "10",
        "userId": "kevin.clark@example.com",
        "loginLatitude": 38.9072,
        "fullName": "Kevin Clark",
        "loginLocationName": "Suburb",
        "logoutLongitude": "",
        "departmentName": "Research"
    },
    {
        "checkinTime": "07:59:17",
        "departmentId": "11",
        "userId": "linda.wilson@example.com",
        "loginLatitude": 33.749,
        "fullName": "Linda Wilson",
        "loginLocationName": "Campus",
        "logoutLongitude": "",
        "departmentName": "Education"
    }
],
        "leaveCount": [
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-06",
              "start_date": "2024-07-06",
              "userid": "john.doe@example.com",
              "fullname": "John Doe",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-07",
              "start_date": "2024-07-07",
              "userid": "emma.smith@example.com",
              "fullname": "Emma Smith",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-08",
              "start_date": "2024-07-08",
              "userid": "william.jones@example.com",
              "fullname": "William Jones",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-09",
              "start_date": "2024-07-09",
              "userid": "olivia.wilson@example.com",
              "fullname": "Olivia Wilson",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-10",
              "start_date": "2024-07-10",
              "userid": "jack.anderson@example.com",
              "fullname": "Jack Anderson",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "1",
              "end_date": "2024-07-06",
              "start_date": "2024-07-06",
              "userid": "emma.smith@example.com",
              "fullname": "Emma Smith",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "2",
              "end_date": "2024-07-07",
              "start_date": "2024-07-07",
              "userid": "john.doe@example.com",
              "fullname": "John Doe",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "3",
              "end_date": "2024-07-08",
              "start_date": "2024-07-08",
              "userid": "olivia.adams@example.com",
              "fullname": "Olivia Adams",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "4",
              "end_date": "2024-07-09",
              "start_date": "2024-07-09",
              "userid": "william.jones@example.com",
              "fullname": "William Jones",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "5",
              "end_date": "2024-07-10",
              "start_date": "2024-07-10",
              "userid": "olivia.wilson@example.com",
              "fullname": "Olivia Wilson",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-11",
              "start_date": "2024-07-11",
              "userid": "jack.anderson@example.com",
              "fullname": "Jack Anderson",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "7",
              "end_date": "2024-07-12",
              "start_date": "2024-07-12",
              "userid": "olivia.lewis@example.com",
              "fullname": "Olivia Lewis",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "8",
              "end_date": "2024-07-13",
              "start_date": "2024-07-13",
              "userid": "daniel.thomas@example.com",
              "fullname": "Daniel Thomas",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "9",
              "end_date": "2024-07-14",
              "start_date": "2024-07-14",
              "userid": "olivia.harris@example.com",
              "fullname": "Olivia Harris",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "10",
              "end_date": "2024-07-15",
              "start_date": "2024-07-15",
              "userid": "william.clark@example.com",
              "fullname": "William Clark",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "11",
              "end_date": "2024-07-16",
              "start_date": "2024-07-16",
              "userid": "olivia.allen@example.com",
              "fullname": "Olivia Allen",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "12",
              "end_date": "2024-07-17",
              "start_date": "2024-07-17",
              "userid": "jacob.davis@example.com",
              "fullname": "Jacob Davis",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "13",
              "end_date": "2024-07-18",
              "start_date": "2024-07-18",
              "userid": "olivia.thompson@example.com",
              "fullname": "Olivia Thompson",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "14",
              "end_date": "2024-07-19",
              "start_date": "2024-07-19",
              "userid": "william.rodriguez@example.com",
              "fullname": "William Rodriguez",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "15",
              "end_date": "2024-07-20",
              "start_date": "2024-07-20",
              "userid": "olivia.gonzalez@example.com",
              "fullname": "Olivia Gonzalez",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "16",
              "end_date": "2024-07-21",
              "start_date": "2024-07-21",
              "userid": "noah.hernandez@example.com",
              "fullname": "Noah Hernandez",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "17",
              "end_date": "2024-07-22",
              "start_date": "2024-07-22",
              "userid": "olivia.morris@example.com",
              "fullname": "Olivia Morris",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "18",
              "end_date": "2024-07-23",
              "start_date": "2024-07-23",
              "userid": "james.young@example.com",
              "fullname": "James Young",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "19",
              "end_date": "2024-07-24",
              "start_date": "2024-07-24",
              "userid": "olivia.nguyen@example.com",
              "fullname": "Olivia Nguyen",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "20",
              "end_date": "2024-07-25",
              "start_date": "2024-07-25",
              "userid": "william.lee@example.com",
              "fullname": "William Lee",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "19",
              "end_date": "2024-07-06",
              "start_date": "2024-07-06",
              "userid": "sophia.johnson@example.com",
              "fullname": "Sophia Johnson",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-07",
              "start_date": "2024-07-07",
              "userid": "daniel.brown@example.com",
              "fullname": "Daniel Brown",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "19",
              "end_date": "2024-07-08",
              "start_date": "2024-07-08",
              "userid": "olivia.davis@example.com",
              "fullname": "Olivia Davis",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-09",
              "start_date": "2024-07-09",
              "userid": "ethan.johnson@example.com",
              "fullname": "Ethan Johnson",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-10",
              "start_date": "2024-07-10",
              "userid": "olivia.thomas@example.com",
              "fullname": "Olivia Thomas",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "11",
              "end_date": "2024-07-06",
              "start_date": "2024-07-06",
              "userid": "william.rodriguez@example.com",
              "fullname": "William Rodriguez",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "12",
              "end_date": "2024-07-07",
              "start_date": "2024-07-07",
              "userid": "olivia.martinez@example.com",
              "fullname": "Olivia Martinez",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-08",
              "start_date": "2024-07-08",
              "userid": "jacob.garcia@example.com",
              "fullname": "Jacob Garcia",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "16",
              "end_date": "2024-07-09",
              "start_date": "2024-07-09",
              "userid": "olivia.adams@example.com",
              "fullname": "Olivia Adams",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "6",
              "end_date": "2024-07-10",
              "start_date": "2024-07-10",
              "userid": "william.smith@example.com",
              "fullname": "William Smith",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "1",
              "department_id": "10",
              "end_date": "2024-07-05",
              "start_date": "2024-07-05",
              "userid": "demo@insyts.co",
              "fullname": "Demo User",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "10",
              "end_date": "2024-07-06",
              "start_date": "2024-07-06",
              "userid": "jennifer@example.com",
              "fullname": "Jennifer",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "2",
              "department_id": "15",
              "end_date": "2024-07-08",
              "start_date": "2024-07-07",
              "userid": "robert@example.com",
              "fullname": "Robert",
              "leave_category_id": "3"
          },
          {
              "number_of_days": "3",
              "department_id": "20",
              "end_date": "2024-07-11",
              "start_date": "2024-07-09",
              "userid": "alice@example.com",
              "fullname": "Alice",
              "leave_category_id": "4"
          },
          {
              "number_of_days": "1",
              "department_id": "12",
              "end_date": "2024-07-12",
              "start_date": "2024-07-12",
              "userid": "david@example.com",
              "fullname": "David",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "2",
              "department_id": "18",
              "end_date": "2024-07-14",
              "start_date": "2024-07-13",
              "userid": "emily@example.com",
              "fullname": "Emily",
              "leave_category_id": "1"
          },
          {
              "number_of_days": "3",
              "department_id": "22",
              "end_date": "2024-07-17",
              "start_date": "2024-07-15",
              "userid": "sam@example.com",
              "fullname": "Sam",
              "leave_category_id": "2"
          },
          {
              "number_of_days": "1",
              "department_id": "25",
              "end_date": "2024-07-18",
              "start_date": "2024-07-18",
              "userid": "lisa@example.com",
              "fullname": "Lisa",
              "leave_category_id": "3"
          },
          {
              "number_of_days": "2",
              "department_id": "30",
              "end_date": "2024-07-20",
              "start_date": "2024-07-19",
              "userid": "michael@example.com",
              "fullname": "Michael",
              "leave_category_id": "4"
          },
          {
              "number_of_days": "3",
              "department_id": "12",
              "end_date": "2024-07-23",
              "start_date": "2024-07-21",
              "userid": "sophia@example.com",
              "fullname": "Sophia",
              "leave_category_id": "1"
          }
      ]
      
    }
]
  const [
    displayDepartmentAttendanceReportModal,
    toggleDepartmentAttendanceReportModal,
  ] = useState(false);
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([]);
  const [reportColor, setReportColor] = useState("");
  const [attendanceReportTitle, setAttendanceReportTitle] = useState("");
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [lateListData, setLateListData] = useState([]);
  const [onTimeList, setOnTimeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [reportData, setReportData] = useState([
    {
      name: "onTime",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Absent",
      type: "Absent",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Post cut Off',
    //   type: 'Post cut Off',
    //   numbers: 0,
    //   list: [],
    // },
    {
      name: "Late",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Leave",
      type: "leave",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Others',
    //   type: 'Present',
    //   numbers: 0,
    //   list: [],
    // }
  ]);
  const config = {
    isStack: true,
    dodgePadding: 0.2,
    data: reportData,
    // height: 400,
    xField: "numbers",
    yField: "type",
    seriesField: "name",
    intervalPadding: 10,
    legend: false,
    color: ({ name }) => {
      if (name === "Absent") {
        return "orange";
      } else if (name === "onTime") {
        return "#0565f5";
      } else if (name === "Late") {
        return "#5d9af5";
      } else if (name === "Leave") {
        return "#bd34eb";
      } else {
        return "#f5e0ff";
      }
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    // legend:{
    //   layout: 'vertical',
    //   position: 'right'
    // },
    label: {
      position: "middle",
      content: "",
    },
    columnStyle: {
      // stroke: COLORS.BLACK,
      // lineWidth: 1,
      cursor: "pointer",
    },
    xAxis: {
      max: isNaN(max(map(reportData, "numbers")))
        ? 100
        : max(map(reportData, "numbers")) + 15,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };


  const annualLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "annualleave"
  );
  const sickLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "sickleave"
  );

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getReportData();
    }
  }, [selectedDepartmentId]);

  console.log('department-->',allDepartmentList)

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { todayAttendenceDetails } = await CALL_API(
        `attendance-service/${ClientId}`,
        "post",
        {
          departmentId:
            userRoleId != 1 && userRoleId != 6 ? selectedDepartmentId : [""],
        }
      );
      let absentList = [];
      let ontimeList = [];
      let lateList = [];
      let leaveList = [];
      dummydata.forEach((value) => {
        const userLeaveList = value?.leaveCount?.map((item) => {
          const department = allDepartmentList?.find((dept) => {
            return dept?.department_id == item?.department_id;
          });
          const leaveCategory = leaveCategoryList?.find((leave) => {
            return leave.leave_category_id === item?.leave_category_id;
          });
          const username = userList?.find((user) => {
            return user.userid == item?.userid;
          });
          return {
            ...item,
            departmentName: department?.department_name,
            leave_category_name: leaveCategory?.leave_category_name,
            name: username?.fullname,
          };
        });
        absentList = concat(absentList, value.absent);
        ontimeList = concat(ontimeList, value.onTime);
        lateList = concat(lateList, value.late);
        leaveList = concat(leaveList, userLeaveList);
      });

      let dupeReportData = [...reportData];
      // let otherList = []
      // let newList = []
      // let lateListFilter = []
      // // let otherList1 =filter(leaves, (e) => e.locationName === 'Other' )
      // // console.log("list other", otherList1)
      // ontimeList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     newList.push(list)
      //   }
      // })
      // lateList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     lateListFilter.push(list)
      //   }
      // })

      // console.log("other",otherList)
      // onTime.map()
      dupeReportData[0].numbers = size(ontimeList);
      dupeReportData[1].numbers = size(absentList);
      dupeReportData[2].numbers = size(lateList);
      dupeReportData[3].numbers = size(leaveList);

      // dupeReportData[3].numbers = size(leave)
      dupeReportData[0].list = ontimeList;
      dupeReportData[1].list = absentList;
      dupeReportData[2].list = lateList;
      dupeReportData[3].list = leaveList
      // const leaveList = leaves
      // let sickCount = 0;
      // let sickLeaveList = [];
      // let annualCount = 0;
      // let annualLeaveList = [];
      // let otherCount = 0;
      // let otherLeaveList = [];
      // leaveList.map((list) => {
      //   if (list.leave_category_id === sickLeaveCategoryId?.leave_category_id) {
      //     sickCount++;
      //     sickLeaveList.push(list);
      //   } else if (
      //     list.leave_category_id === annualLeaveCategoryId?.leave_category_id
      //   ) {
      //     annualCount++;
      //     annualLeaveList.push(list);
      //   } else {
      //     otherCount++;
      //     otherLeaveList.push(list);
      //   }
      // });
      // dupeReportData[5].numbers = otherCount;
      // dupeReportData[5].list = otherLeaveList;
      // dupeReportData[4].numbers = sickCount;
      // dupeReportData[4].list = sickLeaveList;
      // dupeReportData[3].numbers = annualCount;
      // dupeReportData[3].list = annualLeaveList;
      setReportData(dupeReportData);
      toggleReportLoader(false);
      // dupeReportData[6].numbers = size(otherList)
      // dupeReportData[6].list = otherList
      // const groupedData = data.reduce((result, item) => {
      //   (result[item.name] = result[item.name] || []).push(item);
      //   return result;
      // }, {});

      // const groupByAbsentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.absent?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByPresentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.late?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByOnTimeDeptId = groupedData?.undefined[0][selectedDepartmentId]?.onTime?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByLateDeptId = groupedData?.undefined[0][selectedDepartmentId]?.leaves?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByAbsentDeptId = absentList.map((e) => e.departmentId == hrSelectedDept)
      // console.log("---->", groupByAbsentDeptId)

      setAbsentList(absentList);
      setLateListData(lateList);
      setOnTimeList(ontimeList);
      setLeaveList(leaveList);
    }
  }; 

  useEffect(()=>{
    getReportData();
  },[isCheckedIn]);

  return (
    <>
      <StyledTitle>
        ATTENDANCE
      </StyledTitle>
      <StyledChartContainer>
        <div className="name-title">Today's attendance view:</div>
        <Spin spinning={displayReportLoader}>
          <Bar
            {...config}
            onReady={(plot) => {
              plot.on("plot:click", (evt) => {
                if (evt.data && evt.data.data !== null) {
                  const {
                    data: {
                      data: { type, list, name },
                      color,
                    },
                  } = evt;
                  setReportList(list);
                  setReportType(type);
                  setReportName(name);
                  if (userRoleId !== "1") {
                    // setReportColor(color);   
                    if (type === "Absent" || !isHrDashboard) {
                      setAttendanceReportTitle(`${type} staff`);
                      toggleDisplayAttendanceStaffModal(true);  
                    } else {
                      setAttendanceReportTitle(name === "Leave" ? `${type}` : `${type}- ${name}`);
                      toggleDepartmentAttendanceReportModal(true);
                    }  
                  } 
                  // else if (includes(["2", "3"], userRoleId)) {
                  //   if (type === "Absent") {
                  //     setAttendanceReportTitle(`${type} staff`);
                  //   } else {
                  //     setAttendanceReportTitle(`${type}- ${name}`);
                  //   }
                  //   toggleDisplayAttendanceStaffModal(true);
                  // }
                }
              });
            }}
          />
        </Spin>
      </StyledChartContainer>
      <DepartmentAttendanceReport
        displayModal={displayDepartmentAttendanceReportModal}
        toggleModal={() => toggleDepartmentAttendanceReportModal(false)}
        reportType={reportType}
        reportColor={reportColor}
        list={reportList}
        name={reportName}
        lateListData={lateListData}
        onTimeList={onTimeList}
        leaveList={leaveList}
        absentList={absentList}
      />
      <AttendanceStaffList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={attendanceReportTitle}
        reportType={reportType}
        list={reportList}
        name={reportName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  isCheckedIn: getIsCheckedIn(state),
});

export default connect(mapStateToProps, null)(AttendanceBarChart);
