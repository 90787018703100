import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Modal, Spin, Select } from 'antd';
import styled from '@emotion/styled';
import LeaveDonutChart from './LeaveDonutChart';
import { LEAVE_ICON, LEAVE_INFO } from 'common/Constants';
import { CSVLink } from "react-csv";
import COLORS from 'common/Colors';
import Button from 'atoms/Button';
// import COLORS from 'common/Colors';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getLeaveCategoryList,
  getUserList,
  getAllDepartmentList,
  getClientid
} from 'redux/selectors';
import { find, get, map, reduce, includes, concat,uniqBy  } from 'lodash';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import { ButtonOutlined } from 'reusableComponent/UIButtons';

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const StyledButton3 = styled(Button)`
width: 120px;
border: 1px solid ${COLORS.PRIMARY};
color:  ${COLORS.PRIMARY};
width: initial;
background:transparent;
float: right;
border-radius: 5px;
`;

const StyledDepartmentTitle = styled.div`
  padding-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function DepartmentStaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  clientId
}) {
  const dummydata = [
    {
        "18": {
            "available": [
                {
                    "user_id": "sivasmk53@gmail.com",
                    "total_leaves": 27
                }
            ],
            "consumed": [
                {
                    "user_id": "sivasmk53@gmail.com",
                    "total_leaves": 18
                }
            ]
        }
    }
]
  const [ title, setTitle ] = useState('');
  const [ userLeaves, setUserLeaves ] = useState([]);
  const [ departmentUsers, setDepartmentUsers ] = useState([]);
  const [ displayLeaveReportLoader, toggleLeaveReportLoader ] = useState(false);
  const leaveDataArray = [
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: get(COLORS, `LEAVE.${leaveTypeId}.AVAILABLE`, ''), lastYearLeaveBalance : 0 },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: get(COLORS, `LEAVE.${leaveTypeId}.CONSUMED`, '') },
  ];
  const [ filteredUsers, setFilteredUsers ] = useState([]);
  const [ APIResponse, setAPIResponse ] = useState([]);
  const [ CSVDepartmentLeave, setCSVDepartmentLeave ] = useState([]);
  const csvLinkDepartment = useRef();

useEffect(() => {
  if (displayModal) {
      const leaveName = get(find(leaveCategoryList, { leave_category_id: leaveTypeId }), 'leave_category_name', '');

      const departmentNameList = selectedDepartmentId.map(id => 
          get(find(allDepartmentList, { department_id: id }), 'department_name', '')
      );

      setTitle(`${departmentNameList.join(', ')} department's ${leaveName} Balance`);

      const users = [];
      const tmpDepartmentUsers = [];

      userList.forEach(({ userid, fullname, userdepartmentid }) => {
          const flatDepartmentList = Array.from(new Set(Object.values(userdepartmentid).flat()));

          if (selectedDepartmentId.some(id => flatDepartmentList.includes(id))) {
              users.push({
                  userid,
                  fullname,
                  leaveData: leaveDataArray
              });
              tmpDepartmentUsers.push({
                  userid,
                  fullname
              });
          }
      });

      setUserLeaves(uniqBy(users, 'userid'));
      setDepartmentUsers(uniqBy(tmpDepartmentUsers, 'userid'));
      getReportData();
      setAPIResponse({
          availableList: [],
          consumedList: []
      });
  }
}, [displayModal]);

  useEffect(() => {
    const data = reduce(departmentUsers, (result, user) => {
      if (filteredUsers.length === 0 || includes(filteredUsers, user.userid)) {
        let dupeleaveDataArray = JSON.parse(JSON.stringify(leaveDataArray));
        const total_leaves = get(find(APIResponse.availableList, { 'user_id': user.userid }), 'total_leaves', Math.floor(Math.random() * 10) + 20);
        const lastYearLeave = get(find(APIResponse.availableList, { 'user_id': user.userid }), 'previousYearLeaveBalance', 0);
        const leaves_consumed = get(find(APIResponse.consumedList, { 'user_id': user.userid }), 'leaves_consumed', Math.floor(Math.random() * 10));
        dupeleaveDataArray[0].value = total_leaves - leaves_consumed;
        dupeleaveDataArray[0].lastYearLeaveBalance = lastYearLeave;
        dupeleaveDataArray[1].value = leaves_consumed;
        result.push({
          user_id: user.userid,
          name: user.fullname,
          leaveData: dupeleaveDataArray
        });
      }
      return result;
    }, []);
    setUserLeaves(data);
  }, [ filteredUsers, APIResponse ]);
  
  const getReportData = async () => {
    toggleLeaveReportLoader(true);
    if(clientId){
    const data = await CALL_API(`leave-service/${clientId}`, 'post', {
      method: 'userWiseOverallTotalLeavesAvailableAndConsumed', 
      parameters: {
        leave_type: leaveTypeId.toString(),
        year: dateInDetail(getKenyanDateTime()).year,
        department_id: selectedDepartmentId
      },
    });
    let availableList = []
    let consumedList = []

    dummydata.map(list => {
      Object.entries(list).forEach(([key, value]) => {
        availableList = concat(availableList, value.available) 
        consumedList = concat(consumedList, value.consumed) 
      })
    })
  
    setAPIResponse({
      availableList,
      consumedList
    });
    // const data = reduce(userList, (result, user) => {
    //   if (user.department_id === selectedDepartmentId) {
    //     let dupeleaveDataArray = JSON.parse(JSON.stringify(leaveDataArray));
    //     const total_leaves = get(find(available, { 'user_id': user.user_id }), 'total_leaves', 0);
    //     const leaves_consumed = get(find(consumed, { 'user_id': user.user_id }), 'leaves_consumed', 0);
    //     dupeleaveDataArray[0].value = total_leaves - leaves_consumed;
    //     dupeleaveDataArray[1].value = leaves_consumed;
    //     result.push({
    //       user_id: user.user_id,
    //       name: user.name,
    //       leaveData: dupeleaveDataArray
    //     });
    //   }
    //   return result;
    // }, []);
    // setUserLeaves(data);
    toggleLeaveReportLoader(false);
  }
  }

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },   
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];
  const prepareCSV = async (event, done) => {
    if (userLeaves.length > 0) {
      setCSVDepartmentLeave(
        reduce(userLeaves, function(result, leave) {
          result.push({
            // "Employee ID": leave.user_id,
            "Name": leave.name,
            "Available Leaves": leave.leaveData[0].value,           
            "Consumed Leaves": leave.leaveData[1].value,
            "Total Leaves":leave.leaveData[0].value + leave.leaveData[1].value,
            // "Last Year Leave Balance": leave.leaveData[0].lastYearLeaveBalance
          });
          return result;
        }, [])
      );
    
      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
      console.log('No data available to download')
      // message.error(`No data available to download.`);
    }
  }

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={24}>
              <StyledSelect
                mode="multiple"
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder='Search Staff'
                name='user'
                optionFilterProp='fullname'
                filterOption={(input, option) => option.fullname.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => {
                  optionA.fullname.toLowerCase().localeCompare(optionB.fullname.toLowerCase())
                }}
                options={departmentUsers}
                fieldNames={{
                  label: 'fullname',
                  value: 'userid'
                }}
                onChange={newValue => setFilteredUsers(newValue)}
              />
            </Col>
          </Row>
          <Row style={{justifyContent:'flex-end'}}>
          <ButtonOutlined  onClick={prepareCSV}  disabled={userLeaves.length === 0}>
            Export CSV file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVDepartmentLeave}
            filename={"Department Leave List.csv"}
            asyncOnClick={true}
            ref={csvLinkDepartment}
          />
          </Row>         
          <Row gutter={16}>
            {
              map(userLeaves, ({ name, leaveData }, index) => (
                <StyledCol md={12} sm={24} xs={24} key={index}>
                  <StyledDepartmentTitle>
                    <b>{name}</b>
                  </StyledDepartmentTitle>
                  <LeaveDonutChart iconName={LEAVE_ICON[leaveTypeId]} title={leaveName} data={leaveData} staffLeave={leaveName}/>
                </StyledCol>
              ))
            }
          </Row>
        </Spin>
      </StyledModal>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  clientId: getClientid(state),
});

export default connect(mapStateToProps, null)(DepartmentStaffLeaveBalance);
