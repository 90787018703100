import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API, CALL_FEATCH_API } from "common/API";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  STATUS_CODE,
  countryList,
  CompetencyQC,
  ServiceLine,
  SubServiceLine,
  CompetencyRole,
  IndustryRole,
  Industry,
  Grade,
  Greetings,
  Degree,
  ProfessionalCertification,
  CountryCompany,
  Sector,
} from "common/Constants";
import {
  getAllDepartmentList,
  getLocations,
  getClientid,
  getLeaveCategoryList,
  getRoleList,
  getBandList,
  getUserList,
  getUserRoleId,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { setUserList, setRoleList } from "redux/actions";
import { useDropzone } from "react-dropzone";
import { chain, concat, find, flatMap, get, includes, isEmpty } from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SelectTable from "../molecules/EditEmployeeRole";
import FormModal from "./FormModal";
import TabPane from "antd/lib/tabs/TabPane";
import COLORS from "common/Colors";
import PhoneInput from "react-phone-number-input";

const StyledModal = styled(Form)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .check-box {
    font-size: 14px;
    font-weight: 500;
  }
  .ant-tabs-nav {
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

function EditEmployee({
  closeModal,
  getEmployeeList,
  EmployeeDetails,
  ClientId,
  updateUserList,
  roleList,
  bandList,
  userList,
  saveRoleList,
  locationList,
  userRoleId,
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const { Option } = Select;
  const containerRef = useRef(null);
  const [filename, setFileName] = useState("");
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [formValues, setFormValues] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const [employeeStatusId, setEmployeeStatusId] = useState("1");

  const employeesList = userList?.map((user) => user.userid);

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;

  const filterbandlist = bandList.filter((item) =>
    item?.departmentId.some((id) => filterdepartmentid?.includes(id))
  );

  // Note: first params is needed
  const validateEmployeeId = (rule, value) => {
    if (EmployeeDetails?.userid === value) {
      return Promise.resolve();
    } else if (value && employeesList.includes(value)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setSelectAllRoleChecked(selectedRoleId.length === roleList.length);
  }, [selectedRoleId, selectAllRoleChecked, roleList]);

  useEffect(() => {
    setEmployeeStatusId(
      EmployeeDetails?.statusid !== "" ? EmployeeDetails?.statusid : ""
    );
  }, []);

  const [activeTab, setActiveTab] = useState("0");

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        updateUserList(userList);
        saveRoleList(roleList);
      }
    }
  };

  useEffect(() => {
    const {
      userid = "",
      startdate = "",
      dateofbirth = "",
      enddate = "",
      bandname = "",
      bandid = "",
      overtimestatus = "",
      salary = "",
      password = "",
      profileurl = "",
      gender = "",
      maritalstatus = "",
      nationality = "",
      facerecognition,
      roleid = "",
      nssf = "",
      national_id = "",
      address = "",
      phoneno = "",
      institutename = "",
      degree = "",
      specialization = "",
      yearofcompletion = "",
      companyname = "",
      jobtitle = "",
      professionalsince = "",
      hireddate = "",
      relevant = "",
      employeesponser = "",
      statusname = "",
      jobdescription = "",
      emergencycontactname = "",
      emergencycontactnumber = "",
      employementtype = "",
      employeeid = "",
      initial,
      firstname,
      lastname,
      salutation,
      competencyQC,
      serviceline,
      subserviceline,
      competencyrole,
      industry,
      sector,
      industryrole,
      expertise,
      designation,
      immediatesupervisor,
      grade,
      targetchargeability,
      chargeoutrate,
      greetings,
      professionalcertification,
      lastpromotionlevel,
      company_Name,
      dependent,
      managingoffice,
      organisationlevel,
      manager,
      supervisor,
    } = EmployeeDetails;
    setFormValues({
      user_id: userid,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      password: password,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      employementtype: employementtype,
      facerecognition: facerecognition,
      profile_url: profileurl !== ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      phone: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion
        ? moment(yearofcompletion, "YYYY-MM-DD")
        : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      company_Name: company_Name,
      status_id: employeeStatusId,
      description: jobdescription,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      industry: industry,
      sector: sector,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      immediatesupervisor: immediatesupervisor,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      professionalcertification: professionalcertification,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      managingoffice: managingoffice,
      organisationlevel: organisationlevel,
      manager: manager,
      supervisor: supervisor,
    });
    form.setFieldsValue({
      user_id: userid,
      password: password,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      facerecognition: facerecognition,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      employementtype: employementtype,
      phone: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion
        ? moment(yearofcompletion, "YYYY-MM-DD")
        : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      description: jobdescription,
      emergencycontactnumber: emergencycontactnumber,
      emergencycontactname: emergencycontactname,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      company_Name: company_Name,
      industry: industry,
      sector: sector,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      immediatesupervisor: immediatesupervisor,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      professionalcertification: professionalcertification,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      managingoffice: managingoffice,
      organisationlevel: organisationlevel,
      manager: manager,
      supervisor: supervisor,
    });
  }, [EmployeeDetails]);

  useEffect(() => {
    setSelectedRoleId(EmployeeDetails.roleid);
    setBaseLocation(
      EmployeeDetails?.baselocationid ? EmployeeDetails?.baselocationid : ""
    );
  }, [EmployeeDetails]);

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId?.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter((val) =>
    locationId.includes(val?.location_id)
  );

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const onFinish = () => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
  };

  useEffect(() => {
    onFinish();
  }, [selectedRoleId]);

  useEffect(() => {}, [storeId]);

  const handlesubmit = async () => {
    try {
      toggleLoader(true);
      setUniqueId(generateUniqueId());
      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      let updatedUserId = userIdWithoutDomain + storeId;
      const {
        user_id,
        start_date,
        password,
        gender,
        dateof_birth,
        nationality,
        maritalstatus,
        bandid,
        overtime,
        salary,
        facerecognition,
        nssf,
        national_id,
        address,
        phone,
        selectedRoleId,
        institutename,
        degree,
        specialization,
        company_Name,
        yearofcompletion,
        companyname,
        jobtitle,
        professionalsince,
        hireddate,
        relevent,
        employeesponser,
        employeestatus,
        description,
        emergencycontactname,
        emergencycontactnumber,
        employementtype,
        employeeid,
        initial,
        firstname,
        lastname,
        salutation,
        competencyQC,
        serviceline,
        subserviceline,
        competencyrole,
        industry,
        sector,
        industryrole,
        expertise,
        designation,
        immediatesupervisor,
        grade,
        targetchargeability,
        chargeoutrate,
        greetings,
        professionalcertification,
        lastpromotionlevel,
        dependent,
        managingoffice,
        organisationlevel,
        manager,
        supervisor,
      } = formValues;

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      const dobDate = new Date(dateof_birth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const bandNameList = bandList?.filter((item) => item?.bandId === bandid);

      const response = await CALL_API(`add-users/${ClientId}`, "patch", {
        user_id: user_id?.toLowerCase(),
        full_name: `${firstname} ${lastname}`,
        end_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MM-DD")
          : "",
        start_date: start_date
          ? moment(start_date?._d).format("YYYY-MM-DD")
          : "",
        overtime_status: overtime,
        bandname: bandNameList?.length !== 0 ? bandNameList[0]?.bandName : "",
        bandid: bandid,
        gender: gender,
        dateofbirth: dateof_birth
          ? moment(dateof_birth?._d).format("YYYY-MM-DD")
          : "",
        age: agecalculte,
        password: password,
        maritalstatus: maritalstatus,
        nationality: nationality,
        salary: salary,
        facerecognition: facerecognition,
        role_id: selectedRoleId,
        role_name: roleNames ? roleNames : "",
        nssf: nssf,
        national_id: national_id,
        phoneno: phone,
        address: address,
        permanentaddress: address,
        presentaddress: address,
        employementtype: employementtype,
        profile_url: profileUploaded
          ? `https://insyts-bdo-profiles-live.s3.ap-south-1.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
          : EmployeeDetails?.profileurl,
        institutename: institutename,
        degree: degree,
        specialization: specialization,
        company_Name: company_Name,
        yearofcompletion: yearofcompletion
          ? moment(yearofcompletion?._d).format("YYYY-MM-DD")
          : "",
        companyname: companyname,
        jobtitle: jobtitle,
        professionalsince: professionalsince
          ? moment(professionalsince?._d).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? moment(hireddate?._d).format("YYYY-MM-DD") : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        status_id: employeeStatusId,
        jobdescription: description,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baseLocation,
        employeeid: employeeid,
        initial: initial,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        competencyQC: competencyQC,
        serviceline: serviceline,
        subserviceline: subserviceline,
        competencyrole: competencyrole,
        industry: industry,
        sector: sector,
        industryrole: industryrole,
        expertise: expertise,
        designation: designation,
        immediatesupervisor: immediatesupervisor,
        grade: grade,
        targetchargeability: targetchargeability,
        chargeoutrate: chargeoutrate,
        greetings: greetings,
        professionalcertification: professionalcertification,
        lastpromotionlevel: lastpromotionlevel,
        dependent: dependent,
        managingoffice: managingoffice,
        organisationlevel: organisationlevel,
        manager: manager,
        supervisor: supervisor,
        educationdetails: [],
        workexperience: [],
        professionalcertifications: [],
        practicaltrainings: [],
      });

      toggleLoader(false);
      setFileName("");
      form.resetFields();
      closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
        }
      }

      await getEmployeeList();
      await fetchMasterData(true);
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (form.getFieldValue("user_id")) {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(acceptedFiles[0].path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            let userId = form.getFieldValue("user_id");
            let splitUserId = userId.split("@");
            let userIdWithoutDomain = splitUserId[0];
            let updatedUserId = userIdWithoutDomain + uniqueId;
            await CALL_API(`profile-upload/${ClientId}`, "post", {
              userid: updatedUserId,
              filetype: get(file, "type", ""),
              content: e.target.result,
              filename: uniqueId,
              emailid: "",
            });
            setStoreId(uniqueId);
          } else {
            setProfileUploaded(false);
            message.error("Please upload valid image file.");
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
  }, [acceptedFiles]);

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => {
              getRoleId(roleId, checked);
            }}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => {
              setBaseLocation(checked ? location_id : "");
              onFinish();
            }}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    onFinish();
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const handleResetAllRole = (e) => {
    setSelectedRoleId([]);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const tabTitles = ["Basic", "Role", "Location", "Education", "Work"];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const selectedCompetency = form.getFieldValue("competencyQC");
  const filterserviceline =
    ServiceLine.find((item) => item.competency === selectedCompetency)
      ?.serviceline || [];



  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [organisationLevelData, setOrganisationLevelData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [competencyroleData, setcompetencyroleData] = useState([]);

  const fetchData = async () => {
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level1`, "get");
    if (!isEmpty(response)) {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setManagingOfficeData(result);
    }
  };

  const organisationLevelfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item)=>form.getFieldValue('managingoffice').includes(item.value));
    const mapdata = filtervalue.map((item)=>item?.name);
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`, "get");
    if (!isEmpty(response))  {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setOrganisationLevelData(result);
    }
  };

  

  const competencyQCfetchData = async () => {
    const filtervalue = organisationLevelData.filter((item)=>form.getFieldValue('organisationlevel').includes(item.value));
    const mapdata = filtervalue.map((item)=>item?.name);
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`, "get");
    if (!isEmpty(response))  {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setCompetencyQCfetchData(result);
    }
  };

  const servicelinefetchData = async () => {
    const filtervalue = competencyQCData.filter((item)=>form.getFieldValue('competencyQC')?.includes(item.value));
    const mapdata = filtervalue.map((item)=>item?.name);
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`, "get");
    if (!isEmpty(response))  {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setServicelinefetchData(result);
    }
  };

  const subservicelinefetchData = async () => {
    const filtervalue = servicelineData?.filter((item)=>form.getFieldValue('serviceline')?.includes(item.value));
    const mapdata = filtervalue.map((item)=>item?.name);
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`, "get");
    if (!isEmpty(response))  {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setsubservicelineData(result);
    }
  };
  const competencyrolefetchData = async () => {
    const filtervalue = subservicelineData.filter((item)=>form.getFieldValue('subserviceline')?.includes(item.value));
    const mapdata = filtervalue.map((item)=>item?.name);
    const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`, "get");
    if (!isEmpty(response))  {
      const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
      setcompetencyroleData(result);
    }
  };

  useEffect(() => {
        fetchData();
}, []);

useEffect(() => {
  if(organisationLevelData.length == 0){
    organisationLevelfetchData();
  }
}, [managingOfficeData]);

useEffect(() => {
  if(competencyQCData.length == 0){
    competencyQCfetchData();
  }
}, [organisationLevelData]);

useEffect(() => {
  if(servicelineData.length == 0){
    servicelinefetchData();
  }
}, [competencyQCData]);

useEffect(() => {
  if(subservicelineData.length == 0){
    subservicelinefetchData();
  }
}, [servicelineData]);

useEffect(() => {
  if(competencyroleData.length == 0){
    competencyrolefetchData();
  }
}, [subservicelineData]);





  return (
    <>
      <StyledModal>
        <label
          className="bg-transparent border-0 mb-3 text-left styled-cursor"
          onClick={() => closeModal(false)}
        >
          Employee <ArrowForwardIosIcon className="px-1" />
        </label>{" "}
        <b className=" pb-0 pt-1">Edit Employee</b>
        <Tabs
          defaultActiveKey={activeTab}
          className="mt-3"
          onChange={handleTabChange}
        >
          {tabTitles.map((title, index) => (
            <TabPane tab={title} key={index.toString()}>
              {activeTab === "0" && (
                <>
                  <div className="styled-card mb-5">
                    {step === 0 && (
                      <StyledModal>
                        <Form
                          className=""
                          form={form}
                          name="addemployeeform"
                          layout="vertical"
                          initialValues={{
                            user_id: "",
                            department_id: "",
                            password: "",
                            gender: "",
                            dateof_birth: "",
                            maritalstatus: "",
                            nationality: "",
                            nssf: "",
                            national_id: "",
                            address: "",
                            phone: "",
                            overtime: "",
                            start_date: "",
                            halfpay: false,
                            facerecognition: false,
                            emergencycontactname: "",
                            emergencycontactnumber: "",
                            employementtype: "",
                            bandid: "",
                            initial: "",
                            first_name: "",
                            last_name: "",
                            salutation: "",
                            competencyQC: [],
                            serviceline: [],
                            subserviceline: [],
                            competencyrole: [],
                            managingoffice: [],
                            organisationlevel: [],
                            greetings: "",
                            dependent: "",
                          }}
                          onFinish={() => {
                            handlesubmit();
                          }}
                        >
                          <Row gutter={20}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="salutation"
                                label="Salutation"
                                placeholder="Select Salutation"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Salutation is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Salutation"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="Mr.">Mr.</Option>
                                  <Option value="Dr.">Dr.</Option>
                                  <Option value="Prof.">Prof.</Option>
                                  <Option value="Ms.">Ms.</Option>
                                  <Option value="Mrs.">Mrs.</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="initial"
                                label="Initial"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Initial is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Initial"
                                  maxLength={5}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="firstname"
                                label="First Name"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "First name is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="First name"
                                  maxLength={50}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="lastname"
                                label="Last Name"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Last name is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Last name"
                                  maxLength={50}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="employeeid"
                                label="Employee ID"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Employee ID is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Employee ID"
                                  maxLength={15}
                                  onChange={onFinish}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z0-9\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="user_id"
                                label="User ID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Email ID is required",
                                  },
                                  { validator: validateEmployeeId },
                                ]}
                              >
                                <Input
                                  placeholder="Email ID"
                                  disabled={true}
                                  onChange={onFinish}
                                  maxLength={60}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="phone" label="Phone No">
                                <PhoneInput
                                  type="text"
                                  placeholder="Phone No"
                                  maxLength={20}
                                  onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9+]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="gender"
                                label="Gender"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Gender is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select gender"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="male">Male</Option>
                                  <Option value="female">Female</Option>
                                  <Option value="They/ Them">They/ Them</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="dependent"
                                label="No. of Dependents"
                              >
                                <Input
                                  placeholder="No. of Dependents"
                                  maxLength={10}
                                  onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9+]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Date of Birth"
                                name="dateof_birth"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Date of Birth is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  onChange={() => {
                                    onFinish();
                                  }}
                                  placeholder="Select Date of Birth"
                                  getPopupContainer={() => containerRef.current}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="managingoffice"
                                label="Managing Office"
                                rules={[
                                  {
                                    required: true,
                                    message: "Managing Office is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  options={managingOfficeData}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                  getPopupContainer={() => containerRef.current}
                                  onChange={() => {
                                    onFinish();
                                    organisationLevelfetchData();
                                    form.setFieldValue("organisationlevel", []);
                                    form.setFieldValue("competencyQC", []);
                                    form.setFieldValue("serviceline", []);
                                    form.setFieldValue("subserviceline", []);
                                    form.setFieldValue("competencyrole", []);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="organisationlevel"
                                label="Organisation Level"
                                rules={[
                                  {
                                    required: true,
                                    message: "Organisation Level is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  getPopupContainer={() => containerRef.current}
                                  disabled={form.getFieldValue('organisationlevel')?.length == 0}
                                  options={organisationLevelData}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                  onChange={() => {
                                    onFinish();
                                    competencyQCfetchData();
                                    form.setFieldValue("competencyQC", []);
                                    form.setFieldValue("serviceline", []);
                                    form.setFieldValue("subserviceline", []);
                                    form.setFieldValue("competencyrole", []);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="competencyQC"
                                label="Competency/ QC"
                                rules={[
                                  {
                                    required: true,
                                    message: "Competency/ QC is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  getPopupContainer={() => containerRef.current}
                                  disabled={form.getFieldValue('competencyQC')?.length == 0}
                                  options={competencyQCData}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                  onChange={() => {
                                    onFinish();
                                    servicelinefetchData();
                                    form.setFieldValue("serviceline", []);
                                    form.setFieldValue("subserviceline", []);
                                    form.setFieldValue("competencyrole", []);
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="serviceline"
                                label="Service Line"
                                rules={[
                                  {
                                    required: false,
                                    message: "Service Line is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  getPopupContainer={() => containerRef.current}
                                  disabled={form.getFieldValue('serviceline')?.length == 0}
                                  options={servicelineData}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                  onChange={() => {
                                    onFinish();
                                    subservicelinefetchData();
                                    form.setFieldValue("subserviceline", []);
                                    form.setFieldValue("competencyrole", []);
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="subserviceline"
                                label="Sub-Service Line"
                                rules={[
                                  {
                                    required: false,
                                    message: "Sub-Service Line is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  getPopupContainer={() => containerRef.current}
                                  disabled={subservicelineData?.length == 0}
                                  options={subservicelineData}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                  onChange={() => {
                                    onFinish();
                                    competencyrolefetchData();
                                    form.setFieldValue("competencyrole", []);
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="competencyrole"
                                label="Competency Role"
                                rules={[
                                  {
                                    required: false,
                                    message: "Competency Role is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  mode="multiple"
                                  getPopupContainer={() => containerRef.current}
                                  disabled={competencyroleData?.length == 0}
                                  options={competencyroleData}
                                  onChange={onFinish}
                                  fieldNames={{
                                    label: "value",
                                    value: "value",
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="supervisor"
                                label="Supervisor"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Supervisor is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  {userList?.map((index) => (
                                    <Select.Option
                                      key={index?.fullname}
                                      value={index?.fullname}
                                      label={index?.fulltname}
                                    >
                                      {index?.fullname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="manager"
                                label="Manager"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Manager is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  getPopupContainer={() => containerRef.current}
                                >
                                  {userList?.map((index) => (
                                    <Select.Option
                                      key={index?.fullname}
                                      value={index?.fullname}
                                      label={index?.fulltname}
                                    >
                                      {index?.fullname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="maritalstatus"
                                label="Marital Status"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="single">Single</Option>
                                  <Option value="married">Married</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="nationality"
                                label="Country"
                                rules={[
                                  {
                                    required: true,
                                    message: "Country is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  onChange={onFinish}
                                  getPopupContainer={() => containerRef.current}
                                >
                                  {countryList?.map((country, index) => (
                                    <Select.Option
                                      key={index}
                                      value={country}
                                      label={country}
                                    >
                                      {country}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="emergencycontactname"
                                label="Emergency Contact Name"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message:
                                      "Emergency Contact Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Emergency Contact Name"
                                  maxLength={15}
                                  onChange={onFinish}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="emergencycontactnumber"
                                label="Emergency Contact Number"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message:
                                      "Emergency Contact Name is required",
                                  },
                                ]}
                              >
                                <PhoneInput
                                  placeholder="Emergency Contact Number"
                                  maxLength={20}
                                  onChange={onFinish}
                                  onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9+]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="nssf" label="NSSF">
                                <Input
                                  placeholder="NSSF"
                                  onChange={onFinish}
                                  maxLength={20}
                                  onKeyDown={(e) => {
                                    const value =
                                      /^[0-9]$/?.test(e.key) ||
                                      /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="national_id" label="National ID">
                                <Input
                                  placeholder="National ID"
                                  onChange={onFinish}
                                  maxLength={20}
                                  onKeyDown={(e) => {
                                    const value =
                                      /^[0-9]$/?.test(e.key) ||
                                      /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="address"
                                label="Address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Address is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Address"
                                  onChange={onFinish}
                                  maxLength={60}
                                  onKeyDown={(e) => {
                                    const value =
                                      /^[0-9,/]$/.test(e.key) ||
                                      /^[a-zA-Z\s,/]+$/.test(e.key);
                                    if (!value && e.key !== "Backspace") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Band Name"
                                name="bandid"
                                rules={[
                                  {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: " This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Band"
                                  name="bandid"
                                  options={concat(
                                    {
                                      bandId: "",
                                      bandName: "Select Band",
                                    },
                                    filterbandlist
                                  )}
                                  fieldNames={{
                                    label: "bandName",
                                    value: "bandId",
                                  }}
                                  onChange={onFinish}
                                  getPopupContainer={() => containerRef.current}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="overtime"
                                label="Overtime Eligible"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Date of Joining"
                                name="start_date"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Start Date is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  disabled={true}
                                  onChange={() => {
                                    onFinish();
                                  }}
                                  value={null}
                                  placeholder="Select start date"
                                  getPopupContainer={() => containerRef.current}
                                />
                              </Form.Item>
                            </Col>

                            {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                    name="country"
                    label="Country/ Company"
                    rules={[
                      { required: true, message: "Please select Country/ Company" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                    >
                      {CountryCompany?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.country}
                          label={value.country}
                        >
                          {value.country}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      { required: true, message: "Please select Location" },
                    ]}
                    disabled={!form.getFieldValue('country')}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                    >
                      {filterlocation?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.location}
                          label={value.location}
                        >
                          {value.location}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="salary" label=" Salary">
                                <Input
                                  placeholder="Salary"
                                  type="text"
                                  maxLength={10}
                                  onKeyDown={(e) => {
                                    const value = /^[0-9]$/?.test(e.key);
                                    if (
                                      !value &&
                                      e.key !== "Backspace" &&
                                      /^[0-9]$/
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="employementtype"
                                label="Employement Type"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="Full time">Full time</Option>
                                  <Option value="Part time">Part time</Option>
                                  <Option value="Contract">Contract</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {/* <Col xs={24} sm={24} md={24} className="mb-2">
                              <Form.Item
                                name="facerecognition"
                                valuePropName="checked"
                              >
                                <Checkbox>
                                  <p className="check-box">Face Recognition</p>
                                </Checkbox>
                              </Form.Item>
                            </Col> */}

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item name="profile" label="Profile Pic">
                                <StyledDropzone {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p>
                                    {filename !== "" ? (
                                      <>{filename}</>
                                    ) : (
                                      <> choose file</>
                                    )}
                                  </p>
                                </StyledDropzone>
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="text-end">
                            <StyledButton
                              loading={showLoader}
                              type="primary"
                              htmlType="submit"
                            >
                              {showLoader ? "Update" : "Update"}
                            </StyledButton>
                          </div>
                        </Form>
                      </StyledModal>
                    )}
                  </div>
                </>
              )}

              {activeTab === "1" && (
                <>
                  <div className="styled-card mb-5">
                    {roleList.length === 0 && (
                      <StyledButton
                        className="mt-2"
                        onClick={() => setOpenModal(true)}
                      >
                        Add Role
                      </StyledButton>
                    )}
                    {roleList?.length > 0 && (
                      <Checkbox
                        className="mt-3"
                        checked={selectAllRoleChecked}
                        onChange={handleSelectAllRole}
                      >
                        Select All
                      </Checkbox>
                    )}
                    <SelectTable
                      step={step}
                      setStep={setStep}
                      columns={rolecolumns}
                      tabledata={roleList}
                      handleClickRole={handlesubmit}
                      handleReset={handleResetAllRole}
                      selectedRoleId={selectedRoleId}
                      showLoader={showLoader}
                    />
                    <FormModal
                      isModalVisible={openModal}
                      closeModal={() => setOpenModal(false)}
                      Name="Role"
                    />
                  </div>
                </>
              )}

              {activeTab === "2" && (
                <>
                  <div className="styled-card mb-5">
                    <SelectTable
                      step={step}
                      setStep={setStep}
                      columns={baseLocationColumn}
                      tabledata={locationBasedOnRole}
                      editRole="edit"
                      handleClickRole={handlesubmit}
                      selectedBaseLocation={baseLocation}
                      showLoader={showLoader}
                    />
                  </div>
                </>
              )}

              {activeTab === "3" && (
                <>
                  <div className="styled-card mb-5">
                    <StyledModal>
                      <Form
                        className=""
                        form={form}
                        name="addEmployeeEducationForm"
                        layout="vertical"
                        initialValues={{
                          institutename: "",
                          degree: [],
                          specialization: "",
                          professionalcertification: [],
                        }}
                        onFinish={() => {
                          handlesubmit();
                        }}
                      >
                        <Row gutter={20}>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              name="institutename"
                              label="Institute Name"
                              rules={[
                                {
                                  required: false,
                                  message: "Institute Name is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Institute Name"
                                maxLength={50}
                                onChange={onFinish}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="degree"
                              label="Educational Degree & Status"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Educational Degree & Status is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {Degree?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="professionalcertification"
                              label="Professional Certification & Status"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Professional Certification & Status is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {ProfessionalCertification?.map(
                                  (value, index) => (
                                    <Select.Option
                                      key={index}
                                      value={value}
                                      label={value}
                                    >
                                      {value}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="specialization"
                              label="Specialization"
                              rules={[
                                {
                                  required: false,
                                  message: "Specialization is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Specialization"
                                maxLength={50}
                                onChange={onFinish}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Year of Completion"
                              name="yearofcompletion"
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                onChange={() => {
                                  onFinish();
                                }}
                                value={null}
                                placeholder="Select start date"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <StyledButton
                            loading={showLoader}
                            type="primary"
                            htmlType="submit"
                          >
                            {showLoader ? "Update" : "Update"}
                          </StyledButton>
                        </div>
                      </Form>
                    </StyledModal>
                  </div>
                </>
              )}

              {activeTab === "4" && (
                <>
                  <div className="styled-card mb-5">
                    <StyledModal>
                      <Form
                        className=""
                        form={form}
                        name="addemployeeidentityform"
                        layout="vertical"
                        initialValues={{
                          company_Name: "",
                          industry: [],
                          sector: [],
                          industryrole: "",
                          expertise: "",
                          designation: "",
                          immediatesupervisor: "",
                          grade: "",
                          targetchargeability: "",
                          chargeoutrate: "",
                          jobtitle: "",
                          professionalsince: "",
                          hireddate: "",
                          employeesponser: "",
                          relevent: "",
                          maritalstatus: "",
                          description: "",
                          lastpromotionlevel: "",
                        }}
                        onFinish={() => handlesubmit()}
                      >
                        <Row gutter={20}>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              name="companyname"
                              label="Company Name"
                              rules={[
                                {
                                  required: false,
                                  message: "Company name is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Company name"
                                maxLength={50}
                                onChange={onFinish}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="industry"
                              label="Industry"
                              rules={[
                                {
                                  required: false,
                                  message: "Industry is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {Industry?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="sector"
                              label="Sector"
                              rules={[
                                {
                                  required: false,
                                  message: "Sector is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {Sector?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="industryrole"
                              label="Industry Role"
                              rules={[
                                {
                                  required: false,
                                  message: "Industry Role is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {IndustryRole?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="expertise"
                              label="Expertise"
                              rules={[
                                {
                                  required: false,
                                  message: "Expertise is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Expertise"
                                // maxLength={50}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="designation"
                              label="Designation"
                              rules={[
                                {
                                  required: false,
                                  message: "Designation is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Designation"
                                maxLength={50}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="immediatesupervisor"
                              label="Immediate Supervisor"
                              rules={[
                                {
                                  required: false,
                                  message: "Immediate Supervisor is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Immediate Supervisor"
                                maxLength={50}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="grade"
                              label="Grade"
                              rules={[
                                {
                                  required: false,
                                  message: "Grade is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                onChange={onFinish}
                                getPopupContainer={() => containerRef.current}
                              >
                                {Grade?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="targetchargeability"
                              label="Target Chargeability %"
                              rules={[
                                {
                                  required: false,
                                  message: "Target Chargeability % is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Target Chargeability %"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="chargeoutrate"
                              label="Charge Out Rate"
                              rules={[
                                {
                                  required: false,
                                  message: "Charge Out Rate is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Charge Out Rate"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Career Movement - Last Promotion & Level"
                              name="lastpromotionlevel"
                              rules={[
                                {
                                  required: false,
                                  pattern: NON_EMPTY_REGEX,
                                  message:
                                    "Career Movement - Last Promotion & Level is required",
                                },
                              ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                placeholder="Select Career Movement - Last Promotion & Level"
                                getPopupContainer={() => containerRef.current}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="jobtitle"
                              label="Job Title"
                              rules={[
                                {
                                  required: true,
                                  message: "Job title is required",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Job Title"
                                maxLength={50}
                                onChange={onFinish}
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Professional Since"
                              name="professionalsince"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Professional Since is required",
                                },
                              ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                onChange={onFinish}
                                placeholder="Professional Since"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Hired Date"
                              name="hireddate"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Hired Date is required",
                                },
                              ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                onChange={onFinish}
                                placeholder="Select Hired Date"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="employeesponser"
                              label="Employee Sponsor"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={onFinish}
                              >
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="employeestatus"
                              label="Status"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={(value) => {
                                  setEmployeeStatusId(
                                    value === "active" ? "1" : "0"
                                  );
                                  onFinish();
                                }}
                              >
                                <Option value="active">Active</Option>
                                <Option value="in active">In active</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="relevent"
                              label="Relevant"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={onFinish}
                              >
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item name="description" label="Description">
                              <Input.TextArea
                                onChange={onFinish}
                                placeholder="description"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <StyledButton
                            loading={showLoader}
                            type="primary"
                            htmlType="submit"
                          >
                            {showLoader ? "Update" : "Update"}
                          </StyledButton>
                        </div>
                      </Form>
                    </StyledModal>
                  </div>
                </>
              )}
            </TabPane>
          ))}
        </Tabs>
      </StyledModal>
    </>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveRoleList: setRoleList,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  roleList: getRoleList(state),
  bandList: getBandList(state),
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(EditEmployee);
