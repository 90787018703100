import React from "react";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
} from "redux/selectors";
import { Row, Col } from "antd";
import styled from "@emotion/styled";
import CheckInCheckout from "molecules/CheckInCheckout";
import UserMonthlyReport from "molecules/UserMonthlyReport";
import UserCheckinReport from "molecules/UserCheckinReport";
import NonStaffDashboard from "molecules/NonStaffDashboard";
import UserLeaveReport from "molecules/UserLeaveReport";

const StyledRow = styled(Row)`
  padding-top: 5px;
`;

const MyDashboard = ({ userRoleId }) => {
  return (
    <div>
      <>
        <StyledRow gutter={16}>
          <Col md={24} sm={24} xs={24} xl={12}>
            <CheckInCheckout />

            <UserMonthlyReport />
          </Col>

          <Col md={24} sm={24} xs={24} xl={12}>
            <UserCheckinReport />
          </Col>
          <Col md={24} sm={24} xs={24} xl={24}>
            <UserLeaveReport />
          </Col>
        </StyledRow>
      </>
      {userRoleId == 1 && <NonStaffDashboard />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps, null)(MyDashboard);
