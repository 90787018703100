import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  message,
  Spin,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { getLeaveCategoryList, getUserInfo, getClientid, getUserRoleId, getRoleList, getAllDepartmentList, getUserList } from "redux/selectors";
import styled from "@emotion/styled";
import { NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";
import { find, result, concat, intersection, get } from "lodash";
import Button from "atoms/Button";
import {
  addDaysinDate,
  getKenyanDateTime,
  getMySqlDate,
  momentDate,
} from "utils/Date";
import { displayErrorModal } from "common/common";
import { CALL_API } from "common/API";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
  border-radius: 5px;
  width: initial;
`;

const StyledFooterDiv = styled.div`
  padding-top: 30px;
`;

function LeaveForm({
  isModalVisible,
  closeModal,
  leaveCategoryList,
  leaveDetails,
  ClientId,
  AllDepartmentList,
  userRoleId,
  roleList,
  userInfo,
  userList
}) {


  const [showLoader, toggleLoader] = useState(false);
  const [showLeaveValidationLoader, toggleLeaveValidationLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isCancelRequest, setCancelRequest] = useState(false);
  const [form] = Form.useForm();
  const [halfDay, setHalfDay] = useState(false);
  const [showHalfDayCheckbox, setShowHalfDayCheckbox] = useState(true);
  const { TextArea } = Input;
  const containerRef = useRef(null);

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );
  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = AllDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  const resetForm = () => {
    setShowHalfDayCheckbox(true);
    form.setFieldsValue({
      leave_id: "",
      start_date: momentDate(new Date()),
      end_date: momentDate(new Date()),
      number_of_days: 1,
      leave_type: filteredCategoriesbygender[0]?.leave_category_id,
      description: "",
    });
  };

  useEffect(() => {
    resetForm();
  }, [userRoleId, leaveCategoryList])

  const resetHalfDay = () => {
    setHalfDay(false);
    form.setFieldsValue({ number_of_days: 1 });
  };

  const updateHalfDay = (halfday) => {
    const { number_of_days } = form.getFieldsValue();
    const roundedDays = number_of_days;
    if (halfday === true) {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: roundedDays - 0.5,
        });
      }
    } else {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: Math.ceil(roundedDays),
        });
      }
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      if (
        moment(leaveDetails?.end_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("L") ===
        moment(leaveDetails?.end_date).format("L")
      ) {
        setShowHalfDayCheckbox(false);
      } else {
        setShowHalfDayCheckbox(true);
      }
    }
  }, [isModalVisible]);



  useEffect(() => {
    const {
      user_leave_id = "",
      start_date = "",
      end_date = "",
      reason = "",
      number_of_days = 0,
      leave_category_id,
      isViewOnly,
      isCancel = false,
      department_id = ""
    } = leaveDetails;
    if (user_leave_id !== "") {
      setIsViewOnly(isViewOnly);
      setCancelRequest(isCancel);

      form.setFieldsValue({
        leave_id: user_leave_id,
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        number_of_days: number_of_days,
        leave_type: leave_category_id,
        description: reason,
        remark: "",
        department: department_id
      });
      console.log('number_of_days--->',number_of_days)
      if (!isNaN(parseFloat(number_of_days)) && number_of_days.toString().includes('.')) {
        setHalfDay(true);
    }
    } else if (start_date && end_date) {
      setCancelRequest(isCancel);
      form.setFieldsValue({
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        leave_id: "",
        number_of_days: 1,
        leave_type: filteredCategoriesbygender[0]?.leave_category_id,
        description: "",
        remark: "",
        department: "",
      });
      setHalfDay(false)
    }
  }, [leaveDetails, isModalVisible]);


  // Note: First params is needed
  const onFinish = async (values, saveLeave = true) => {
    if (saveLeave) toggleLoader(true);
    else toggleLeaveValidationLoader(true);
    const {
      start_date,
      end_date,
      number_of_days,
      leave_type,
      department,
      description,
      remark,
    } = form.getFieldsValue();
    const filterleavetype = leaveCategoryList?.filter((item) => item.leave_category_id === leave_type?.toString());
    const deptName = result(
      find(AllDepartmentList, (e) => {
        return department?.includes(e.department_id);
      }),
      "department_name"
    );
    const { user_leave_id = "" } = leaveDetails;
    const { code, leaveBalance, totalNumberOfDaysInLeave, reason } = await CALL_API(
      `save-leave/${ClientId}`,
      "post",
      {
        leave_id: user_leave_id,
        start_date: getMySqlDate(start_date),
        end_date: getMySqlDate(end_date),
        number_of_days,
        leave_category_id: leave_type.toString(),
        reason: description,
        decline_reason: isCancelRequest ? remark : "",
        is_cancel_request: isCancelRequest,
        department_name: deptName,
        isSaving: saveLeave,
        department_id: department,
        old_number_of_day: leaveDetails?.number_of_days
          ? leaveDetails?.number_of_days
          : "",
        holiday: filterleavetype[0]?.holiday?.length ? filterleavetype[0]?.holiday : "No",
        weekend: filterleavetype[0]?.weekend?.length ? filterleavetype[0]?.weekend : "No",
        employeeid: filteruser[0]?.employeeid,
        leave_category_name: get(find(filteredCategoriesbygender, { 'leave_category_id': leave_type.toString() }), 'leave_category_name', ''),
        fullname: filteruser[0]?.fullname
      }
    );
    const payloadVariable = {
      leave_id: user_leave_id,
      start_date: getMySqlDate(start_date),
      end_date: getMySqlDate(end_date),
      number_of_days,
      leave_category_id: leave_type,
      reason: description,
      decline_reason: isCancelRequest ? remark : "",
      is_cancel_request: isCancelRequest,
      isSaving: saveLeave,
    };
    if (saveLeave) toggleLoader(false);
    else toggleLeaveValidationLoader(false);
    if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
      message.error(`Oops!! something went wrong`);
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      if (filterleavetype[0]?.holiday === "No" && filterleavetype[0]?.weekend === "No") {
        message.error(`You cannot apply for leave on Holidays and Sundays`);
      } else if (filterleavetype[0]?.holiday === "No") {
        message.error(`You cannot apply for leave on Holidays`);
      } else if (filterleavetype[0]?.weekend === "No") {
        message.error(`You cannot apply for leave on Sundays`);
      } else {
        message.error(`Invalid payload. Please enter correct data`);
      }
    } else if (code === STATUS_CODE.RECORD_EXIST) {
      message.error(`Leave already exists`);
    } else if (code === STATUS_CODE.ANNUAL_LEAVE_AVAILABLE) {
      message.error(
        `You can not apply compassionate leave. You still have annual leaves.`
      );
    } else if (code === STATUS_CODE.SICK_LEAVE_AVAILABLE) {
      message.error(
        `You can not apply sick leave(half-pay). You still have sick leaves.`
      );
    } else if (code === STATUS_CODE.LEAVE_EXCEED) {
      form.setFieldsValue({
        number_of_days: totalNumberOfDaysInLeave,
      });
      displayErrorModal(
        `You are exceeding your leave quota. your current leave balance is ${leaveBalance}`
      );
    } else if (code === STATUS_CODE.SUCCESS) {
      if (saveLeave) {
        message.success(
          isCancelRequest
            ? "Leave application request has been submitted"
            : `Leave has been ${user_leave_id ? "updated" : "applied"}`
        );
        form.resetFields();
        closeModal(true);
        setShowHalfDayCheckbox(true);

      } else {
        form.setFieldsValue({
          number_of_days: totalNumberOfDaysInLeave,
        });
      }
    }
  };


  const restrictPastDate = (current, element) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");
    if (element === "end_date") {
      return current < moment(form.getFieldValue("start_date"));
    }
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const validateDateRange = () => {
    const endDate = moment(form.getFieldValue("end_date"));
    const startDate = moment(form.getFieldValue("start_date"));
    if (endDate < startDate) {
      form.setFieldsValue({ end_date: startDate });
      onFinish({}, false);
    } else {
      onFinish({}, false);
    }
  };

  const dateChange = () => {
    onFinish({}, false);
    updateHalfDay();
  };

  const hideHalfDay = () => {
    const { start_date, end_date } = form.getFieldsValue();
    if (
      moment(end_date).format("dddd") == "Saturday" &&
      moment(start_date).format("dddd") == "Saturday" &&
      moment(start_date).format("L") == moment(end_date).format("L")
    ) {
      setShowHalfDayCheckbox(false);
    } else {
      setShowHalfDayCheckbox(true);
    }
  };

  const updateEndDate = () => {
    const { start_date, number_of_days } = form.getFieldsValue();
    if (number_of_days >= 1) {
      const roundedDays = Math.ceil(number_of_days);
      form.setFieldsValue({
        number_of_days: roundedDays,
        end_date: momentDate(addDaysinDate(start_date, roundedDays - 1)),
      });
    }
  };

  const checkBoxStyle = {
    marginTop: '55px',
    position: "relative",
    bottom: "15px",
  };

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterleaves = [];
  leaveCategoryList?.forEach((item) => {
    if (item?.departmentId?.length > 0 && item?.locationId?.length > 0) {
      if (intersection(item?.departmentId, filterdepartmentid).length > 0 &&
        intersection(item?.locationId, filterlocationid).length > 0) {
        filterleaves.push(item);
      }
    } else {
      if (intersection(item?.locationId, filterlocationid).length > 0) {
        filterleaves.push(item);
      }
    }

  });

  const filteruser = userList?.filter((item) => item.userid === userInfo?.userid);
  const filtergender = filteruser[0]?.gender;
  const filteredCategoriesbygender = filtergender?.toLowerCase() === "male" ? filterleaves?.filter((item) => (item.eligible)?.toLowerCase() !== "female") : filterleaves?.filter((item) => (item.eligible)?.toLowerCase() !== "male")
  return (
    <StyledModal
      title="Leave Details"
      visible={isModalVisible}
      onCancel={() => {
        closeModal(false);
        resetHalfDay();
        resetForm();
      }}
      width={600}
      footer={[]}
    >
      <Form
        form={form}
        name="leaveform"
        layout="vertical"
        initialValues={{
          leave_id: "",
          start_date: momentDate(getKenyanDateTime()),
          end_date: momentDate(getKenyanDateTime()),
          number_of_days: 1,
          leave_type: filteredCategoriesbygender[0]?.leave_category_id,
          description: "",
          remark: "",
          department: "",
        }}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Start date is required",
                },
                () => ({
                  // Note: First param is needed
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid()
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={() => {
                  validateDateRange();
                  hideHalfDay();
                }}
                disabled={isViewOnly || halfDay}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.start_date !== currentValues.start_date
              }
            >
              {() => (
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "End date is required",
                    },
                    () => ({
                      // first parameter is needed
                      validator(rule, value) {
                        const date = moment(value);
                        return date.isValid()
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      restrictPastDate(current, "end_date")
                    }
                    format="DD-MM-YYYY"
                    onChange={() => {
                      dateChange();
                      setHalfDay(false);
                      hideHalfDay();
                    }}
                    disabled={isViewOnly || halfDay}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Spin spinning={showLeaveValidationLoader}>
              <Form.Item label="No. of Days" name="number_of_days">
                <InputNumber min={0.5} onChange={updateEndDate} disabled />
              </Form.Item>
            </Spin>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {showHalfDayCheckbox && (
              <Checkbox
                style={checkBoxStyle}
                checked={halfDay}
                onChange={(e) => {
                  updateHalfDay(!halfDay);
                  setHalfDay((prevCheck) => !prevCheck);
                }}
              >
                Half day
              </Checkbox>
            )}
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Competencies and Servicelines"
              name="department"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Competencies and Servicelines is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Competencies and Servicelines"
                name="department"
                options={concat(
                  {
                    department_id: "",
                    department_name: "Select Competencies and Servicelines",
                  },
                  filterDepartment
                )}
                fieldNames={{
                  label: "department_name",
                  value: "department_id",
                }}
                disabled={isViewOnly}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Leave Type"
              name="leave_type"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Leave type is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search leave type"
                name="leave_type"
                optionFilterProp="leave_category_name"
                filterOption={(input, option) =>
                  option.leave_category_name
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.leave_category_name
                    .toLowerCase()
                    .localeCompare(optionB.leave_category_name.toLowerCase());
                }}
                options={filteredCategoriesbygender}
                fieldNames={{
                  label: "leave_category_name",
                  value: "leave_category_id",
                }}
                disabled={(leaveDetails?.status_id === "5" && leaveDetails?.having_cancel_request === "0") || halfDay ? true : isViewOnly}
                onChange={() => validateDateRange()}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
        </Row>

        {!isViewOnly && (
          <div>
            {isCancelRequest && (
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const leave_type = getFieldValue("leave_type");
                  return (
                    leave_type !== true && (
                      <Form.Item
                        label="Detailed Reason"
                        name="description"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Leave reason is required",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Type something.."
                          maxLength={500}
                          disabled={isViewOnly}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            )}
            {!isCancelRequest && (
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const leave_type = getFieldValue("leave_type");
                  return (
                    (leave_type !== true) && (
                      <Form.Item
                        label="Detailed Reason"
                        name="description"
                        rules={[
                          {
                            required: form.getFieldValue("leave_type"),
                            pattern: NON_EMPTY_REGEX,
                            message: "Reason is required",
                          },
                        ]}
                      >
                        <TextArea
                          laceholder="Type something.."
                          maxLength={500}
                          disabled={isViewOnly}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
        {!isViewOnly && (
          <StyledFooterDiv className="align-right">
            {!isCancelRequest && (
              <StyledButton
                type="primary"
                onClick={() => {
                  resetForm();
                  resetHalfDay();
                }}
                disabled={showLeaveValidationLoader}
              >
                Reset All
              </StyledButton>
            )}
            <StyledButton
              loading={showLoader}
              type="primary"
              htmlType="submit"
              disabled={showLeaveValidationLoader}
            >
              {showLoader ? "Submitting" : "Submit"}
            </StyledButton>
          </StyledFooterDiv>
        )}
      </Form>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
  ClientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(LeaveForm);
