import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const activePage = window.location.pathname.replace('/', '');

let base64Val = '';
let nameVal = '';
let MIMEtypeVal = '';
// Note : This function is needed
function uploadDocumentApp(base64, name, MIMEtype) {
  base64Val = base64;
  nameVal = name;
  MIMEtypeVal = MIMEtype;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App activePage={activePage} isApp={window.location.search.indexOf('app') >= 0} documentData={{ base64Val, nameVal, MIMEtypeVal }} />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
