import React from "react";
import styled from "styled-components";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getUserRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
  getClientid,
  getMenuList,
} from "redux/selectors";
import { connect } from "react-redux";
import { Drawer, Skeleton } from "antd";
// nee this
// import Task from "../assets/productlogo/Task.svg";
import Manage from "../assets/productlogo/Manage.svg";
import { IconButton } from "@mui/material";

const StyledAppDiv = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 2px 5px 0px #b1b1b1;
  color: black;
  width: 70px;
  padding: 2px;
  padding-top: 100px;
  margin: auto;
  @media (max-width: 992px) {
    padding-top: 150px;
  }
  @media (max-width: 571px) {
    padding-top: 200px;
  }
  @media (max-width: 426px) {
    padding-top: 220px;
  }
`;

const Centereddiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppSideBar = ({ isLoadingDataFirstTime, open, setOpen }) => {
  function handleManagementView() {
    const jwtToken = window.localStorage.getItem("token");
    const hyperlink = `https://test.mgntview.bdo.insyts.co/?jwtToken=${jwtToken}`;
    window.open(hyperlink, "_blank");
  }
  // need for future
  function handleTaskView() {
    const jwtToken = window.localStorage.getItem("token");
    const hyperlink = `https://task.tymeplushr.com/?jwtToken=${jwtToken}`;
    window.open(hyperlink, "_blank");
  }

  return (
    <>
      {window.innerWidth > 567 ? (
        <StyledAppDiv>
          {!isLoadingDataFirstTime ? (
            <>
              <>
                <Centereddiv>
                  <IconButton onClick={handleManagementView}>
                    <img src={Manage} alt="logo" />
                  </IconButton>
                </Centereddiv>
                <div className="text-center mb-3">Manage</div>
              </>
            </>
          ) : (
            <Centereddiv>
              <Skeleton.Avatar active className="d-block mb-3" />{" "}
            </Centereddiv>
          )}

          {/* // Note : need this */}
          {/* {!isLoadingDataFirstTime ? (
        <>
         
          <>
            <Centereddiv>
        <IconButton onClick={handleTaskView}>
                <img src={Task} alt="logo" />
              </IconButton>
            </Centereddiv>
            <div className="text-center mb-3">Task</div>
          </>
        </>
      ) : (
        <Centereddiv>
          <Skeleton.Avatar className="mb-3" active />
        </Centereddiv>
      )} */}
        </StyledAppDiv>
      ) : (
        <Drawer
          width={100}
          visible={!isLoadingDataFirstTime && open}
          onClose={() => setOpen(false)}
        >
          {!isLoadingDataFirstTime ? (
            <>
              <div className="text-center mb-2">
                <IconButton onClick={handleManagementView}>
                  <img src={Manage} alt="logo" />
                </IconButton>
              </div>
              <div className="text-center mb-3">Manage</div>
            </>
          ) : (
            <div className="text-center">
              <Skeleton.Avatar active className="d-block mb-3" />
            </div>
          )}
        </Drawer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userRoleList: getUserRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps)(AppSideBar);
