
import React from 'react'
import { Grid, IconButton, Box, Radio, Checkbox, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from '../../common/common';


export default function FormOptions({ form, i, handleOptionValue, optionRemove }) {

    return (
        <>
            {form?.options?.map((option, optionindex) => {
                return (
                    <>
                        <Grid container key={`grid-key${i}`}>
                            <Grid item lg={11} md={10} xs={8}>

                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px' }}>
                                    {form.formType === "MULIPLE_CHOICE" && <Radio disabled key={`radio-disbaled-${i}`} />}
                                    {form.formType === "CHECKBOX" && <Checkbox disabled key={`check-disbaled-${i}`} />}
                                    {form.formType === "DROPDOWN" && <Box sx={{ p: '10px' }} key={`dropdown-${i}`}>{optionindex + 1}.</Box>}

                                    {(form.formType === "DROPDOWN" || form.formType === "CHECKBOX" || form.formType === "MULIPLE_CHOICE") &&

                                        <CustomTextField
                                            fullWidth
                                            variant="standard"
                                            placeholder="Option text"
                                            key={`txtField-${i}`}
                                            value={option?.optionText || ""}
                                            style={{ marginTop: '5px' }}
                                            onChange={(e) => { handleOptionValue(e.target.value, i, optionindex) }}
                                        />

                                    }
                                </Box>
                            </Grid>
                            {(form.formType === "DROPDOWN" || form.formType === "CHECKBOX" || form.formType === "MULIPLE_CHOICE") &&
                                <Grid item lg={1} sm={1}>
                                    {/* no need to show delete icon for 1st option in a choice question as it's mandatory */}
                                    {(optionindex !== 0) && (
                                        <div className='text-center'>
                                            <Tooltip title="Remove" key={`removeOption-${i}`}>
                                                <IconButton onClick={() => optionRemove(i, optionindex)}  ><CloseIcon /></IconButton>
                                            </Tooltip>
                                        </div>
                                    )}

                                </Grid>
                            }
                        </Grid>
                        <Grid container key={`grid-${i}`}>
                            <Grid item lg={12} sm={12}>
                                {form.formType === "PARAGRAPH" &&
                                    <CustomTextField
                                        fullWidth
                                        placeholder="Long paragraph answer"
                                        id={`para-${i}`}
                                        variant="standard"
                                        key={`para-${i}`}
                                        className='mb-3'
                                    />
                                }

                                {form.formType === "SHORT_ANS" &&
                                    <CustomTextField
                                        fullWidth
                                        placeholder="Short answer"
                                        id={`SHORT_ANS${optionindex}${i + 1}`}
                                        variant="standard"
                                        key={`SHORT_ANS${optionindex}${i + 1}`}
                                        className='mb-3'
                                    />
                                }

                                {form.formType === "DATE" &&
                                    <CustomTextField key={`DATE${optionindex}${i + 1}`} variant="standard" type="date" className='mb-3' />
                                }

                                {form.formType === "TIME" &&
                                    <CustomTextField key={`TIME${optionindex}${i + 1}`} variant="standard" type="time" className='mb-3' />
                                }
                            </Grid>

                        </Grid>
                    </>
                )
            })}
        </>
    )
}
