import React from "react";
import { Row, Col,} from "antd";
import styled from "@emotion/styled";
import AttendanceBarChart from "molecules/AttendanceBarChart";
import LocationBasedPresentStaff from "molecules/LocationBasedPresentStaff";

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function ManagerDashboard({}) {
  return (
    <>
        <>
          <StyledRow gutter={16}>
             <Col md={24} sm={24} xs={24} xl={12}>
                <AttendanceBarChart />
              </Col>

              <Col md={24} sm={24} xs={24} xl={12}>
                <LocationBasedPresentStaff />
              </Col>
          </StyledRow>
        </>
    </>
  );
}



export default ManagerDashboard;
