import COLORS from "common/Colors";
import React from "react";
import { UploadOutlined } from '@ant-design/icons';
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDropzone = styled.div`
  text-align: center;
  padding: 20px;
  width: 100%;
  margin: auto;
  height: 300px;
  background: #e3e3e3;
  border-radius: 5px;
  .anticon {
    padding-top: 5rem;
  }
  .message {
    font-size: 1rem;
    padding-top: 1rem;
    color: ${COLORS.GREY};
  }
  .upload-icon {
    font-size: 3rem;
    color: ${COLORS.GREY};
  }
`;

const Dropzone = ({ onDrop, accept, fileName, access }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: !access, 
  });

  return (
    <>
      <StyledDropzone className="dropzone-div" {...getRootProps()}>
        {access ? (
          <input className="dropzone-input" {...getInputProps()} />
        ) : null}
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content">
              <UploadOutlined className="upload-icon" />
              <div className="message">
                {navigator.userAgent !== 'application' ? (
                  access ? 'Drag and drop documents here' : "Upload Permission not allowed"
                ) : (
                  'Upload documents'
                )}
              </div>
              <div>{fileName}</div>
            </p>
          )}
        </div>
      </StyledDropzone>
    </>
  );
};

export default Dropzone;
