import React, { useEffect, useState, useRef } from "react";
import EmployeeCard from "molecules/EmployeeCard";
import {
  Row,
  Col,
  Select,
  Button,
  Spin,
  Modal,
  Tabs,
  message,
  Empty,
  Form,
} from "antd";
import { DEVICE } from "common/Device";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { flatMap, isEmpty, reduce, uniq } from "lodash";
import { CSVLink } from "react-csv";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getClientid,
  getMenuList,
  getLocations,
  getSupervisorList,
  getlevelList,
  getUserInfo,
  getFilters,
} from "redux/selectors";
import { setFilter, setUserList } from "redux/actions";
import styled from "@emotion/styled";
import { CALL_API, CALL_FEATCH_API } from "common/API";
import { map, concat } from "lodash";
import Icon from "atoms/Icon";
import AddEmployee from "organisms/AddEmployee";
import EditEmployee from "organisms/EditEmployee";
import Confirm from "../../src/assets/Confirm.svg";
import { CompetencyRole, STATUS_CODE, SUPERVISOR } from "common/Constants";
import { bindActionCreators } from "redux";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import { getMySqlDate } from "../utils/Date";
import moment from "moment";
import Organization from "molecules/Organization";

const StyledCol = styled(Col)`
  padding: 10px;
  .ant-card {
    border-radius: 10px !important;
  }
`;
const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  .ant-row {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-ink-bar {
    background-color: #000;
  }
  #rc-tabs-1-tab-1:hover,
  #rc-tabs-1-tab-2:hover {
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
  }
  .ant-tabs-tab {
    color: #b2beb5;
  }
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  .custom-prefix-icon {
    color: #636980;
  }
  .people_font_style {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
  }
  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
  }
  .custom-select-container {
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .custom-select .ant-select-selector {
    border: none !important;
    flex: 1;
    border-radius: 10px;
    padding-right: 50px;
  }
  .ant-select-selection-placeholder {
    color: #636980 !important;
  }
  .custom-dropdown-icon {
    color: #636980;
    width: 10px;
  }
  .count_cls {
    display: flex;
  }
  .selectbox {
    width: 100%;
    margin-left: 10px;
  }
  .select_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 12px;
  }
  .filter_row {
    display: flex;
    justify-content: space-between;

    align-items: flex-start;
    gap: 8px;
    margin-left: 150px;
  }
  .row_cls_style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter_col {
    display: flex;
    gap: 15px;
    flex-direction: row;
    margin-right: 10px;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledContainerRow = styled(Row)`
  padding-top: 20px;
  .loader_container {
    width: 100%;
    text-align: center;
  }
  .noEmployee {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 10%;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Employee({
  ClientId,
  updateUserList,
  updateFilters,
  menuList,
  userRoleId,
  departmentList,
  userList,
  locationList,
  supervisorList,
  levelList,
  filters,
}) {
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [displayEditEmployeeModal, toggleEditEmployeeModal] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeFilterType, setEmployeeFilterType] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [displayEmployeeLoader, setdisplayEmployeeLoader] = useState(false);
  const [EmployeeList, setEmployeeList] = useState(userList);
  const [deactivateEmployee, toggledeactivateEmployee] = useState(false);
  const [confirmdeactivateEmployee, toggleconfirmdeactivateEmployee] =
    useState(false);
  const [empId, setEmpId] = useState(0);
  const [employeeCSVlist, setEmployeeCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [employeeFilterList, setEmployeeFilterList] = useState([]);
  const [employeeType, setEmployeeType] = useState("1");
  const containerRef = useRef(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const csvLinkEl = useRef();

  const filteredUserName = userList
    ?.filter((item) => item.roleid.includes("4"))
    ?.map((item) => item.fullname);

  const STAFF_LIST =
    employeeFilterList?.map((val) => {
      return {
        user_id: val?.userid,
        name: val?.fullname,
        type: val?.usertype,
        department: val?.userdepartmentid,
      };
    }) || [];

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const items = [
    {
      key: 1,
      label: "Employee",
      children: <></>,
    },
    {
      key: 2,
      label: "Ex Employee",
      children: <></>,
    },
    {
      key: 3,
      label: "Office Org Chart",
      children: (
        <>
          <Organization type="office" />
        </>
      ),
    },
    {
      key: 4,
      label: "Regional Org Chart",
      children: (
        <>
          <Organization type="regional" />
        </>
      ),
    },
  ];
  const getFilterList = () => {
    if (
      form.getFieldValue("employee") !== "" ||
      form.getFieldValue("supervisor") !== "All" ||
      form.getFieldValue("competencyrole") !== "" ||
      form.getFieldValue("managingoffice")?.length !== 0 ||
      form.getFieldValue("leveldepartmentname")?.length !== 0 ||
      form.getFieldValue("competencyQC")?.length !== 0 ||
      form.getFieldValue("serviceline")?.length !== 0 ||
      form.getFieldValue("team")?.length !== 0
    ) {
      return employeeFilterList
        .filter((data) => {
          if (employeeNameFilter !== "") {
            return data?.userid === employeeNameFilter;
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("employee") !== "") {
            return data?.userid === form.getFieldValue("employee");
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("supervisor") !== "All") {
            return data?.officesupervisor === form.getFieldValue("supervisor");
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyrole") !== "") {
            return data?.competencyrole?.includes(
              form.getFieldValue("competencyrole")
            );
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("managingoffice")?.length !== 0) {
            return form
              .getFieldValue("managingoffice")
              ?.some((office) => data?.managingoffice?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("leveldepartmentname")?.length !== 0) {
            return form
              .getFieldValue("leveldepartmentname")
              ?.some((office) => data?.leveldepartmentname?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyQC")?.length !== 0) {
            return form
              .getFieldValue("competencyQC")
              .some((office) => data?.competencyQC?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("serviceline")?.length !== 0) {
            return form
              .getFieldValue("serviceline")
              ?.some((office) => data?.serviceline?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("team")?.length !== 0) {
            return form
              .getFieldValue("team")
              ?.some((office) => data?.subserviceline?.includes(office));
          }
          return data;
        });
    } else {
      return employeeFilterList;
    }
  };

  const CSVHeaders = [
    { label: "Salutation", key: "Salutation" },
    { label: "Initial", key: "Initial" },
    { label: "User Id", key: "User Id" },
    { label: "Name", key: "Name" },
    { label: "Employee Id", key: "Employee Id" },
    { label: "Start Date", key: "Start Date" },
    { label: "Date of Birth", key: "Date of Birth" },
    { label: "Gender", key: "Gender" },
    { label: "Age", key: "Age" },
    { label: "Marital Status", key: "Marital Status" },
    { label: "Greetings", key: "Greetings" },
    { label: "Nationality", key: "Nationality" },
    { label: "No Of Dependent", key: "No Of Dependent" },
    { label: "UAN", key: "UAN" },
    { label: "PAN", key: "PAN" },
    { label: "Address", key: "Address" },
    { label: "Phone No", key: "Phone No" },
    { label: "Country", key: "Country" },
    { label: "Competency/ QC", key: "Competency/ QC" },
    { label: "Service Line", key: "Service Line" },
    { label: "Sub Serviceline", key: "Sub Serviceline" },
    { label: "Competency Role", key: "Competency Role" },
    { label: "Location", key: "Location" },
    { label: "Supervisor / Manager", key: "Supervisor / Manager" },
    { label: "Contracting Company", key: "Contracting Company" },
    { label: "Primary Industry", key: "Primary Industry" },
    { label: "Secondary Industry", key: "Secondary Industry" },
    { label: "Sector", key: "Sector" },
    { label: "Expertise", key: "Expertise" },
    { label: "Industry Role", key: "Industry Role" },
    { label: "Designation", key: "Designation" },
    { label: "Grade", key: "Grade" },
    { label: "Target Chargeability %", key: "Target Chargeability %" },
    { label: "Charge Out Rate", key: "Charge Out Rate" },
    { label: "Last Promotional Level", key: "Last Promotional Level" },
    { label: "Last Promotional Date", key: "Last Promotional Date" },
    { label: "Job Title", key: "Job Title" },
    { label: "Professional Since", key: "Professional Since" },
    { label: "Hired Date", key: "Hired Date" },
    { label: "Relevant", key: "Relevant" },
    { label: "Employee Sponser", key: "Employee Sponser" },
    { label: "Job Description", key: "Job Description" },
    { label: "Emergency Contact Name", key: "Emergency Contact Name" },
    { label: "Emergency Contact Number", key: "Emergency Contact Number" },
    { label: "Regional Supervisor", key: "Regional Supervisor" },
    { label: "Office Supervisor", key: "Office Supervisor" },
    { label: "Engagement Supervisor", key: "Engagement Supervisor" },
  ];

  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (getFilterList()?.length > 0) {
      setEmployeeCSVlist(
        reduce(
          getFilterList(),
          function (result, list) {
            const filterLevelLocationName = locationList?.find(
              (item) => item.location_id === list?.levellocationid
            );
            console.log("list--", list);
            result.push({
              Salutation: list?.salutation || "",
              Initial: list?.initial || "",
              "User Id": list?.userid || "",
              Name: list?.fullname || "",
              "Employee Id": list?.employeeid || "",
              "Start Date":
                list?.startdate && moment(list.startdate).isValid()
                  ? moment(list?.startdate).format("DD/MM/YYYY")
                  : "-",
              "Date of Birth":
                list?.dateofbirth && moment(list.dateofbirth).isValid()
                  ? moment(list?.dateofbirth).format("DD/MM/YYYY")
                  : "-",
              Gender: list?.gender || "",
              Age: list?.age || "",
              "Marital Status": list?.maritalstatus || "",
              "No Of Dependent": list?.dependent || "",
              Greetings: list?.greetings || "",
              Nationality: list?.nationality || "",
              NSSF: list?.nssf || "",
              "National ID": list?.national_id || "",
              Address: list?.presentaddress || "",
              "Phone No": list?.phoneno || "",
              Country: list?.managingoffice || "",
              "Competency/ QC": list?.competencyQC || "",
              "Service Line": list?.serviceline || "",
              "Sub Serviceline": list?.subserviceline || "",
              Department: list?.leveldepartmentname || "",
              "Competency Role": list?.competencyrole || "",
              "Institute Name": list?.institutename || "",
              Location: filterLevelLocationName?.location_name || "",
              "Supervisor / Manager": list?.supervisor || "",
              "Contracting Company": list?.companyname || "",
              "Primary Industry": list?.industry || "",
              "Secondary Industry": list?.secondaryindustry || "",
              Sector: list?.sector || "",
              Expertise: list?.expertise || "",
              "Industry Role": list?.industryrole || "",
              Designation: list?.designation || "",
              Grade: list?.grade || "",
              "Target Chargeability %": list?.targetchargeability || "",
              "Charge Out Rate": list?.chargeoutrate || "",
              "Last Promotion Date":
                list?.lastpromotionlevel == null ||
                list?.lastpromotionlevel == ""
                  ? ""
                  : moment(list?.lastpromotionlevel).format("DD-MM-YYYY") || "",
              "Last Promotional Level": list?.lastpromotion || "",
              "Job Title": list?.jobtitle || "",
              "Professional Since":
                list?.professionalsince &&
                moment(list.professionalsince).isValid()
                  ? moment(list.professionalsince).format("DD/MM/YYYY")
                  : "-",
              "Hired Date":
                list?.hireddate && moment(list.hireddate).isValid()
                  ? moment(list.hireddate).format("DD/MM/YYYY")
                  : "-",
              Relevant: list?.relevant || "",
              "Employee Sponser": list?.employeesponser || "",
              "Job Description": list?.jobdescription || "",
              "Emergency Contact Name": list?.emergencycontactname || "",
              "Emergency Contact Number": list?.emergencycontactnumber || "",
              "Regional Supervisor": list?.regionalsupervisor || "",
              "Office Supervisor": list?.officesupervisor || "",
              "Engagement Supervisor": list?.engagementsupervisor || "",
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };
  useEffect(() => {
    var result;
    if (employeeType) {
      result = EmployeeList?.filter((data) => data?.statusid == employeeType);
    }
    setEmployeeFilterList(result);
  }, [selectedDepartment, employeeType, EmployeeList]);

  useEffect(() => {
    setEmployeeList(userList);
  }, [userList]);

  // const getEmployeeList = async () => {
  //   try {
  //     setdisplayEmployeeLoader(true);
  //     const { response } = await CALL_API(`add-users/${ClientId}`, "get", {});
  //     setEmployeeList(response);
  //     setdisplayEmployeeLoader(false);
  //   } catch (error) {
  //     console.log("[getEmployeeList] Error-->", error);
  //   }
  // };
  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, levelList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        let level1_values = [];
        let level2_values = [];
        let level3_values = [];
        let level4_values = [];
        let level5_values = [];

        updateUserList(userList);

        levelList.forEach((level) => {
          switch (level.levelName) {
            case "Level1":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level1_values.add(item);
                });
              });
              break;
            case "Level2":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level2_values.add(item);
                });
              });
              break;
            case "Level3":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level3_values.add(item);
                });
              });
              break;
            case "Level4":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level4_values.add(item);
                });
              });
              break;
            case "Level5":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level5_values.add(item);
                });
              });
              break;
            default:
              break;
          }
        });

        setManagingOfficeData(Array.from(level1_values));
        setCompetencyQCfetchData(Array.from(level2_values));
        setServicelinefetchData(Array.from(level3_values));
        setsubservicelineData(Array.from(level4_values));
        setDepartmentLevelData(Array.from(level5_values));
      }
    }
  };

  const ViewEmployee = (EmployeeObject, isViewOnly = false) => {
    toggleEditEmployeeModal(true);
    setEmployeeDetails({
      ...EmployeeObject,
      isViewOnly,
    });
  };
  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId);
  };
  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: employee_id,
      status_id: "0",
      status_name: "inactive",
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    fetchMasterData();
    // getEmployeeList();
  };

  const [activeTabKey, setActiveTabKey] = useState(1);

  const handleTabSelect = (key) => {
    if (key === 2) {
      setEmployeeType("0");
      setEmployeeNameFilter("");
    } else {
      setEmployeeType("1");
      setEmployeeNameFilter("");
    }
    setActiveTabKey(key);
  };

  const Menulist =
    menuList[userRoleId] || Object?.values(menuList)[0] || menuList;

  const filtermenulist =
    Menulist &&
    Menulist?.filter(
      (data) =>
        data.key !== "managerdashboard" &&
        data.key !== "hrdashboard" &&
        data.key !== "teamcalendar" &&
        data.key !== "allcalendar"
    );

  useEffect(() => {
    if (levelList?.length > 0) {
      let level1_values = new Set();
      let level2_values = new Set();
      let level3_values = new Set();
      let level4_values = new Set();
      let level5_values = new Set();

      levelList.forEach((level) => {
        switch (level.levelName) {
          case "Level1":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level1_values.add(item);
              });
            });
            break;
          case "Level2":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level2_values.add(item);
              });
            });
            break;
          case "Level3":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level3_values.add(item);
              });
            });
            break;
          case "Level4":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level4_values.add(item);
              });
            });
            break;
          case "Level5":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level5_values.add(item);
              });
            });
            break;
          default:
            break;
        }
      });

      setManagingOfficeData(Array.from(level1_values));
      setCompetencyQCfetchData(Array.from(level2_values));
      setServicelinefetchData(Array.from(level3_values));
      setsubservicelineData(Array.from(level4_values));
      setDepartmentLevelData(Array.from(level5_values));
    }
  }, [levelList]);

  const defaultPath =
    filtermenulist?.length > 0 ? filtermenulist[0]?.path : "/";

  const isEmployeeTab = activeTabKey === 1 || activeTabKey === 2;

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    updateFilters("employee", values.employee);
    updateFilters("supervisor", values.supervisor);
    updateFilters("competencyrole", values.competencyrole);
    updateFilters("managingoffice", values.managingoffice);
    updateFilters("leveldepartmentname", values.leveldepartmentname);
    updateFilters("competencyQC", values.competencyQC);
    updateFilters("serviceline", values.serviceline);
    updateFilters("team", values.team);
  };

  return (
    <>
      {!displayAddEmployeeModal && !displayEditEmployeeModal && (
        <>
          {isEmployeeTab && (
            <Row className="page-title">
              <Col sm={24} md={12} style={headerstyle}>
                List of Employee
              </Col>
              <Col sm={24} md={12} className="align-right">
                <ButtonOutlined onClick={prepareCSV}>
                  <span className="add-title">Export CSV File</span>
                </ButtonOutlined>
                <CSVLink
                  header={CSVHeaders}
                  data={employeeCSVlist}
                  filename={"Employee-list.csv"}
                  asyncOnClick={true}
                  ref={csvLinkEl}
                />
                {Menulist?.find(
                  (item) =>
                    item?.key === "manage" &&
                    item?.subMenu?.find(
                      (subItem) =>
                        subItem?.key === "employee" && subItem?.permission.edit
                    )
                ) && (
                  <ButtonFilled onClick={() => toggleAddEmployeeModal(true)}>
                    <span>+</span>
                    <span className="add-title">Add People</span>
                  </ButtonFilled>
                )}
              </Col>
            </Row>
          )}

          <StyledTitle className="page-title-head">
            <Row>
              <Tabs
                activeKey={activeTabKey}
                items={items}
                onChange={handleTabSelect}
                indicator={{
                  size: (origin) => origin - 20,
                  align: "center",
                }}
              />
            </Row>
          </StyledTitle>

          {isEmployeeTab && (
            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{ ...filters }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                  <Form.Item name="employee" label="People">
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="name"
                      getPopupContainer={() => containerRef.current}
                      options={concat(
                        {
                          user_id: "",
                          name: "All Employee",
                        },
                        STAFF_LIST
                      )}
                      fieldNames={{
                        label: "name",
                        value: "user_id",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="managingoffice" label="Country">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="value"
                      mode="multiple"
                      getPopupContainer={() => containerRef.current}
                    >
                      {managingOfficeData?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="competencyQC" label="Competency/ QC">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="value"
                      mode="multiple"
                      getPopupContainer={() => containerRef.current}
                      disabled={competencyQCData?.length === 0}
                    >
                      {competencyQCData?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="serviceline" label="Serviceline">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      mode="multiple"
                      getPopupContainer={() => containerRef.current}
                      disabled={servicelineData?.length === 0}
                    >
                      {servicelineData?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="team" label="Sub Serviceline">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      mode="multiple"
                      getPopupContainer={() => containerRef.current}
                      disabled={subservicelineData?.length == 0}
                    >
                      {subservicelineData?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="leveldepartmentname" label="Department">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      mode="multiple"
                      getPopupContainer={() => containerRef.current}
                      disabled={departmentLevelData?.length == 0}
                    >
                      {departmentLevelData?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={4} ref={containerRef}>
                  <Form.Item name="competencyrole" label="Competency role">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="">All</Option>
                      {CompetencyRole?.map((value, index) => (
                        <Option key={index} value={value} label={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                  <Form.Item name="supervisor" label="Office Supervisor">
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="All">All</Option>
                      {supervisorList?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value?.name}
                          label={value?.name}
                        >
                          {value?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}

          {isEmployeeTab && (
            <>
              <StyledFilterRow className="filter_row" gutter={16}>
                <Col className="" lg={6} md={12} sm={24} xs={24}>
                  <div className="people_font_style mb-2">
                    Total People: {getFilterList().length}
                  </div>
                </Col>
              </StyledFilterRow>
              <StyledContainerRow gutter={16}>
                {displayEmployeeLoader ? (
                  <div className="loader_container">
                    <Spin spinning={displayEmployeeLoader} />
                  </div>
                ) : (
                  <>
                    {employeeFilterList?.length > 0 ? (
                      <>
                        {map(getFilterList(), (data, index) => (
                          <>
                            <StyledCol
                              key={index}
                              xs={24}
                              sm={12}
                              md={12}
                              lg={8}
                              xl={6}
                            >
                              <Link
                                to={`/profile-details/${data.userid}/?type=manage`}
                              >
                                <EmployeeCard
                                  id={data.userid}
                                  name={data.fullname}
                                  Department={data.department_name}
                                  clientid={data.clientid}
                                  employeeid={data.employeeid}
                                  data={data}
                                  getEmployeeList={fetchMasterData}
                                  ViewEmployee={ViewEmployee}
                                  deletedEmployee={deletedEmployee}
                                  profile={data.profileurl}
                                  employeeType={employeeType}
                                  rolename={data.rolename}
                                />
                              </Link>
                            </StyledCol>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <p className="noEmployee">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </p>
                      </>
                    )}
                  </>
                )}
              </StyledContainerRow>
            </>
          )}
        </>
      )}
      {displayAddEmployeeModal && (
        <AddEmployee
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={fetchMasterData}
          setSelectedDepartment={setSelectedDepartment}
          EmployeeList={EmployeeList}
        />
      )}
      {displayEditEmployeeModal && (
        <EditEmployee
          isModalVisible={displayEditEmployeeModal}
          closeModal={() => toggleEditEmployeeModal(false)}
          getEmployeeList={fetchMasterData}
          EmployeeDetails={EmployeeDetails}
          EmployeeList={EmployeeList}
        />
      )}
      <StyleddeactivateModal
        title="Are you sure you want to terminate this Employee?"
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head" gutter={16}>
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateEmployee(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteEmployee(empId)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}
const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  userInfo: getUserInfo(state),
  departmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  locationList: getLocations(state),
  supervisorList: getSupervisorList(state),
  levelList: getlevelList(state),
  filters: getFilters(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      updateFilters: setFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Employee);
