import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import {
  toggleProcessingModal,
  toggleSuccessModal,
  setAllDepartmentList,
  setLocationList,
  setRoleList,
} from "redux/actions";
import { getLocations, getClientid, getUserMenuList } from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { NON_EMPTY_REGEX, NON_CHARACTER } from "common/Constants";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import { Row, Col } from "react-bootstrap";
const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    display: none;
  }
  .ant-form-item-label > label {
    color: #636980 !important;
  }
  .ant-input {
    border-radius: 5px;
  }
  .Pop_buttons {
    padding: 5px 48px;
  }
  .style_row {
    display: flex;
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 40px 40px 0 0;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: left;
    font-size: 24px;
    color: #1b1b1b;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
`;

function FormModal({
  isModalVisible,
  closeModal,
  saveAllDepartmentList,
  ClientId,
  Name,
  saveLocationList,
  userMenuList,
  saveRoleList,
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();
  const fetchMasterData = async (fetch_master_data) => {
    const { code, departmentList, locations, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveAllDepartmentList(departmentList);
        saveLocationList(locations);
        saveRoleList(roleList);
      }
    }
  };
  const onFinish = async (values) => {
    toggleLoader(true);
    const { name, location_latitude, location_longitude } = values;
    if (Name === "Department") {
      const { code } = await CALL_API(`owner-department/${ClientId}`, "post", {
        department_name: name,
        location_id: [],
        role_id: [],
        status_id: 1,
      });
      if (code === STATUS_CODE.SUCCESS) {
        fetchMasterData(true);
        toggleLoader(false);
        form.resetFields();
        closeModal(true);
        message.success("Successfully Added");
      }
    } else if (Name === "Location") {
      const { code } = await CALL_API(`owner-location/${ClientId}`, "post", {
        department_id: [],
        latitude: location_latitude,
        longitude: location_longitude,
        location_name: name,
        role_id: [],
        status_id: 1,
      });
      if (code === STATUS_CODE.SUCCESS) {
        fetchMasterData(true);
        message.success("Successfully Added");
        toggleLoader(false);
        closeModal(true);
      }
    } else if ((Name = "Role")) {
      await CALL_API(`owner-roles/${ClientId}`, "post", {
        rolename: name,
        department: [],
        location: [],
        menuList: userMenuList,
      });
      fetchMasterData(true);
      closeModal(true);
      message.success("Role Created Successfully");
    }
  };
  const Reset = () => {
    form.resetFields();
  };
  return (
    <>
      <StyledModal
        title={`Add ${Name}`}
        visible={isModalVisible}
        onCancel={() => {
          closeModal(false);
          form.resetFields();
        }}
        width={600}
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={`${Name} Name`}
            name="name"
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: `${Name} name is required`,
              },
            ]}
          >
            <Input placeholder={`${Name} Name`} maxLength={25} />
          </Form.Item>
          {Name === "Location" && (
            <>
              <Row>
                <Col md={6}>
                  <Form.Item
                    label="Latitude"
                    name="location_latitude"
                    rules={[
                      {
                        required: true,
                        pattern: NON_CHARACTER,
                        message: "Latitude is required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter latitude" maxLength={20} />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Longitude"
                    name="location_longitude"
                    rules={[
                      {
                        required: true,
                        pattern: NON_CHARACTER,
                        message: "ongitude is required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter longitude" maxLength={20} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <div className="align-right">
            <ButtonOutlined
              key="cancel"
              className="Pop_buttons"
              onClick={() => {
                closeModal(false);
                form.resetFields();
              }}
            >
              {"Cancel"}
            </ButtonOutlined>
            <ButtonFilled
              className="Pop_buttons"
              loading={showLoader}
              type="primary"
              htmlType="submit"
            >
              {" "}
              Add
            </ButtonFilled>
          </div>
        </Form>
      </StyledModal>
    </>
  );
}
const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  ClientId: getClientid(state),
  userMenuList: getUserMenuList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveAllDepartmentList: setAllDepartmentList,
      saveLocationList: setLocationList,
      saveRoleList: setRoleList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(FormModal);
