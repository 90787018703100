import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";
import {
  concat,
  chain,
  groupBy,
  toPairs,
  zipObject,
  map,
  includes,
  filter,
} from "lodash";
// Redux
import { connect } from "react-redux";
import {
  getLocations,
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
} from "redux/selectors";

import { CALL_API } from "common/API";
import { size } from "lodash";
import LocationBasedAttendanceList from "./LocationBasedAttendanceList";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  height: 504px;
  border-radius: 3px;
  padding: 10px;
  padding-bottom: 35px;
  padding-top: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
  }
`;

function LocationBasedPresentStaff({
  locationList,
  userRoleId,
  selectedDepartmentId,
  ClientId,
}) {
  console.log('location--->',locationList)
  const dummydata = [
    {
        "checkinTime": "09:00:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "2",
        "userId": "user1@example.com",
        "loginLatitude": 36.8813027,
        "fullName": "John Doe",
        "loginLocationName": "Dar es Salaam",
        "departmentName": "HR"
    },
    {
        "checkinTime": "09:15:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "8",
        "userId": "user2@example.com",
        "loginLatitude": 8.1981763,
        "fullName": "Emma Smith",
        "loginLocationName": "Other",
        "departmentName": "HR"
    },
    {
        "checkinTime": "09:30:00",
        "departmentId": "15",
        "loginLongitude": 78.1197754,
        "loginLocationId": "1",
        "userId": "user3@example.com",
        "loginLatitude": 9.9252007,
        "fullName": "Michael Brown",
        "loginLocationName": "Kampala",
        "departmentName": "HR"
    },
    {
        "checkinTime": "09:45:00",
        "departmentId": "15",
        "loginLongitude": 77.4242304,
        "loginLocationId": "6",
        "userId": "user4@example.com",
        "loginLatitude": 8.1755264,
        "fullName": "Sophia Johnson",
        "loginLocationName": "Addis Ababa",
        "departmentName": "HR"
    },
    {
        "checkinTime": "10:00:00",
        "departmentId": "15",
        "loginLongitude": 76.955648,
        "loginLocationId": "5",
        "userId": "user5@example.com",
        "loginLatitude": 11.0395392,
        "fullName": "William Clark",
        "loginLocationName": "Bujumbura",
        "departmentName": "HR"
    },
    {
        "checkinTime": "10:15:00",
        "departmentId": "15",
        "loginLongitude": 77.4192646,
        "loginLocationId": "4",
        "userId": "user6@example.com",
        "loginLatitude": 8.1865083,
        "fullName": "Olivia Wilson",
        "loginLocationName": "Kigali",
        "departmentName": "HR"
    },
    {
        "checkinTime": "10:30:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "7",
        "userId": "user7@example.com",
        "loginLatitude": 8.1981763,
        "fullName": "Daniel Smith",
        "loginLocationName": "Kinshasa",
        "departmentName": "HR"
    },
    {
        "checkinTime": "10:45:00",
        "departmentId": "15",
        "loginLongitude": 77.42754,
        "loginLocationId": "3",
        "userId": "user8@example.com",
        "loginLatitude": 36.881302,
        "fullName": "Emily Davis",
        "loginLocationName": "Nairobi",
        "departmentName": "Technology"
    },
    {
        "checkinTime": "11:00:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "2",
        "userId": "user9@example.com",
        "loginLatitude": 36.8813027,
        "fullName": "James Anderson",
        "loginLocationName": "Dar es Salaam",
        "departmentName": "Technology"
    },
    {
        "checkinTime": "11:15:00",
        "departmentId": "15",
        "loginLongitude": 8.1981763,
        "loginLocationId": "8",
        "userId": "user10@example.com",
        "loginLatitude": 77.427539,
        "fullName": "Oliver Martin",
        "loginLocationName": "Other",
        "departmentName": "Technology"
    },
    {
      "checkinTime": "09:00:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "2",
      "userId": "user21@example.com",
      "loginLatitude": 36.8813027,
      "fullName": "Sophia Lee",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Technology"
  },
  {
      "checkinTime": "09:15:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user22@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Jacob Martin",
      "loginLocationName": "Other",
      "departmentName": "Technology"
  },
  {
      "checkinTime": "09:30:00",
      "departmentId": "15",
      "loginLongitude": 76.955648,
      "loginLocationId": "5",
      "userId": "user23@example.com",
      "loginLatitude": 11.0395392,
      "fullName": "Isabella Brown",
      "loginLocationName": "Bujumbura",
      "departmentName": "Technology"
  },
  {
      "checkinTime": "09:45:00",
      "departmentId": "15",
      "loginLongitude": 77.4192646,
      "loginLocationId": "2",
      "userId": "user24@example.com",
      "loginLatitude": 8.1865083,
      "fullName": "William Johnson",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Technology"
  },
  {
      "checkinTime": "10:00:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user25@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Olivia Davis",
      "loginLocationName": "Other",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "10:15:00",
      "departmentId": "15",
      "loginLongitude": 78.1197754,
      "loginLocationId": "5",
      "userId": "user26@example.com",
      "loginLatitude": 9.9252007,
      "fullName": "Lucas Wilson",
      "loginLocationName": "Bujumbura",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "10:30:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "2",
      "userId": "user27@example.com",
      "loginLatitude": 36.8813027,
      "fullName": "Emily Thompson",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "10:45:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user28@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Jack Anderson",
      "loginLocationName": "Other",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "11:00:00",
      "departmentId": "15",
      "loginLongitude": 76.955648,
      "loginLocationId": "5",
      "userId": "user29@example.com",
      "loginLatitude": 11.0395392,
      "fullName": "Sophia Wilson",
      "loginLocationName": "Bujumbura",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "11:15:00",
      "departmentId": "15",
      "loginLongitude": 77.4192646,
      "loginLocationId": "2",
      "userId": "user30@example.com",
      "loginLatitude": 8.1865083,
      "fullName": "Daniel Brown",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "11:30:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user31@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Ella Martinez",
      "loginLocationName": "Other",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "11:45:00",
      "departmentId": "15",
      "loginLongitude": 78.1197754,
      "loginLocationId": "5",
      "userId": "user32@example.com",
      "loginLatitude": 9.9252007,
      "fullName": "William Garcia",
      "loginLocationName": "Bujumbura",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "12:00:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "2",
      "userId": "user33@example.com",
      "loginLatitude": 36.8813027,
      "fullName": "Oliver Adams",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "12:15:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user34@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Sophia Miller",
      "loginLocationName": "Other",
      "departmentName": "Finance"
  },
  {
      "checkinTime": "12:30:00",
      "departmentId": "15",
      "loginLongitude": 76.955648,
      "loginLocationId": "5",
      "userId": "user35@example.com",
      "loginLatitude": 11.0395392,
      "fullName": "James Brown",
      "loginLocationName": "Bujumbura",
      "departmentName": "Development"
  },
  {
      "checkinTime": "12:45:00",
      "departmentId": "15",
      "loginLongitude": 77.4192646,
      "loginLocationId": "2",
      "userId": "user36@example.com",
      "loginLatitude": 8.1865083,
      "fullName": "Isabella Wilson",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Development"
  },
  {
      "checkinTime": "13:00:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user37@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Lucas Thompson",
      "loginLocationName": "Other",
      "departmentName": "Development"
  },
  {
      "checkinTime": "13:15:00",
      "departmentId": "15",
      "loginLongitude": 78.1197754,
      "loginLocationId": "5",
      "userId": "user38@example.com",
      "loginLatitude": 9.9252007,
      "fullName": "Emily Davis",
      "loginLocationName": "Bujumbura",
      "departmentName": "Development"
  },
  {
      "checkinTime": "13:30:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "2",
      "userId": "user39@example.com",
      "loginLatitude": 36.8813027,
      "fullName": "Alexander Brown",
      "loginLocationName": "Dar es Salaam",
      "departmentName": "Development"
  },
  {
      "checkinTime": "13:45:00",
      "departmentId": "15",
      "loginLongitude": 77.427539,
      "loginLocationId": "8",
      "userId": "user40@example.com",
      "loginLatitude": 8.1981763,
      "fullName": "Sophie Johnson",
      "loginLocationName": "Other",
      "departmentName": "Development"
  },
  {
    "checkinTime": "08:00:00",
    "departmentId": "15",
    "loginLongitude": 77.42754,
    "loginLocationId": "3",
    "userId": "user41@example.com",
    "loginLatitude": 36.881302,
    "fullName": "Sophia Brown",
    "loginLocationName": "Nairobi",
    "departmentName": "Development"
},
{
    "checkinTime": "08:15:00",
    "departmentId": "15",
    "loginLongitude": 77.4242304,
    "loginLocationId": "6",
    "userId": "user42@example.com",
    "loginLatitude": 8.1755264,
    "fullName": "William Clark",
    "loginLocationName": "Addis Ababa",
    "departmentName": "Development"
},
{
    "checkinTime": "08:30:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "7",
    "userId": "user43@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Olivia Wilson",
    "loginLocationName": "Kinshasa",
    "departmentName": "Development"
},
{
    "checkinTime": "08:45:00",
    "departmentId": "15",
    "loginLongitude": 76.955648,
    "loginLocationId": "5",
    "userId": "user44@example.com",
    "loginLatitude": 11.0395392,
    "fullName": "Jack Anderson",
    "loginLocationName": "Bujumbura",
    "departmentName": "Development"
},
{
    "checkinTime": "09:00:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "8",
    "userId": "user45@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Oliver Adams",
    "loginLocationName": "Other",
    "departmentName": "Development"
},
{
    "checkinTime": "09:15:00",
    "departmentId": "15",
    "loginLongitude": 77.4192646,
    "loginLocationId": "3",
    "userId": "user46@example.com",
    "loginLatitude": 8.1865083,
    "fullName": "Emily Martinez",
    "loginLocationName": "Nairobi",
    "departmentName": "Operations"
},
{
    "checkinTime": "09:30:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "6",
    "userId": "user47@example.com",
    "loginLatitude": 8.1755264,
    "fullName": "Sophia Garcia",
    "loginLocationName": "Addis Ababa",
    "departmentName": "Operations"
},
{
    "checkinTime": "09:45:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "7",
    "userId": "user48@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Lucas Brown",
    "loginLocationName": "Kinshasa",
    "departmentName": "Operations"
},
{
    "checkinTime": "10:00:00",
    "departmentId": "15",
    "loginLongitude": 76.955648,
    "loginLocationId": "5",
    "userId": "user49@example.com",
    "loginLatitude": 11.0395392,
    "fullName": "Ella Wilson",
    "loginLocationName": "Bujumbura",
    "departmentName": "Operations"
},
{
    "checkinTime": "10:15:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "8",
    "userId": "user50@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Daniel Thompson",
    "loginLocationName": "Other",
    "departmentName": "Operations"
},
{
    "checkinTime": "10:30:00",
    "departmentId": "15",
    "loginLongitude": 77.42754,
    "loginLocationId": "3",
    "userId": "user51@example.com",
    "loginLatitude": 36.881302,
    "fullName": "Isabella Martin",
    "loginLocationName": "Nairobi",
    "departmentName": "Operations"
},
{
    "checkinTime": "10:45:00",
    "departmentId": "15",
    "loginLongitude": 77.4242304,
    "loginLocationId": "6",
    "userId": "user52@example.com",
    "loginLatitude": 8.1755264,
    "fullName": "William Garcia",
    "loginLocationName": "Addis Ababa",
    "departmentName": "Operations"
},
{
    "checkinTime": "11:00:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "7",
    "userId": "user53@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Olivia Davis",
    "loginLocationName": "Kinshasa",
    "departmentName": "Operations"
},
{
    "checkinTime": "11:15:00",
    "departmentId": "15",
    "loginLongitude": 76.955648,
    "loginLocationId": "5",
    "userId": "user54@example.com",
    "loginLatitude": 11.0395392,
    "fullName": "Lucas Thompson",
    "loginLocationName": "Bujumbura",
    "departmentName": "Operations"
},
{
    "checkinTime": "11:30:00",
    "departmentId": "15",
    "loginLongitude": 77.427539,
    "loginLocationId": "8",
    "userId": "user55@example.com",
    "loginLatitude": 8.1981763,
    "fullName": "Sophia Adams",
    "loginLocationName": "Other",
    "departmentName": "Operations"
},
    {
        "checkinTime": "11:30:00",
        "departmentId": "15",
        "loginLongitude": 78.1197754,
        "loginLocationId": "1",
        "userId": "user11@example.com",
        "loginLatitude": 9.9252007,
        "fullName": "Isabella White",
        "loginLocationName": "Kampala",
        "departmentName": "Operations"
    },
    {
        "checkinTime": "11:45:00",
        "departmentId": "15",
        "loginLongitude": 77.4242304,
        "loginLocationId": "6",
        "userId": "user12@example.com",
        "loginLatitude": 8.1755264,
        "fullName": "Alexander Brown",
        "loginLocationName": "Addis Ababa",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "12:00:00",
        "departmentId": "15",
        "loginLongitude": 76.955648,
        "loginLocationId": "5",
        "userId": "user13@example.com",
        "loginLatitude": 11.0395392,
        "fullName": "Sophie Taylor",
        "loginLocationName": "Bujumbura",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "12:15:00",
        "departmentId": "15",
        "loginLongitude": 77.4192646,
        "loginLocationId": "4",
        "userId": "user14@example.com",
        "loginLatitude": 8.1865083,
        "fullName": "Henry Wilson",
        "loginLocationName": "Kigali",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "12:30:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "7",
        "userId": "user15@example.com",
        "loginLatitude": 8.1981763,
        "fullName": "Ella Davis",
        "loginLocationName": "Kinshasa",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "12:45:00",
        "departmentId": "15",
        "loginLongitude": 77.42754,
        "loginLocationId": "3",
        "userId": "user16@example.com",
        "loginLatitude": 36.881302,
        "fullName": "Mason Johnson",
        "loginLocationName": "Nairobi",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "13:00:00",
        "departmentId": "15",
        "loginLongitude": 77.427539,
        "loginLocationId": "2",
        "userId": "user17@example.com",
        "loginLatitude": 36.8813027,
        "fullName": "Ava Smith",
        "loginLocationName": "Dar es Salaam",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "13:15:00",
        "departmentId": "15",
        "loginLongitude": 8.1981763,
        "loginLocationId": "8",
        "userId": "user18@example.com",
        "loginLatitude": 77.427539,
        "fullName": "Benjamin Martin",
        "loginLocationName": "Other",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "13:30:00",
        "departmentId": "15",
        "loginLongitude": 78.1197754,
        "loginLocationId": "1",
        "userId": "user19@example.com",
        "loginLatitude": 9.9252007,
        "fullName": "Grace White",
        "loginLocationName": "Kampala",
        "departmentName": "RAS"
    },
    {
        "checkinTime": "13:45:00",
        "departmentId": "15",
        "loginLongitude": 77.4242304,
        "loginLocationId": "6",
        "userId": "user20@example.com",
        "loginLatitude": 8.1755264,
        "fullName": "Lucas Brown",
        "loginLocationName": "Addis Ababa",
        "departmentName": "RAS"
    }
]

  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([]);

  const [reportList, setReportList] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { locationList } = await CALL_API(
        `location-list/${ClientId}`,
        "post",
        {
          departmentId:
            userRoleId != 6 || userRoleId != 1 ? selectedDepartmentId : [""],
        }
      );
      let result = chain(dummydata)
        .groupBy((x) => x.loginLocationName)
        .map((value, key) => ({
          loginLocationName: key,
          prensentList: value,
          presentCount: size(value),
        }))
        .value();

      setReportData(result);

      toggleReportLoader(false);
    }
  };
  useEffect(() => {
    getReportData();
  }, [selectedDepartmentId]);
  const config = {
    // height:350,
    padding: "auto",
    data: reportData,
    angleField: "presentCount",
    colorField: "loginLocationName",
    radius: 0.7,
    label: {
      type: "inner",
      offset: "-30%",
      autoRotate: false,
      // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    legend: {
      position: "right",
      layot: "vertical",
      offsetX: -30,
      itemName: {
        // formatter: (text,item) => {
        //   return " hi all the user interface check nterface"
        // },
        style: {
          fontSize: 11,
        },
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      <Spin spinning={displayReportLoader}>
        <StyledTitle>LOCATION BASED PRESENT STAFF OVERVIEW</StyledTitle>
        <StyledChartContainer>
          <div className="name-title">Today's Report:</div>
          {reportData?.length > 0 ? (
            <>
              <Pie
                {...config}
                onEvent={(plot) => {
                  plot.on("element:click", (x) => {
                    setLocationName(x.data.data.loginLocationName);
                    setReportList(x.data.data.prensentList);
                    toggleDisplayAttendanceStaffModal(true);
                  });
                }}
              />
            </>
          ) : (
            <p
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Staff Present
            </p>
          )}
        </StyledChartContainer>
      </Spin>
      <LocationBasedAttendanceList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={locationName}
        list={reportList}
        reportType={locationName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(LocationBasedPresentStaff);
