import { VALIDATE_CHARACTER } from "common/Constants";

export const getEditRoleJson = (data) => {
  const EditRoleForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Role Name",
              type: "text",
              name: "roleName",
              Placeholder: "Role Name",
              value: data["roleName"],
              maxLength: 35,
              validationRules: [
                {
                  required: true,
                  message: `Role Name is required`,
                },
                // Note: this need for future
                // {
                //   validator: (rule, value, callback) => {
                //     if (value && !VALIDATE_CHARACTER.test(value)) {
                //       callback("Role Name should not contain special characters");
                //     } else {
                //       callback();
                //     }
                //   },
                // }
              ],
            },
          ],
        ],
      },
    ],
  };

  return {
    EditRoleForm,
  };
};
