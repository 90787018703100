import React, { useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { Row, Col, Button, Modal, Form, Input, Select, message } from "antd";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// import PaymentSuccess from './PaymentSuccess';
import { useHistory } from "react-router-dom";
import {
  setCurrentPlan,
  setTrailPeriod,
  setSubscriptionId,
} from "redux/actions";
import { getClientid } from "redux/selectors";
import { STATUS_CODE } from "common/Constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";

const CheckoutForm = ({
  planName,
  setshowplan,
  getSubscriptionDetails,
  saveTrailPeriod,
  saveCurrentPlan,
  subscriptionId,
  ClientId,
  setStripeModalVisible,
  saveSubscriptionId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errormessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const history = useHistory();

  const fetchMasterData = async () => {
    let fetch_master_data = true;
    const {
      code,
      trailperiodendon = "",
      currentPlan,
    } = await CALL_API(`master-data/${ClientId}`, "post", {
      fetch_master_data,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveCurrentPlan(currentPlan);
        saveTrailPeriod(trailperiodendon);
      }
    }
  };

  const updateSubcriptionStatus = async () => {
    const { code } = await CALL_API(
      `payment/subscriptioncreate/${ClientId}`,
      "patch",
      {
        planName,
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    try {
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `http://localhost:3001`,
        },
        redirect: "if_required",
      });
      if (paymentIntent) {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        setIsPaymentComplete(true);
        message.success("payment complete");
        updateSubcriptionStatus();
        await delay(100);
        fetchMasterData();
        getSubscriptionDetails();
        setIsLoading(false);
        setStripeModalVisible(false);
        setshowplan(false);
      } else if (
        error.type === "card_error" ||
        error.type === "validation_error"
      ) {
        errormessage.error(error.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Payment error:", error);
      setErrorMessage(error.message || "An unexpected error occurred.");
    }
    
  };

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement id="link-authentication-element" />
        <PaymentElement id="payment-element" />
        <button className="btn btn-primary mt-2" disabled={isLoading || !stripe || !elements} id="submit">
         Pay now
        </button>
        {errormessage && (
          <div id="payment-message text-danger">{errormessage}</div>
        )}
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCurrentPlan: setCurrentPlan,
      saveTrailPeriod: setTrailPeriod,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
