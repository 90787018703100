import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { utils, writeFileXLSX } from "xlsx";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getUserList,
  getClientid,
} from "redux/selectors";
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, get, includes, orderBy, } from "lodash";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTable = styled(Table)`
  padding-top: 20px;
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  a{
    color: #000 !important;
  :hover{
    color: #C2FAE5;
  }
  :active{
    color: #C2FAE5;
  }
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }

  a{
    color:#000 !important;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
`;

function Approval({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  ClientId,
  userList
}) {

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [form] = Form.useForm();
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const containerRef = useRef(null);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = attendanceList.map((val) => {
    return { user_id: val.userId, name: val.fullName };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const filterattentancereport = attendanceList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userId === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
      width: "7%",
    },
    {
      title: "Employee Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "10%",
      render: (fullName) => {
        if (fullName !== ("" || null || undefined)) {
          return fullName;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      width: "10%",
      render: (departmentName) => {
        if (departmentName !== ("" || null || undefined)) {
          return departmentName;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Checkin Station",
      dataIndex: "loginLocationName",
      key: "loginLocationName",
      width: "10%",
      render: (loginLocationName) => {
        if (loginLocationName !== ("" || null || undefined)) {
          return loginLocationName;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Check-in date",
      dataIndex: "checkinDate",
      key: "checkinDate",
      width: "7%",
      render: (checkinDate) => {
        if (checkinDate === ("" || null || undefined)) return "-";
        const checkinDateFormat = moment(checkinDate).format("DD MMM YYYY");
        return `${checkinDateFormat}`;
      },
    },
    {
      title: "Check-in day",
      dataIndex: "checkinDate",
      key: "checkinDate",
      width: "7%",
      render: (checkinDate) => {
        if (checkinDate === ("" || null || undefined)) return "-";
        const getdayAlone = moment(checkinDate).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Check-in time",
      dataIndex: "checkinDateTime",
      key: "checkinDateTime",
      width: "5%",
      render: (checkinDateTime) => {
        if (checkinDateTime === "") return "-";
        const { time24 } = dateInDetail(checkinDateTime);
        return `${time24}`;
      },
    },
    {
      title: "Check-in Coordinates",
      dataIndex: "loginLatitude",
      key: "loginLatitude",
      width: "10%",
      render: (_, record) => {
        const url1 =
          "http://maps.google.com/maps/?q=" +
          record.loginLatitude +
          "," +
          record.loginLongitude +
          "";
        return (
          <div>
            {record.loginLatitude !== ("" || null || undefined) &&
              record.loginLongitude !== ("" || null || undefined) ? (
              <>
                <div id="loc">
                  <a href={url1} target="_blank" >
                    <b>{record.loginLatitude},{record.loginLongitude}</b>
                  </a>
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Checkout Station",
      dataIndex: "logoutLocationName",
      key: "logoutLocationName",
      width: "10%",
      render: (logoutLocationName) => {
        if (
          logoutLocationName === null ||
          logoutLocationName === "" ||
          logoutLocationName === undefined
        ) {
          return "-";
        } else {
          return logoutLocationName;
        }
      },
    },
    {
      title: "Check-out date",
      dataIndex: "checkoutDate",
      key: "checkoutDate",
      width: "7%",
      render: (checkoutDate) => {
        if (
          checkoutDate === null ||
          checkoutDate === "" ||
          checkoutDate === undefined
        ) {
          return "-";
        } else {
          const dateFormat = moment(checkoutDate).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Check-out day",
      dataIndex: "checkoutDate",
      key: "checkoutDate",
      width: "7%",
      render: (checkoutDate) => {
        if (
          checkoutDate === null ||
          checkoutDate === "" ||
          checkoutDate === undefined
        ) {
          return "-";
        } else {
          const getdayAlone = moment(checkoutDate).format("dddd");
          return `${getdayAlone}`;
        }
      },
    },
    {
      title: "Check-out time",
      dataIndex: "checkoutDateTime",
      key: "checkoutDateTime",
      width: "5%",
      render: (checkoutDateTime) => {
        if (
          checkoutDateTime === null ||
          checkoutDateTime === "" ||
          checkoutDateTime === undefined
        ) {
          return "-";
        } else {
          const { time24 } = dateInDetail(checkoutDateTime);
          return `${time24}`;
        }
      },
    },
    {
      title: "Check-out Coordinates",
      dataIndex: "end_lat_long",
      key: "logoutLatitude",
      width: "10%",
      render: (_, record) => {
        const url =
          "http://maps.google.com/maps/?q=" +
          record.logoutLatitude +
          "," +
          record.logoutLongitude +
          "";
        return (
          <div>
            {record.logoutLatitude === null ||
              record.logoutLatitude === "" ||
              (record.logoutLatitude === undefined &&
                record.logoutLongitude === null) ||
              record.logoutLongitude === "" ||
              record.logoutLongitude === undefined ? (
              "-"
            ) : (
              <>
                <div>
                  <a href={url} target="_blank">
                    <b>{record.logoutLatitude},{record.logoutLongitude}</b>
                  </a>
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("department"),
  ]);

  const getAttendanceReport = async () => {
    list();
    return new Promise(async (resolve, reject) => {
      setAttendanceList([]);
      const { start_date, end_date, duration_type, month } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      const { code, attendanceList = [] } = await CALL_API(
        `attendance-report/${ClientId}`,
        "post",
        {
          department: selectedDepartmentId,
          start_date: getMySqlDate(
            duration_type === 2 ? startDateOfMonth : start_date
          ),
          end_date: getMySqlDate(
            duration_type === 2 ? lastDateOfMonth : end_date
          ),
        }
      );
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        setAttendanceList(orderBy(attendanceList, ["checkinDate"], ["desc"]));
      }
    });
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "attendance-report.xlsx");
  });

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                department: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select..."
                          options={monthList}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <ButtonOutlined
            onClick={xport}
            disabled={filterattentancereport.length === 0}
          >
            Export XLSX file
          </ButtonOutlined>
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterattentancereport}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={
            filterattentancereport.length > 20 ? paginationOptions : false
          }
        />
        <Table
          style={{ display: "none" }}
          dataSource={filterattentancereport}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={false}
          id="Table"
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(Approval);
