import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CustomTextField } from "../common/common";
import {
  Typography,
  Paper,
  Box,
  Radio,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const ViewAppraisalResponse = ({ response, appraisal, setSelectedItem }) => {
  const [receivedResponse, setReceivedResponse] = useState([]);

  React.useEffect(() => {
    answeredForm();
  }, [response]);
  

  const answeredForm = () => {
    const emptyArray = [];
    const userResponse = response?.answer;
    userResponse?.forEach((element) => {
      emptyArray?.push({
        question: element.question,
        formType: element.formType,
        id: element.id,
        requiredSwitch: element.requiredSwitch,
        answer: element.answer,
        options:
          element.formType === "MULIPLE_CHOICE" ||
          element.formType === "CHECKBOX"
            ? element.options
            : "",
      });
    });
    setReceivedResponse(emptyArray);
  };

  const questionStyle = {
    fontSize: "16px",
    fontWeight: "medium",
    display: "inline-block",
  };

  const findAppraisalName = (appraisalFormId) => {
    const department = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department ? department.title : "N/A";
  };

  const findAppraisalDisc = (appraisalFormId) => {
    const department = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department ? department.description : "N/A";
  };

  useEffect(()=>{
    setSelectedItem(findAppraisalName(response?.appraisalFormId));
  },[])

  return (
    <div>
      <section className="form_builder main_container">
        <Container className="p-5">
          <>
            <Paper elevation={0} className="mb-3">
              <Box
                sx={{
                  maxWidth: 280,
                  minWidth: 250,
                  mt: 2,
                }}
              >
                <div className="p-3">
                  <h5>{findAppraisalName(response.appraisalFormId)}</h5>
                  <p>{findAppraisalDisc(response.appraisalFormId)}</p>
                </div>
                <p></p>
              </Box>
            </Paper>
            <div className="mt-4">
              {receivedResponse.map((form, idx) => {
                return (
                  <Paper elevation={0} className="mb-3">
                    <div className="p-3">
                      <Typography variant="h6" sx={questionStyle}>
                        {form?.question}
                      </Typography>
                      {form?.requiredSwitch && (
                        <span className="text-danger">*</span>
                      )}

                      {/* Selected Option */}
                      <Box key={`selectedOption-${form.id}`} sx={{ mt: 2 }}>
                        {form?.answer == "" || null || undefined ? (
                          <Box sx={{ paddingX: 5 }}>Not answered</Box>
                        ) : (
                          <>
                            {form?.formType === "PARAGRAPH" && (
                              <p>{form?.answer}</p>
                            )}

                            {form?.formType === "SHORT_ANS" && (
                              <p>{form?.answer}</p>
                            )}

                            {form?.formType === "TIME" && (
                              <CustomTextField
                                value={form?.answer}
                                type="time"
                                variant="standard"
                                disabled
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                }}
                              />
                            )}

                            {form?.formType === "DATE" && (
                              <CustomTextField
                                value={form?.answer}
                                type="date"
                                disabled
                                variant="standard"
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                }}
                              />
                            )}

                            {form?.formType === "DROPDOWN" && (
                              <Box
                                sx={{
                                  maxWidth: 280,
                                  minWidth: 250,
                                  mt: 2,
                                }}
                              >
                                <FormControl fullWidth>
                                  <Select
                                    labelId="select"
                                    id="select-filled"
                                    value={form?.answer}
                                    disabled
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                    }}
                                  >
                                    <MenuItem value={form?.answer}>
                                      {form?.answer}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            )}

                            {form?.formType === "MULIPLE_CHOICE" && (
                              <>
                                {form?.options === "" || null || undefined ? (
                                  <Box sx={{ paddingX: 5 }}>Not answered</Box>
                                ) : (
                                  <>
                                    {form?.options.map((option) => {
                                      return (
                                        <div>
                                          {option?.optionText ===
                                          form?.answer ? (
                                            <FormControlLabel
                                              value={option?.optionText}
                                              control={<Radio checked />}
                                              label={option?.optionText}
                                            />
                                          ) : (
                                            <FormControlLabel
                                              value={option?.optionText}
                                              control={<Radio disabled />}
                                              label={option?.optionText}
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </>
                            )}

                            {form?.formType === "CHECKBOX" && (
                              <>
                                {form?.options.map((option, checkBoxindex) => {
                                  return (
                                    <div>
                                      {form.answer.includes(
                                        option?.optionText
                                      ) ? (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={option?.optionText}
                                              inputProps={{
                                                "aria-label": "controlled",
                                              }}
                                              checked
                                            />
                                          }
                                          label={option?.optionText}
                                        />
                                      ) : (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={option?.optionText}
                                              inputProps={{
                                                "aria-label": "controlled",
                                              }}
                                              disabled
                                            />
                                          }
                                          label={option?.optionText}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </div>
                  </Paper>
                );
              })}
            </div>
          </>
        </Container>
      </section>
    </div>
  );
};

export default ViewAppraisalResponse;
