import React from "react";
import { Row, Col,} from "antd";
import styled from "@emotion/styled";
import AttendanceBarChart from "molecules/AttendanceBarChart";
import LocationBasedPresentStaff from "molecules/LocationBasedPresentStaff";
import DashboardPendingLeaves from "molecules/DashboardPendingLeaves";
import AllEmployees from "molecules/AllEmployees";
import LeaveBifurcationReport from "molecules/LeaveBifurcationReport";

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function HrDashboard({}) {
  return (
    <>
        <>
          <StyledRow gutter={16}>
          
             <Col md={24} sm={24} xs={24} xl={12}>
                <AttendanceBarChart isHrDashboard={true}/>
              </Col>

              <Col md={24} sm={24} xs={24} xl={12}>
                <LocationBasedPresentStaff />
              </Col>

              <Col md={24} sm={24} xs={24} xl={18}>
                <DashboardPendingLeaves />
              </Col>

              <Col md={24} sm={24} xs={24} xl={6}>
                <AllEmployees />
              </Col>
              <Col md={24} sm={24} xs={24} xl={24}>
              <LeaveBifurcationReport />
            </Col>
          </StyledRow>
        </>
    </>
  );
}



export default HrDashboard;
