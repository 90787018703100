import React, { useState } from "react"
import styled from "styled-components"
import OTPInput from "otp-input-react"
// import { primaryColor } from "../../constant"
import { useEffect } from "react"
import { ButtonFilled } from "reusableComponent/UIButtons"
import { Spin } from "antd"


const OTPFieldWrap = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  input {
    border: 1px solid #ccc;
    border-radius: 0.25em;
    /* margin: 0 10px; */
    color: black;
    &:focus-within,
    &:focus {
      outline: 1px solid #ccc
    }
    @media (max-width: 480px) {
            margin: 0 !important;
        }
  }
`

const btnStyle = {minWidth: "130px", margin: "10px"}
const resendButtonInterval = 30

const OTPVerification = ({ handleOtpVerification, email, sendPhone , resendOtp, loading }) => {
  const [otp, setOtp] = useState("")

  const [disableResendButton, setDisableResendButton] = useState({
    interval: resendButtonInterval,
    disable: true,
  })

  useEffect(() => {
    let interval
    if (disableResendButton.disable) {
      interval = setInterval(() => {
        setDisableResendButton((prevState) => {
          if (prevState.interval === 0) {
            clearInterval(interval)
            return { interval: resendButtonInterval, disable: false }
          } else {
            return { interval: prevState.interval - 1, disable: true }
          }
        })
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [disableResendButton.disable])

  return (
    <OTPFieldWrap>
      <div className='font-bold pb-2'>Verify your Email to continue</div>
      <div className='pb-3 font-medium'>Enter the 6 digit code sent to {email}</div>

      <OTPInput
        value={otp}
        onChange={setOtp}
        placeholder='******'
        autoFocus
        OTPLength={6}
        otpType='number'
        disabled={false}
        // secure
      />
      <br/>

      <div className='mt-6 flex justify-center items-center gap-3'>
        <ButtonFilled
          style={btnStyle}
          disabled={disableResendButton.disable}
          onClick={() => {
            // sendPhone({
            //   phonenumber: phoneNumber,
            // })
            resendOtp()
            setOtp("")
            setDisableResendButton({
              interval: resendButtonInterval,
              disable: true,
            })
          }}
        >
          <div className='font-medium'>  
            Resend {disableResendButton.interval === 30 ? "" : `(${disableResendButton.interval})`}
          </div>
        </ButtonFilled>

        <ButtonFilled  style={btnStyle} disabled={otp.length < 6} onClick={() => handleOtpVerification(otp)}>
         {loading && <Spin/>}
         {!loading && (<div className='font-medium'>Verify</div>)}
        </ButtonFilled>
      </div>
    </OTPFieldWrap>
  )
}

export default OTPVerification
