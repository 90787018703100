
import { SearchOutlined } from '@mui/icons-material';
import { Input, Menu, Dropdown } from 'antd';
import Table from 'atoms/Table';
import COLORS from 'common/Colors';
import React from 'react';
import styled from "styled-components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { find, get } from 'lodash';
import { CALL_API } from 'common/API';
import AlertModal from 'organisms/AlertModal';

const StyledViewMenu = styled.div`
  width: 150px;
  cursor: pointer;
`;
const StyledDropdownMenu = styled(Dropdown)`
  width: 150px;
  cursor: pointer;
`;


function Candidate({ data, jobList, processList, setShowComponent, getData, setIndex, MenuList }) {
  const [loading, setLoading] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('')

  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = data?.filter((item) =>
    item.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteData = async () => {
    setLoading(true)
    await CALL_API(`recruitment/candidate/${deleteId}`, 'patch', {
      deleteStatus: true
    });
    getData()
    setLoading(false)
    setDeleteOpen(false)

  };

  const moveToTalentPool = async (selectedCandidate) => {
    setLoading(true)
    await CALL_API(`recruitment/candidate/${selectedCandidate?.candidateId}`, "patch", {
      talentPoolStatus: true
    });
    getData()
    setLoading(false)
  }
  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => {
        return record?.firstName + " " + record?.lastName
      }
    },
    {
      title: "Applied For",
      dataIndex: "jobId",
      key: "jobId",
      render: (_, record) => {
        const job = find(jobList, { 'jobId': record?.jobId })
        return job?.title
      }
    },
    {
      title: "Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (_, record) => {
        const process = find(processList, { 'processId': record?.processId });

        if (process) {
          const steps = get(process, 'steps', []);
          const currentStatus = record?.currentStatus || '0';
          return get(steps, `[${currentStatus}][${currentStatus}]`, 'Unknown Status');
        }

        return 'Unknown Process';
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <StyledDropdownMenu
          overlay={
            <StyledViewMenu>
              <Menu>
                {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.edit)) && (
                  <Menu.Item key="2"
                    onClick={(e) => {
                      setDeleteId(record?.candidateId); setDeleteOpen(true)
                    }}
                  >Delete
                  </Menu.Item>
                )}
                {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.delete)) && (
                  <Menu.Item key="3" onClick={(e) => {
                    moveToTalentPool(record)
                  }
                  }>Add to Talent Pool</Menu.Item>
                )}

              </Menu>
            </StyledViewMenu>
          }
          trigger={["hover"]}
          placement="bottomRight"
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && (subItem?.permission.edit || subItem?.permission.delete))) && (
              <MoreHorizIcon />
            )}
          </span>
        </StyledDropdownMenu>
      ),
    },
  ];



  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: '18px' }
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: '16px' }
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div>
          <Input style={styledInput} prefix={<SearchOutlined style={styledIcon} />} placeholder='Search People' onChange={handleSearchChange} />
        </div>
      </div>

      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey="candidateId"
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              if (!e.target.closest('.ant-dropdown-menu')) {
                setIndex(rowIndex)
                setShowComponent('candidateDetails');
              }
            },
          };
        }}
      />

      <AlertModal
        isModalOpen={deleteOpen}
        handleCancel={() => setDeleteOpen(false)}
        handleOk={deleteData}
        name={"Are you sure you want to delete this candidate?"}
        loading={loading}
      />

    </div>
  )
}

export default Candidate;
