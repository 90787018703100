import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Space,
  Table,
  Switch,
  Spin,
  message,
} from "antd";
import COLORS from "common/Colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import {
  getClientid,
  getAllDepartmentList,
  getLocations,
} from "redux/selectors";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setRoleList } from "redux/actions";
import { bindActionCreators } from "redux";


const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-tbody> tr> td {
    border: 1px solid #f0f0f0;
  }
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #F4F4F4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;



const Styleddiv = styled(Row)`
  .styled-table {
    width: 100%;
  }

  .table-container {
    width: 100%;
    height: 470px;
    overflow-x: auto;
  }
  table,th{
    border: 1px solid #E6E6E6;
  }

  th {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
  }
  /* thead {
    position: sticky;
    top: 0px;
    z-index: 1;
  } */

  td {
    border-top: 1px solid #f4f4f4;
    padding: 5px 10px;
    font-size: 16px;
    letter-spacing: 0.08px;
    overflow: hidden;
    white-space: nowrap;
  }

  .menu-container {
    width: 100%;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .list-type {
    list-style-type: disc;
  }
  .form-height {
    line-height: 4;
  }
  .title {
    color: black;
    background: #f4f4f4;
    border-radius: 10px;
  }
  ul {
    list-style-type: none;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-collapse-header {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
  }
  .ant-collapse-header {
    margin-top: 10px;
  }
  .ant-tabs-nav {
    background: #e6f5ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 5px;
    padding-left: 20px;
  }
  .styledbtn {
    background-color: #26a4ff;
    color: white;
    border: none;
    border-radius: 10px;
    position: fixed;
    z-index: 100;
    padding: 5px 10px;
  }
  .ant-switch-checked {
    background-color: #03C83E;
  }
`;

const Permission = ({
  ClientId,
  AllDepartmentList,
  saveRoleList,
  locationList,
}) => {

  const departmentcolumns = [
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: 'center',
      render: (department_id, record) => {
        const check = departmentId?.includes(department_id) ? true : false;
        return <Switch size="small" defaultChecked checked={check} onChange={(checked) => onFinish(department_id, checked)} />
      }
    },
  ]

  const locationcolumns = [
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: 'center',
      render: (location_id, record) => {
        const check = locationId?.includes(location_id) ? true : false;
        return <Switch size="small" defaultChecked checked={check} onChange={(checked) => updateLocation(location_id, checked)} />
      }
    },
  ]


  const { roleId } = useParams();
  const { roleName } = useParams();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [rolesList, setRolesList] = useState([]);
  const [roleValue, setRoleValue] = useState([]);
  const history = useHistory();
  const [loader, setLoader] = useState(false);



  const getRoleList = async () => {
    const { code, response } = await CALL_API(
      `owner-roles/${ClientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setRoleValue(response.reverse());
    }
  };

  const filterRoleList = roleValue?.filter((item) => item.roleId === roleId);

  const filterDepartment =
    filterRoleList.length > 0 ? filterRoleList[0].department : null;

  const filterLocation =
    filterRoleList.length > 0 ? filterRoleList[0].locationId : null;

  const department = filterDepartment
    ? AllDepartmentList?.filter((item) =>
      filterDepartment.includes(item.department_id)
    )
    : [];




  const location = filterLocation
    ? locationList?.filter((item) => filterLocation.includes(item.location_id))
    : [];

  const departmentName = department?.map((item) => item?.department_name);

  const departmentId = department?.map((item) => item?.department_id);


  const locationId = location?.map((item) => item?.location_id);

  const [open, setopen] = useState(false);
  const [openlocation, setopenlocation] = useState(false);
  const [showLoader, toggleLoader] = useState(false);


  const onFinish = async (department_id, checked) => {
    const newdepartmentId = checked
      ? [...departmentId, department_id]
      : departmentId.filter(item => item !== department_id);
    toggleLoader(true);
    const { code } = await CALL_API(
      `owner-roles/${ClientId}/${roleId}`,
      "patch",
      {
        rolename: filterRoleList[0].roleName,
        description: filterRoleList[0].description,
        department: newdepartmentId,
        location: locationId,
      }
    );
    if (STATUS_CODE.SUCCESS === code) {
      toggleLoader(false);
      fetchMasterData();
      getRoleList();
      setopen(false);
      setopenlocation(false);
    }
  };

  const updateLocation = async (location_id, checked) => {
    const newLocationId = checked
      ? [...locationId, location_id]
      : locationId.filter(item => item !== location_id);
    toggleLoader(true);
    const { code } = await CALL_API(
      `owner-roles/${ClientId}/${roleId}`,
      "patch",
      {
        rolename: filterRoleList[0].roleName,
        description: filterRoleList[0].description,
        department: departmentId,
        location: newLocationId,
      }
    );
    if (STATUS_CODE.SUCCESS === code) {
      toggleLoader(false);
      fetchMasterData();
      getRoleList();
      setopen(false);
      setopenlocation(false);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveRoleList(roleList);
        getRoleList();
      }
    }
  };

  const getRoles = async (item) => {
    try {
      const { response, code } = await CALL_API(
        `roles-and-permissions/${ClientId}`,
        "post",
        {
          role_id: roleId,
        }
      );
      if (code === STATUS_CODE.SUCCESS) {
        setLoader(false);
        setRolesList(response);
        form.resetFields([item.title]);
      }
    } catch (error) {
      console.log("Error in getRoles-->", error);
    }
  };

  const handleCheckboxChange = async (
    checked,
    id,
    access,
    key,
    record,
    name
  ) => {
    try {
      const updatedPermissions = {
        ...record,
        [key]: checked,
      };

      const updatedAccess =
        Object.values(updatedPermissions).some((value) => value) || false;

      const { code } = await CALL_API(
        `roles-and-permissions/${ClientId}`,
        "patch",
        {
          role_id: roleId,
          id: id,
          access: updatedAccess,
          permissions: updatedPermissions,
        }
      );

      if (code === STATUS_CODE.SUCCESS) {
        message.success("Permission Successfully Updated");
        getRoles(name);
        form.setFieldsValue({
          [name]: checked,
        });
      }
    } catch (error) {
      console.log("Error in handleCheckboxChange-->", error);
    }
  };

  const updateRoles = async (
    checked,
    id,
    access,
    key,
    record,
    name,
    submenuId,
    menupermission,
    items
  ) => {
    const submenuAccessList = items.submenu
      .map((submenuItem) => submenuItem.submenuaccess)
      .filter((val) => val === true);

    try {
      const updatedPermissions = {
        ...record,
        [key]: checked,
      };

      const updatedAccess =
        Object.values(updatedPermissions).some((value) => value) || false;

      const { code } = await CALL_API(
        `roles-and-permissions/${ClientId}`,
        "patch",
        {
          role_id: roleId,
          id: id,
          access: checked ? true : submenuAccessList.length < 2 ? false : true,
          submenuid: submenuId,
          permissions: menupermission,
          submenupermission: updatedPermissions,
          submenuaccess: updatedAccess,
        }
      );

      if (code === STATUS_CODE.SUCCESS) {
        message.success("Permission Successfully Updated");
        getRoles(name);
        form.setFieldsValue({
          [name]: checked,
        });
      }
    } catch (error) {
      console.log("Error in handleCheckboxChange-->", error);
    }
  };

  useEffect(() => {
    getRoles();
    getRoleList();
    if (rolesList.length == 0) {
      setLoader(true);
    }
  }, [roleId]);

  useEffect(() => {
    form.resetFields();
  }, [rolesList]);

  return (
    <Styleddiv>
      <button
        className="bg-transparent border-0"
        onClick={() => history.push(`/roles`)}
      >
        <b>Role</b>{" "}
        <ArrowForwardIosIcon className="px-1" style={{ fontSize: "20px" }} />
      </button>{" "}
      <b className="text-info pb-0 pt-1">{roleName}</b>
      <Col xs={24}>
        <Tabs defaultActiveKey="1" className="mt-3">
          <TabPane tab="Permission" key="1">
            <Spin spinning={loader}>
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th className="border-0">
                        Menu Access Level
                      </th>
                      <th className="border-0">Read</th>
                      <th colspan="3" className="text-center">
                        Write
                      </th>
                    </tr>
                    <tr>
                      <th className="border-0"></th>
                      <th className="border-0 border-bottom-1"></th>
                      <th>Create</th>
                      <th>Modify</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesList?.map((item) => (
                      <>
                        {item.submenu.length == 0 && (
                          <tr>
                            <td>{item.title}</td>
                            <td>
                              <Switch
                                size="small"
                                defaultChecked
                                checked={item.permission.view}
                                valuePropName="checked"
                                onChange={(checked) =>
                                  handleCheckboxChange(
                                    checked,
                                    item.id,
                                    item.access,
                                    "view",
                                    item.permission,
                                    `${item.title}`
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Switch
                                size="small"
                                defaultChecked
                                checked={item.permission.create}
                                valuePropName="checked"
                                onChange={(checked) =>
                                  handleCheckboxChange(
                                    checked,
                                    item.id,
                                    item.access,
                                    "create",
                                    item.permission,
                                    `${item.title}`
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Switch
                                size="small"
                                defaultChecked
                                checked={item.permission.edit}
                                valuePropName="checked"
                                onChange={(checked) =>
                                  handleCheckboxChange(
                                    checked,
                                    item.id,
                                    item.access,
                                    "edit",
                                    item.permission,
                                    `${item.title}`
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Switch
                                size="small"
                                defaultChecked
                                checked={item.permission.delete}
                                valuePropName="checked"
                                onChange={(checked) =>
                                  handleCheckboxChange(
                                    checked,
                                    item.id,
                                    item.access,
                                    "delete",
                                    item.permission,
                                    `${item.title}`
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )}

                        {item.submenu?.map((submenuItem) => (
                          <tr>
                            <td>{submenuItem.subtitle}</td>
                            <td>
                              <Switch
                                size="small"
                                defaultChecked
                                checked={
                                  submenuItem.submenupermission.view
                                }
                                valuePropName="checked"
                                onChange={(checked) =>
                                  updateRoles(
                                    checked,
                                    item.id,
                                    item.access,
                                    "view",
                                    submenuItem.submenupermission,
                                    `${submenuItem.submenutitle}`,
                                    submenuItem.submenuid,
                                    item.permission,
                                    item
                                  )
                                }
                              />
                            </td>
                            <td>
                              {" "}
                              <Switch
                                size="small"
                                defaultChecked
                                checked={
                                  submenuItem.submenupermission.create
                                }
                                valuePropName="checked"
                                onChange={(checked) =>
                                  updateRoles(
                                    checked,
                                    item.id,
                                    item.access,
                                    "create",
                                    submenuItem.submenupermission,
                                    `${submenuItem.submenutitle}`,
                                    submenuItem.submenuid,
                                    item.permission,
                                    item
                                  )
                                }
                              />
                            </td>
                            <td>
                              {" "}
                              <Switch
                                size="small"
                                defaultChecked
                                checked={
                                  submenuItem.submenupermission.edit
                                }
                                valuePropName="checked"
                                onChange={(checked) =>
                                  updateRoles(
                                    checked,
                                    item.id,
                                    item.access,
                                    "edit",
                                    submenuItem.submenupermission,
                                    `${submenuItem.submenutitle}`,
                                    submenuItem.submenuid,
                                    item.permission,
                                    item
                                  )
                                }
                              />
                            </td>
                            <td>
                              {" "}
                              <Switch
                                size="small"
                                defaultChecked
                                checked={
                                  submenuItem.submenupermission.delete
                                }
                                valuePropName="checked"
                                onChange={(checked) =>
                                  updateRoles(
                                    checked,
                                    item.id,
                                    item.access,
                                    "delete",
                                    submenuItem.submenupermission,
                                    `${submenuItem.submenutitle}`,
                                    submenuItem.submenuid,
                                    item.permission,
                                    item
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </Spin>
          </TabPane>

          <TabPane tab="Department" key="2">
            <>

              <StyledTable
                className="mb-5"
                dataSource={AllDepartmentList}
                columns={departmentcolumns}
                rowKey="department_id"
                pagination={false}

              />
            </>
          </TabPane>
          <TabPane tab="Location" key="3">
            <>
              <StyledTable
                className="mb-5"
                dataSource={locationList}
                columns={locationcolumns}
                rowKey="location_id"
                pagination={false}
                stripe={false}
              />
            </>
          </TabPane>
        </Tabs>
      </Col>
    </Styleddiv>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
